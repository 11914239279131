import React from 'react'
import styled from 'styled-components'

const HomeSVG = ({ ...props }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M34.0003 40H30.0003C28.8958 40 28.0003 39.1046 28.0003 38V29C28.0003 28.4477 27.5526 28 27.0003 28H21.0003C20.4481 28 20.0003 28.4477 20.0003 29V38C20.0003 39.1046 19.1049 40 18.0003 40H14.0003C12.8958 40 12.0003 39.1046 12.0003 38V26L9.41455 28.5858C8.63351 29.3668 7.36717 29.3668 6.58613 28.5858L6.37876 28.3784C5.61183 27.6115 5.59595 26.373 6.34297 25.5867L22.5503 8.52632C23.3388 7.69632 24.6618 7.69632 25.4503 8.52632L41.6577 25.5867C42.4047 26.373 42.3889 27.6115 41.6219 28.3784L41.4146 28.5858C40.6335 29.3668 39.3672 29.3668 38.5861 28.5858L36.0003 26V38C36.0003 39.1046 35.1049 40 34.0003 40Z'
      fill='#090E12'
    />
  </svg>
)

export default styled(HomeSVG)``
