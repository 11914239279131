import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Youtube = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M12.677 2H3.323C1.488 2 0 3.577 0 5.522v4.956C0 12.423 1.488 14 3.323 14h9.354C14.512 14 16 12.423 16 10.478V5.522C16 3.577 14.512 2 12.677 2zM10.43 8.241l-4.375 2.212c-.117.059-.252-.031-.252-.168V5.723c0-.139.138-.229.255-.166l4.375 2.35c.13.07.128.268-.003.334z"/></g>
    </StyledIcon>
  )

export default styled(Youtube)``
  