// @noSnapshot

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { reduce, uniqueId } from 'lodash'

function useClickOutside(onClickOutside = () => {}, refs = []) {
  useEffect(() => {
    function clickOutside(e) {
      const { target } = e
      const hasClickedInsideRefs = reduce(
        refs,
        (acc, { current }) => {
          if (!current) {
            return acc
          }

          return acc || current?.contains(target)
        },
        false,
      )

      if (!hasClickedInsideRefs) {
        onClickOutside && onClickOutside()
      }
    }

    document.addEventListener('mousedown', clickOutside)
    return () => {
      document.removeEventListener('mousedown', clickOutside)
    }
  }, [refs, onClickOutside])
}

const ReferencesContext = React.createContext(undefined)
const DropdownContext = React.createContext(undefined)

function useGenericContext(genericContext) {
  const context = useContext(genericContext)
  if (!context) {
    throw new Error(
      'Menu compound components cannot be rendered outside the Menu component',
    )
  }
  return context
}

function useReferences() {
  return useGenericContext(ReferencesContext)
}
function useDropdown() {
  return useGenericContext(DropdownContext)
}

function useRegisterComponent() {
  const setReferences = useReferences()
  const component = useRef(null)
  const { current } = useRef(uniqueId())

  useEffect(() => {
    setReferences((prevState) => ({ ...prevState, [current]: component }))
  }, [component])

  return component
}

function MenuProvider({ children, disabled, onOpen, onClose }) {
  const [isOpen, setIsOpen] = useState(false)
  const [refs, setElement] = useState([])

  // I use useCallback and useMemo to prevent a rerender on useClickOutside
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen])
  useClickOutside(handleClickOutside, refs)

  useEffect(() => {
    if (disabled) {
      setIsOpen(false)
    }
  }, [disabled])

  useEffect(() => {
    isOpen ? onOpen && onOpen() : onClose && onClose()
  }, [isOpen])

  return (
    <ReferencesContext.Provider value={setElement}>
      <DropdownContext.Provider value={{ disabled, isOpen, setIsOpen }}>
        {children}
      </DropdownContext.Provider>
    </ReferencesContext.Provider>
  )
}

export { MenuProvider, useDropdown, useRegisterComponent }
