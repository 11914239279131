import styled from 'styled-components'

const PaginationLeftPart = styled('div')`
  align-items: center;
  display: flex;
  margin-right: auto;
  order: 1;
`

export default PaginationLeftPart
