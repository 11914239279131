import React from 'react'

const LinkedInSVG = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="linkedin">
      <path 
        clipRule="evenodd" 
        d="M26.3333 18.3374V26.3334H21.9038V18.8729C21.9038 16.9988 21.2631 15.7198 19.6598 15.7198C18.4358 15.7198 17.7074 16.5818 17.387 17.4156C17.2702 17.7136 17.2401 18.1285 17.2401 18.5458V26.3334H12.8091C12.8091 26.3334 12.8687 13.6978 12.8091 12.3887H17.2396V14.3653C17.2307 14.3801 17.219 14.396 17.2105 14.4104H17.2396V14.3653C17.8283 13.4165 18.8796 12.0611 21.2326 12.0611C24.1479 12.0611 26.3333 14.0541 26.3333 18.3374ZM8.17401 5.66669C6.65816 5.66669 5.66667 6.70721 5.66667 8.07552C5.66667 9.41402 6.62948 10.4861 8.1152 10.4861H8.1448C9.69 10.4861 10.651 9.41422 10.651 8.07552C10.6219 6.70721 9.69 5.66669 8.17401 5.66669ZM5.92984 26.3334H10.3592V12.3887H5.92984V26.3334Z" 
        fill="#9EA9B0" />
        fillRule="evenodd" 
        id="Vector" 
    </g>
  </svg>
)

export default LinkedInSVG
