import React, { PureComponent } from 'react'
import styled from 'styled-components'
import DefaultAccordion from './DefaultAccordion'
import LinkAccordion from './LinkAccordion'

class Accordion extends PureComponent {
  static defaultProps = {
    type: 'default',
  }

  getAccordion = () => {
    const { type } = this.props
    const {
      className,
      content,
      'data-testid': dataTestId,
      disabled,
      iconSize,
      isLoading,
      label,
      locator,
      multi,
      noPadding,
      opened,
      reducedBorders,
      _ref,
      small,
      style,
    } = this.props

    switch (type) {
      case 'link':
        return (
          <LinkAccordion
            className={className}
            content={content}
            data-testid={dataTestId}
            disabled={disabled}
            iconSize={iconSize}
            isLoading={isLoading}
            label={label}
            locator={locator}
            opened={opened}
          />
        )
      case 'default':
      default:
        return (
          <DefaultAccordion
            _ref={_ref}
            className={className}
            content={content}
            locator={locator}
            multi={multi}
            noPadding={noPadding}
            reducedBorders={reducedBorders}
            small={small}
            style={style}
          />
        )
    }
  }

  render() {
    return this.getAccordion()
  }
}

export default styled(Accordion)``