// @noSnapshot
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { deprecated, resetCSS } from '../../../helpers'

class TypoWrapper extends React.Component{
  render() {
    const { className, style, children } = this.props
    return (
      <h1 className={className} style={style}>
        {children}
      </h1>
    )
  }
}

const Title = styled(TypoWrapper).attrs(resetCSS)`
  color: ${({ color, theme }) =>
    color ? theme.colors[color] || color : theme.components.typography.title.text};
  font-family: ${({ theme }) => theme.fonts.title.fontFamily};
  font-size: ${({ theme }) => theme.fonts.title.fontSize};
  font-weight: ${({ theme }) => theme.fonts.title.fontWeight};
  line-height: ${({ theme }) => theme.fonts.title.lineHeight};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
`

export default deprecated(Title, 'Title', 'Please use Header3 instead.')
