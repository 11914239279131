import styled, { css } from 'styled-components'
import { styleForDesktop, styleForMobile, styleForTablet } from '@utils'
import PropTypes from 'prop-types'

export const DivListCardsSection = styled.div`
  ${({ theme, spread = false }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.sizes.s4};

    ${styleForDesktop} {
      ${
        // in case the div should be the width of whole page
        !spread &&
        `
          max-width: 920px;
          min-width: 920px;
        `
      }
    }

    ${styleForTablet} {
      ${
        // in case the div should be the width of whole page
        !spread
          ? `max-width: 608px;`
          : `max-width: 704px;
          padding-left: 0;
      `
      }
    }

    ${styleForMobile} {
      max-width: 400px;
    }
  `}
`
DivListCardsSection.propTypes = {
  spread: PropTypes.bool,
}
