import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AlignBot = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M3 0h4v12H3V0zm6 4h4v8H9V4zM0 14h16v2H0v-2z"/></g>
    </StyledIcon>
  )

export default styled(AlignBot)``
  