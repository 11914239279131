import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Pencil = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M11.29.71a2.42 2.42 0 0 0-3.42 0L1 7.55a.6.6 0 0 0-.16.28L0 11.25a.6.6 0 0 0 .75.75l3.42-.86a.6.6 0 0 0 .28-.14l6.85-6.85a2.42 2.42 0 0 0 0-3.42zm-7 7.9l4.44-4.49.44.44-4.78 4.77zm-.89-.86l-.73-.14 4.78-4.77.44.44zm-1.5.92l1.19.24.24 1.19-1.9.47zM8.29 2l.43-.43a1.25 1.25 0 0 1 1.72 0 1.24 1.24 0 0 1 0 1.72l-.44.42z"/></g>
    </StyledIcon>
  )

export default styled(Pencil)``
  