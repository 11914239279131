import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Dribbble = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M7.986 0A7.984 7.984 0 0 0 0 7.987a7.984 7.984 0 0 0 7.986 7.985 7.984 7.984 0 0 0 7.986-7.985A7.984 7.984 0 0 0 7.986 0zm4.896 4.14a6.192 6.192 0 0 1 1.326 3.551 12.502 12.502 0 0 0-2.544-.283h-.011c-.639 0-1.237.061-1.807.157-.132-.335-.27-.658-.411-.968 1.254-.552 2.466-1.34 3.447-2.456zM7.986 1.757c1.468 0 2.82.515 3.884 1.37-.813.976-1.89 1.686-3.058 2.185-.818-1.584-1.609-2.714-2.15-3.407a6.16 6.16 0 0 1 1.324-.148zm-2.752.648c.43.514 1.3 1.636 2.223 3.391-1.88.56-3.775.693-4.921.693l-.096-.001a9.5 9.5 0 0 1-.499-.012 6.245 6.245 0 0 1 3.293-4.07zm-3.48 5.583c0-.026.002-.062.007-.093.174.012.405.014.679.014h.001c1.253-.007 3.439-.11 5.654-.812.12.264.24.54.359.826a9.928 9.928 0 0 0-3.509 2.064 10.81 10.81 0 0 0-1.717 2.008 6.187 6.187 0 0 1-1.473-4.007zm6.232 6.235a6.183 6.183 0 0 1-3.699-1.231c.22-.36.689-1.06 1.444-1.779A8.417 8.417 0 0 1 8.967 9.25a31.136 31.136 0 0 1 1.232 4.558 6.182 6.182 0 0 1-2.213.415zm3.526-1.108a32.081 32.081 0 0 0-1.152-4.19 8.79 8.79 0 0 1 1.278-.093h.026c.737 0 1.56.093 2.439.294a6.226 6.226 0 0 1-2.591 3.99z"/></g>
    </StyledIcon>
  )

export default styled(Dribbble)``
  