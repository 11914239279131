import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Letterspacing = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M10.01 14.665l1.133-1.22H4.857l1.132 1.22L4.75 16 1.5 12.5 4.75 9l1.24 1.335-1.133 1.22h6.286l-1.133-1.22L11.25 9l3.25 3.5-3.25 3.5-1.24-1.335zM15 8h-2V6h-2v2H9V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v7zm-4-4h2V2h-2v2zM3 6v2H1V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v7H5V6H3zm2-4H3v2h2V2z"/></g>
    </StyledIcon>
  )

export default styled(Letterspacing)``
  