import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Undo = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M9.003 1c-3.139 0-5.8 2.078-6.685 4.93L1.212 4.012 0 4.713l2.45 4.244 4.242-2.45-.7-1.213-2.427 1.401c.59-2.459 2.8-4.295 5.438-4.295A5.605 5.605 0 0 1 14.6 8c0 3.088-2.512 5.6-5.598 5.6V15C12.86 15 16 11.86 16 8s-3.139-7-6.997-7z"/></g>
    </StyledIcon>
  )

export default styled(Undo)``
  