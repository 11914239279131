import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const CheckCircleOutline = props => (
    <StyledIcon viewBox="0 0 14 14" {...props}>
      <g>
        <path d="m7.18737,13.07469c-3.47197,0 -6.29687,-2.81993 -6.29687,-6.2875c0,-3.4668 2.8249,-6.2875 6.29687,-6.2875s6.29687,2.8208 6.29687,6.2875c0,3.46757 -2.8249,6.2875 -6.29687,6.2875zm0,-11.31756a5.04301,5.0355 0 0 0 -5.0375,5.03006c0,2.77361 2.25984,5.03006 5.0375,5.03006c2.77766,0 5.0375,-2.25646 5.0375,-5.03006c0,-2.77361 -2.25984,-5.03006 -5.0375,-5.03006z"/>
        <path d="m6.37737,8.93068a0.84,0.84 0 0 1 -0.571,-0.22l-1.619,-1.503l1.144,-1.062l1.047,0.97l2.665,-2.472l1.144,1.063l-3.237,3.004a0.84,0.84 0 0 1 -0.572,0.22l-0.001,0z"/>
      </g>
    </StyledIcon>
  )

export default styled(CheckCircleOutline)``
