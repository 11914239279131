import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const MoveBlockDown = props => (
    <StyledIcon viewBox="0 0 10.58 12" {...props}>
      <g><path d="M4.7 4.7v5.05L3.35 8.4l-.83.83L5.29 12l2.77-2.77-.83-.83-1.35 1.35V4.7H4.7zm5.88-2.35V1.18A1.18 1.18 0 0 0 9.41 0H1.18A1.18 1.18 0 0 0 0 1.18v1.17a1.18 1.18 0 0 0 1.18 1.18h8.23a1.18 1.18 0 0 0 1.17-1.18zm-9.41 0V1.18h8.24v1.17z"/></g>
    </StyledIcon>
  )

export default styled(MoveBlockDown)``
  