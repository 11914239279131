import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Loader = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M3.774 12.26A5.957 5.957 0 0 0 8 14c3.309 0 6-2.691 6-6 0-3.308-2.691-6-6-6S2 4.691 2 8H0c0-4.412 3.588-8 8-8s8 3.59 8 8c0 4.412-3.588 8-8 8a7.944 7.944 0 0 1-5.657-2.343l1.431-1.397z"/></g>
    </StyledIcon>
  )

export default styled(Loader)``
  