import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Comment = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M5.333 8.778A.77.77 0 0 1 4.571 8a.77.77 0 0 1 .762-.778.77.77 0 0 1 .762.778.77.77 0 0 1-.762.778zm3.048 0A.77.77 0 0 1 7.619 8a.77.77 0 0 1 .762-.778.77.77 0 0 1 .761.778.77.77 0 0 1-.761.778zm3.047 0A.77.77 0 0 1 10.666 8a.77.77 0 0 1 .762-.778.77.77 0 0 1 .762.778.77.77 0 0 1-.762.778zM8.381 15H.762a.76.76 0 0 1-.698-.467.786.786 0 0 1 .14-.84l1.756-1.932A6.51 6.51 0 0 1 .763 8c0-3.86 3.417-7 7.618-7C12.582 1 16 4.14 16 8s-3.418 7-7.62 7zm-5.872-1.556H8.38c3.36 0 6.095-2.442 6.095-5.444 0-3.001-2.735-5.444-6.095-5.444S2.286 4.999 2.286 8c0 1.195.432 2.333 1.25 3.292a.79.79 0 0 1-.015 1.04l-1.012 1.112z"/></g>
    </StyledIcon>
  )

export default styled(Comment)``
  