import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Lock = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M7.895 12.632a1.263 1.263 0 1 0 0-2.527 1.263 1.263 0 0 0 0 2.527zm4.21-5.895H5.368V4.21a2.53 2.53 0 0 1 2.527-2.527 2.53 2.53 0 0 1 2.526 2.527v2.526h1.684V4.21A4.215 4.215 0 0 0 7.895 0a4.215 4.215 0 0 0-4.21 4.21v2.527C2.754 6.737 2 7.493 2 8.42v5.895C2 15.245 2.755 16 3.684 16h8.421c.93 0 1.684-.755 1.684-1.684V8.42c0-.929-.755-1.684-1.684-1.684zm-8.42 7.579V8.42h8.42l.001 5.895H3.684z"/></g>
    </StyledIcon>
  )

export default styled(Lock)``
  