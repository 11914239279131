import { css } from 'styled-components'

const LinkStyle = css`
  color: ${({ theme }) => theme.components.linkButton.text};
  text-decoration: none;
  cursor: pointer;
  position: relative;
  text-decoration: underline;
  color: ${({ theme }) => theme.components.linkButton.text};

  &:hover {
    color: ${({ theme, disabled }) => !disabled && theme.components.linkButton.textHover};
    text-decoration: underline;
  }

  &:active,
  &:focus {
    color: ${({ theme }) => theme.components.linkButton.textActive};
  }

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`

export default LinkStyle
