import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { isEmpty } from 'lodash'

import { useInfiniteScroll } from '../../../hooks/infiniteScroll'

import EmptyState from './EmptyState'
import InfiniteLoadingRows from './InfiniteLoadingRows'
import LoadingBody from './LoadingBody'

import { useIsInfinite, useIsLoading } from '../hooks'

const TBody = styled('tbody')`
  position: relative;
`

function Body({
  children,
  className,
  'data-testid': dataTestId,
  emptySrc,
  emptySubtitle,
  emptyTitle,
  onLoadMore,
}) {
  const isInfinite = useIsInfinite()
  const isLoading = useIsLoading()
  const isLoadingMore = useRef(false)
  const tbody = useRef(null)

  useEffect(() => {
    isLoadingMore.current = false
  }, [isLoading])

  if (isInfinite) {
    useInfiniteScroll(isLoading, isLoadingMore, tbody, onLoadMore)
  }

  return (
    <TBody className={className} data-testid={dataTestId} ref={tbody}>
      {isEmpty(children) && !isLoading ? (
        <EmptyState emptySrc={emptySrc} emptySubtitle={emptySubtitle} emptyTitle={emptyTitle} />
      ) : isLoading && !isInfinite ? (
        <LoadingBody />
      ) : (
        <>
          {children}
          {isLoading && isInfinite && <InfiniteLoadingRows />}
        </>
      )}
    </TBody>
  )
}

export const DocBody = Body
export default styled(Body)``
