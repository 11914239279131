import React from 'react'

const PressReleaseSVG = () => (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <title>PressRelease-Icon</title>
    <g
      id='Symbols-Buttons'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g id='PressRelease' fill='#EF5858'>
        <path
          d='M20,0 L20,17.5003143 C19.9960466,18.8226724 18.9675989,19.9037285 17.6642908,19.9917402 L17.5,19.9975152 L3.33,19.997 L3.33084081,20 L2.49751119,19.9975115 C1.16908472,19.9935445 0.0841496639,18.9560405 0.00465626842,17.644627 L0,17.4978258 L0,9.99875761 L2.5,9.99875761 L2.5,11.6652172 L1.66583333,11.6652172 L1.66666295,17.4953373 C1.66794154,17.9233949 1.9900008,18.2758983 2.40513903,18.3251743 L2.499,18.331 L2.60222046,18.3242689 C2.97950107,18.2761163 3.27837333,17.9774304 3.32654174,17.6019909 L3.33333333,17.4978258 L3.33333333,0 L20,0 Z M18.3333333,1.6664596 L5,1.6664596 L4.99999628,17.5003143 C4.99944525,17.6847951 4.97895468,17.8645794 4.94056599,18.0376262 L4.89628686,18.2083516 L4.85583333,18.3310593 L17.4975112,18.3310593 C17.889946,18.3298874 18.2188866,18.0592042 18.3089422,17.6959107 L18.3274483,17.5946674 L18.3333333,17.4978258 L18.3333333,1.6664596 Z M16.6666667,14.1649066 L16.6666667,15.8313662 L6.66666667,15.8313662 L6.66666667,14.1649066 L16.6666667,14.1649066 Z M16.6666667,10.8319874 L16.6666667,12.498447 L6.66666667,12.498447 L6.66666667,10.8319874 L16.6666667,10.8319874 Z M16.6666667,4.166149 L16.6666667,9.16552781 L6.66666667,9.16552781 L6.66666667,4.166149 L16.6666667,4.166149 Z M15,5.83260861 L8.33333333,5.83260861 L8.33333333,7.49906821 L15,7.49906821 L15,5.83260861 Z'
          id='News'
          fill='#000000'
          fillRule='nonzero'
        ></path>
      </g>
    </g>
  </svg>
)

export default PressReleaseSVG
