import React, { useEffect } from 'react'
import styled from 'styled-components'
import cx from 'classnames'

import { useIds, useIsSelectable, useTableContext } from '../hooks'
import CheckboxRadio from '../../CheckboxRadio'
import visuallyHidden from '../../VisuallyHidden'
import { CustomSkeleton } from '../../Skeleton'
import Cell from './Cell'

const SelectCheckbox = styled(CheckboxRadio)`
  label {
    ${visuallyHidden};
  }
`

function Row({
  children,
  className,
  'data-testid': dataTestId,
  id,
  selectItemLabel = 'Select item',
  showSkeleton = false,
  ...rest
}) {
  const isSelectable = useIsSelectable()
  const { name: tableName } = useTableContext()
  const [listIds, { select, unselect, register }] = useIds()

  useEffect(() => {
    register(id)
  }, [id])

  const toggle = () => {
    if (!listIds[id]) {
      select(id)
    } else {
      unselect(id)
    }
  }

  return (
    <tr
      className={cx(className, listIds[id] ? 'is-checked' : undefined)}
      data-testid={dataTestId}
      {...rest}
    >
      <>
        {isSelectable && (
          <Cell isCheckbox>
            {showSkeleton ? (
              <CustomSkeleton bR={showSkeleton} h="s4" w="s4" />
            ) : (
              <SelectCheckbox
                checked={listIds[id]}
                data-testid={`${dataTestId}-checkbox`}
                disabled={showSkeleton}
                id={`${tableName}-check-${id}`}
                label={selectItemLabel}
                onChange={toggle}
                type="checkbox"
              />
            )}
          </Cell>
        )}
        {children}
      </>
    </tr>
  )
}

export const DocRow = Row
export default styled(Row)``
