import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { ChevronRight } from 'component-library/icons'

import PaginationIcon from './PaginationIcon'
import { usePage } from '../contexts'

const PaginationNext = ({ onClick, ...rest }) => {
  const { currentPage, lastPage, goToNextPage } = usePage()

  const onGoToNextPage = () => {
    goToNextPage()
    onClick?.()
  }

  return currentPage < lastPage ? (
    <PaginationIcon icon={ChevronRight} onClick={onGoToNextPage} {...rest} />
  ) : null
}

PaginationNext.propTypes = {
  /**
   * The Button props.
   */
  '...rest': PropTypes.object,
  /**
   * Function called when the button-icon is clicked.
   */
  onClick: PropTypes.func,
}

export default styled(PaginationNext)``
