import { isInBrowser } from '../../../helpers'
/**
 * style: PropTypes.string
 * id: PropTypes.string.required unique identifier
 */
export default (style = ``, id) => {
  if (isInBrowser()) {
    const element = document.getElementById(id)
    if (!element) {
      const styleNode = document.createElement('style')
      styleNode.type = 'text/css'
      styleNode.id = id
      styleNode.appendChild(document.createTextNode(style))
      document.head.insertAdjacentElement('afterbegin', styleNode)
    }
  }
}
