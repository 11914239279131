import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ShieldCheck = props => (
    <StyledIcon viewBox="0 0 10.8 12" {...props}>
      <g><path d="M10.47.06a.6.6 0 0 0-.63.06l-2 1.53L5.76.12a.6.6 0 0 0-.76 0L3 1.65 1 .12A.6.6 0 0 0 0 .6V6c0 3.31 4.92 5.83 5.13 5.94a.6.6 0 0 0 .54 0c.21-.11 5.13-2.63 5.13-5.94V.6a.6.6 0 0 0-.33-.54zM5.4 10.72C4 10 1.2 8 1.2 6V1.8l1.44 1.08a.6.6 0 0 0 .72 0l2-1.53 2 1.53a.6.6 0 0 0 .72 0L9.6 1.8V6c0 2-2.82 4-4.2 4.72z"/><path d="M4.8 6.95L3.42 5.58l-.84.84L4.8 8.65l4.02-4.02-.84-.85L4.8 6.95z"/></g>
    </StyledIcon>
  )

export default styled(ShieldCheck)``
  