// @noSnapshot

import React from 'react'
import styled from 'styled-components'
import { MenuProvider, useDropdown, useRegisterComponent } from './hooks'
import CustomOptions from './components/Options/OptionsPanel'

import Button from './components/Header/Button'
import Caret from './components/Header/Caret'
import Header from './components/Header/Header'
import IconButton from './components/Header/IconButton'

import Options from '../Options'

const StyledMenu = styled.div.attrs(() => ({
  style: {
    // StickyPortal is fun
    position: 'relative',
  },
}))`
  display: inline-flex;
`

const Menu = ({ disabled, onOpen, onClose, ...rest }) => (
  <MenuProvider disabled={disabled} onClose={onClose} onOpen={onOpen}>
    <StyledMenu {...rest} />
  </MenuProvider>
)

Menu.Button = Button
Menu.Caret = Caret
Menu.Header = Header
Menu.IconButton = IconButton

Menu.Divider = Options.Divider
Menu.NoResultOption = Options.NoResultOption
Menu.Option = Options.Option
Menu.SubOptions = Options.SubOptions
Menu.OptionWithSubs = Options.OptionWithSubs
Menu.OptionsPanel = CustomOptions

Menu.useDropdown = useDropdown
Menu.useRegisterComponent = useRegisterComponent

export default Menu
