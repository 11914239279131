import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AddCross = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M12 5.33H6.67V0H5.33v5.33H0v1.34h5.33V12h1.34V6.67H12V5.33z"/></g>
    </StyledIcon>
  )

export default styled(AddCross)``
  