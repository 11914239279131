import React from 'react'
import styled from 'styled-components'

import { TableProvider, useIds } from '../hooks'
import Body from '../components/Body'
import Cell from '../components/Cell'
import FullWidth from '../components/FullWidth'
import Header from '../components/Header'
import HeaderCell from '../components/HeaderCell'
import Row from '../components/Row'

import DataTableControls from './components/DataTableControls'
import DataTablePagination from './components/DataTablePagination'
import Table from './components/Table'

function DataTable({
  children,
  isInfinite,
  isLoading = false,
  isSelectable = false,
  name,
  outerBorder = true,
}) {
  return (
    <TableProvider
      isInfinite={isInfinite}
      isLoading={isLoading}
      isSelectable={isSelectable}
      name={name}
      outerBorder={outerBorder}
    >
      {children}
    </TableProvider>
  )
}

DataTable.Body = Body
DataTable.Cell = Cell
DataTable.Controls = DataTableControls
DataTable.FullWidth = FullWidth
DataTable.Header = Header
DataTable.HeaderCell = HeaderCell
DataTable.Pagination = DataTablePagination
DataTable.Row = Row
DataTable.Table = Table
DataTable.useIds = useIds

const DocDataTable = DataTable
export { DocDataTable }
export default styled(DataTable)``
