import styled, { css } from 'styled-components'
import { map } from 'lodash'

import { fadeInDownAnim } from '@styles/Animations'
import { OnlyMobile } from '@styles/DisplayOnMedia'
import { Body2 } from '@typography'
import { styleForMobile, styleForTablet } from '@utils'

import { RightButton } from '../components'
import {
  ColumnLargeDropdown,
  MainMenuButton,
  SubMenuCard,
} from './SubPanelElement'

// Lateral menu on tablet and mobile
const StyledMenuPanel = styled.div`
  ${({ isLarge = false, tabletStep, theme }) => css`
    @keyframes fadeInRight {
      from {
        opacity: 0;
        transform: translate3d(-50%, 0, 0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
    animation: fadeInRight 0.25s;
    background-color: ${theme.colors.neutral0};
    display: flex;
    flex-direction: column;
    position: absolute;
    top: ${theme.sizes.menuScrolledHeight};
    width: 344px;

    ${styleForTablet} {
      height: ${isLarge
        ? `calc(100vh - ${theme.sizes.menuHeight});`
        : `calc(100vh - ${theme.sizes.menuScrolledHeight});`};
    }

    ${styleForMobile} {
      height: calc(100vh - ${theme.sizes.menuScrolledHeight});
    }

    li {
      list-style: none;
    }

    ${tabletStep === 1 &&
    css`
      ${StyledMenuPanelTabletStep1} {
        display: flex;
      }

      ${StyledMenuPanelTabletStep2} {
        display: none;
      }
    `}

    ${tabletStep === 2 &&
    css`
      ${StyledMenuPanelBackBtn} {
        display: flex;
      }
    `}
  `};
`

// First panel inside lateral menu
const StyledMenuPanelTabletStep1 = styled.ul`
  ${({ theme }) => css`
    display: none;
    flex-direction: column;
    margin: 0;
    padding: ${theme.sizes.s7} ${theme.sizes.s6};

    a {
      width: 100%;
    }

    ${styleForMobile} {
      height: 100%;

      & > ${OnlyMobile} {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        ${RightButton} {
          width: 100%;
        }
      }
    }
  `}
`

// Second panel inside lateral menu, correspond to the submenu of a dropdown
const StyledMenuPanelTabletStep2 = styled.div`
  ${({ isOpen = false, selectedPanel = 'subpanel-mainMenu-999', theme }) => css`
    border-top: solid ${theme.globals.borderWidth.bW1} ${theme.colors.neutral30};
    padding: ${theme.sizes.s7} ${theme.sizes.s6};

    .subpanel-${selectedPanel} {
      display: flex;

      ${map(
        Array(4),
        (_child, index) => css`
          & > ${ColumnLargeDropdown}:nth-child(${index + 1}),
          & > ${SubMenuCard}:nth-child(${index + 1}) {
            ${fadeInDownAnim(isOpen, 80 + 40 * (index + 1))}
          }
        `,
      )}
    }
  `}
`

// The back to first panel button
const StyledMenuPanelBackBtn = styled(MainMenuButton)`
  ${({ theme }) => css`
    display: none;
    margin: ${theme.sizes.s3} ${theme.sizes.s6};
    padding: ${theme.sizes.s3} ${theme.sizes.s4};

    ${Body2} {
      margin-left: ${theme.sizes.s3};
    }
  `}
`

export {
  StyledMenuPanel,
  StyledMenuPanelBackBtn,
  StyledMenuPanelTabletStep1,
  StyledMenuPanelTabletStep2,
}
