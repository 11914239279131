const BOX_MODES = {
  large: {
    small: false,
    imageSize: 'imageSize1',
    padding: 's7',
  },
  medium: {
    small: false,
    imageSize: 'imageSize2',
    padding: 's5',
  },
  small: {
    small: true,
    imageSize: 'imageSize2',
    padding: 's4',
  },
}

export default BOX_MODES
