import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const SignalAntenna = props => (
    <StyledIcon viewBox="0 0 12 10.25" {...props}>
      <g><path d="M6 3a1.2 1.2 0 0 0-.6 2.23v5h1.2v-5A1.2 1.2 0 0 0 6 3zM2.6.85L1.75 0a6 6 0 0 0 0 8.49l.85-.85a4.81 4.81 0 0 1 0-6.79zM10.24 0l-.85.85a4.8 4.8 0 0 1 0 6.79l.85.85a6 6 0 0 0 0-8.49z"/><path d="M3.45 1.7a3.6 3.6 0 0 0 0 5.09l.85-.85a2.4 2.4 0 0 1 0-3.39zm5.09 0l-.85.85a2.4 2.4 0 0 1 0 3.39l.85.85a3.6 3.6 0 0 0 0-5.09z"/></g>
    </StyledIcon>
  )

export default styled(SignalAntenna)``
  