import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ImageContent = props => (
    <StyledIcon  {...props}>
      <g><path d="M45 4.33H3a3 3 0 0 0-3 3v34a3 3 0 0 0 3 3h42a3 3 0 0 0 3-3v-34a3 3 0 0 0-3-3zm1 37a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-34a1 1 0 0 1 1-1h42a1 1 0 0 1 1 1z"/><path d="M14 14.33a4 4 0 1 0-4 4 4 4 0 0 0 4-4zm-6 0a2 2 0 1 1 2 2 2 2 0 0 1-2-2zm23.42 5.58a2.61 2.61 0 0 0-1.74-.66 2.64 2.64 0 0 0-1.68.6L16.87 29a.51.51 0 0 1-.31.11.47.47 0 0 1-.33-.14l-.89-.85a1.87 1.87 0 0 0-2.84.32l-4.62 7.22a1.75 1.75 0 0 0 1.55 2.67h30.74A1.79 1.79 0 0 0 42 36.58v-6.53a1.7 1.7 0 0 0-.59-1.29zM40 36.33H9.82l4.26-6.64.76.74a2.52 2.52 0 0 0 1.72.69 2.45 2.45 0 0 0 1.58-.57l8.08-6.64 3.06-2.52a.59.59 0 0 1 .4-.14.64.64 0 0 1 .42.16l9.9 8.77z"/></g>
    </StyledIcon>
  )

export default styled(ImageContent)``
  