import React, { useEffect, useMemo } from 'react'

import { forEach, noop } from 'lodash'

import PaginationToggle from './PaginationToggle'
import PaginationPageList from '../PaginationPageList'
import { useIds, useIsInfinite, useIsLoading, useSortedHeaders } from '../hooks'

import PaginationNav from './PaginationNav'

const itemPerPageDefault = [
  {
    id: 10,
    value: '10',
    label: '10',
    isSelected: true,
    isSelectedOnLoad: true,
  },
  {
    id: 20,
    value: '20',
    label: '20',
    isSelected: false,
    isSelectedOnLoad: false,
  },
  {
    id: 50,
    value: '50',
    label: '50',
    isSelected: false,
    isSelectedOnLoad: false,
  },
]

const defaultPagination = {
  currentPage: 1,
  itemCountPerPage: 10,
  lastPage: 1,
}

function Pagination({
  className,
  itemPerPage = itemPerPageDefault,
  onChangePagination,
  pagination = defaultPagination,
}) {
  const [listIds, { register, reset }] = useIds()
  const isLoading = useIsLoading()
  const isInfinite = useIsInfinite()
  const [, setSortedHeaders] = useSortedHeaders()
  const { total } = pagination

  useEffect(() => {
    if (isLoading) {
      reset()
    }
  }, [isLoading])

  const allItemsPerPage = useMemo(
    () =>
      itemPerPage.map(item => ({
        id: item.id,
        label: item.label,
        isSelectedOnLoad: item.id === pagination.itemCountPerPage,
        value: item.value,
      })),
    [pagination.itemCountPerPage],
  )

  const goTo = ({
    pageNumber,
    isLessItem = false,
    itemPerPageNumber = pagination.itemCountPerPage,
    shouldReset = true,
  }) => {
    onChangePagination(pageNumber, itemPerPageNumber)
    if (shouldReset || pageNumber !== pagination.currentPage) {
      reset()
    } else if (isLessItem) {
      const oldList = listIds
      reset()
      let iterator = 0
      forEach(oldList, (_, id) => {
        if (itemPerPageNumber && iterator < itemPerPageNumber) {
          register(id)
        }
        iterator++
      })
    }
    // @ts-expect-error We know this does not have the correct shape,
    // but apparently this works
    setSortedHeaders({})
  }

  const getPaginationNav = () =>
    total ? (
      <PaginationNav className={className} isLoading={isLoading}>
        <PaginationToggle
          disableDeselect
          onChange={(value) =>
            value && pagination.itemCountPerPage
              ? goTo({
                  pageNumber: 1,
                  isLessItem: value < pagination.itemCountPerPage,
                  itemPerPageNumber: value,
                  shouldReset: false,
                })
              : noop
          }
          options={allItemsPerPage}
        />

        <PaginationPageList
          goTo={goTo}
          onChangePagination={onChangePagination}
          pagination={{
            ...pagination,
            currentPage: pagination.currentPage
              ? pagination.currentPage
              : defaultPagination.currentPage,
            lastPage: pagination.lastPage ? pagination.lastPage : defaultPagination.lastPage,
          }}
        />
      </PaginationNav>
    ) : null

  return isInfinite ? null : getPaginationNav()
}

export default Pagination
