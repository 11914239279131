import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Stats = props => (
    <StyledIcon viewBox="0 0 24 24" {...props}>
      <g><path d="M23 10a1 1 0 011 1v13h2v2H2v-2h2V14a1 1 0 011-1h4a1 1 0 011 1v10h1v-7a1 1 0 011-1h4a1 1 0 011 1v7h1V11a1 1 0 011-1zM8 15H6v9h2v-9zm7 3h-2v6h2v-6zm7-6h-2v12h2V12zM21 2a2 2 0 010 4c-.178 0-.348-.03-.511-.074l-4.563 4.563c.043.164.074.332.074.51a2 2 0 01-3.999.01L7.999 8.723A1.976 1.976 0 017 9a2 2 0 010-4c1.102 0 1.994.89 1.999 1.991l4.002 2.286c.295-.17.634-.277.999-.277.178 0 .347.031.511.074l4.563-4.563a1.996 1.996 0 01-.074-.51A2 2 0 0121 2z"/></g>
    </StyledIcon>
  )

export default styled(Stats)``