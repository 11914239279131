import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const PencilRuler = props => (
    <StyledIcon viewBox="0 0 9.82 12" {...props}>
      <g><path d="M1.64 1.64A1.64 1.64 0 0 0 0 3.27v6.17l1.64 2.45 1.63-2.45V3.27a1.64 1.64 0 0 0-1.63-1.63zm.55 7.47l-.55.82-.55-.82v-4.2h1.09zm-1.1-5.29v-.55a.55.55 0 1 1 1.09 0v.55zM8.73 0H5.45a1.09 1.09 0 0 0-1.09 1.09v9.82A1.09 1.09 0 0 0 5.45 12h3.28a1.09 1.09 0 0 0 1.09-1.09V1.09A1.09 1.09 0 0 0 8.73 0zM5.45 10.91V9.27h1.64V8.18H5.45V6.55h1.64v-1.1H5.45V3.82h1.64V2.73H5.45V1.09h3.28v9.82z"/></g>
    </StyledIcon>
  )

export default styled(PencilRuler)``
  