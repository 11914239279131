import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ArrowBack = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M7.89 12.547L4.933 9.381H16V6.619H4.934l2.955-3.166L6.067 1.5 0 8l6.067 6.5z"/></g>
    </StyledIcon>
  )

export default styled(ArrowBack)``
  