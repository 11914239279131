import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ArrowNext = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g fillRule="evenodd">
        <path d="M9.94289082,2 L16,8 L9.94289082,14 L9.14285714,13.2075094 L13.794,8.6 L0,8.6 L0,7.4 L13.794,7.4 L9.14285714,2.79249059 L9.94289082,2 Z" />
      </g>
    </StyledIcon>
  )

export default styled(ArrowNext)``
