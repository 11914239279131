import React from 'react'

const PlaySVG = () => (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <title>Play</title>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Play'>
        <polygon
          fill='#131B20'
          transform='translate(14.000000, 12.000000) rotate(90.000000) translate(-14.000000, -12.000000) '
          points='14 2 26 22 2 22'
        ></polygon>
      </g>
    </g>
  </svg>
)

export default PlaySVG
