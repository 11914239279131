// @noSnapshot
import React from 'react'
import styled from 'styled-components'

import { map } from 'lodash'

import Div from '../../Div'
import Badge from '../styles/Badge'

import { useLanguage } from '../hooks'

const LanguagesDesktop = ({ className, handleChange }) => {
  const [selectedLanguage, languages] = useLanguage()

  return (
    <Div className={className}>
      {map(languages, language => (
        <Badge
          as="button"
          key={language}
          onClick={() => handleChange(language)}
          selected={selectedLanguage === language}
        >
          {language}
        </Badge>
      ))}
    </Div>
  )
}

export default styled(LanguagesDesktop)``
