import * as React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { get, noop } from 'lodash';

import { Check, CheckIndeterminate, CheckPlain } from 'component-library/icons';
import visuallyHidden from '../VisuallyHidden';

import ContainerIcon from './styles/ContainerIcon';
import Label from './styles/Label';
import LabelContainer from './styles/LabelContainer';
import SubLabel from './styles/SubLabel';
import Wrapper from './styles/Wrapper';

class CheckboxRadio extends React.Component {
  static defaultProps = {
    /** The data-testid */
    'data-testid': 'dataTestId-checkboxRadio-component',
    /** CheckboxRadio value. */
    value: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      checkedState: this.props.checked,
      isHovered: false,
    };
    this.inputRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.checked !== prevState.checkedState) {
      return {
        checkedState: nextProps.checked,
      };
    }

    return null;
  }

  componentDidMount() {
    const { id } = this.props;
    if (!id) {
      console.warn('Please set id prop to make CheckboxRadio accessible');
    }
  }

  componentDidUpdate() {
    const { checked } = this.props;
    if (checked === 'indeterminate') {
      this.inputRef.current?.setAttribute('indeterminate', 'true');
    }
  }

  onMouseOver = () => this.setState({ isHovered: true });

  onMouseOut = () => this.setState({ isHovered: false });

  handleCheck = (type, checkedState) => {
    if (type === 'radio') {
      return true;
    }

    return checkedState !== true;
  };

  handleClick = () => {
    const { disabled, onChange, onClick, readOnly, type } = this.props;
    const { checkedState } = this.state;

    if (!disabled && !readOnly) {
      onClick && onClick();

      this.inputRef.current?.removeAttribute('indeterminate');

      this.setState({ checkedState: this.handleCheck(type, checkedState) }, () => {
        onChange && onChange(checkedState === false);
      });
    }
  };

  renderLabelOrSubLabel = (value, isLabel, dataTestId) => {
    const { disabled, ellipsis, id, medium } = this.props;

    if (typeof value === 'string') {
      return isLabel ? (
        <Label
          as={!!id ? 'label' : undefined}
          data-testid={`${dataTestId}-label`}
          disabled={disabled}
          ellipsis={ellipsis}
          htmlFor={id}
          medium={medium}
        >
          {value}
        </Label>
      ) : (
        <SubLabel data-testid={`${dataTestId}-sublabel`} disabled={disabled} id={id}>
          {value}
        </SubLabel>
      );
    }
    return value;
  };

  render() {
    const {
      className,
      'data-testid': dataTestId,
      disabled,
      ellipsis,
      id,
      label,
      labelStyle,
      locator,
      name,
      readOnly,
      style,
      subLabel,
      theme,
      type,
      value,
    } = this.props;
    const { checkedState, isHovered } = this.state;

    const checkmarkColor = get(theme, 'components.checkbox.checkmarkColor');
    const CheckedIcon = type === 'checkbox' ? Check : CheckPlain;
    const IndeterminateIcon = type === 'checkbox' ? CheckIndeterminate : CheckPlain;

    return (
      <Wrapper
        className={className}
        disabled={disabled}
        ellipsis={ellipsis}
        onMouseOut={this.onMouseOut}
        onMouseOver={this.onMouseOver}
        style={style}
      >
        <input
          checked={checkedState === true}
          data-testid={dataTestId}
          id={id}
          name={name}
          onChange={this.handleClick}
          ref={this.inputRef}
          style={visuallyHidden}
          type={type}
          value={value}
        />
        <ContainerIcon
          checked={checkedState !== false}
          className={locator}
          data-testid={`${dataTestId}-icon`}
          disabled={disabled}
          hovered={isHovered}
          onClick={this.handleClick}
          readOnly={readOnly}
          type={type}
        >
          {checkedState === true && (
            <CheckedIcon
              color={checkmarkColor}
              data-testid={`${dataTestId}-icon-checked`}
              size="size3"
            />
          )}
          {checkedState === 'indeterminate' && (
            <IndeterminateIcon
              color={checkmarkColor}
              data-testid={`${dataTestId}-icon-indeterminate`}
              size="size3"
            />
          )}
        </ContainerIcon>
        <LabelContainer
          ellipsis={ellipsis}
          onClick={!id ? this.handleClick : noop}
          style={labelStyle}
        >
          {this.renderLabelOrSubLabel(label, true, dataTestId)}
          {this.renderLabelOrSubLabel(subLabel, false)}
        </LabelContainer>
      </Wrapper>
    );
  }
}

CheckboxRadio.propTypes = {
  /** Boolean to know if CheckboxRadio is checked, unchecked or 'indeterminate'. */
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['indeterminate'])]).isRequired,
  /** Global theme. */
  theme: PropTypes.object.isRequired,
  /** Input type (checkbox or radio). */
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,

  /** CheckboxRadio value. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Class names. */
  className: PropTypes.string,
  /** The data-testid */
  'data-testid': PropTypes.string,
  /** Indicates if CheckboxRadio is disabled. */
  disabled: PropTypes.bool,
  /** Display an ellipsis on the label */
  ellipsis: PropTypes.bool,
  /** CheckboxRadio identifier. */
  id: PropTypes.string,
  /** Text next to CheckboxRadio. */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Inline style of the wrapper of the label. */
  labelStyle: PropTypes.object,
  /** locator for QAs. */
  locator: PropTypes.string,
  /** Label is bold */
  medium: PropTypes.bool,
  /** CheckboxRadio name. */
  name: PropTypes.string,
  /** Method called when user checks CheckboxRadio. */
  onChange: PropTypes.func,
  /** Method called when user clicks on CheckboxRadio. */
  onClick: PropTypes.func,
  /** Indicates if CheckboxRadio is read only. */
  readOnly: PropTypes.bool,
  /** The inline style. */
  style: PropTypes.object,
  /** CheckboxRadio sublabel. */
  subLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default styled(withTheme(CheckboxRadio))``;
