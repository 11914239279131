import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Information = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M6 12a6 6 0 1 1 6-6 6 6 0 0 1-6 6zM6 1.2A4.8 4.8 0 1 0 10.8 6 4.81 4.81 0 0 0 6 1.2z"/><path d="M6.6 7.8V5.4a.6.6 0 0 0-.6-.6H4.8V6h.6v1.8H4.2V9h3.6V7.8z"/><circle cx="6" cy="3.6" r=".75"/></g>
    </StyledIcon>
  )

export default styled(Information)``
  