import styled from 'styled-components'
import { Caption } from '../../Typography'

/**
 * The notification text, for LabelledWrapper's children. For example, this can be an error notification.
 */
const NotifText = styled(Caption)`
  color: ${({ error, theme }) =>
    error
      ? theme.components.labelledWrapper.notification.textError
      : theme.components.labelledWrapper.notification.text};
`

export default NotifText
