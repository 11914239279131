import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const BrokenLink = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M8.4 1.2a1.7 1.7 0 0 0-2.4 0l-2 2 .8.8 2-2a.57.57 0 0 1 .8 0zM10 4.4a.57.57 0 0 1 0 .8l-2 2 .8.8 2-2a1.7 1.7 0 0 0 0-2.4zm-8.8 4l.8-.8a.57.57 0 0 1 0-.8l2-2-.8-.8-2 2a1.7 1.7 0 0 0 0 2.4zm6-.4l-2 2a.57.57 0 0 1-.8 0l-.8.8a1.7 1.7 0 0 0 2.4 0l2-2zm-.809-3.202L10.393.796l.8.8-4.003 4zm-5.6 5.6l4.002-4.002.8.799-4.003 4.002zM10.4 7.43H12v1.13h-1.6zM7.43 10.4h1.13V12H7.43zM3.43 0h1.13v1.6H3.43zM0 3.43h1.6v1.13H0z"/></g>
    </StyledIcon>
  )

export default styled(BrokenLink)``
  