import React from 'react'
import styled from 'styled-components'

const NewsSVG = ({ fill = '#FFFFFF', ...props }) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g id='Icon/02-Icons-24'>
      <mask
        id='mask0_535_555'
        style={{ 'mask-type': 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='20'
        height='20'
      >
        <rect id='Rectangle' width='20' height='20' fill='white' />
      </mask>
      <g mask='url(#mask0_535_555)'>
        <g id='Cursor'>
          <path
            d='M10.8333 10.8334V16.6667H9.16666V10.8334H10.8333Z'
            fill={fill}
          />
          <path
            d='M14.1667 10.8334H12.5V16.6667H14.1667V10.8334Z'
            fill={fill}
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M7.91327 0.0331859C6.35534 -0.219216 5 0.981104 5 2.49996V8.33329H4.16666C2.78559 8.33329 1.66666 9.45222 1.66666 10.8333V13.2458C1.66666 14.5702 2.11782 15.8554 2.94505 16.8896L5.43275 20H17.3816L18.787 10.865C19.0613 9.08015 17.8652 7.4009 16.0891 7.0784L10 5.97116V2.58913C10 1.41318 9.19452 0.241731 7.91327 0.0331859ZM6.66666 2.49996C6.66666 1.9939 7.11618 1.5926 7.64647 1.67836C8.00342 1.73667 8.33333 2.10524 8.33333 2.58913V7.36209L15.7909 8.71818C16.6779 8.87915 17.277 9.71832 17.1397 10.6117L15.9517 18.3333H6.23391L4.24661 15.8486C3.65551 15.1096 3.33333 14.1914 3.33333 13.2458V10.8333C3.33333 10.3727 3.70607 9.99996 4.16666 9.99996H5V12.5001H6.66666V2.49996Z'
            fill={fill}
          />
        </g>
      </g>
    </g>
  </svg>
)

export default styled(NewsSVG)``
