import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Folder = props => (
    <StyledIcon viewBox="0 0 16 14" {...props}>
      <g><path d="M14.4,2.4 L7.8,2.4 L6.48,0.64 C6.18,0.2392 5.7008,0 5.2,0 L1.6,0 C0.7176,0 0,0.7184 0,1.6 L0,12 C0,12.8824 0.7176,13.6 1.6,13.6 L14.4,13.6 C15.2824,13.6 16,12.8824 16,12 L16,4 C16,3.1184 15.2824,2.4 14.4,2.4 Z M1.6,12 L1.6,1.6 L5.2,1.6 L6.52,3.36 C6.82,3.7608 7.2992,4 7.8,4 L14.4,4 L14.4008,12 L1.6,12 Z"/></g>
    </StyledIcon>
  )

export default styled(Folder)``
