import styled from 'styled-components'

import Controls from '../../components/Controls'

const DataTableControls = styled(Controls)`
  ${({ theme }) => `
    padding: ${theme.sizes.s12} ${theme.sizes.s5};

    & > * {
      margin-right: ${theme.sizes.s5};
    }

    &.isScrolling {
      border-bottom: ${theme.globals.borderWidth.borderWidth1} solid ${theme.components.datatable.borderColor};
      box-shadow: none;
    }
  `};
`

export default DataTableControls
