import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Error } from 'component-library/icons'

/**
 * The notification icon, for LabelledWrapper's children. For example, this can be an error notification.
 */
const NotifIcon = styled(Error)`
  margin-right: ${({ theme }) => theme.sizes.s3};
  fill: ${({ error, theme }) =>
    error
      ? theme.components.labelledWrapper.notification.textError
      : theme.components.labelledWrapper.notification.text};
`

NotifIcon.propTypes = {
  error: PropTypes.bool,
}

export default NotifIcon
