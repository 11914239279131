import { forEach, isNil } from 'lodash'

const transformControls = (
  tableRefElement,
  controlsElement,
  controlsHeight,
  currentScroll,
) => {
  const isScrollElemDefined = !isNil(currentScroll)
  const isTableElDefined = !isNil(tableRefElement)
  const valueFromTop =
    isScrollElemDefined && isTableElDefined
      ? retrieveDistanceFromTopWhenScroll(tableRefElement, controlsHeight, currentScroll)
      : (tableRefElement?.getBoundingClientRect().top ?? 0) - controlsHeight
  const valueFromTopAbs = Math.abs(valueFromTop)
  let xCoordinate = 0
  if (isScrollElemDefined && isTableElDefined && shouldTranslateX(tableRefElement, currentScroll)) {
    xCoordinate = currentScroll.scrollLeft - tableRefElement.offsetLeft
  }

  if (valueFromTop < 0) {
    forEach(controlsElement, control => {
      control.classList.add('isScrolling')
      control.style.transform = `translate(${
        isScrollElemDefined && isTableElDefined && tableRefElement.offsetLeft <= xCoordinate
          ? xCoordinate
          : 0
      }px, ${valueFromTopAbs}px)`
    })
  } else {
    forEach(controlsElement, control => {
      control.classList.remove('isScrolling')
      control.style.transform = `translate(${
        isScrollElemDefined && isTableElDefined && tableRefElement.offsetLeft <= xCoordinate
          ? xCoordinate
          : 0
      }px, 0px)`
    })
  }

  return { valueFromTop, valueFromTopAbs }
}

const retrieveDistanceFromTopWhenScroll = (
  tableRefElement,
  controlsHeight,
  currentScroll,
) => tableRefElement.offsetTop - controlsHeight - currentScroll.scrollTop - currentScroll.offsetTop

const shouldTranslateX = (tableRefElement, currentScroll) =>
  tableRefElement.scrollWidth - currentScroll.offsetWidth + 2 * tableRefElement.offsetLeft >=
  currentScroll.scrollLeft

export { transformControls }
