import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Check = props => (
  <StyledIcon viewBox="0 0 16 16" {...props}>
    <g><path d="M12 7V9H4V7H12Z"/></g>
  </StyledIcon>
  )

export default styled(Check)``
  