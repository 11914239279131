import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const SpacerContent = props => (
    <StyledIcon  {...props}>
      <g><path d="M44.65 4.33H3.35A3.37 3.37 0 0 0 0 7.69V41a3.36 3.36 0 0 0 3.35 3.35h41.3A3.36 3.36 0 0 0 48 41V7.69a3.37 3.37 0 0 0-3.35-3.36zM46 41a1.37 1.37 0 0 1-1.35 1.35H3.35A1.37 1.37 0 0 1 2 41V7.69a1.37 1.37 0 0 1 1.35-1.36h41.3A1.37 1.37 0 0 1 46 7.69z"/><path d="M27.21 30.59H25.6V18.07h1.59a.79.79 0 0 0 .64-1.26l-3.19-4.16a.81.81 0 0 0-1.28 0l-3.19 4.16a.79.79 0 0 0 .64 1.26h1.59v12.52h-1.61a.77.77 0 0 0-.63 1.24L23.37 36a.79.79 0 0 0 1.26 0l3.21-4.19a.77.77 0 0 0-.63-1.22z"/></g>
    </StyledIcon>
  )

export default styled(SpacerContent)``
  