import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { StyledIcon } from '../../../../icons';
import Panel from '../../../Panel';
import AccordionText from './AccordionText';

const getActiveOpenStyle = (accordion) => css`
  background-color: ${accordion.backgroundPressed};
  ${StyledIcon} {
    fill: ${accordion.iconPressed};
  }
  ${AccordionText} {
    color: ${accordion.labelPressed};
  }
`;

const getLockedProperties = ({ locked, theme }) => {
  if (locked) {
    return css`
      cursor: default;
    `;
  }

  const { accordion } = theme.components;

  return css`
    cursor: pointer;

    &:hover:not(:active):not(.is-opened) {
      background-color: ${accordion.backgroundHover};
    }

    &:active:not(.is-opened) {
      ${getActiveOpenStyle(accordion)}
    }

    &.is-opened {
      ${getActiveOpenStyle(accordion)}
    }
  `;
};

const calculateOverriddenColor = ({ iconColor, locked, theme }) =>
  iconColor
    ? css`
        fill: ${theme.colors[iconColor] || iconColor};
      `
    : css`
        fill: ${locked ? theme.components.accordion.iconLocked : theme.components.accordion.icon};
      `;

const AccordionButton = styled(Panel)`
  user-select: none;
  background-color: ${({ theme }) => theme.components.accordion.background};
  padding: ${({ small, theme }) =>
    `${small ? theme.sizes.s12 : theme.sizes.s4} ${theme.sizes.s4} ${
      small ? theme.sizes.s12 : theme.sizes.s4
    } ${theme.sizes.s4} `};
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${getLockedProperties};
  border-bottom: ${({ theme, unExpandable }) =>
    unExpandable &&
    `${theme.globals.borderWidth.borderWidth1} solid ${theme.components.accordion.border}`};
  text-decoration: ${({ isLink }) => isLink && 'none'};

  ${StyledIcon} {
    ${calculateOverriddenColor};
  }
`;

AccordionButton.propTypes = {
  iconColor: PropTypes.string,
  locked: PropTypes.bool,
};

export default AccordionButton;

