const resetCSS = ({ className, theme }) => {
  const { global, nav } = theme.globals.cssClearer
  return {
    className: `${(global && 'c-g ') || ''}${(nav && 'c-n ') || ''}${
      className || ''
    }`,
  }
}

export default resetCSS
