import React from 'react'

const FacebookSVG = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="facebook">
      <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M21.5481 5.33775L18.6699 5.33331C15.4362 5.33331 13.3465 7.39401 13.3465 10.5835V13.0042H10.4526C10.2025 13.0042 10 13.199 10 13.4394V16.9467C10 17.187 10.2027 17.3817 10.4526 17.3817H13.3465V26.2316C13.3465 26.472 13.549 26.6666 13.7991 26.6666H17.5749C17.825 26.6666 18.0275 26.4718 18.0275 26.2316V17.3817H21.4112C21.6613 17.3817 21.8638 17.187 21.8638 16.9467L21.8652 13.4394C21.8652 13.324 21.8174 13.2134 21.7326 13.1318C21.6479 13.0501 21.5324 13.0042 21.4123 13.0042H18.0275V10.9521C18.0275 9.96582 18.272 9.46513 19.6087 9.46513L21.5477 9.46447C21.7975 9.46447 22 9.2696 22 9.02947V5.77275C22 5.53284 21.7977 5.3382 21.5481 5.33775Z" fill="#9EA9B0" />
    </g>
  </svg>
)

export default FacebookSVG
