import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Warning = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M11.4 12H.6a.6.6 0 0 1-.54-.87L5.46.31a.62.62 0 0 1 1.07 0l5.4 10.82a.6.6 0 0 1-.54.87zm-9.83-1.2h8.86L6 1.92z"/><path d="M5.4 4.78h1.2v3.61H5.4z"/><circle cx="6" cy="9.59" r=".75"/></g>
    </StyledIcon>
  )

export default styled(Warning)``
  