import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const MultipleFiles1 = props => (
    <StyledIcon viewBox="0 0 11 12" {...props}>
      <g><path d="M4 0H1a1 1 0 0 0-1 1v3.5a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM1 4.5V1h2v1h1v2.5zM10 0H7a1 1 0 0 0-1 1v3.5a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zM7 4.5V1h2v1h1v2.5zm-3 2H1a1 1 0 0 0-1 1V11a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7.5a1 1 0 0 0-1-1zM1 11V7.5h2v1h1V11zm9-4.5H7a1 1 0 0 0-1 1V11a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7.5a1 1 0 0 0-1-1zM7 11V7.5h2v1h1V11z"/></g>
    </StyledIcon>
  )

export default styled(MultipleFiles1)``
  