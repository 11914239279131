import styled from 'styled-components';
import { Body2 } from '../../Typography';

/**
 * Adjust the border color accordingly if it's focused and not readonly.
 */
const borderColor = (
  colors,
  error,
  isFocus,
  isLoading,
  readOnly
) => {
  if (error) {
    return colors.borderError;
  }
  if (isFocus && !readOnly && !isLoading) {
    return colors && colors.borderFocus;
  }

  return colors && colors.border;
};

/**
 * Adjust the border color in hover mode accordingly if it's disabled focused and readonly.
 */
const borderColorHover = (
  colors,
  disabled,
  error,
  isFocus,
  isLoading,
  readOnly
) => {
  if (error) {
    return colors && colors.borderError;
  }
  if (isFocus && !readOnly && !isLoading) {
    return colors && colors.borderFocus;
  }
  if (disabled || readOnly || isLoading) {
    return colors && colors.border;
  }

  return colors && colors.borderHover;
};

const Container = styled(Body2.withComponent('div'))`
  &:before {
    pointer-events: none;
    content: ' ';
    position: absolute;
    border-radius: ${({ theme }) => theme.globals.borderRadius.borderRadius1};
    border: ${({ theme, inline }) =>
      inline
        ? 'none'
        : `${theme.globals.borderWidth.borderWidth1} solid
      ${theme.components.select.border}`};
    border-color: ${({ error, isFocus, isLoading, readOnly, theme }) =>
      borderColor(theme.components.select, error, isFocus, isLoading, readOnly)};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  display: flex;
  align-items: center;
  position: relative;
  background-color: ${({ isFocus, theme }) =>
    isFocus ? theme.components.select.backgroundFocus : theme.components.select.background};
  color: ${({ theme }) => theme.components.select.text};
  fill: ${({ theme }) => theme.components.select.text};
  width: ${({ inline }) => (inline ? 'fit-content' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled, theme }) =>
    disabled ? theme.globals.opacity.o1 : theme.globals.opacity.o3};
  min-height: ${({ small, theme }) => (small ? '28px' : theme.sizes.s18)};

  &:hover {
    &:before {
      border-color: ${({ disabled, error, isFocus, isLoading, readOnly, theme }) =>
        borderColorHover(theme.components.select, disabled, error, isFocus, isLoading, readOnly)};
    }
    fill: ${({ theme }) => theme.components.select.selectedText};
    background-color: ${({ isFocus, theme }) =>
      isFocus ? theme.components.select.backgroundFocus : theme.components.select.backgroundHover};
  }
`;

export default Container;
