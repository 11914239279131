import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const MoreActions = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8 4a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"/></g>
    </StyledIcon>
  )

export default styled(MoreActions)``
  