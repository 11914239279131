// @noSnapshot
import React from 'react'
import styled from 'styled-components'

import Div from '../../Div'
import Select from '../../Select'

import { useLanguage } from '../hooks'

const LanguagesMobile = ({ className, handleChange }) => {
  const [selectedLanguage, languages] = useLanguage()

  const getOptions = () =>
    languages.map(language => ({
      id: language,
      isSelected: selectedLanguage === language,
      label: language,
    }))

  return (
    <Div className={className} di="ib">
      <Select
        isPanelWidthCustom
        noResultText=""
        onSelect={language => handleChange(language)}
        options={getOptions()}
        small
      />
    </Div>
  )
}

export default styled(LanguagesMobile)``
