import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AlignTextRight = props => (
    <StyledIcon viewBox="0 0 12 10.8" {...props}>
      <g><path d="M1.2 0H12v1.2H1.2zm2.4 2.4H12v1.2H3.6zM0 4.8h12V6H0zm3.6 2.4H12v1.2H3.6zM1.2 9.6H12v1.2H1.2z"/></g>
    </StyledIcon>
  )

export default styled(AlignTextRight)``
  