import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const TextColor = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M10.692 12.703H5.297L4.272 16H1L6.559 0H9.41L15 16h-3.272l-1.036-3.297zm-4.564-2.67h3.734L7.985 4.044l-1.857 5.989z"/></g>
    </StyledIcon>
  )

export default styled(TextColor)``
  