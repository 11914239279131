import * as React from 'react'
import PropTypes from 'prop-types'
import { range, reduce, split } from 'lodash'
import { Container, LineNumbers, TextAreaStyled } from '../styles'

class TextAreaContainer extends React.Component {
  _container = null

  _textArea = null

  _textAreaLines = null

  state = {
    height: this.props.minHeight || 0,
    isFocus: false,
    lines: '',
    value: this.props.value || '',
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value } = nextProps

    if (value !== prevState.value) {
      return {
        value,
      }
    }

    return null
  }

  componentDidMount() {
    const { defaultValue, lineNumbers } = this.props

    // Add an event listener to listen about clicks outside the container.
    // Handle focus
    document.addEventListener('mousedown', this.handleClickOutside)
    if (this._textArea) {
      this._textArea.addEventListener('scroll', this.handleTextAreaDetectedScroll)
    }

    // Potentially resize the text area as it may already contains some text.
    this.resize()

    // Show line numbers
    lineNumbers && this.showLineNumbers(defaultValue || '')
  }

  componentDidUpdate(_, prevState) {
    const { onResize } = this.props
    const { height } = this.state

    if (prevState.height !== height) {
      onResize && onResize()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    if (this._textArea) {
      this._textArea.removeEventListener('scroll', this.handleTextAreaDetectedScroll)
    }
  }

  onContainerClick = (e) => {
    e.stopPropagation()

    if (!this.props.disabled && !this.state.isFocus) {
      this.handleFocus()
      this._textArea.focus()
    }
  }

  onKeyDown = (e) => {
    const { onKeyDown } = this.props

    // Resize on each key.
    this.resize()

    onKeyDown && onKeyDown(e)
  }

  onKeyUp = (e) => {
    const { onKeyUp } = this.props

    // Resize on each key.
    this.resize()

    onKeyUp && onKeyUp(e)
  }

  handleBlur = (e) => {
    const { onBlur } = this.props

    this.setState({
      isFocus: false,
    })

    onBlur && onBlur(e)
  }

  handleChange = (e) => {
    const { lineNumbers, onChange } = this.props
    const { value } = e.currentTarget

    lineNumbers && this.showLineNumbers(value)

    onChange && onChange(value)
  }

  handleClickOutside = (e) => {
    if (this.state.isFocus && this._container && !this._container.contains(e.target)) {
      this.handleBlur(e)
    }
  }

  handleFocus = () => {
    const { onFocus } = this.props
    this.setState({
      isFocus: true,
    })
    onFocus && onFocus()
  }

  handleTextAreaDetectedScroll = () => {
    const { height } = this.state
    const { autoResize, maxHeight = Infinity } = this.props

    if (autoResize && height < maxHeight) {
      this._textArea.scrollTop = 0
    }

    const textAreaScroll = this._textArea ? this._textArea.scrollTop : 0
    if (this._textAreaLines) {
      this._textAreaLines.scrollTop = textAreaScroll
    }
  }

  setContainerRef = (ref) => {
    this._container = ref
  }

  setTextAreaRef = (ref) => {
    this._textArea = ref
    this.props.textAreaRef && this.props.textAreaRef(ref)
  }

  setTextAreaLinesRef = (ref) => {
    this._textAreaLines = ref
  }

  /**
   * Text area prop autocomplete should be 'on' | 'off' string,
   * this method takes a boolean and return 'on' or 'off'
   */
  isAutocomplete = () => {
    const { autoComplete } = this.props

    return autoComplete ? 'on' : 'off'
  }

  showLineNumbers = (text) => {
    // range from 1
    const nbLines = range(1, split(text, /\r|\r\n|\n/).length + 1)

    this.setState({
      lines: reduce(nbLines, (acc, n) => `${acc} <br> ${n}`),
    })
  }

  resize = () => {
    const { autoResize } = this.props

    if (autoResize && this._textArea) {
      // If autoResize is on, recalculate the height from the scrollHeight. To do that we need first to
      // remove the height.
      this._textArea.style.height = ''
      const height = this._textArea.scrollHeight
      this._textArea.style.height = `${height}px`

      this.setState({
        height,
      })
    }
  }

  render() {
    const {
      autoFocus,
      autoResize,
      canExceedMaxLength,
      'data-testid': dataTestId,
      defaultValue,
      disabled,
      error,
      id,
      lineNumbers,
      locator,
      maxHeight = Infinity,
      maxLength,
      minHeight,
      name,
      onInput,
      placeholder,
      readOnly,
      required,
      type,
      value,
    } = this.props

    const { height, isFocus, lines } = this.state

    return (
      <Container
        disabled={disabled}
        error={error}
        isFocus={isFocus}
        lineNumbers={lineNumbers}
        onClick={this.onContainerClick}
        readOnly={readOnly}
        ref={this.setContainerRef()}
      >
        {lineNumbers && (
          <LineNumbers
            dangerouslySetInnerHTML={{ __html: lines }}
            maxH={maxHeight ? `${maxHeight}px` : autoResize ? null : '96px'}
            ref={this.setTextAreaLinesRef}
          />
        )}
        <TextAreaStyled
          autoComplete={this.isAutocomplete()}
          autoFocus={autoFocus}
          className={locator}
          data-testid={dataTestId}
          defaultValue={defaultValue}
          disabled={disabled}
          id={id}
          lineNumbers={lineNumbers}
          maxH={maxHeight ? `${maxHeight}px` : null}
          maxLength={canExceedMaxLength ? null : maxLength}
          minH={autoResize && minHeight ? `${minHeight}px` : '96px'}
          name={name || id}
          noOverflow={
            lineNumbers ? false : (autoResize && !maxHeight) || (autoResize && height < maxHeight)
          }
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onInput={onInput}
          onKeyDown={this.onKeyDown}
          onKeyUp={this.onKeyUp}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={this.setTextAreaRef}
          required={required}
          rows={1}
          type={type}
          value={readOnly ? value : undefined}
        />
      </Container>
    )
  }
}

TextAreaContainer.defaultProps = {
  autoComplete: false,
  autoFocus: false,
  disabled: false,
  lineNumbers: false,
  minHeight: 36,
  readOnly: false,
  required: false,
  type: 'text',
}

TextAreaContainer.propTypes = {
  /** Specifies whether element should have auto-complete enabled. */
  autoComplete: PropTypes.bool,
  /** Specifies that element should automatically get focus when the page loads. */
  autoFocus: PropTypes.bool,
  /** Indicate that the text area will automatically resize itself when the text is longer than one line. */
  autoResize: PropTypes.bool,
  /**
   * Indicate if we have no limit to the maximum number of characters. maxLength is, in that case,
   * just an indicator.
   */
  canExceedMaxLength: PropTypes.bool,
  /** Data-testid */
  'data-testid': PropTypes.string,
  /** Specifies default value of element. */
  defaultValue: PropTypes.string,
  /** Specifies that element should be disabled. */
  disabled: PropTypes.bool,
  /** Indicates if the text area is in error. */
  error: PropTypes.bool,
  /** Specifies id of element. */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Add a column with line numbers on the left. */
  lineNumbers: PropTypes.bool,
  /** Locators for QAs. */
  locator: PropTypes.string,
  /** The max height of the text area, in pixels, when autoResize prop is on. */
  maxHeight: PropTypes.number,
  /**
   * The maximum number of characters for the indicator. If it is undefined, then no indicator
   * will be displayed.
   */
  maxLength: PropTypes.number,
  /** The min height of the text area, in pixels, when autoResize prop is on. */
  minHeight: PropTypes.number,
  /** Specifies the name of an element. */
  name: PropTypes.string,
  /** Specifies method on blur of element. */
  onBlur: PropTypes.func,
  /** Specifies method on change of element. */
  onChange: PropTypes.func,
  /** Specifies method on focus of element. */
  onFocus: PropTypes.func,
  /** The onInput event occurs when an element gets user text area. */
  onInput: PropTypes.func,
  /** The onKeyUp event occurs when user press a key. */
  onKeyDown: PropTypes.func,
  /** The onKeyUp event occurs when user release a key. */
  onKeyUp: PropTypes.func,
  /** Called when the text area changes its size in autoResize mode. */
  onResize: PropTypes.func,
  /** Specifies placeholder of text area. */
  placeholder: PropTypes.string,
  /** Specifies text area field is read-only. */
  readOnly: PropTypes.bool,
  /** Specifies text area is required. */
  required: PropTypes.bool,
  /** The text area forwarded ref. */
  textAreaRef: PropTypes.func,
  /** Specifies type of text area (string, password etc). */
  type: PropTypes.string,
  /** The value of the text area. */
  value: PropTypes.string,
}

export default React.forwardRef((props, ref) => <TextAreaContainer textAreaRef={ref} {...props} />)
