import styled from 'styled-components'

import Pagination from '../../components/Pagination'

const DataTablePagination = styled(Pagination)`
  ${({ theme }) => `
    padding: ${theme.sizes.s12} ${theme.sizes.s5};
    border: ${theme.globals.borderWidth.borderWidth1} solid ${theme.components.datatable.borderColor};
  `};
  border-top-color: transparent;
`

export default DataTablePagination
