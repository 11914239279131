import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'

import { filter, isNil, join } from 'lodash'
import Copy from '../../Copy'
import BaseInput from '../styles/BaseInput'
import BaseLoader from '../styles/BaseLoader'
import BaseSide from '../styles/BaseSide'
import BaseWrapper from '../styles/BaseWrapper'

import { ERROR_MSG_NS, INFO_MSG_NS, KEY_ENTER, KEY_TAB, SIDE, SIZES } from '../config'


import {
  useCounter,
  useFocus,
  useInfoStatus,
  useInputContext,
  useInputId,
  useLocalValue,
} from '../hooks'

const Base = ({
  'aria-describedby': ariaDescribedBy,
  'data-testid': dataTestId,
  defaultValue,
  forwardedRef,
  id,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  onPressEnter,
  placeholder,
  left,
  right,
  tooltipCopied,
  tooltipCopy,
  type = 'text',
  value,
  withCopy = false,
  ...rest
}) => {
  const [inputCounter] = useCounter()
  const [isFocus, setFocus] = useFocus()
  const { inputId, setInputId } = useInputId()
  const { localValue, setLocalValue } =
    typeof defaultValue === 'number'
      ? useLocalValue(defaultValue.toString())
      : useLocalValue(defaultValue)
  const { hasInfo, isError } = useInfoStatus()

  const {
    autoFocus,
    cantTypeWhenLoading,
    disabled,
    isLoading,
    maxLength,
    readOnly,
    required,
    size,
  } = useInputContext()

  const baseRef = useRef(null)

  if (!isNil(value) && !onChange) {
    throw new Error(
      `You provided the Input.Base component with a non-nil value "${value}" but no 'onChange' prop function was passed to it. Use 'defaultValue' prop instead.`,
    )
  }

  useEffect(() => {
    if (autoFocus) {
      baseRef && baseRef.current?.focus()
    }
  }, [autoFocus])

  useEffect(() => {
    typeof id === 'number' ? setInputId(id.toString()) : setInputId(id)
  }, [id])

  useEffect(() => {
    typeof value === 'number' ? setInputId(value.toString()) : setLocalValue(value)
  }, [value])

  useImperativeHandle(forwardedRef, () => baseRef.current, [baseRef])

  const handleBlur = (
    event,
  ) => {
    setFocus(false)

    onBlur?.(event)
  }

  // I'm *guessing* BaseInput uses the 'as' prop to create an input element. Hence the HTMLInputElement
  // type chosen for handleChange, handleFocus and handleKeyDown
  const handleChange = (event) => {
    // If we use a paragraph, ts correctly indicates an error here because BaseInput is derived from Body2,
    //  a styled paragraph. An input has `currentTarget.value`, para does not.
    const newValue = event.currentTarget.value

    isNil(value) && setLocalValue(newValue)
    onChange?.(newValue)
  }

  const handleFocus = (event) => {
    setFocus(true)

    onFocus?.(event)
  }

  const handleKeyDown = (event) => {
    switch (event.keyCode) {
      case KEY_ENTER:
        onPressEnter?.(event)
        break
      case KEY_TAB:
        handleBlur(event)
        break
    }

    onKeyDown?.(event)
  }

  const shouldApplyMaxLength = () => {
    if (maxLength && maxLength <= 0) {
      throw new Error('You must set a value greater than 0 for "maxLength"')
    }

    /**
     * 1. We don't apply maxLength if there's a value for inputCounter.current,
     * because it would be inconsistent with the "fake" counting.
     * 2. maxLength is overriden by the counter's "max"
     */
    return (
      !!maxLength &&
      (typeof inputCounter === 'boolean' ||
        (!inputCounter?.current && (!inputCounter?.max || inputCounter.max === maxLength)))
    )
  }

  const getDescribedBy = () => {
    const ids = {
      errorId: isError && `${inputId}-${ERROR_MSG_NS}`,
      infoId: hasInfo && `${inputId}-${INFO_MSG_NS}`,
    }

    let idsAsAString = join(
      filter(ids, id => !!id),
      ' ',
    )
    if (!!ariaDescribedBy) {
      idsAsAString += ` ${ariaDescribedBy}`
    }
    return idsAsAString !== '' ? idsAsAString : undefined
  }

  const getLeftPart = () =>
    !!left && (
      <BaseSide side={SIDE.LEFT} size={size}>
        {left}
      </BaseSide>
    )

  const getRightPart = () => {
    if (withCopy) {
      return (
        <Copy
          codeRef={baseRef}
          mr={size === SIZES.SMALL ? 's2' : 's3'}
          tooltipMessageCopied={tooltipCopied}
          tooltipMessageCopy={tooltipCopy}
        />
      )
    }
    if (!!right) {
      return (
        <BaseSide side={SIDE.RIGHT} size={size}>
          {right}
        </BaseSide>
      )
    }
  }

  const finalValue = isNil(value) ? localValue : value

  return (
    <BaseWrapper
      cantTypeWhenLoading={cantTypeWhenLoading}
      disabled={disabled}
      error={isError}
      isFocus={isFocus}
      isLoading={isLoading}
      readOnly={readOnly}
    >
      {getLeftPart()}
      <BaseInput
        {...rest}
        aria-describedby={getDescribedBy()}
        as="input"
        cantTypeWhenLoading={cantTypeWhenLoading}
        data-testid={dataTestId}
        disabled={disabled || (isLoading && cantTypeWhenLoading)}
        id={inputId}
        isLoading={isLoading}
        maxLength={shouldApplyMaxLength() ? maxLength : undefined}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        readOnly={readOnly}
        ref={baseRef}
        required={required}
        // @ts-expect-error: Inputs have an intrinsic 'size' attribute which this is
        // overriding, by accepting string values :-(
        size={size}
        type={type}
        value={finalValue}
      />
      {isLoading ? <BaseLoader size="s4" /> : getRightPart()}
    </BaseWrapper>
  )
}


export default styled(
  forwardRef((props, ref) => <Base forwardedRef={ref} {...props} />),
)``
