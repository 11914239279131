import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Transfer = props => (
    <StyledIcon viewBox="0 0 12 11.1" {...props}>
      <g><path d="M8.1 5.1L9 6l3-3-3-3-.9.9 1.46 1.46H2.18v1.28h7.38L8.1 5.1zM3.9 6L3 5.1l-3 3 3 3 .9-.9-1.46-1.46h7.38V7.46H2.44L3.9 6z"/></g>
    </StyledIcon>
  )

export default styled(Transfer)``
  