import React from 'react'
import styled from 'styled-components'
import { resetCSS } from '../../../helpers'
import Block from '../../Block'

/**
 * A Panel is just a block with a height, a width and a color.
 * So it can use all Block's props.
 */
const StyledPanel = styled(Block).attrs(resetCSS)`
  display: block;
  background-color: ${({ color = 'snow', theme }) => theme.colors[color] || color};
  width: ${({ theme, width = 'auto' }) =>
    theme.globals.panelSize[width] || theme.sizes.widths[width] || theme.sizes[width] || width};
  height: ${({ height = 'auto', theme }) =>
    theme.sizes.heights[height] || theme.sizes[height] || height};
`

export default React.forwardRef((props, ref) => (
  <StyledPanel _ref={ref} {...props} />
))

export const StyledPanelForDocs = StyledPanel
