import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Preferences = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M12 7.09V4.91h-1.79a4.39 4.39 0 0 0-.43-1.05l1.13-1.13-1.64-1.64-1.13 1.13a4.25 4.25 0 0 0-1.05-.43V0H4.91v1.79a4.26 4.26 0 0 0-1.05.43L2.73 1.09 1.09 2.73l1.13 1.13a4.41 4.41 0 0 0-.43 1.05H0v2.18h1.79a4.33 4.33 0 0 0 .43 1.05L1.09 9.27l1.64 1.64 1.13-1.13a4.35 4.35 0 0 0 1.05.43V12h2.18v-1.79a4.36 4.36 0 0 0 1.05-.43l1.13 1.13 1.64-1.64-1.13-1.13a4.33 4.33 0 0 0 .43-1.05H12zM6 8.4A2.4 2.4 0 1 1 8.4 6 2.4 2.4 0 0 1 6 8.4z"/></g>
    </StyledIcon>
  )

export default styled(Preferences)``
  