import styled, { css } from 'styled-components'
import Panel from '../../../Panel'

const getTransition = ({ isOpened, theme }) => {
  const { easing, velocities } = theme.animations
  const property = 'height'
  const duration = isOpened ? velocities.v5 : velocities.v4
  const timing = easing.standard
  const delay = '0s'
  return css`
    transition: ${property} ${duration} ${timing} ${delay};
  `
}

const AccordionContent = styled(Panel)`
  ${({ calculatedHeight, isOpened, theme }) => `
    height: ${isOpened ? calculatedHeight : 0};
    overflow: hidden;
    border-bottom: ${theme.globals.borderWidth.borderWidth1} solid ${
    theme.components.accordion.border
  };
    background-color: ${theme.components.accordion.background};
  `}
  ${getTransition};
`

export default AccordionContent
