import styled from 'styled-components'

const OptionLeftItems = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: center;
  > * {
    margin-right: ${({ theme }) => theme.sizes.s3};
  }
`

export default OptionLeftItems
