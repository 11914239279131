import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Graph = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M1.33 10.67V0H0v11.33a.67.67 0 0 0 .67.67H12v-1.33z"/><path d="M5.33 6.28L6.86 7.8a.67.67 0 0 0 .94 0l4-4-.94-.94-3.53 3.53L5.8 4.86a.67.67 0 0 0-.94 0L2.2 7.53l.94.94z"/></g>
    </StyledIcon>
  )

export default styled(Graph)``
  