import styled, { css } from 'styled-components'

import CheckSVG from '@icons/CheckSVG'
import { Caption1 } from "@typography"

const NewsletterForm = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.sizes.s4};

    .grecaptcha-badge {
      left: 0;
      visibility: hidden;
    }

    button {
      height: ${theme.sizes.s7};
      min-height: ${theme.sizes.s7};
      max-width: ${theme.sizes.s7};
      min-width: ${theme.sizes.s7};
      padding: 0;
      width: ${theme.sizes.s7};

      p,
      svg {
        font-size: 0;
        height: ${theme.sizes.s13};
        width: ${theme.sizes.s13};

        * {
          fill: ${theme.colors.neutral0};
        }

        ${CheckSVG} * {
          fill: transparent;
          stroke: ${theme.colors.neutral0};
        }
      }

      svg {
        transition:
          opacity 500ms,
          transform 700ms;

        &.enter {
          opacity: 1;
        }

        &.enter-done {
          opacity: 1;
          transform: scale(1);
        }
      }

      svg.enter-active {
        opacity: 0;
        transform: scale(1.2);
      }
    }

    ${Caption1} {
      color: ${theme.colors.neutral70};
    }
  `}
`

export default NewsletterForm
