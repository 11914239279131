import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const VerticalMirror = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M0 8.8V0h16v8.8h-3.2V7.2h1.6V1.6H1.6v5.6H4v1.6H0zM7.2 16v-1.6h1.6V16H7.2zm-2.4 0v-1.6h1.6V16H4.8zm-2.4 0v-1.6H4V16H2.4zM0 11.2V9.6h1.6v1.6H0zm0 2.4V12h1.6v1.6H0zM0 16v-1.6h1.6V16H0zm9.6 0v-1.6h1.6V16H9.6zm2.4 0v-1.6h1.6V16H12zm2.4 0v-1.6H16V16h-1.6zm0-2.4V12H16v1.6h-1.6zm0-2.4V9.6H16v1.6h-1.6zM8.8 8h2.4L8 12 4.8 8h2.4V4.8h1.6V8z"/></g>
    </StyledIcon>
  )

export default styled(VerticalMirror)``
  