import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Server1 = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M10.8 0H1.2A1.2 1.2 0 0 0 0 1.2v9.6A1.2 1.2 0 0 0 1.2 12h9.6a1.2 1.2 0 0 0 1.2-1.2V1.2A1.2 1.2 0 0 0 10.8 0zm0 1.2v2.4H1.2V1.2zm0 3.6v2.4H1.2V4.8zm-9.6 6V8.4h9.6v2.4z"/><path d="M1.8 1.8h3V3h-3zm0 3.6h3v1.2h-3zm0 3.6h3v1.2h-3z"/><circle cx="7.8" cy="2.4" r=".6"/><circle cx="9.6" cy="2.4" r=".6"/><circle cx="7.8" cy="6" r=".6"/><circle cx="9.6" cy="6" r=".6"/><circle cx="7.8" cy="9.6" r=".6"/><circle cx="9.6" cy="9.6" r=".6"/></g>
    </StyledIcon>
  )

export default styled(Server1)``
  