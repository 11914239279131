import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const VisaCard = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M14.667 2H1.333C.598 2 0 2.597 0 3.333v9.334C0 13.402.598 14 1.333 14h13.334c.736 0 1.333-.598 1.333-1.333V3.333C16 2.597 15.403 2 14.667 2zm-.002 10.667H1.333V3.333h13.334l-.002 9.334zm-3.11-2.889v-.89h.89v.89h.888V7.555a1.334 1.334 0 0 0-2.666 0V9.78h.888v-.001zm0-2.223a.445.445 0 0 1 .89 0V8h-.89v-.445zm-4.888-.444h-.445v-.889H8v.89h-.445v1.777h2a.221.221 0 0 0 .223-.222.221.221 0 0 0-.223-.222h-.444A1.114 1.114 0 0 1 8 7.333c0-.612.499-1.111 1.111-1.111h1.556v.89H9.11a.222.222 0 1 0 0 .444h.444a1.112 1.112 0 0 1 0 2.222H6.222v-.89h.445V7.112zM4.428 9.778l-.654-2.667H2.667v-.889h1.778l.438 1.735.425-1.735h.914l-.889 3.52.01.036h-.915z"/></g>
    </StyledIcon>
  )

export default styled(VisaCard)``
  