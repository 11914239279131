import styled from 'styled-components'
import PropTypes from 'prop-types'

import { resetCSS } from '../../../helpers'

const OptionsWrapper = styled.div.attrs(resetCSS)`
  background-color: ${({ theme }) => theme.components.optionPanel.option.background};
  border-radius: ${({ theme }) => theme.globals.borderRadius.borderRadius1};
  box-shadow: ${({ theme }) => theme.globals.shadows.shadow2};
  box-sizing: border-box;
  display: block;
  max-height: ${({ maxHeight, theme }) =>
    theme.sizes.heights[maxHeight] || theme.sizes[maxHeight] || maxHeight};
  overflow-y: ${({ maxHeight }) => (maxHeight ? 'auto' : null)};
  padding-bottom: ${({ theme }) => theme.sizes.s3};
  padding-top: ${({ theme }) => theme.sizes.s3};
  z-index: 1;
`

OptionsWrapper.propTypes = {
  maxHeight: PropTypes.string,
}

export default OptionsWrapper
