import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const RotateDevice = props => (
    <StyledIcon viewBox="0 0 12 11" {...props}>
      <g><path d="M10 2.26v.2H9l1.5 2 1.5-2h-1v-.2A2.31 2.31 0 0 0 8.5 0H8v1h.5A1.35 1.35 0 0 1 10 2.26zM2 8.57v-.2h1l-1.5-2-1.5 2h1v.2a2.31 2.31 0 0 0 2.5 2.26H4v-1h-.5A1.35 1.35 0 0 1 2 8.57zM5.54 1a1 1 0 0 0-1.42 0L1.29 3.75a1 1 0 0 0 0 1.39l5.66 5.57a1 1 0 0 0 1.41 0l2.83-2.78a1 1 0 0 0 0-1.39zm2.12 9L2 4.44l2.83-2.78 5.66 5.57z"/></g>
    </StyledIcon>
  )

export default styled(RotateDevice)``
  