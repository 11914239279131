import React from 'react'
import styled, { css } from 'styled-components'
import { SectionWithBackgroundColor } from '@styles/SectionWithBackgroundColor'
import { Spinner } from '@styles/Spinner'
import { Container } from 'component-library/components'
import { styleForMobile, styleForTablet } from '@utils'

const StyledSectionWithBackgroundColor = styled(SectionWithBackgroundColor)`
  ${({ theme }) => css`
    ${Container} {
      display: flex;
      justify-content: center;
      padding: ${theme.sizes.s15} 0;

      ${styleForTablet} {
        padding: ${theme.sizes.s10} 0;
      }

      ${styleForMobile} {
        padding: ${theme.sizes.s8} 0;
      }
    }

    ${Spinner} {
      margin: ${theme.sizes.s11} auto;
    }
  `}
`

const SpinnerSection = ({ backgroundColor = 'neutral0' }) => (
  <StyledSectionWithBackgroundColor backgroundColor={backgroundColor}>
    <Container>
      <Spinner />
    </Container>
  </StyledSectionWithBackgroundColor>
)

export default SpinnerSection
