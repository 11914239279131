import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Desktop = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="m11.5,15c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5l-7,0c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5l7,0zm4,-15c0.276,0 0.5,0.224 0.5,0.5l0,12c0,0.276 -0.224,0.5 -0.5,0.5l-15,0c-0.276,0 -0.5,-0.224 -0.5,-0.5l0,-12c0,-0.276 0.224,-0.5 0.5,-0.5l15,0zm-0.5,1l-14,0l0,11l14,0l0,-11z"/></g>
    </StyledIcon>
  )

export default styled(Desktop)``
