import styled from 'styled-components'

const OptionRightItems = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: ${({ theme }) => theme.sizes.s3};
  }
`

export default OptionRightItems
