import React from 'react'
import styled from 'styled-components'

import { isString } from '../../helpers/typePredicates'

import Div from '../Div'

import { LabelProvider } from './hooks'
import Label from './components/Label'

const UnstyledFormLabel = ({
  children,
  className,
  htmlFor,
  required = false,
  ...rest
}) => (
  <LabelProvider htmlFor={htmlFor} required={required}>
    {isString(children) ? (
      <UnstyledFormLabel.Base di="ib" mb="s3" {...rest}>
        {children}
      </UnstyledFormLabel.Base>
    ) : (
      <Div className={className} mb="s3" {...rest}>
        {children}
      </Div>
    )}
  </LabelProvider>
)

/** Exposed components */
/**
 * This is the real html element (`<label>` by default), which is linked to the form field through the "htmlFor" prop.
 * Renders a `<div>` instead of a `<label>`, if child content is not a string.
 */
UnstyledFormLabel.Base = Label

const FormLabel = styled(UnstyledFormLabel)``
export default FormLabel
