import * as React from 'react'
import AbstractSelectionSelect from './AbstractSelectionSelect' // eslint-disable-line import/no-cycle
import Tag from '../../Tag'

/**
 * A wrapper to add multiple selection on a select
 */
class MultiSelect extends AbstractSelectionSelect {
  /**
   * Render a list of items, before previous content.
   */
  renderSelected = (previous) => {
    const { autoContrast = false, selected = [], disabled, onDelete = () => undefined } = this.props

    return [
      ...selected.map(s => (
        <Tag
          autoContrast={autoContrast}
          color={s.leftIconColor}
          disabled={disabled}
          key={s.label}
          label={s.label}
          onIconClick={() => onDelete(s)}
        />
      )),
      ...previous,
    ]
  }
}

export default MultiSelect
