// @noSnapshot

import React from 'react'
import styled from 'styled-components'
import { CarretDown } from '../../../../icons'
import { useDropdown } from '../../hooks'

const RotatingIcon = styled(CarretDown)`
  ${({ isOpen, theme: { animations, sizes } }) => `
    margin-left: ${sizes.s3};
    transition: transform ${animations.velocities.v4} ${animations.easing.standard};
    transform: rotate(${isOpen ? animations.degrees.d3 : '0deg'});
  `};
`

function Caret(props) {
  const { isOpen } = useDropdown()

  return <RotatingIcon isOpen={isOpen} size="s3" {...props} />
}

export default Caret
