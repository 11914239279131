import styled from 'styled-components'
import {
  styleForDesktop,
  styleForMobile,
  styleForTablet,
  styleForTabletAndMobile,
} from '@utils'

// TODO generate this styled components
const OnlyDesktop = styled.div`
  ${styleForDesktop} {
    display: initial;
  }

  ${styleForTabletAndMobile} {
    display: none;
  }
`

const OnlyDesktopAndTablet = styled.div`
  ${styleForDesktop} {
    display: initial;
  }

  ${styleForTablet} {
    display: initial;
  }

  ${styleForMobile} {
    display: none;
  }
`

const OnlyDesktopAndMobile = styled.div`
  ${styleForDesktop} {
    display: initial;
  }

  ${styleForTablet} {
    display: none;
  }

  ${styleForMobile} {
    display: initial;
  }
`

const OnlyTabletAndMobile = styled.div`
  ${styleForDesktop} {
    display: none;
  }

  ${styleForTabletAndMobile} {
    display: initial;
  }
`

const OnlyTablet = styled.div`
  ${styleForDesktop} {
    display: none;
  }

  ${styleForTablet} {
    display: initial;
  }

  ${styleForMobile} {
    display: none;
  }
`

const OnlyMobile = styled.div`
  ${styleForDesktop} {
    display: none;
  }

  ${styleForTablet} {
    display: none;
  }

  ${styleForMobile} {
    display: initial;
  }
`

export {
  OnlyDesktop,
  OnlyDesktopAndTablet,
  OnlyDesktopAndMobile,
  OnlyTablet,
  OnlyTabletAndMobile,
  OnlyMobile,
}
