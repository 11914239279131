import React, { Children, useEffect } from 'react'
import styled from 'styled-components'

import { useHeaderCellNumber, useIsLoading, useIsSelectable } from '../hooks'
import visuallyHidden from '../../VisuallyHidden'
import LoadingHeader from './LoadingHeader'
import HeaderCell from './HeaderCell'

const StyledThead = styled.thead`
  ${({ hidden }) => (hidden ? visuallyHidden : '')};
`

function Header({ children, hidden, ...rest }) {
  const [headerCellNumber, setHeaderCellNumber] = useHeaderCellNumber()
  const isLoading = useIsLoading()
  const isSelectable = useIsSelectable()
  const childCount = Children.count(children)
  const cellCount = childCount + (isSelectable ? 1 : 0)

  useEffect(() => {
    if (headerCellNumber !== cellCount) {
      setHeaderCellNumber(cellCount)
    }
  }, [headerCellNumber, cellCount])

  return (
    <StyledThead hidden={hidden} {...rest}>
      <tr>
        {isSelectable && <HeaderCell id="checkboxes" />}
        {isLoading && childCount === 0 ? <LoadingHeader /> : children}
      </tr>
    </StyledThead>
  )
}

export const DocHeader = Header
export default styled(Header)``
