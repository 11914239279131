import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const GalleryView = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M0 0h7v7H0V0zm9 0h7v7H9V0zM0 9h7v7H0V9zm9 0h7v7H9V9z"/></g>
    </StyledIcon>
  )

export default styled(GalleryView)``
  