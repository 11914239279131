import React, { PropsWithChildren, useEffect } from 'react'
import Div from '../../Div'
import Counter from './Counter'

import { STATUS } from '../config'

import {
  useCounter,
  useFocus,
  useInfoStatus,
  useInputContext,
  useLocalValue,
} from '../hooks'

const InfoBar = ({
  children,
  counter,
  ...rest
}) => {
  const [inputCounter, setInputCounter] = useCounter()
  const [isFocus] = useFocus()
  const { infoStatus, maxLength } = useInputContext()
  const { localValue } = useLocalValue()
  const { setHasInfo, setIsError } = useInfoStatus()

  useEffect(() => {
    setIsError(infoStatus === STATUS.ERROR)
    setHasInfo(infoStatus === STATUS.INFO || (infoStatus === STATUS.INFO_ON_FOCUS && isFocus))
  }, [infoStatus, isFocus])

  const getCurrent = () =>
    typeof inputCounter === 'boolean' ? localValue?.length : inputCounter?.current ?? 0

  const getMax = () => {
    if (typeof inputCounter !== 'boolean' && inputCounter?.max) {
      return inputCounter.max
    }
    if (maxLength) {
      return maxLength
    }
    throw new Error('You must specify either maxLength on Input or counter.max on Input.InfoBar')
  }

  useEffect(() => {
    setInputCounter(counter)
  }, [counter])

  return children || !!inputCounter ? (
    <Div di="f" {...rest}>
      {children}
      {!!inputCounter && <Counter current={getCurrent()} max={getMax()} />}
    </Div>
  ) : null
}

export default InfoBar
