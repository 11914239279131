const getButton = ({
  colors,
  baseShadePrimary = 50,
  baseShadeSecondary = 70,
}) => {
  const baseColorPrimary = colors['primary' + baseShadePrimary]
  const baseColorSecondary = colors['secondary' + baseShadeSecondary]
  const lightColorPrimary = colors['primary' + (baseShadePrimary - 10)]
  const strongColorPrimary = colors['primary' + (baseShadePrimary + 10)]
  const { neutral0 } = colors

  return {
    primary: {
      background: baseColorPrimary,
      backgroundFocus: baseColorPrimary,
      backgroundHover: lightColorPrimary,
      backgroundInactive: strongColorPrimary,
      backgroundLoading: strongColorPrimary,
      backgroundPressed: strongColorPrimary,
      borderColor: baseColorPrimary,
      borderFocusColor: colors.ocean40,
      borderHoverColor: lightColorPrimary,
      borderInactiveColor: strongColorPrimary,
      borderLoadingColor: strongColorPrimary,
      borderPressedColor: strongColorPrimary,
      boxShadow: strongColorPrimary + '3d',
      loader: neutral0,
      text: neutral0,
      textDark: baseColorSecondary,
      textFocus: neutral0,
      textHover: neutral0,
      textPressed: neutral0,
    },
    secondary: {
      background: 'transparent',
      backgroundFocus: neutral0 + '52',
      backgroundHover: neutral0 + '29',
      backgroundInactive: 'transparent',
      backgroundLoading: 'transparent',
      backgroundPressed: neutral0 + '52',
      borderColor: baseColorPrimary,
      borderFocusColor: colors.ocean40,
      borderFocusWidth: '2px',
      borderHoverColor: lightColorPrimary,
      borderHoverWidth: '2px',
      borderLoadingColor: baseColorPrimary,
      borderLoadingWidth: '2px',
      borderPressedColor: strongColorPrimary,
      borderPressedWidth: '2px',
      borderWidth: '2px',
      loader: baseColorSecondary,
      text: 'neutral100',
      textDark: neutral0,
      textFocus: baseColorSecondary,
      textHover: baseColorSecondary,
      textPressed: baseColorSecondary,
    },
  }
}

export default getButton
