import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const getTransition = ({ isOpened, theme }) => {
  const { easing, velocities } = theme.animations
  const property = 'opacity'
  const duration = isOpened ? velocities.v5 : velocities.v4
  const timing = isOpened ? easing.decelerate : easing.accelerate
  const delay = '0s'
  return css`
    transition: ${property} ${duration} ${timing} ${delay};
  `
}

const ContentSpacer = styled('div')`
  ${({ isOpened, noPadding, small, theme }) => `
    ${!noPadding ? `padding: ${theme.sizes.s4}` : ''};
    ${!noPadding && small ? `padding-top: ${theme.sizes.s3}` : ''};
    opacity: ${isOpened ? 1 : 0};
  `}
  ${getTransition};
`
ContentSpacer.propTypes = {
  noPadding: PropTypes.bool,
}

export default ContentSpacer
