import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Info = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8.667 10.5H10v1.333H6V10.5h1.333v-2h-.666V7.167H8c.369 0 .667.298.667.666V10.5zM8 6.667A.833.833 0 1 1 8 5a.833.833 0 0 1 0 1.667zM8 16c-4.411 0-8-3.588-8-8 0-4.41 3.589-8 8-8s8 3.589 8 8c0 4.412-3.589 8-8 8zM8 1.6A6.407 6.407 0 0 0 1.6 8c0 3.529 2.871 6.4 6.4 6.4 3.529 0 6.4-2.871 6.4-6.4 0-3.529-2.871-6.4-6.4-6.4z"/></g>
    </StyledIcon>
  )

export default styled(Info)``
  