import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const HourGlass = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g>
        <path d="M11.25 1.5c.45 0 .75-.3.75-.75S11.7 0 11.25 0H.75C.3 0 0 .3 0 .75s.3.75.75.75h.75C1.575 3.3 2.1 4.875 3 6c-.9 1.05-1.5 2.7-1.5 4.5H.75c-.45 0-.75.3-.75.75s.3.75.75.75h10.5c.45 0 .75-.3.75-.75s-.3-.75-.75-.75h-.75C10.425 8.625 9.9 7.05 9 6c.975-1.125 1.5-2.7 1.5-4.5h.75zM7.425 5.475l-.675.6.75.525c.9.675 1.5 2.175 1.575 3.9H3c.075-1.725.675-3.225 1.575-3.9l.75-.525-.75-.6C3.6 4.575 3.075 3.15 3 1.5h6c-.075 1.65-.6 3.075-1.575 3.975z " />
        <path d="M4 9.6C4.667 7.867 5.333 7 6 7s.667.867 0 2.6H4z" />
        <path d="M8 9.6C7.333 7.867 6.667 7 6 7s-.667.867 0 2.6h2z" />
      </g>
    </StyledIcon>
  )

export default styled(HourGlass)``
