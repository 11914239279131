import styled from 'styled-components'
import { isKeyOf } from 'component-library/helpers'

export const Image = styled.div.attrs(props => ({
  className: props.locator,
  as: 'img',
}))`
  width: ${({ theme, width, size = 'imageSize1' }) =>
    (isKeyOf(width, theme.sizes.widths) && theme.sizes.widths[width]) ||
    (isKeyOf(width, theme.sizes) && theme.sizes[width]) ||
    width ||
    theme.globals.imageSize[size]?.width};
  height: ${({ theme, height = '', size = 'imageSize1' }) =>
    (isKeyOf(height, theme.sizes.heights) && theme.sizes.heights[height]) ||
    (isKeyOf(height, theme.sizes) && theme.sizes[height]) ||
    height ||
    theme.globals.imageSize[size]?.height};
  border-radius: ${({ theme, bR = '0px' }) =>
    (bR !== '0px' && theme.globals.borderRadius[bR]) || bR};
`
Image.defaultProps = { size: 'imageSize1' }

export default Image
