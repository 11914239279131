import styled from 'styled-components';

import { borders, display, margins, paddings, sizes } from '../../helpers';
import PropTypes from 'prop-types';

const Div = styled.div`
  ${borders};
  ${display};
  ${margins};
  ${paddings};
  ${sizes};
`;

Div.propTypes = {
  ...borders.propTypes,
  ...display.propTypes,
  ...margins.propTypes,
  ...paddings.propTypes,
  ...sizes.propTypes,
};

export default Div;
