import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const VideoContent2 = props => (
    <StyledIcon  {...props}>
      <g><path d="M45 5.33H3a3 3 0 0 0-3 3v34a3 3 0 0 0 3 3h42a3 3 0 0 0 3-3v-34a3 3 0 0 0-3-3zm-43 3a1 1 0 0 1 1-1h5v8H2zm0 9h6v4H2zm0 6h6v4H2zm0 6h6v4H2zm1 14a1 1 0 0 1-1-1v-7h6v8zm7 0v-36h28v36zm36-1a1 1 0 0 1-1 1h-5v-8h6zm0-9h-6v-4h6zm0-6h-6v-4h6zm0-6h-6v-4h6zm0-6h-6v-8h5a1 1 0 0 1 1 1z"/><path d="M28.4 24.24l-6.3-4.08a1.35 1.35 0 0 0-2.1 1.09v8.17a1.35 1.35 0 0 0 2.1 1.09l6.3-4.08a1.3 1.3 0 0 0 0-2.19z"/></g>
    </StyledIcon>
  )

export default styled(VideoContent2)``
  