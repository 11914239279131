import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Smartphone = props => (
    <StyledIcon viewBox="0 0 7 12" {...props}>
      <g><path d="M5.83 0H1.17A1.18 1.18 0 0 0 0 1.2v9.6A1.18 1.18 0 0 0 1.17 12h4.66A1.18 1.18 0 0 0 7 10.8V1.2A1.18 1.18 0 0 0 5.83 0zm0 1.2v1.2H1.17V1.2zm0 2.4v4.8H1.17V3.6zm-4.66 7.2V9.6h4.66v1.2z"/></g>
    </StyledIcon>
  )

export default styled(Smartphone)``
  