import React from 'react'
import styled from 'styled-components'

const BadgeCrossSVG = (props) => {
  return (
    <svg
      fill='none'
      height='20'
      viewBox='0 0 20 20'
      width='20'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        clipRule='evenodd'
        d='M9.99998 16.6666C13.6819 16.6666 16.6666 13.6819 16.6666 9.99998C16.6666 6.31808 13.6819 3.33331 9.99998 3.33331C6.31808 3.33331 3.33331 6.31808 3.33331 9.99998C3.33331 13.6819 6.31808 16.6666 9.99998 16.6666ZM13.0282 6.97174C13.435 7.37854 13.435 8.03809 13.0282 8.44488L11.4731 9.99998L13.0282 11.5551C13.435 11.9619 13.435 12.6214 13.0282 13.0282C12.6214 13.435 11.9619 13.435 11.5551 13.0282L9.99998 11.4731L8.44488 13.0282C8.03809 13.435 7.37854 13.435 6.97174 13.0282C6.56495 12.6214 6.56495 11.9619 6.97174 11.5551L8.52684 9.99998L6.97174 8.44488C6.56495 8.03809 6.56495 7.37854 6.97174 6.97174C7.37854 6.56495 8.03809 6.56495 8.44488 6.97174L9.99998 8.52684L11.5551 6.97174C11.9619 6.56495 12.6214 6.56495 13.0282 6.97174Z'
        fill='#C9D1D6'
        fillRule='evenodd'
      />
    </svg>
  )
}
export default styled(BadgeCrossSVG)``
