// @noSnapshot
import React from 'react'

import styled from 'styled-components'

import PropTypes from 'prop-types'

import ThemePartialProvider from '../ThemePartialProvider'
import { CodeEditorProvider } from './hooks'

import Copy from './components/Copy'
import EditorWrapper from './components/EditorWrapper'
import Languages from './components/Languages'

import ContainerStyled from './styles/ContainerStyled'
import Header from './styles/Header'

function CodeEditor({
  children,
  isScrollable,
  hasNoResponsive,
  selectedLanguage,
  values,
  ...otherProps
}) {
  return (
    <CodeEditorProvider
      hasNoResponsive={hasNoResponsive}
      selectedLanguage={selectedLanguage}
      values={values}
    >
      <ThemePartialProvider dark>
        <ContainerStyled
          hasNoResponsive={hasNoResponsive}
          isScrollable={isScrollable}
          {...otherProps}
        >
          {children}
        </ContainerStyled>
      </ThemePartialProvider>
    </CodeEditorProvider>
  )
}

CodeEditor.propTypes = {
  // List of code snippets to display in the editor
  values: PropTypes.object.isRequired,

  children: PropTypes.node,
  // Class names
  className: PropTypes.string,
  // Disables responsive display
  hasNoResponsive: PropTypes.bool,
  // Make the editor scrollable
  isScrollable: PropTypes.bool,
  // Language of the code displayed in the editor
  selectedLanguage: PropTypes.string,
}

CodeEditor.Header = Header
CodeEditor.Languages = Languages
CodeEditor.Copy = Copy
CodeEditor.Editor = EditorWrapper

export default styled(CodeEditor)``
