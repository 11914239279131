// @noSnapshot
import React from 'react'

import { toLower } from 'lodash'

import styled from 'styled-components'
import { useLanguage, useValue } from '../hooks'

import Editor from './Editor'
import EditorWrapperStyled from '../styles/EditorWrapperStyled'

function EditorWrapper({
  allowTabFocusExit,
  children,
  hideLineNumbers,
  onChange,
  plainPage,
  readOnly,
  setCodeMirrorRef,
  ...rest
}) {
  const [selectedLanguage] = useLanguage()
  const [value] = useValue()

  const getLanguageMime = (selectedLanguage) => {
    switch (selectedLanguage) {
      case 'cURL':
        return 'text/x-sh'
      case 'NODE':
        return 'text/javascript'
      case 'C#':
        return 'text/x-csharp'
      case 'JAVA':
        return 'text/x-java'
      case 'PHP':
        return 'text/x-php'
      case 'JSON':
        return 'application/json'
      case 'YML':
        return 'text/x-yaml'
      case 'RUBY':
        return 'text/x-ruby'
      case 'GO':
        return 'text/x-go'
      default:
        return toLower(selectedLanguage)
    }
  }

  const onChangeLanguage = (value) => {
    onChange && onChange({ lang: selectedLanguage, code: value })
  }

  return (
    <EditorWrapperStyled plainPage={plainPage} {...rest}>
      {children}
      <Editor
        allowTabFocusExit={allowTabFocusExit}
        hideLineNumbers={hideLineNumbers}
        highlight={getLanguageMime(selectedLanguage)}
        onChange={onChangeLanguage}
        plainPage={plainPage}
        readOnly={readOnly}
        setCodeMirrorRef={setCodeMirrorRef}
        value={value || ''}
      />
    </EditorWrapperStyled>
  )
}

export default styled(EditorWrapper)``
