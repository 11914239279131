import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AlignLeft = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M0 0h2v16H0V0zm4 3h12v4H4V3zm0 6h8v4H4V9z"/></g>
    </StyledIcon>
  )

export default styled(AlignLeft)``
  