import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Envelope = props => (
    <StyledIcon viewBox="0 0 12 8" {...props}>
      <g><path d="M10.8 0H1.2A1.22 1.22 0 0 0 0 1.23v5.54A1.22 1.22 0 0 0 1.2 8h9.6A1.22 1.22 0 0 0 12 6.77V1.23A1.22 1.22 0 0 0 10.8 0zM9.67 1.23L6 3.58 2.33 1.23zM1.2 6.77V2l4.48 2.83a.59.59 0 0 0 .64 0L10.8 2v4.77z"/></g>
    </StyledIcon>
  )

export default styled(Envelope)``
  