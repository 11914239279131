import styled from 'styled-components'
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Image from '@components/Image'
import { Background } from '@styles/Background'
import { SectionWithBackgroundColor } from '@styles/SectionWithBackgroundColor'

const Section = forwardRef(
  (
    {
      backgroundColor,
      backgroundOnlyDesktop = true,
      backgroundPicture,
      backgroundPictureColor,
      children,
      ...rest
    },
    ref,
  ) => (
    <SectionWithBackgroundColor
      backgroundColor={backgroundColor}
      noBackgroundImage={!backgroundPicture}
      ref={ref}
      {...rest}
      as='section'
    >
      {children}
      {backgroundPicture && (
        <Background
          backgroundPictureColor={backgroundPictureColor}
          onlyDesktop={backgroundOnlyDesktop}
        >
          <Image
            alt={backgroundPicture.alternative}
            {...backgroundPicture.picture}
          />
        </Background>
      )}
    </SectionWithBackgroundColor>
  ),
)

Section.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundOnlyDesktop: PropTypes.bool,
  backgroundPicture: PropTypes.object,
  backgroundPictureColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default styled(Section)``
