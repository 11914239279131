import React from 'react'

const LaurelLvl02SVG = () => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    viewBox='0 0 64 40'
    style={{ enableBackground: 'new 0 0 64 40' }}
    xmlSpace='preserve'
  >
    <path
      d='M53.9,25.4c-0.9-0.5-1.7-0.6-2.5-0.3c0.1-0.2,0.1-0.3,0.2-0.5c2.2-5.7,0.9-12.2-3.7-18.9
        c-0.2-0.2-0.5-0.3-0.7-0.1C46.9,5.7,46.9,6,47,6.3c4.4,6.4,5.7,12.6,3.6,18c-0.1,0.2-0.1,0.3-0.2,0.5c-0.3-0.8-0.9-1.4-1.9-1.8
        l-0.4-0.2l-0.2,0.4c-0.5,0.9-0.6,1.8-0.3,2.6c0.2,0.7,0.7,1.2,1.4,1.6c-0.5,0.7-1,1.3-1.5,2c-0.1-0.9-0.5-1.6-1.4-2.2l-0.4-0.3
        l-0.3,0.3c-0.7,0.8-1,1.6-1,2.5c0.1,0.6,0.3,1.2,0.8,1.7c-0.5,0.4-1.1,0.9-1.7,1.3c0,0,0-0.1,0-0.1c0.2-0.9-0.1-1.7-0.7-2.5
        l-0.3-0.4l-0.4,0.2c-0.9,0.6-1.4,1.3-1.6,2.1c-0.1,0.7,0,1.4,0.4,2.1c-0.7,0.4-1.5,0.7-2.2,1c0-0.1,0.1-0.1,0.1-0.2
        c0.4-0.8,0.4-1.7-0.1-2.7l-0.2-0.4L38.3,32c-1,0.3-1.7,0.9-2.1,1.7c-0.3,0.7-0.3,1.5,0,2.4c-0.3,0.1-0.6,0.2-1,0.3
        c-1.1,0.3-2.2,0.7-3.4,1.1c-1.1-0.5-2.3-0.9-3.4-1.1c-0.3-0.1-0.7-0.2-1-0.3c0.3-0.8,0.3-1.6,0-2.4c-0.4-0.8-1.1-1.4-2.1-1.7
        l-0.4-0.1l-0.2,0.4c-0.4,1-0.4,1.9-0.1,2.7c0,0.1,0.1,0.1,0.1,0.2c-0.8-0.3-1.5-0.6-2.2-1c0.4-0.7,0.6-1.4,0.4-2.1
        c-0.2-0.9-0.7-1.6-1.6-2.1L21,29.6L20.8,30c-0.6,0.8-0.9,1.7-0.7,2.5c0,0,0,0.1,0,0.1c-0.6-0.4-1.2-0.8-1.7-1.3
        c0.5-0.5,0.7-1.1,0.8-1.7c0.1-0.9-0.2-1.7-1-2.5l-0.3-0.3l-0.4,0.3c-0.8,0.6-1.3,1.3-1.4,2.2c-0.6-0.6-1.1-1.3-1.5-2
        c0.7-0.4,1.2-0.9,1.4-1.6c0.3-0.8,0.2-1.7-0.3-2.6l-0.2-0.4l-0.4,0.2c-1,0.4-1.6,1-1.9,1.8c-0.1-0.2-0.1-0.3-0.2-0.5
        c-2-5.4-0.8-11.6,3.6-18c0.2-0.2,0.1-0.5-0.1-0.7c-0.2-0.2-0.5-0.1-0.7,0.1c-4.6,6.7-5.9,13.2-3.7,18.9c0.1,0.2,0.1,0.3,0.2,0.5
        c-0.8-0.3-1.6-0.2-2.5,0.3l-0.4,0.2L9.5,26c0.4,1,1,1.6,1.8,1.9c0.3,0.1,0.6,0.2,1,0.2c0.4,0,0.9-0.1,1.4-0.3
        c0.5,0.8,1.1,1.5,1.7,2.2c-0.9-0.1-1.7,0.3-2.4,1l-0.3,0.3l0.3,0.4c0.6,0.8,1.4,1.3,2.3,1.4c0.1,0,0.2,0,0.3,0
        c0.8,0,1.5-0.3,2.1-0.9c0.5,0.4,1.1,0.9,1.7,1.3c-0.8,0.2-1.4,0.7-1.9,1.5l-0.2,0.4l0.4,0.3c0.7,0.5,1.4,0.8,2.1,0.8
        c0.2,0,0.3,0,0.5,0c0.8-0.1,1.4-0.6,1.9-1.3c0.7,0.3,1.4,0.7,2.1,1c-0.6,0.4-1.1,1-1.4,1.9l-0.1,0.4l0.4,0.2c0.5,0.2,1,0.3,1.5,0.3
        c0.4,0,0.8-0.1,1.2-0.3c0.6-0.3,1.1-0.8,1.5-1.5c0.4,0.1,0.7,0.2,1.1,0.3c0.8,0.2,1.6,0.4,2.3,0.7c-0.5,0.2-0.9,0.5-1.4,0.7
        c-0.2,0.1-0.3,0.4-0.2,0.7c0.1,0.2,0.3,0.3,0.4,0.3c0.1,0,0.2,0,0.2-0.1c0.7-0.4,1.4-0.7,2.1-1.1c0.7,0.3,1.4,0.7,2.1,1.1
        c0.1,0,0.2,0.1,0.2,0.1c0.2,0,0.3-0.1,0.4-0.3c0.1-0.2,0.1-0.5-0.2-0.7c-0.5-0.3-0.9-0.5-1.4-0.7c0.8-0.3,1.6-0.5,2.3-0.7
        c0.4-0.1,0.7-0.2,1.1-0.3c0.3,0.7,0.8,1.2,1.5,1.5c0.4,0.2,0.8,0.3,1.2,0.3c0.5,0,0.9-0.1,1.5-0.3l0.4-0.2l-0.1-0.4
        c-0.3-0.9-0.7-1.5-1.4-1.9c0.7-0.3,1.4-0.6,2.1-1c0.5,0.7,1.2,1.2,1.9,1.3c0.2,0,0.3,0,0.5,0c0.7,0,1.4-0.3,2.1-0.8l0.4-0.3
        l-0.2-0.4c-0.5-0.8-1.1-1.3-1.9-1.5c0.6-0.4,1.2-0.8,1.7-1.3c0.7,0.6,1.4,0.9,2.1,0.9c0.1,0,0.2,0,0.3,0c0.9-0.1,1.6-0.5,2.3-1.4
        l0.3-0.4l-0.3-0.3c-0.7-0.7-1.6-1-2.4-1c0.6-0.7,1.2-1.4,1.7-2.2c0.5,0.2,0.9,0.3,1.4,0.3c0.3,0,0.7-0.1,1-0.2
        c0.8-0.3,1.4-0.9,1.8-1.9l0.2-0.4L53.9,25.4z'
    />
  </svg>
)

export default LaurelLvl02SVG
