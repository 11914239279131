import styled from 'styled-components'
import { CaptionMedium } from '../../Typography'

const TitleText = styled(CaptionMedium)`
  color: ${({ theme }) => theme.components.optionPanel.title.text};
  text-transform: uppercase;
  display: flex;
  flex-grow: 1;
  margin-left: ${({ theme }) => theme.sizes.s4};
  margin-right: ${({ theme }) => theme.sizes.s4};
  padding-top: ${({ theme }) => theme.sizes.s3};
  padding-bottom: ${({ theme }) => theme.sizes.s2};
`

export default TitleText
