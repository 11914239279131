import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const OpenMessage = props => (
    <StyledIcon viewBox="0 0 24 24" {...props}>
      <g><path d="M24 10v12.495c0 .903-.6 1.505-1.5 1.505h-21C.6 24 0 23.398 0 22.495V10l11.25 6.323c.3.15.45.15.75.15s.45 0 .75-.15L24 10zM19.5 0c.9 0 1.5.584 1.5 1.46v6.574L12 13 3 8.034V1.46C3 .584 3.6 0 4.5 0z"  fillRule="evenodd"/></g>
    </StyledIcon>
  )

export default styled(OpenMessage)``
