import React from 'react';
import cx from 'classnames';
import { pickBy } from 'lodash';

/**
 * A transparent block handling mouse events.
 */
class Block extends React.Component {
  static defaultProps = {
    style: {},
  };

  state = {
    hovered: false,
  };

  handleMouseEnter = (e) => {
    const { onMouseOver } = this.props;
    const { hovered } = this.state;

    if (!hovered) {
      this.setState({
        hovered: true,
      });

      onMouseOver && onMouseOver(e);
    }
  };

  handleMouseLeave = (e) => {
    const { onMouseOut } = this.props;
    const { hovered } = this.state;

    if (hovered) {
      this.setState({
        hovered: false,
      });

      onMouseOut && onMouseOut(e);
    }
  };

  render() {
    const {
      _ref,
      canHovered,
      canSelected,
      className,
      children,
      'data-testid': dataTestId,
      locator,
      onClick,
      onMouseDown,
      onMouseUp,
      onTransitionEnd,
      selected,
      style,
      ...rest
    } = this.props;

    const { hovered } = this.state;

    const realClassName = cx(
      {
        'can-hovered': canHovered,
        'can-selected': canSelected,
        'is-hovered': hovered,
        'is-selected': selected,
      },
      locator,
      className,
    );

    const getDataProps = () =>
      pickBy(
        this.props,
        (value, key) => typeof key === 'string' && key.includes('data-'),
      );

    const dataProps = getDataProps ? getDataProps() : {};

    // Keep only the props which start with "aria" and the role.
    const restToKeep = pickBy(
      rest,
      (value, key) => key.startsWith('aria') || key === 'role' || key === 'tabIndex',
    );

    return (
      <div
        {...restToKeep}
        {...dataProps}
        className={realClassName}
        data-testid={dataTestId}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onMouseUp={onMouseUp}
        onTransitionEnd={onTransitionEnd}
        ref={_ref}
        style={style}
      >
        {children}
      </div>
    );
  }
}

export default Block;
