import React from 'react'
import styled from 'styled-components'

const PlayAnimationSVG = (props) => (
  <svg
    width='56'
    height='40'
    viewBox='0 0 56 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='56' height='40' rx='8' fill='black' fillOpacity='0.08' />
    <rect width='56' height='40' rx='8' fill='black' fillOpacity='0.08' />
    <rect width='56' height='40' rx='8' fill='black' fillOpacity='0.08' />
    <path
      d='M34.25 18.701C35.25 19.2783 35.25 20.7217 34.25 21.2991L25.25 26.4952C24.25 27.0726 23 26.3509 23 25.1962L23 14.8039C23 13.6492 24.25 12.9275 25.25 13.5048L34.25 18.701Z'
      fill='white'
    />
  </svg>
)

export default styled(PlayAnimationSVG)``
