import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Cog = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M15.998 9.455v-2.91h-2.387a5.852 5.852 0 0 0-.572-1.403l1.505-1.506-2.18-2.18-1.508 1.507a5.667 5.667 0 0 0-1.403-.576V0H6.545v2.387a5.68 5.68 0 0 0-1.402.576L3.636 1.456l-2.182 2.18 1.507 1.506a5.85 5.85 0 0 0-.572 1.403H0v2.908h2.387c.13.5.325.967.573 1.403l-1.507 1.506 2.182 2.18 1.507-1.505a5.79 5.79 0 0 0 1.401.574v2.386H9.45V13.61c.5-.13.966-.326 1.403-.574l1.507 1.505 2.18-2.18-1.505-1.506c.248-.436.444-.903.573-1.403h2.39v.002zM8 11.199a3.2 3.2 0 1 1 0-6.4 3.2 3.2 0 0 1 0 6.4z"/></g>
    </StyledIcon>
  )

export default styled(Cog)``
  