import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const UserSolid = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g fillRule="evenodd">
        <circle cx="6" cy="3" r="3" />
        <path d="M12 9.9a1.492 1.492 0 0 0-.872-1.364A12.49 12.49 0 0 0 6 7.5 12.49 12.49 0 0 0 .872 8.537 1.492 1.492 0 0 0 0 9.9V12h12V9.9z" />
      </g>
    </StyledIcon>
  )

export default styled(UserSolid)``
