import styled from 'styled-components'
import PropTypes from 'prop-types'
import StyledSkeleton from '../styles/StyledSkeleton'

const ThumbnailSkeleton = styled(StyledSkeleton)`
  ${({ size = 'imageSize2', height, theme, type = 'default', width }) => `
    border-radius: ${type === 'radius' && theme.globals.borderRadius.bRCircle};
    height: ${height || theme.globals.imageSize[size].height};
    width: ${width || theme.globals.imageSize[size].width};
    
    &:before {
      border-radius: ${type === 'radius' && theme.globals.borderRadius.bRCircle};
    }
  `}
`

ThumbnailSkeleton.propTypes = {
  /** Height of the Thumbnail. */
  height: PropTypes.string,
  /** Assign to both height and width (using theme imageSize). */
  size: PropTypes.string,
  /** Type of the Thumbnail. If radius then border-radius will be set to 50%. */
  type: PropTypes.oneOf(['default', 'radius']),
  /** Width of the Thumbnail. */
  width: PropTypes.string,
}

export default ThumbnailSkeleton
