import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const KeyLock = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M7.82 4.83a1.8 1.8 0 1 0-2.65 1.54L4.74 9h2.4l-.32-2.59a1.79 1.79 0 0 0 1-1.58z"/><path d="M5.89 12A6 6 0 1 1 12 6.11 6 6 0 0 1 5.89 12zm.2-10.8a4.8 4.8 0 1 0 4.71 4.89A4.81 4.81 0 0 0 6.09 1.2z"/></g>
    </StyledIcon>
  )

export default styled(KeyLock)``
  