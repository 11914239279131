import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import PropTypes from 'prop-types'
import { Copy as CopyIcon } from 'component-library/icons'

import withTranslations from '../../../translations/withTranslations'

// @noSnapshot
import { useValue } from '../hooks'

import Button from '../../Button'
import Div from '../../Div'
import visuallyHidden from '../../VisuallyHidden'

const TIME_FOR_MESSAGE_RESET = 3000

// SetTimeOut event token that will be registered to reset the message.
let _timeOutMessageToken = null

function Copy({ className, textCopyButton, tooltipMessageCopied, tooltipMessageCopy }) {
  const [value] = useValue()

  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState(false)

  // Unmount
  useEffect(() => () => clearTimeout(_timeOutMessageToken), [])

  useEffect(() => {
    if (isCopied) {
      setIsCopied(false)
    }
  }, [value])

  useEffect(() => {
    // Reset the message 3 seconds after Copy was clicked.
    if (isCopied) {
      _timeOutMessageToken = setTimeout(() => {
        setIsCopied(false)
      }, TIME_FOR_MESSAGE_RESET)
    }
  }, [isCopied])

  const copyToClipboard = () => {
    const codeRef = document.createElement('textarea')
    codeRef.value = value
    document.body && document.body.appendChild(codeRef)
    codeRef.select()
    document.execCommand('copy')
    document.body && document.body.removeChild(codeRef)
    // Clear last reset setTimeout if needed
    if (_timeOutMessageToken) {
      clearTimeout(_timeOutMessageToken)
    }

    setIsCopied(true)
  }

  return (
    <Div className={className} di="ib">
      <Button
        icon={CopyIcon}
        mode="icon"
        onClick={copyToClipboard}
        small
        tooltip={{
          message: isCopied
            ? tooltipMessageCopied || t('codeeditor.copy.tooltip.copied')
            : tooltipMessageCopy || t('codeeditor.copy.tooltip.copy'),
          direction: 'top',
        }}
      >
        <span style={visuallyHidden}>{textCopyButton || t('codeeditor.copy.button.copy')}</span>
      </Button>
    </Div>
  )
}

Copy.propTypes = {
  // Label for the button
  textCopyButton: PropTypes.string,
  // Tooltip message after click on copy button.
  tooltipMessageCopied: PropTypes.string,
  // Tooltip message before click on copy button.
  tooltipMessageCopy: PropTypes.string,
}

export default styled(withTranslations(Copy))``
