import React from 'react'
import styled from 'styled-components'
import { margins } from '../../helpers'

import { SIZES, STATUS } from './config'
import { InputProvider } from './hooks'

import Base from './components/Base'
import InfoBar from './components/InfoBar'
import ErrorMsg from './components/ErrorMsg'
import InfoMsg from './components/InfoMsg'
import Label from './components/Label'

import GlobalWrapper from './styles/GlobalWrapper'

const UnstyledInput = ({
  autoFocus = false,
  children,
  className,
  cantTypeWhenLoading = false,
  disabled = false,
  isLoading = false,
  infoStatus,
  maxLength,
  readOnly = false,
  required = false,
  size = SIZES.NORMAL,
}) => (
  <InputProvider
    autoFocus={autoFocus}
    cantTypeWhenLoading={cantTypeWhenLoading}
    disabled={disabled}
    infoStatus={infoStatus}
    isLoading={isLoading}
    maxLength={maxLength}
    readOnly={readOnly}
    required={required}
    size={size}
  >
    <GlobalWrapper className={className} disabled={disabled}>
      {children}
    </GlobalWrapper>
  </InputProvider>
)

/** Exposed components */

/** Required label to be linked to input tag through htmlFor attr */
UnstyledInput.Label = Label
/** Use this to wrap input tag and optional icons */
UnstyledInput.Base = Base
/** Use this to wrap Input.Error, Input.Info and Input.Counter in a unique container */
UnstyledInput.InfoBar = InfoBar
/** Use this as the error message of the input.
 * This will be displayed only when infoStatus prop === "error", in place of Input.Info. */
UnstyledInput.Error = ErrorMsg
/** Use this as an informative message about the input (ex. indication on the expected value, type, format, etc.)
 * This will be masked by Input.Error when infoStatus prop === "error".
 */
UnstyledInput.Info = InfoMsg

UnstyledInput.Status = STATUS

const Input = styled(UnstyledInput)`
  ${margins}
`

const InputBase = ({
  id,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  onPressEnter,
  placeholder,
  type,
  value,
  withCopy,
  ...args }) => {
  return (
    <Input {...args}>
      <Input.Base
        data-testid='my-input'
        id={id}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        onPressEnter={onPressEnter}
        placeholder={placeholder}
        type={type}
        value={value}
        withCopy={withCopy}
      />
    </Input>
  )
}

export default InputBase
