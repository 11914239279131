const { getAnd } = require('./getHardDataTranslated')

const getLocaleFromPath = (path) => {
  if (path.includes('/fr/')) {
    return 'fr-FR'
  } else if (path.includes('/de/')) {
    return 'de-DE'
  } else if (path.includes('/es/')) {
    return 'es-ES'
  } else {
    return 'en-US'
  }
}

const getPathWithoutDoubleSlash = (path) =>
  path.length > 1
    ? path.indexOf('//') !== -1
      ? path.replace(/\/{1,}/g, '/')
      : path
    : path

// Add trailing slash and format each slugs of the app. Should be used on every links performing internal navigation.
const sanitizeTrailingSlash = (path) => {
  if (!path) return
  //  Internal link
  if (!path.includes('https://') && !path.includes('http://')) {
    // Remove every special chars but not dash
    const locale = getLocaleFromPath(path)
    path = path
      ? path
          .normalize('NFD')
          .replace('&', getAnd()[locale])
          .replace(/[\u0300-\u036f]/gm, '')
          .replace(/[$&+,:;=?@|'"<>.^*()%!’”“‘]/gm, '')
      : ''
    path = path + '/'

    return getPathWithoutDoubleSlash(path)
  }
  // External link
  else {
    const separator = path.includes('https://') ? 'https://' : 'http://'
    return separator + getPathWithoutDoubleSlash(path.split(separator)[1])
  }
}

module.exports = { sanitizeTrailingSlash }
