import * as React from 'react'
import styled from 'styled-components'
import {
  compact,
  drop,
  filter,
  forEach,
  get,
  includes,
  isEmpty,
  isEqual,
  isNil,
  keys,
  map,
  omit,
  pullAllBy,
  sortBy,
  toLower,
} from 'lodash'
import { Info } from 'component-library/icons'
import MultiSelect from './components/MultiSelect' // eslint-disable-line import/no-cycle
import SingleSelect from './components/SingleSelect' // eslint-disable-line import/no-cycle
import SearchSelect from './components/SearchSelect' // eslint-disable-line import/no-cycle
import Select from './components/Select' // eslint-disable-line import/no-cycle


class InterwovenSelect extends React.Component {
  static defaultProps = {
    actions: [],
    clearInputOnActionClick: false,
    closeOnAction: false,
    closeOnChange: true,
    isPortal: true,
    limitOptionsHeight: false,
    options: [],
    placeholder: '',
    shouldSearchAfterSelect: false,
    showSelectCarret: true,
    small: false,
    sorted: false,
  }

  /**
   * Check if at least one option had its selected boolean changed.
   */
  static hasIsSelectedBooleansChanged = (
    nextOptions,
    optionsSelectedInProps,
  ) =>
    // Must check for explicit differences both ways since `optionsSelectedInProps` only contains true values
    // otherwise `false !== undefined` will trip a false negative
    nextOptions.some(
      o =>
        (optionsSelectedInProps[o.id] && !o.isSelected) ||
        (o.isSelected && !optionsSelectedInProps[o.id]),
    )

  /**
   * Returns a no result option.
   */
  static noResultOption = (noResultText) => ({
    id: 1,
    disabled: true,
    label: noResultText,
    value: null,
    leftIconName: Info,
    leftIconColor: 'grey',
    readOnly: true,
    type: 'option',
  })

  /**
   * Put options in groups by looking to their 'group' attribute.
   * Return an object containing the groups with their options and the options without group.
   */
  static sortOptionsInGroups(options, sorted, maxOptions = Infinity) {
    const groups = {};
    const optionsWithoutGroup = [];

    for (let i = 0; i < Math.min(maxOptions, options.length); i++) {
      const o = options[i];
      if (o.group) {
        if (!groups[o.group]) {
          groups[o.group] = [];
        }
        groups[o.group].push(o);
      } else {
        optionsWithoutGroup.push(o);
      }
    }

    const sortedKeys = sortBy(keys(groups));
    const sortedGroups = {};
    forEach(sortedKeys, k => {
      sortedGroups[k] = sorted ? sortBy(groups[k], ['label']) : groups[k];
    });

    return {
      groups: sortedGroups,
      optionsWithoutGroup: sorted ? sortBy(optionsWithoutGroup, ['label']) : optionsWithoutGroup,
    };
  }

  /**
   * Keep only option which are not already selected.
   * Sort them by text if needed.
   * Return the filtered options.
   */
  static filterOptionsNotSelected(options, sorted) {
    // Sort by text if necessary.
    return sorted ? sortBy(options, ['label']) : options
  }

  /**
   * Filter the options by keeping those which contains the searchValue.
   * Return the filtered options.
   */
  static filterOptionsSearched(
    searchValue,
    options,
    onSearch,
  ) {
    if (!searchValue) {
      return options
    }

    return !!onSearch
      ? options
      : filter(options, o => includes(toLower(o.label), toLower(searchValue)))
  }

  /**
   * Get selected options and filter options again.
   * Return an object containing the selected options and the filtered options.
   */
  static selectOptionsFromProps(options, sorted) {
    const selected = filter(options, opt => opt.isSelected || false)

    const newOptions = InterwovenSelect.filterOptionsNotSelected(options, sorted)

    return {
      options: newOptions,
      selected,
      selectedText: get(selected, '[0].label', ''),
      showSelectedText: true,
    }
  }

  static updateSearch = (
    filterSearch,
    options,
    onSearch,
    searchText,
    sorted,
    maxOptions,
  ) => {
    // If the onSearch method is present, don't filter the options.
    if (!onSearch) {
      // Filter the options.
      let filteredOptions = options

      if (!isEmpty(searchText)) {
        filteredOptions = filterSearch
          ? filter(
              options,
              option => filterSearch && filterSearch(option, searchText),
            )
          : filter(options, option =>
              includes(toLower(option.label), toLower(searchText)),
            )
      }

      return {
        options: filteredOptions,
        searchText,
        ...InterwovenSelect.sortOptionsInGroups(filteredOptions, sorted, maxOptions),
      }
    }

    return {
      searchText,
    }
  }

  /** ***********************
   *       REFERENCES        *
   ************************ */

  // Reference of the option input (only for search select)
  _inputRef = null

  // Reference of the wrapper of the option panel
  _optionPanelRef = null

  // Reference of the wrapper
  _wrapperRef = null

  /** ***********************
   *          STATE          *
   ************************ */
  state = {
    disabled: false,
    isFocus: false,
    isOpened: false,
    propsDefaultSearch: '',
    propsNoResultText: '',
    propsOptions: [],
    propsOptionSelected: {},
    searchText: '',
    ...InterwovenSelect.selectOptionsFromProps(
      this.props.options || [],
      this.props.sorted || false,
    ),
    ...InterwovenSelect.sortOptionsInGroups(
      this.props.options || [],
      this.props.sorted || false,
      this.props.maxOptions,
    ),
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      defaultSearch,
      disabled,
      noResultText,
      options = [],
      sorted = false,
      maxOptions,
    } = nextProps

    let nextState = {}

    if (disabled && !prevState.disabled) {
      // If the select will be disabled, reset the focus and close the options panel.
      nextState = {
        disabled,
        isFocus: false,
        isOpened: false,
      }
    } else if (disabled !== prevState.disabled) {
      // Save the disabled value.
      nextState = {
        disabled,
      }
    }

    let newOptions = options

    // Force re-render if the user change the noResultText
    if (prevState.propsNoResultText !== noResultText) {
      nextState = {
        ...nextState,
        propsNoResultText: noResultText,
      }
    }

    if (
      InterwovenSelect.hasIsSelectedBooleansChanged(options, prevState.propsOptionSelected) ||
      !isEqual(nextProps.options, prevState.propsOptions)
    ) {
      const optionsSelectedInProps = options
        .filter(o => o.isSelected)
        .reduce((acc, o) => {
          acc[o.id] = true;
          return acc;
        }, {});

      const selectOptions = InterwovenSelect.selectOptionsFromProps(options, sorted)

      newOptions = selectOptions.options

      nextState = {
        ...nextState,
        options: newOptions,
        propsNoResultText: noResultText,
        propsOptions: nextProps.options,
        propsOptionSelected: optionsSelectedInProps,
        selected: selectOptions.selected,
        selectedText: selectOptions.selectedText,
        showSelectedText: selectOptions.showSelectedText,
        ...InterwovenSelect.sortOptionsInGroups(newOptions, sorted, maxOptions),
      }
    }

    if (defaultSearch !== prevState.propsDefaultSearch) {
      nextState = {
        ...nextState,
        propsDefaultSearch: defaultSearch,
        propsOptions: nextProps.options,
        ...InterwovenSelect.updateSearch(
          nextProps.filterSearch,
          newOptions,
          nextProps.onSearch,
          nextProps.defaultSearch || '',
          nextProps.sorted || false,
          nextProps.maxOptions,
        ),
      }
    }

    return isEmpty(nextState) ? null : nextState
  }

  /** ***********************
   *        LIFECYCLE        *
   ************************ */

  componentDidMount() {
    // Add an event listener to listen about clicks outside the select.
    document.addEventListener('mousedown', this.handleClickOutside, true)
    document.addEventListener('focus', this.handleFocusDocument, true)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
    document.removeEventListener('focus', this.handleFocusDocument)
  }

  /** ***********************
   *        HANDLERS         *
   ************************ */

  handleActionClick = (action) => {
    const { id, onClick } = action
    const { clearInputOnActionClick, closeOnAction } = this.props
    if (clearInputOnActionClick) {
      this.setState({
        searchText: '',
      })
    }
    onClick && onClick(id)
    closeOnAction && this.close()
  }

  // Add the given option to the selected options
  // And, if necessary :
  // - Remove it from the options
  // - Sort the options
  // - Close the options panel
  handleAdd = (option) => {
    const { multi, options = [], sorted = false, maxOptions } = this.props
    const { selected } = this.state

    const selection = {
      ...option,
      isSelected: true,
    }

    //  Single select case
    const newSelected = multi ? [...selected, selection] : [selection]
    const selectedId = map(newSelected, 'id')

    // Reset the selected attribute
    let newOptions = map(options, opt => ({
      ...opt,
      isSelected: includes(selectedId, opt.id),
    }))

    // Empty input
    const searchText = ''
    const selectedText = option.label

    // If needed, sort the options
    if (sorted) {
      newOptions = sortBy(newOptions, ['label'])
    }

    this.setState(
      {
        options: newOptions,
        selected: newSelected,
        searchText,
        selectedText,
        ...InterwovenSelect.sortOptionsInGroups(newOptions, sorted, maxOptions),
      },
      () => {
        const { closeOnChange, onSelect, onSearch, shouldSearchAfterSelect } = this.props
        this.focus()
        onSelect && onSelect(selection.id, map<PropsOptionType>(this.state.selected, 'id'))
        onSearch && (!onSelect || shouldSearchAfterSelect) && onSearch(selection.label)
        closeOnChange && this.close()
      },
    )
  }

  /**
   * Remove the focus from the component
   */
  handleBlur = () => {
    const { onBlur } = this.props

    this.setState({ isFocus: false }, () => {
      onBlur && onBlur()
      this.blur()
    })
  }

  // Callback when an option is selected by the user
  // If the option is already selected, remove the option from the selected one
  // Else, add it to the selected
  handleChange = (option) => {
    if (!isEmpty(filter(this.state.selected, opt => opt.id === option.id)) && this.props.multi) {
      this.handleDelete(option)
    } else {
      this.handleAdd(option)
    }
  }

  /**
   * Close the select when the user clicks outside.
   */
  handleClickOutside = (e) => {
    const { isOpened } = this.state

    if (
      isOpened &&
      this._wrapperRef &&
      !this._wrapperRef.contains(e.target) &&
      this._optionPanelRef &&
      !this._optionPanelRef.contains(e.target)
    ) {
      this.close(e)
    }
  }

  handleClose = () => {
    const { onClose } = this.props

    this.setState(
      {
        searchText: '',
        showSelectedText: true,
      },
      () => onClose && onClose(),
    )
  }

  /**
   * Put the deleted selected options back in the list.
   */
  handleDelete = (option) => {
    const { selected } = this.state
    const { options, sorted = false, maxOptions } = this.props

    const selection = {
      ...option,
      isSelected: false,
    }

    // Remove it from the selected
    const newSelected = filter(selected, select => select.id !== selection.id)

    const selectedId = map(newSelected, 'id')

    let newOptions = map(options, opt => ({
      ...opt,
      isSelected: includes(selectedId, opt.id),
    }))

    // If needed, sort the options
    if (sorted) {
      newOptions = sortBy(newOptions, ['label'])
    }

    this.setState(
      {
        options: newOptions,
        selected: newSelected,
        searchText: '',
        ...InterwovenSelect.sortOptionsInGroups(newOptions, sorted, maxOptions),
      },
      () => {
        const { onDelete } = this.props
        onDelete && onDelete(option.id || '', map<PropsOptionType>(this.state.selected, 'id'))
      },
    )
  }

  /**
   * Set the focus on the component
   */
  handleFocus = () => {
    const { onFocus } = this.props

    this.setState(
      {
        isFocus: true,
      },
      () => {
        onFocus && onFocus()
        this._inputRef && this._inputRef.focus()
      },
    )
  }

  /**
   * If the user changes of input by pressing tab, it closes the option panel
   */
  handleFocusDocument = (e) => {
    const { isFocus } = this.state

    // If the user change the focus, and the new element in focus is not the options panel,
    // then, close the panel
    if (
      isFocus &&
      this.isSelectedOpened() &&
      this._wrapperRef &&
      !this._wrapperRef.contains(e.target) &&
      this._optionPanelRef &&
      !this._optionPanelRef.contains(e.target)
    ) {
      this.close()
    }
  }

  /**
   * On search, register the new search value.
   * If the user doesn't passed onSearch props, filter the options by the search value.
   */
  handleSearch = (
    event,
  ) => {
    const { value = '' } = event.currentTarget
    const { filterSearch, options = [], onSearch, sorted = false, maxOptions } = this.props
    const { selected } = this.state

    // If the onSearch method is present, don't filter the options.
    if (!onSearch) {
      // Filter the options.
      let filteredOptions = options

      if (!isEmpty(value)) {
        filteredOptions = filterSearch
          ? filter(options, option => filterSearch(option, value))
          : filter(options, option => includes(toLower(option.label), toLower(value)))
      }

      const newOptions = pullAllBy([...filteredOptions], selected, 'id')

      this.setState({
        options: newOptions,
        searchText: value,
        showSelectedText: false,
        ...InterwovenSelect.sortOptionsInGroups(newOptions, sorted, maxOptions),
      })

      return
    }

    // Call the onSearch props.
    // User will have to pass new options props to refresh them.
    onSearch && onSearch(value)

    this.setState({
      searchText: value,
      showSelectedText: false,
    })
  }

  /** ***********************
   *      REF SETTERS        *
   ************************ */

  /**
   * Register a reference of the input tag.
   */
  setInputRef = (node) => {
    this._inputRef = node
  }

  /**
   * Register a reference on the wrapper of the option panel.
   */
  setOptionPanelRef = (ref) => {
    this._optionPanelRef = ref
  }

  /**
   * Register a reference on the wrapper component.
   */
  setWrapperRef = (ref) => {
    this._wrapperRef = ref
  }

  /** ***********************
   *           OTHERS        *
   ************************ */

  /**
   * Returns true if the select has the focus.
   */
  isSelectedOpened = () => (!isNil(this.props.isOpened) ? this.props.isOpened : this.state.isOpened)

  /**
   * Blur the input
   */
  blur = () => {
    this._inputRef && this._inputRef.blur()
  }

  /**
   * Close the select.
   */
  close = (e) => {
    const { onClose } = this.props

    if (!!e && this._inputRef && e.target === this._inputRef) {
      return
    }

    this.setState(
      {
        isOpened: false,
        showSelectedText: true,
      },
      () => {
        this.handleBlur()
        onClose && onClose()
      },
    )
  }

  /**
   * Set the focus on the input
   */
  focus = () => {
    this._inputRef && this._inputRef.focus()
  }

  /**
   * Indicate if the error message should be displayed.
   */
  hasError = () => !!this.props.error || !!this.props.errorMessage

  /**
   * Indicate if the selected text should be shown
   * The selected text is only displayed if the select is a single search one
   */
  shouldShowSelectedText = () =>
    this.props.search &&
    !this.props.multi &&
    this.state.showSelectedText &&
    !isEmpty(this.state.selectedText)

  /**
   * Open the select.
   */
  open = () => {
    const { onOpen } = this.props
    const { searchText } = this.state

    this.handleFocus()

    this.setState(
      {
        isOpened: true,
        showSelectedText: isEmpty(searchText),
      },
      () => onOpen && onOpen(),
    )
  }

  /**
   * Toggle the opening of the select.
   */
  toggleOpening = (e) => {
    this.state.isOpened ? this.close(e) : this.open()
  }

  /** ***********************
   *        RENDER           *
   ************************ */

  renderActions = () => {
    const { actions, 'data-testid': dataTestId = '' } = this.props

    if (isEmpty(actions)) {
      return []
    }

    return [
      ...map(actions, a => ({
        disabled: a.disabled,
        'data-testid': get(a, 'data-testid') || `${dataTestId}-action-${a.id}`,
        id: a.id,
        label: a.label,
        onClick: () => this.handleActionClick(a),
        type: 'option',
        leftIconName: a.leftIconName,
        leftIconColor: a.leftIconColor,
      })),
      {
        id: 'dividerForActionsReceived',
        type: 'divider',
        label: '',
      },
    ]
  }

  /**
   * Render the options, beginning with the option without group.
   */
  // eslint-disable-next-line no-unused-vars
  renderOptions = (onSelect) => {
    const { groups, optionsWithoutGroup } = this.state
    const { 'data-testid': dataTestId = '' } = this.props
    const toRender = []

    // Render the single options
    forEach(optionsWithoutGroup, opt => {
      const id = get(opt, 'id', opt.value) || ''
      toRender.push({
        id: id,
        'data-testid': get(opt, 'data-testid') || `${dataTestId}-opt-${id}`,
        disabled: opt.disabled,
        isChecked: opt.isChecked,
        isSelected: opt.isSelected || false,
        label: opt.label,
        leftIconColor: opt.leftIconColor,
        leftIconName: opt.leftIconName,
        leftIconOnClick: opt.leftIconOnClick,
        leftImage: opt.leftImage,
        locator: opt.locator,
        rightLabel: opt.rightLabel,
        rightIconName: opt.rightIconName,
        rightIconOnClick: opt.rightIconOnClick,
        options: opt.options,
        styleOption: opt.styleOption,
        subOptionsDirection: opt.subOptionsDirection,
        onClick: () => {
          onSelect(opt)
          opt.onClick && opt.onClick(opt.id)
        },
        tooltip: opt.tooltip,
        type: opt.type || 'option',
      })
    })

    // Render the groups.
    forEach(groups, (opts, group) => {
      toRender.push({
        label: group,
        type: 'title',
      })

      forEach(opts, opt => {
        toRender.push({
          id: get(opt, 'id', opt.value) || '',
          disabled: opt.disabled,
          isChecked: opt.isChecked,
          isSelected: opt.isSelected || false,
          label: opt.label,
          leftIconColor: opt.leftIconColor,
          leftIconName: opt.leftIconName,
          leftIconOnClick: opt.leftIconOnClick,
          rightLabel: opt.rightLabel || '',
          rightIconName: opt.rightIconName,
          rightIconOnClick: opt.rightIconOnClick,
          options: opt.options,
          styleOption: opt.styleOption,
          subOptionsDirection: opt.subOptionsDirection,
          onClick: () => {
            onSelect(opt)
            opt.onClick && opt.onClick(opt.id)
          },
          tooltip: opt.tooltip,
          type: opt.type || 'option',
        })
      })
    })

    if (isEmpty(toRender)) {
      // No options, add the "no result" option.
      toRender.push(InterwovenSelect.noResultOption(this.state.propsNoResultText))
    }

    return toRender
  }

  render() {
    const { autoContrast, className, disabled, multi, search, ...rest } = this.props

    const open = !disabled ? this.open : () => undefined
    const toggle = !disabled ? this.toggleOpening : this.isSelectedOpened() ? this.close : undefined

    // Add options renderer.
    const optionsToRender = [
      ...this.renderActions(),
      ...this.renderOptions(this.handleChange),
    ]

    const selectToGenerate = compact([
      search && SearchSelect,
      multi && MultiSelect,
      !multi && SingleSelect,
      Select,
    ])

    const propsToPass = {
      ...rest,
      ...omit(this.state, 'options'),
      /**
       * These are props for the Select component defined in this file that were passed
       * to the downstream components, but they were not defined in those components.
       * actions: actions,
       * maxOptions,
       * onBlur: this.handleBlur,
       * onChange: this.handleChange,
       * onChangeArray: [],
       * onFocus: this.handleFocus,
       * renderOptionsArray: [],
       * preventClose: [],
       * hasNoError: this.hasNoError(),
       */
      autoContrast,
      className,
      disabled,
      isOpened: this.isSelectedOpened(),
      multi,
      onDelete: this.handleDelete,
      onSearch: this.handleSearch,
      onSelectedTextClick: this.focus,
      open,
      options: optionsToRender,
      selectToGenerate: drop(selectToGenerate),
      setInputRef: this.setInputRef,
      setOptionPanelRef: this.setOptionPanelRef,
      setWrapperRef: this.setWrapperRef,
      shouldShowSelectedText: this.shouldShowSelectedText(),
      toggle: toggle,
    }

    const Selected = selectToGenerate[0]
    return <Selected {...propsToPass} />
  }
}
export { InterwovenSelect as DocSelect }
export default styled(InterwovenSelect)``
