import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import withTranslations from '../../translations/withTranslations'
import { Copy as CopyIcon } from 'component-library/icons'

import { margins } from '../../helpers'

import Button from '../Button'
import visuallyHidden from '../VisuallyHidden'

const TIME_FOR_MESSAGE_RESET = 3000

const Copy = ({
  className,
  codeRef,
  'data-testid': dataTestId,
  textCopyButton,
  tooltipMessageCopied,
  tooltipMessageCopy,
  value,
}) => {
  const { t } = useTranslation()
  const [isCopied, setIsCopied] = useState(false)
  const [htmlCodeRef, setHtmlCodeRef] = useState()
  let copyValue = htmlCodeRef && htmlCodeRef.value

  useEffect(() => {
    if (codeRef) {
      setHtmlCodeRef(codeRef.current)
    }
    copyValue = htmlCodeRef && htmlCodeRef.value
  }, [codeRef])

  useEffect(() => setIsCopied(false), [copyValue])

  useEffect(() => {
    let timeOutMessageToken = null
    // Reset the message 3 seconds after Copy was clicked.
    if (isCopied) {
      timeOutMessageToken = setTimeout(() => {
        setIsCopied(false)
      }, TIME_FOR_MESSAGE_RESET)
    }
    return () => clearTimeout(timeOutMessageToken)
  }, [isCopied])

  const copyToClipboard = async () => {
    try {
      // Copy value if defined
      if (value) {
        await navigator.clipboard.writeText(value)

        setIsCopied(true)
        return
      }
      // Copy ref value if defined
      if (htmlCodeRef) {
        htmlCodeRef.select()
        document.execCommand('copy')

        setIsCopied(true)
      }
    } catch (e) {
      setIsCopied(false)
    }
  }

  return (
    <Button
      className={className}
      data-testid={dataTestId}
      icon={CopyIcon}
      mode="icon"
      onClick={copyToClipboard}
      small
      tooltip={{
        message: isCopied
          ? tooltipMessageCopied || t('codeeditor.copy.tooltip.copied')
          : tooltipMessageCopy || t('codeeditor.copy.tooltip.copy'),
        direction: 'top',
      }}
    >
      <span style={visuallyHidden}>{textCopyButton || t('codeeditor.copy.button.copy')}</span>
    </Button>
  )
}

Copy.propTypes = {
  /**
   * Additional class names
   */
  className: PropTypes.string,
  /**
   * The ref of the component whose value must be copied
   */
  codeRef: PropTypes.object,
  /**
   * The data-testid for automated testing
   */
  'data-testid': PropTypes.string,
  /**
   * Label for the button
   */
  textCopyButton: PropTypes.string,
  /**
   * Tooltip message after click on copy button
   */
  tooltipMessageCopied: PropTypes.string,
  /**
   * Tooltip message before click on copy button
   */
  tooltipMessageCopy: PropTypes.string,
  /**
   * Value to be copied (has priority before codeRef)
   */
  value: function (props, propName, componentName) {
    if (props['codeRef'] === undefined && props[propName] === undefined) {
      return new Error(`At least ${propName} or codeRef are required by ${componentName}`)
    }
    if (props[propName] !== undefined && typeof props[propName] !== 'string') {
      return new Error(`${propName} should be a string`)
    }
  },
}

export default styled(withTranslations(Copy))`
  ${margins};
`
