import React from 'react'

const SendSVG = () => (
  <svg
    fill='none'
    height='17'
    viewBox='0 0 17 17'
    width='17'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.56261 4.81245C0.752098 5.08262 0.631739 6.17906 1.36434 6.61863L6.33705 9.60225L9.96963 5.96967C10.2625 5.67677 10.7374 5.67677 11.0303 5.96967C11.3232 6.26256 11.3232 6.73743 11.0303 7.03033L7.39771 10.6629L10.3813 15.6356C10.8209 16.3682 11.9173 16.2479 12.1875 15.4374L16.8675 1.39736C17.1281 0.615607 16.3844 -0.128134 15.6026 0.132452L1.56261 4.81245Z'
      fill='#EB5454'
    />
  </svg>
)

export default SendSVG
