import React, { createContext, useContext } from 'react'

import { LOC_EN } from './config'

const defaultLocale = LOC_EN

const TranslationsContext = createContext(defaultLocale)

const useTranslationsContext = () => useContext(TranslationsContext)

const TranslationsProvider = ({ children, locale }) => (
  <TranslationsContext.Provider value={locale || defaultLocale}>
    {children}
  </TranslationsContext.Provider>
)

export { defaultLocale, TranslationsProvider, useTranslationsContext }
