import React from 'react'
import RemoteMailjetSVG from './RemoteSVG/mj_logo_with_text_color'

const MailjetLogoSVG = () => {
  const svgString = RemoteMailjetSVG.replace('width="357" height="116"', '')

  return <div dangerouslySetInnerHTML={{ __html: svgString }} />
}

export { MailjetLogoSVG, RemoteMailjetSVG as MailjetLogoSVGString }
