import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Odometer = props => (
    <StyledIcon viewBox="0 0 24 24" {...props}>
      <g><path d="M8.363 8.016s7.385 7.799 8.138 10.177c.754 2.378-.65 4.89-3.134 5.612-2.485.721-5.11-.622-5.864-3-.753-2.378.86-12.789.86-12.789zM12.003 0c4.81 0 9.254 2.687 11.324 6.846L24 8.201l-2.83 1.29-.673-1.356C18.945 5.016 15.61 3 12.002 3 8.391 3 5.055 5.019 3.504 8.143L2.83 9.498 0 8.211l.673-1.355C2.74 2.69 7.188 0 12.003 0z"  fillRule="evenodd"/></g>
    </StyledIcon>
  )

export default styled(Odometer)``
