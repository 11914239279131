import React from 'react'

const GuidesEbooksSVG = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M9 14H8C6.89543 14 6 14.8954 6 16V35C6 36.1046 6.89543 37 8 37H20.0557C20.6767 37 21.2892 37.1446 21.8446 37.4223L22.1554 37.5777C22.7108 37.8554 23.3233 38 23.9443 38H24.2297C24.7385 38 25.2428 37.9029 25.7152 37.7139L26.7848 37.2861C27.2572 37.0971 27.7615 37 28.2703 37H40C41.1046 37 42 36.1046 42 35V16C42 14.8954 41.1046 14 40 14H39V32.5C39 33.3284 38.3284 34 37.5 34H27.3028C26.4234 34 25.5637 34.2603 24.8321 34.7481C24.3282 35.084 23.6718 35.084 23.1679 34.7481C22.4363 34.2603 21.5766 34 20.6972 34H10.5C9.67157 34 9 33.3284 9 32.5V14Z'
      fill='#090E12'
    />
    <path
      d='M22.2064 11.9861L22.5 12.2209V20C22.5 20.8284 23.1716 21.5 24 21.5C24.8284 21.5 25.5 20.8284 25.5 20V12.2209L25.7936 11.9861C26.5915 11.3478 27.5829 11 28.6047 11H36V31H27.3028C26.1529 31 25.0232 31.2659 24 31.7716C22.9768 31.2659 21.8471 31 20.6972 31H12V11H19.3953C20.4171 11 21.4085 11.3478 22.2064 11.9861Z'
      fill='#090E12'
    />
  </svg>
)

export default GuidesEbooksSVG
