import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AlignBottom = props => (
    <StyledIcon viewBox="0 0 12 11" {...props}>
      <g><path d="M1.5 9h4V0h-4zm1-8h2v7h-2zm4 2.52h4V9h-4zM0 10h12v1H0z"/></g>
    </StyledIcon>
  )

export default styled(AlignBottom)``
  