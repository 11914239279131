import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Expand = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M10.623 4.702l1.127-1.086L8 0 4.25 3.616l1.127 1.086 1.826-1.761v10.118l-1.826-1.761-1.127 1.086L8 16l3.75-3.616-1.127-1.086-1.826 1.761V2.941z"/></g>
    </StyledIcon>
  )

export default styled(Expand)``
  