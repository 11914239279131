import styled from 'styled-components'

const Marker = styled('div')`
  border-radius: ${({ theme }) => theme.globals.borderRadius.borderRadiusCircle};
  width: ${({ size, theme }) =>
    (size && theme.sizes[size]) || (size && theme.sizes.sizes[size]) || size};
  height: ${({ size, theme }) =>
    (size && theme.sizes[size]) || (size && theme.sizes.sizes[size]) || size};
  background-color: ${({ color, theme }) => (color ? theme.colors[color] || color : 'inherit')};
`

export default Marker
