import styled from 'styled-components'
import { Caption } from '../../Typography'

const InfoMsg = styled(Caption)`
  &.isHidden {
    display: none;
  }
`

export default InfoMsg
