import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const StrikeThrough = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M12 5.33H0v1.34h6a2 2 0 0 1 0 4H4V10H2.67v1.33a.67.67 0 0 0 .67.67H6a3.34 3.34 0 0 0 3.33-3.33 3.3 3.3 0 0 0-.68-2H12zM4.12 4A2 2 0 0 1 4 3.33a2 2 0 0 1 2-2h1.33V2h1.34V.67A.67.67 0 0 0 8 0H6a3.33 3.33 0 0 0-3.27 4z"/></g>
    </StyledIcon>
  )

export default styled(StrikeThrough)``
  