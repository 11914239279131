import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Cross = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M12 1.2L10.8 0 6 4.8 1.2 0 0 1.2 4.8 6 0 10.8 1.2 12 6 7.2l4.8 4.8 1.2-1.2L7.2 6 12 1.2z"/></g>
    </StyledIcon>
  )

export default styled(Cross)``
  