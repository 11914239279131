import styled from 'styled-components'
import { display, margins } from '../../../helpers'

/**
 * The notification block, for LabelledWrapper's children. For example, this can be an error notification.
 */
const InformationErrorBlock = styled.div`
  ${display};
  ${margins};
  transition: margin-top 0.5s;
`

export default InformationErrorBlock
