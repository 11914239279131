import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Copy = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M2.444 2.91H1v11.635A1.45 1.45 0 0 0 2.444 16h8.667v-1.455H2.444V2.91zM10.688 0H5.333A1.45 1.45 0 0 0 3.89 1.455v10.181a1.45 1.45 0 0 0 1.444 1.455h7.223a1.45 1.45 0 0 0 1.444-1.455v-8.3L10.688 0zm1.868 11.636H5.333V1.455h4.334v2.909h2.889v7.272z"/></g>
    </StyledIcon>
  )

export default styled(Copy)``
  