import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Tumblr = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M11.3 12.724s-.47.546-1.36.546c-.89 0-1.288-.546-1.288-1.353V7.343h2.879V4.572H8.652V0H6.484A6.179 6.179 0 0 1 3 4.598v2.745h2.114v5.267c0 .731.68 3.39 4.147 3.39 2.038 0 2.882-1.33 2.882-1.33l-.844-1.946z"/></g>
    </StyledIcon>
  )

export default styled(Tumblr)``
  