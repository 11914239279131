import styled, { css } from 'styled-components'

import { ChevronSVG } from '@icons'

export const IconPart = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;

    svg {
      height: ${theme.sizes.s8};
      width: ${theme.sizes.s8};
    }

    & * {
      fill: ${theme.colors.primary40};
    }

    ${ChevronSVG} {
      height: ${theme.sizes.s13};
      width: ${theme.sizes.s13};
    }
  `}
`
