import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const LineHeight = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M5.4 4.472L3.851 2.943v10.114l1.547-1.528.955.943-3.177 3.138L0 12.472l.954-.943 1.548 1.528V2.943L.954 4.472 0 3.529 3.177.391l3.177 3.138-.955.943zM9.25 0H16v1.333H9.25V0zM7.227 2.667H16V4H7.226V2.667zm0 2.666H16v1.334H7.226V5.333zm2.025 4H16v1.334H9.25V9.333zM7.226 12H16v1.333H7.226V12zm0 2.667H16V16H7.226v-1.333z"/></g>
    </StyledIcon>
  )

export default styled(LineHeight)``
  