import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Github = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M11.682 2c.208.472.342 1.257.141 1.831.823.702 1.105 2.409.708 3.804 1.172.09 2.568-.043 3.469.316-.842-.108-1.993-.32-3.08-.246-.205.014-.51-.008-.495.281 1.258.099 2.507.207 3.54.529-1.024-.167-2.267-.422-3.54-.458-.526 1.038-1.584 1.547-3.044 1.655.157.342.458.471.531 1.021.11.821-.171 2.023.106 2.57.133.263.35.27.496.494-.358.423-1.245-.048-1.346-.494-.173-.763.264-1.947-.212-2.464.034.832-.197 2.002.036 2.746.091.293.356.406.283.67-1.663.168-.968-2.152-1.24-3.522-.25.018-.14.375-.141.528-.007 1.338.274 3.172-1.168 2.994-.042-.282.193-.377.283-.634.263-.752-.05-1.874.071-2.782-.55.415.058 1.863-.283 2.605-.196.428-.83.614-1.31.423.063-.318.392-.267.531-.563.195-.414.001-1.01.07-1.62-1.018.203-1.808-.03-2.194-.67-.173-.286-.216-.625-.425-.88-.208-.254-.54-.288-.637-.633 1.261-.303 1.31 1.298 2.442 1.338.348.012.528-.1.85-.176.09-.404.282-.705.566-.916-1.41-.193-2.573-.632-3.115-1.69a19.32 19.32 0 0 0-3.54.493c.986-.358 2.202-.486 3.505-.528-.077-.462-.75-.335-1.168-.317-.777.034-1.755.13-2.372.246.895-.319 2.15-.279 3.363-.281-.37-1.163-.242-3.01.567-3.698C3.7 3.394 3.772 2.45 4.07 2c.903.04 1.45.434 2.018.81.703-.2 1.443-.3 2.407-.246.405.022.827.204 1.132.175.3-.028.63-.364.92-.492.38-.168.7-.231 1.134-.247z"/></g>
    </StyledIcon>
  )

export default styled(Github)``
  