import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Export = props => (
    <StyledIcon viewBox="0 0 12 10" {...props}>
      <g><path d="M9.27 1L12 3.75 9.27 6.51l-.87-.89 1.23-1.24H6.18a1.24 1.24 0 0 0-1.24 1.24v.62H3.71v-.62a2.49 2.49 0 0 1 2.47-2.5h3.45L8.4 1.88zM6.8 8.75V6.87H8V10H0V0h8v1.25H1.24v7.5z"/></g>
    </StyledIcon>
  )

export default styled(Export)``
  