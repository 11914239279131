import styled from 'styled-components'
import Image from '@components/Image'
import { styleForMobile } from '@utils'

export const IconRight = styled(Image)`
  display: flex;
  margin-left: ${({ theme }) => theme.sizes.s6};

  svg {
    height: ${({ theme }) => theme.sizes.s13};
    width: ${({ theme }) => theme.sizes.s13};

    & * {
      fill: transparent;
    }
  }

  ${styleForMobile} {
    margin-left: ${({ theme }) => theme.sizes.s3};
    padding-left: 0;
  }
`
