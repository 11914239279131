import styled, { css } from 'styled-components'

export const AnimatedIncrementNumber = styled.span`
  ${({ isAnimating = false, value = 0 }) => css`
    @property --num-${value} {
      inherits: false;
      initial-value: 0;
      syntax: '<integer>';
    }

    ::before {
      ${isAnimating && `animation: count-${value} 1.5s ease-out forwards;`}
      content: counter(my-counter-${value});
      counter-reset: my-counter-${value} var(--num-${value});
    }

    @keyframes count-${value} {
      to { --num-${value}: ${value};}
    }
  `}
`
