import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const MultipleFolder = props => (
    <StyledIcon viewBox="0 0 12 10.8" {...props}>
      <g><path d="M1.2 1.8H0v7.8a1.2 1.2 0 0 0 1.2 1.2h9V9.6h-9z"/><path d="M10.8 1.8h-3L6.81.48a1.21 1.21 0 0 0-1-.48H3.6a1.2 1.2 0 0 0-1.2 1.2v6a1.2 1.2 0 0 0 1.2 1.2h7.2A1.2 1.2 0 0 0 12 7.2V3a1.2 1.2 0 0 0-1.2-1.2zM3.6 7.2v-6h2.25l1 1.32A1.21 1.21 0 0 0 7.8 3h3v4.2z"/></g>
    </StyledIcon>
  )

export default styled(MultipleFolder)``
  