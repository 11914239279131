import React from 'react'
import styled from 'styled-components'
import { SvgWithRotation } from '@styles'

const ChevronSVG = styled((props) => (
  <SvgWithRotation
    fill='none'
    height='16'
    viewBox='0 0 16 16'
    width='16'
    {...props}
  >
    <path
      clipRule='evenodd'
      d='M5.29289 12.7072C4.90237 12.3166 4.90237 11.6835 5.29289 11.293L8.58579 8.00006L5.29289 4.70717C4.90237 4.31664 4.90237 3.68348 5.29289 3.29295C5.68342 2.90243 6.31658 2.90243 6.70711 3.29295L10.7071 7.29295C11.0976 7.68348 11.0976 8.31664 10.7071 8.70717L6.70711 12.7072C6.31658 13.0977 5.68342 13.0977 5.29289 12.7072Z'
      fill='#090E12'
      fillRule='evenodd'
    />
  </SvgWithRotation>
))`
  ${({ fill = 'neutral60', height = '15px', theme, width = '10px' }) => `
    &, & * {     
      fill: ${theme.colors[fill]};
    }
    
    height: ${theme.sizes[height] || height};
    width: ${theme.sizes[width] || width};
  `}
`
// Todo: if sizing SVG using property need to be used elsewhere, can create an helper

export default ChevronSVG
