import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { ChevronDown, ChevronRight } from 'component-library/icons'
import LinkAccordionContent from './styles/LinkAccordionContent'
import StyledLinkButton from './styles/StyledLinkButton'

class LinkAccordion extends PureComponent {
  static defaultProps = {
    iconSize: 's3',
    opened: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      opened: this.props.opened,
    }
  }

  componentDidUpdate(prevProps) {
    const { opened } = this.props
    if (opened !== prevProps.opened && opened !== this.state.opened) {
      this.setState({ opened })
    }
  }

  onClickAccordion = () => {
    this.setState(prevState => ({ opened: !prevState.opened }))
  }

  render() {
    const {
      className,
      content,
      'data-testid': dataTestId,
      disabled,
      iconSize,
      isLoading,
      label,
      locator,
    } = this.props
    const { opened } = this.state

    return (
      <div className={className}>
        <StyledLinkButton
          className={`${className}-linkButton`}
          data-testid={dataTestId}
          disabled={disabled}
          icon={opened ? ChevronDown : ChevronRight}
          iconSize={iconSize}
          isLoading={isLoading}
          label={label ?? ''}
          locator={locator}
          onClick={this.onClickAccordion}
        />
        {opened && <LinkAccordionContent>{content}</LinkAccordionContent>}
      </div>
    )
  }
}

export { LinkAccordion as DocLinkAccordion }
export default styled(LinkAccordion)``