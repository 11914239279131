import styled, { css } from 'styled-components'

import { Caption1 } from '@typography'
import { styleForTablet } from '@utils'

export const TopNavigation = styled.ul`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: 1248px;
    padding: 0 ${theme.sizes.s4};

    li {
      list-style: none;
    }

    ${Caption1} {
      color: ${theme.colors.neutral70};
    }

    ${styleForTablet} {
      padding: 0 ${theme.sizes.s5};
    }
  `}
`
