import styled from 'styled-components'
import Loader from '../../Loader'

const BaseLoader = styled(Loader)`
  ${({ theme }) => `
    margin-right: ${theme.sizes.margins.m4};
    border-color: ${theme.components.input.loader};
    border-top-color: ${theme.components.input.background};
    backface-visibility: hidden; /* for anti-aliasing */
  `}
`

export default BaseLoader
