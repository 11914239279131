import styled from 'styled-components'
import StyledSkeleton from '../styles/StyledSkeleton'

const CustomSkeleton = styled(StyledSkeleton).attrs(
  ({
    bR = false,
    h,
    w,
    theme: {
      globals: { borderRadius },
      sizes,
    },
  }) => ({
    bR: bR && borderRadius.bR1,
    h: h || sizes.s12,
    w: w || '100%',
  }),
)``


export default CustomSkeleton
