import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import withTranslations from '../../translations/withTranslations'
import { useTranslation } from 'react-i18next'
import { ArrowUp } from 'component-library/icons'
import StyledButton from './styles/StyledButton'
import { Body2Medium } from '../Typography'

const BackToTop = ({
  children,
  className,
  'data-testid': dataTestId,
  elementRef = {},
  fromScrollTop = 20,
  refToScrollTo = {},
  top = 's5',
  zIndex = 100,
}) => {
  const { t } = useTranslation()
  const [hidden, setHiddenStatus] = useState(true)
  const [lastScrollTop, setLastScrollTop] = useState(0)

  useEffect(() => {
    const { current = {} } = elementRef
    const eventElement = elementRefExists() ? current : window
    if (eventElement != undefined) {
      eventElement.addEventListener('scroll', scrollFunction)
    }
    return () => eventElement.removeEventListener('scroll', scrollFunction)
  })

  const isScrollingUp = () => {
    const { current = {} } = elementRef
    const scrollTop = elementRefExists()
      ? current.scrollTop
      : document.body.scrollTop || document.documentElement.scrollTop
    return lastScrollTop > scrollTop
  }

  const elementRefExists = () => !!(elementRef && elementRef.current)

  const scrollToDestination = () => {
    if (!!refToScrollTo) {
      scrollToRef()
    } else {
      backToTop()
    }
  }

  const scrollToRef = () => {
    const yOffset = get(refToScrollTo, 'refToScrollTo.current.offsetTop', 0)
    backToTop(yOffset)
  }

  const backToTop = (yOffset = 0) => {
    const { current = {} } = elementRef
    if (elementRefExists()) {
      current.scrollTop = 0
    } else if (document && document.body) {
      document.body.scrollTop = yOffset
      document.documentElement.scrollTop = yOffset
    }
  }

  const scrollFunction = () => {
    const { current = {} } = elementRef

    const scrollTop = elementRefExists()
      ? current.scrollTop
      : document.body.scrollTop || document.documentElement.scrollTop

    // Update the last scrollTop position
    setHiddenStatus(!(isScrollingUp() && scrollTop >= fromScrollTop))
    setLastScrollTop(scrollTop)
  }

  return (
    <StyledButton
      className={className}
      data-testid={dataTestId}
      hidden={hidden}
      icon={ArrowUp}
      isAnchoredToRef={elementRefExists()}
      mode="tertiary"
      onClick={scrollToDestination}
      top={top}
      zIndex={zIndex}
    >
      <Body2Medium as="span">{children || t('global.string.back-to-top')}</Body2Medium>
    </StyledButton>
  )
}

BackToTop.propTypes = {
  children: PropTypes.string,
  /** Class names. */
  className: PropTypes.string,
  /** The data-testid */
  'data-testid': PropTypes.string,
  /* The reference to the element to anchor to. Optionnal. */
  elementRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.element }),
    PropTypes.shape({
      current: PropTypes.instanceOf(typeof Element === 'undefined' ? () => undefined : Element),
    }),
  ]),
  /** Limit in px from which BackToTop can be used */
  fromScrollTop: PropTypes.number,
  /* The reference to the element to scroll to. Optionnal. */
  refToScrollTo: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.element }),
  ]),
  /** Value of the distance from top of screen,
      it can be either a value in px or from the theme.sizes array */
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** zIndex applied to the component */
  zIndex: PropTypes.number,
}

export default withTranslations(BackToTop)
