import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ConditionnalDisplay = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M14 10v3.778C14 15.005 13.186 16 12.182 16H10v-2.667h1.818V2.667H10V0h2.182C13.186 0 14 .995 14 2.222V6h2v4h-2zM2 6V2.222C2 .995 2.814 0 3.818 0H6v2.667H4.182v10.666H6V16H3.818C2.814 16 2 15.005 2 13.778V10H0V6h2z"/></g>
    </StyledIcon>
  )

export default styled(ConditionnalDisplay)``
  