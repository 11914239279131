import React from 'react'

const PodcastsSVG = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18 14C18 10.6863 20.6863 8 24 8C27.3137 8 30 10.6863 30 14V22C30 25.3137 27.3137 28 24 28C20.6863 28 18 25.3137 18 22V14Z'
      fill='#090E12'
    />
    <path
      d='M26 34.8471C32.2302 33.8851 37 28.4996 37 22C37 20.8954 36.1046 20 35 20C33.8954 20 33 20.8954 33 22C33 26.9706 28.9706 31 24 31C19.0294 31 15 26.9706 15 22C15 20.8954 14.1046 20 13 20C11.8954 20 11 20.8954 11 22C11 28.4996 15.7698 33.8851 22 34.8471V40C22 41.1046 22.8954 42 24 42C25.1046 42 26 41.1046 26 40V34.8471Z'
      fill='#090E12'
    />
  </svg>
)

export default PodcastsSVG
