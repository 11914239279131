// @noSnapshot
import React from 'react'
import styled from 'styled-components'
import { deprecated, resetCSS } from '../../../helpers'

class TypoWrapper extends React.Component{
  render() {
    const { className, style, children } = this.props
    return (
      <p className={className} style={style}>
        {children}
      </p>
    )
  }
}

const Paragraph = styled(TypoWrapper).attrs(resetCSS)`
  color: ${({ color, theme }) =>
    color ? theme.colors[color] || color : theme.components.typography.paragraph.text};
  font-family: ${({ theme }) => theme.fonts.paragraph.fontFamily};
  font-size: ${({ theme }) => theme.fonts.paragraph.fontSize};
  font-weight: ${({ theme }) => theme.fonts.paragraph.fontWeight};
  line-height: ${({ theme }) => theme.fonts.paragraph.lineHeight};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
`

export default deprecated(Paragraph, 'Paragraph', 'Please use Body2 instead.')
