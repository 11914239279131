import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const DividerDotted = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M1 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm3.5 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm3.5 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM15 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/></g>
    </StyledIcon>
  )

export default styled(DividerDotted)``
  