import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Feature = props => (
    <StyledIcon viewBox="2 2 24 24" {...props}>
      <g><path d="M12.684 15.051c.475-.157.988.058 1.211.502l1 2a1 1 0 01-.188 1.154l-.293.293H22a1 1 0 01.8 1.6l-3 4a1 1 0 01-.8.4H8a1 1 0 01-1 1H3a1 1 0 01-1-1v-8a1 1 0 011-1h4c.424 0 .781.265.927.637zM6 18H4v6h2v-6zm6.494-.777L8 18.72V23h10.5l1.5-2h-9v-1h.01a.99.99 0 01.283-.707l1.49-1.491-.289-.579zM18 2c.408 0 .776.25.929.629L20.678 7H25a.999.999 0 01.644 1.764l-3.757 3.172 2.029 4.665a.999.999 0 01-1.467 1.233l-4.45-2.941-1.948 1.288-.967-1.463-.004.009-1.834-.799.866-1.991-3.757-3.173A.999.999 0 0111 7h4.322l1.749-4.371c.153-.38.52-.629.929-.629zm0 3.692l-1.071 2.679c-.153.38-.521.629-.929.629h-2.266l2.236 1.89a.998.998 0 01.272 1.163l-.979 2.252 2.186-1.444a.992.992 0 011.102-.001l2.186 1.444-.98-2.252a1 1 0 01.273-1.163L22.266 9H20c-.408 0-.776-.249-.93-.629L18 5.692z"/></g>
    </StyledIcon>
  )

export default styled(Feature)``
  