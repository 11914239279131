import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { resetCSS } from '../../../helpers'
import Block from '../../Block'

const styleOptionBlock = ({ deletion, disabled, isLink, isSelected, readOnly, theme }) => {
  const themeObject = deletion
    ? theme.components.optionPanel.deletion
    : theme.components.optionPanel.option
  return css`
    align-items: center;
    background-color: ${isSelected ? themeObject.backgroundSelected : themeObject.background};
    color: ${themeObject.text};
    display: flex;
    fill: ${themeObject.text};
    justify-content: space-between;
    opacity: ${disabled ? 0.4 : 1};
    padding: ${({ theme }) => theme.sizes.s3} ${({ theme }) => theme.sizes.s4};
    text-decoration: ${isLink && 'none'};

    &:hover {
      ${!isSelected && hoverAttributes({ disabled, readOnly, themeObject })};
    }

    &:active {
      background-color: ${themeObject.backgroundActive};
    }

    &:active {
      background-color: ${themeObject.backgroundActive};
    }
  `
}

const hoverAttributes = ({ disabled, readOnly, themeObject }) => {
  if (readOnly) {
    return css`
      cursor: default;
    `
  }

  return css`
    background-color: ${disabled ? themeObject.background : themeObject.backgroundHover};
    color: ${disabled ? themeObject.text : themeObject.textHover};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    fill: ${disabled ? themeObject.text : themeObject.textHover};
  `
}

const OptionBlock = styled(Block).attrs(resetCSS)`
  ${(props) => styleOptionBlock(props)};
`

OptionBlock.defaultProps = {
  disabled: false,
  isLink: false,
  readOnly: false,
}

OptionBlock.propTypes = {
  deletion: PropTypes.bool,
  disabled: PropTypes.bool,
  isLink: PropTypes.bool,
  isSelected: PropTypes.bool,
  readOnly: PropTypes.bool,
}

export default OptionBlock
