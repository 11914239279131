import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const CarretDouble = props => (
    <StyledIcon viewBox="6 6 8 8" {...props}>
      <g><path d="M12.5 8.8c-.1.1-.3.2-.4.2H7.9c-.1 0-.3-.1-.4-.2-.1-.1-.1-.3 0-.4l2.1-2.2c.2-.1.3-.2.4-.2.1 0 .3.1.3.1l2.1 2.2c.1.2.1.4.1.5zm0 2.4c.1.1.1.3 0 .4l-2.1 2.2c-.1.1-.3.2-.4.2-.1 0-.2-.1-.3-.1l-2.1-2.2c-.1-.1-.1-.3 0-.4 0-.2.2-.3.3-.3h4.2c.1 0 .3.1.4.2z"/></g>
    </StyledIcon>
  )

export default styled(CarretDouble)``
  