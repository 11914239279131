import { merge } from 'lodash'
import { hexToRGB } from '../../helpers/color'
import mailjetTheme from './mailjetTheme'

const {
  componentsFactory,
  colors,
  colorsVariationsFactory,
  darkComponentsFactory,
  fonts,
  ...rest
} = mailjetTheme

/*
 * All themable component properties, for reference use.
 * Properties overwritten below should be excluded from comments.
 */
// {
//   avatar: {
//     border: '#3a3c44',
//     text: '#FFFFFF',
//   },
//   backToTop: {
//     color: '#555555',
//     colorActive: '#555555',
//     colorHover: '#555555',
//     background: '#FFFFFF',
//     backgroundActive: '#d9d9d9',
//     backgroundPressed: '#d9d9d9',
//     backgroundHover: '#F2F2F2',
//   },
//   box: {
//     border: '#e5e5e5',
//   },
//   button: {
//     dropdown: {
//       background: 'transparent',
//       backgroundFocus: 'transparent',
//       backgroundHover: 'transparent',
//       backgroundLoading: 'transparent',
//       backgroundPressed: 'transparent',
//       border: '#888888',
//       borderHover: '#555555',
//       borderFocus: '#19BC9C',
//       borderPressed: '#19BC9C',
//       icon: '#555555',
//       loader: '#555555',
//       text: '#555555',
//       textFocus: '#555555',
//       textHover: '#555555',
//       textPressed: '#555555',
//     },
//     icon: {
//       deletion: {
//         text: '#FFFFFF',
//         background: 'transparent',
//         textHover: '#FFFFFF',
//         backgroundHover: '#F86469',
//         colorPressed: '#FFFFFF',
//         backgroundPressed: '#ff7176',
//       },
//     },
//     secondary: {
//       background: 'transparent',
//       backgroundLoading: 'rgba(254, 173, 13, 0.2)',
//       borderFocusWidth: '1px',
//       borderHoverWidth: '1px',
//       borderLoadingColor: '#f1a000',
//       borderLoadingWidth: '1px',
//       borderPressedWidth: '1px',
//       borderWidth: '1px',
//       hidden: 'transparent',
//       loader: '#f1a000',
//       neutral: {
//         background: '#F2F2F2',
//         backgroundFocus: '#d9d9d9',
//         backgroundHover: '#e5e5e5',
//         backgroundLoading: '#F2F2F2',
//         backgroundPressed: '#d9d9d9',
//         borderColor: 'transparent',
//         borderFocusColor: 'transparent',
//         borderHoverColor: 'transparent',
//         borderLoadingColor: 'transparent',
//         borderPressedColor: 'transparent',
//         loader: '#555555',
//         text: '#555555',
//         textFocus: '#555555',
//         textHover: '#555555',
//         textPressed: '#555555',
//       },
//     },
//   },
//   buttonDropdown: {
//     primary: {
//       icon: '#FFFFFF',
//       divider: '#ffba1a',
//     },
//     secondary: {
//       icon: '#FEAD0D',
//     },
//   },
//   codeEditor: {
//     iconCopy: '#888888',
//     iconCopyHover: '#555555',
//     badge: {
//       background: 'transparent',
//       backgroundActive: 'rgba(25, 188, 156, 0.2)',
//       backgroundHover: 'transparent',
//       border: '#e6e6e6',
//       borderActive: 'transparent',
//       borderHover: '#e6e6e6',
//       color: '#e6e6e6',
//       colorActive: '#19BC9C',
//       colorHover: '#e6e6e6',
//     },
//   },
//   colorPicker: {},
//   dropFileBox: {
//     error: '#F86469',
//     hover: '#19BC9C',
//     default: '#555555',
//   },
//   inputFile: {
//     errorIcon: '#F86469',
//     errorText: '#F86469',
//   },
//   inputNumber: {
//     background: '#FFFFFF',
//     border: '#888888',
//     borderError: '#F86469',
//     borderFocus: '#19BC9C',
//     borderHover: '#555555',
//     iconContainer: {
//       icon: '#888888',
//       iconHover: '#555555',
//       iconFocus: '#555555',
//       backgroundHover: '#F2F2F2',
//       backgroundActive: '#e5e5e5',
//       borderColor: '#888888',
//     },
//     placeholder: '#a2a2a2',
//     text: '#555555',
//   },
//   label: {
//     background: '#F2F2F2',
//     backgroundHover: '#e5e5e5',
//     icon: '#888888',
//     iconHover: '#555555',
//     text: '#555555',
//     textHover: '#555555',
//   },
//   labelledWrapper: {
//     notification: {
//       text: '#555555',
//       textError: '#F86469',
//     },
//   },
//   linkButton: {
//     backgroundColor: 'transparent',
//     border: '#33d6b6',
//     borderFocus: '#33d6b6',
//     icon: '#19BC9C',
//     iconHover: '#33d6b6',
//     text: '#19BC9C',
//     textHover: '#33d6b6',
//   },
//   optionPanel: {
//     deletion: {
//       icon: {
//         color: '#F86469',
//         colorHover: '#FFFFFF',
//       },
//       background: '#FFFFFF',
//       backgroundHover: '#F86469',
//       text: '#F86469',
//       textHover: '#FFFFFF',
//     },
//   },
//   phoneNumber: {
//     error: {
//       icon: '#F86469',
//       text: '#F86469',
//     },
//     input: {
//       placeholder: '#a2a2a2',
//       text: '#555555',
//       background: '#FFFFFF',
//     },
//     borderer: {
//       border: '#888888',
//       borderError: '#F86469',
//       borderFocus: '#19BC9C',
//     },
//   },
//   roundNotification: {
//     info: {
//       color: '#309CED',
//     },
//     success: {
//       color: '#4DBC5C',
//     },
//     warning: {
//       color: '#F7A233',
//     },
//   },
//   roundSelector: {
//     background: '#FFFFFF',
//   },
//   scrollbar: {
//     background: 'rgba(217, 217, 217, 0.4)',
//     backgroundHover: 'rgba(217, 217, 217, 0.8)',
//   },
//   skeleton: {
//     background: '#F2F2F2',
//     backgroundStart: '#F2F2F2',
//     backgroundEnd: '#e5e5e5',
//   },
//   stepper: {
//     border: '#e5e5e5',
//     icon: {
//       empty: '#888888',
//       error: '#F86469',
//       success: '#4DBC5C',
//       warning: '#F7A233',
//     },
//     subtitle: '#888888',
//   },
//   switch: {
//     backgroundOn: '#19BC9C',
//     backgroundOff: '#e5e5e5',
//   },
//   tabs: {
//     background: '#FFFFFF',
//     backgroundTabsMode: '#FCFCFC',
//     backgroundTabsModeFocus: '#F2F2F2',
//     backgroundHover: '#F2F2F2',
//     border: '#e5e5e5',
//     indicator: '#19BC9C',
//     indicatorTabsMode: '#FFFFFF',
//     loader: '#19BC9C',
//     text: '#888888',
//     textActive: '#19BC9C',
//     textHover: '#555555',
//     backgroundDropdownActive: 'rgba(25, 188, 156, 0.1)',
//   },
//   toast: {
//     background: '#555555',
//     backgroundError: '#F86469',
//     closeIcon: '#FFFFFF',
//     loader: '#FFFFFF',
//     text: '#FFFFFF',
//   },
//   toggle: {
//     divider: {
//       color: '#e5e5e5',
//     },
//   },
//   tooltip: {
//     background: '#2d2f37',
//     text: '#FFFFFF',
//   },
//   typography: {
//     body1: {
//       text: '#555555',
//     },
//     body1Medium: {
//       text: '#555555',
//     },
//     body2Medium: {
//       text: '#555555',
//     },
//     captionMedium: {
//       text: '#555555',
//     },
//     header1: {
//       text: '#555555',
//     },
//     header2: {
//       text: '#555555',
//     },
//     header3: {
//       text: '#555555',
//     },
//     header4: {
//       text: '#555555',
//     },
//     header4Medium: {
//       text: '#555555',
//     },
//     paragraph: {
//       text: '#555555',
//     },
//     smallText: {
//       text: '#555555',
//     },
//     smallTextBold: {
//       text: '#555555',
//     },
//     subSubTitle: {
//       text: '#555555',
//     },
//     subTitle: {
//       text: '#555555',
//     },
//     text: {
//       text: '#555555',
//     },
//     textLabel: {
//       text: '#555555',
//     },
//     title: {
//       text: '#555555',
//     },
//   },
// }

const mgComponentsFactory = ({ colors: c }) => ({
  accordion: {
    background: c.grey1,
    backgroundHover: c.grey3,
    backgroundPressed: c.grey1,
    border: c.grey4,
    icon: c.grey10,
    iconPressed: c.primary1,
    iconLocked: c.grey10,
    label: c.grey10,
    labelPressed: c.primary1,
  },
  alert: {
    backgroundColor: {
      warning: c.alert1,
      critical: c.error2,
    },
    text: {
      color: c.grey1,
    },
  },
  banner: {
    backgroundColor: {
      info: c.info2,
      error: c.error3,
      success: c.success2,
      tips: c.grey2,
      warning: c.alert2,
    },
    color: {
      icon: c.secondary1,
    },
    button: {
      border: c.grey8,
      background: 'transparent',
      backgroundHover: hexToRGB(c.grey8, 0.1),
      backgroundActive: hexToRGB(c.grey8, 0.1),
      color: c.grey10,
    },
  },
  breadcrumb: {
    link: {
      color: c.grey8,
      hover: c.grey10,
      active: c.primary1,
    },
    chevron: {
      color: c.grey8,
      active: c.primary1,
    },
  },
  button: {
    dropdown: {
      background: c.transparent,
      backgroundFocus: c.transparent,
      backgroundHover: c.transparent,
      backgroundLoading: c.transparent,
      backgroundPressed: c.transparent,
      border: c.grey,
      borderHover: c.grey10,
      borderFocus: c.primary1,
      borderPressed: c.pw60,
      icon: c.neutral60,
      loader: c.neutral40,
      text: c.neutral40,
      textFocus: c.neutral60,
      textHover: c.neutral60,
      textPressed: c.neutral60,
    },
    deletion: {
      background: c.error1,
      backgroundHover: c.error2,
      backgroundLoading: c.error3,
      backgroundPressed: c.error3,
      borderWidth: '0px',
      borderHoverWidth: '0px',
      borderFocusWidth: '0px',
      borderPressedWidth: '0px',
      hidden: 'transparent',
      loader: c.grey1,
      text: c.grey1,
      textFocus: c.grey1,
      textHover: c.grey1,
      textPressed: c.grey1,
    },
    icon: {
      background: 'transparent',
      backgroundFocus: hexToRGB(c.primary1, 0.1),
      backgroundHover: c.grey3,
      backgroundLoading: c.grey4,
      backgroundPressed: hexToRGB(c.primary1, 0.1),
      border: c.grey10,
      loader: c.grey10,
      text: c.grey8,
      textFocus: c.primary1,
      textHover: c.primary1,
      textPressed: c.primary1,
      outline: 'none',
    },
    primary: {
      background: c.primary1,
      backgroundFocus: c.primary3,
      backgroundHover: c.primary2,
      backgroundLoading: c.primary3,
      backgroundPressed: c.primary3,
      borderFocusWidth: '0px',
      borderHoverWidth: '0px',
      borderPressedWidth: '0px',
      borderWidth: '0px',
      hidden: 'transparent',
      loader: c.grey1,
      text: c.grey1,
      textFocus: c.grey1,
      textHover: c.grey1,
      textPressed: c.grey1,
    },
    secondary: {
      backgroundFocus: hexToRGB(c.primary1Light, 0.2),
      backgroundHover: hexToRGB(c.primary1Light, 0.1),
      backgroundPressed: hexToRGB(c.primary1Light, 0.2),
      borderColor: c.primary1,
      borderFocusColor: c.primary1,
      borderHoverColor: c.primary1,
      borderPressedColor: c.primary1,
      text: c.primary1,
      textFocus: c.primary1,
      textHover: c.primary1,
      textPressed: c.primary1,
    },
    tertiary: {
      background: 'transparent',
      backgroundFocus: c.grey3,
      backgroundHover: c.grey3,
      backgroundLoading: hexToRGB(c.primary1, 0.1),
      backgroundPressed: hexToRGB(c.primary1, 0.1),
      border: c.grey10,
      hidden: 'transparent',
      loader: c.grey10,
      text: c.grey8,
      textFocus: c.grey10,
      textHover: c.grey10,
      textPressed: c.primary1,
    },
    toggle: {
      background: c.grey1,
      backgroundActive: hexToRGB(c.primary1, 0.1),
      backgroundFocus: c.grey4,
      backgroundHover: c.grey3,
      backgroundPressed: c.grey4,
      border: c.grey4,
      borderActive: c.grey4,
      borderFocus: c.grey4,
      borderHover: c.grey4,
      borderPressed: c.grey4,
      icon: c.grey8,
      iconActive: c.primary1,
      iconFocus: c.grey10,
      iconHover: c.grey10,
      iconPressed: c.grey10,
      text: c.grey8,
      textActive: c.grey8,
      textFocus: c.grey8,
      textHover: c.grey8,
      textPressed: c.grey8,
    },
  },
  buttonDropdown: {
    primary: {
      divider: c.primary2,
    },
  },
  checkbox: {
    background: c.grey2,
    backgroundChecked: c.primary2,
    backgroundWrapper: 'transparent',
    border: c.grey8,
    borderChecked: c.grey8,
    borderError: c.error2,
    borderFocus: c.primary2,
    borderHover: c.grey10,
    borderWidth: '1px',
    borderFocusWidth: '1px',
    checkmarkColor: c.grey1,
  },
  datatable: {
    borderColor: c.grey4,
    backgroundColorRowChecked: c.grey1,
    controls: {
      background: c.grey1,
    },
    pagination: {
      backgroundSelected: hexToRGB(c.primary1, 0.1),
      colorSelected: c.primary1,
    },
  },
  datePicker: {
    calendar: {
      date: {
        backgroundFocus: c.primary1,
        backgroundSelected: hexToRGB(c.primary1, 0.1),
        borderFocus: c.primary1,
        borderHover: c.grey4,
        borderSelected: hexToRGB(c.primary1, 0.4),
        colorFocus: c.grey1,
        textDisabled: c.grey3,
        text: c.grey10,
        textFocus: c.grey1,
      },
      header: {
        background: c.grey2,
        border: c.grey8,
        text: c.grey10,
      },
      icon: {
        background: 'transparent',
        backgroundFocus: c.grey4,
        backgroundHover: c.grey3,
        backgroundPressed: c.grey4,
        textFocus: c.grey10,
        textHover: c.grey10,
        textPressed: c.grey10,
      },
      subHeader: {
        text: c.grey8,
      },
    },
    input: {
      background: c.grey1,
      border: c.grey8,
      borderFocus: c.primary1,
      icon: c.grey10,
      iconFocus: c.primary1,
      placeholder: c.grey3,
      text: c.grey10,
    },
  },
  link: {
    border: 'transparent',
    borderVisited: 'transparent',
    text: c.bl70,
    textHover: c.bl70,
    textVisited: c.bl70,
    textDecoration: 'underline',
    textDecorationHover: 'underline',
  },
  linkButton: {
    backgroundColor: 'transparent',
    border: c.bl70,
    borderFocus: c.bl70,
    icon: c.bl70,
    iconHover: c.bl70,
    text: c.bl70,
    textHover: c.bl70,
  },
  input: {
    backgrounf: c.grey1,
    border: c.grey8,
    borderError: c.error2,
    borderFocus: c.primary1,
    borderHover: c.grey10,
    icon: c.grey10,
    iconCopy: c.grey8,
    iconCopyHover: c.grey10,
    loader: c.grey10,
    placeholder: c.grey8,
    text: c.secondary1,
  },
  inputDate: {
    border: c.grey4,
    borderRadius: '3px',
  },
  loader: {
    background: 'transparent',
    color: c.primary1,
    colorSecondary: c.secondary1,
  },
  modal: {
    body: {
      background: c.grey1,
    },
    content: {
      borderBottom: c.grey3,
    },
    header: {
      background: c.grey2,
      border: c.grey4,
      text: c.secondary1,
    },
    overlay: {
      color: hexToRGB(c.grey12, 0.6),
    },
  },
  optionPanel: {
    divider: {
      color: c.grey4,
    },
    option: {
      background: c.grey1,
      backgroundActive: c.grey3,
      backgroundFocus: c.grey3,
      backgroundHover: c.grey3,
      backgroundSelected: hexToRGB(c.primary1, 0.1),
      borderFocus: c.primary1,
      text: c.grey10,
      textHover: c.grey10,
    },
    title: {
      text: c.secondary2,
    },
  },
  progressBar: {
    background: c.grey4,
    backgroundFilled: c.primary1,
    backgroundProgress: c.primary1,
  },
  searchBar: {
    border: c.grey8,
    background: c.grey1,
    icon: c.secondary1,
    borderHover: c.grey10,
    iconHover: c.grey8,
    borderFocus: c.primary1,
    iconFocus: c.grey10,
    text: c.secondary1,
    placeholder: c.grey8,
    loader: c.grey8,
    iconClear: c.grey8,
    iconClearHover: c.grey10,
  },
  select: {
    background: 'transparent',
    backgroundHover: 'transparent',
    backgroundFocus: 'transparent',
    border: c.grey8,
    borderError: c.error2,
    borderFocus: c.primary1,
    borderHover: c.grey10,
    icon: c.grey10,
    innerLabel: c.grey8,
    loader: c.grey10,
    placeholder: c.grey8,
    selectedText: c.grey10,
    text: c.secondary1,
  },
  switch: {
    backgroundOn: c.primary1,
    backgroundOff: c.grey4,
  },
  tabs: {
    background: c.grey1,
    backgroundTabsMode: c.grey2,
    backgroundTabsModeFocus: c.grey3,
    backgroundHover: c.grey3,
    border: c.grey4,
    indicator: c.primary1,
    indicatorTabsMode: c.grey1,
    loader: c.primary1,
    text: c.grey8,
    textActive: c.primary1,
    textHover: c.grey10,
    backgroundDropdownActive: hexToRGB(c.primary1, 0.1),
  },
  tag: {
    background: c.secondary1,
    icon: c.grey1,
    iconHover: c.grey1,
    loader: c.grey8,
    loaderBackground: c.grey3,
    text: c.grey1,
    textHover: c.grey10,
  },
  textArea: {
    background: c.grey1,
    border: c.grey8,
    borderError: c.error2,
    borderFocus: c.grey10,
    borderHover: c.grey10,
    placeholder: c.grey8,
    text: c.secondary1,
  },
  typography: {
    body1Medium: {
      text: c.grey10,
    },
    body2Medium: {
      text: c.grey10,
    },
    body2: {
      text: c.grey10,
    },
    caption: {
      text: c.grey10,
    },
  },
})

/* Default to Mailjet dark theme until Mailgun Dark theme is defined */
const mgDarkComponentsFactory = (_args) => ({})

const mgColors = {
  grey1: '#ffffff',
  grey2: '#fcfcfc',
  grey3: '#f2f2f2',
  grey4: '#e5e5e5',
  grey5: '#d9d9d9',
  grey6: '#cccccc',
  grey7: '#a1a1a1',
  grey8: '#888888',
  grey9: '#60626a',
  grey10: '#555555',
  grey11: '#3a3c44',
  grey12: '#2d2f37',

  primary1: '#439b73',
  primary1Light: '#83c9a9',
  primary2: '#34795a',
  primary3: '#5bb88e',
  secondary1: '#051d39',
  secondary2: '#4e5d78',
  secondary3: '#8a94a6',
  secondary4: '#b0b7c3',

  alert1: '#f7a233',
  alert2: '#fdecd6',
  error1: '#DF4B50',
  error2: '#F86469',
  error3: '#fee0e1',
  info1: '#309CED',
  info2: '#D6EBFB',
  success1: '#4dbc5c',
  success2: '#dbf2de',
}

const fontFamilyPrimary = 'Roboto'

const mgFonts = {
  global: {
    fontFamilyRegular: fontFamilyPrimary,
  },
  body1: {
    fontSize: '16px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 400,
    lineHeight: '24px',
  },
  body1Medium: {
    fontSize: '16px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '24px',
  },
  body2: {
    fontSize: '14px',
    fontFamily: fontFamilyPrimary,
    lineHeight: '20px',
    fontWeight: 400,
  },
  body2Medium: {
    fontSize: '14px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '20px',
  },
  caption: {
    fontSize: '12px',
    fontFamily: fontFamilyPrimary,
    lineHeight: '16px',
    fontWeight: 400,
  },
  captionMedium: {
    fontWeight: 500,
  },
  header1: {
    fontSize: '48px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 900,
    lineHeight: '56px',
  },
  header2: {
    fontSize: '32px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '40px',
  },
  header3: {
    fontSize: '24px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '40px',
  },
  header4: {
    fontSize: '20px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 400,
    lineHeight: '32px',
  },
  header4Medium: {
    fontWeight: 500,
  },
  label: {
    fontSize: '14px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '20px',
  },
  paragraph: {
    fontSize: '14px',
    fontFamily: fontFamilyPrimary,
    lineHeight: '20px',
    fontWeight: 400,
  },
  smallText: {
    fontSize: '12px',
    fontFamily: fontFamilyPrimary,
    lineHeight: '16px',
    fontWeight: 400,
  },
  smallTextBold: {
    fontWeight: 500,
  },
  subsubtitle: {
    fontSize: '16px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '24px',
  },
  subtitle: {
    fontSize: '20px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '32px',
  },
  title: {
    fontSize: '24px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '40px',
  },
}
const mergedColors = merge({}, colors, mgColors)
const mergedCompFactory = (args) =>
  merge({}, componentsFactory(args), mgComponentsFactory(args))

const mailgunTheme = {
  ...rest,
  name: 'mailgun',
  colors: mergedColors,
  colorsVariationsFactory,
  components: mergedCompFactory({ colors: colorsVariationsFactory({ colors: mergedColors }) }),
  componentsFactory: mergedCompFactory,
  darkComponentsFactory: args =>
    merge({}, darkComponentsFactory(args), mgDarkComponentsFactory(args)),
  fonts: merge({}, fonts, mgFonts),
}
export default mailgunTheme
export { mgColors }
