import * as React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types'
import {
  borders,
  display,
  isKeyOf,
  margins,
  paddings,
} from '../../helpers';

/**
 * Calculate the real padding by replacing theme indication by real sizes.
 */
const calculatePaddings = (p, sizes) => {
  if (!p) {
    // No padding, just return.
    return p;
  }

  // Two cases : the padding is only a number (like '16px' or 'size4'), or the padding is several
  // numbers (like '16px 8px' or 'size4 size3'). We can check that by looking for spaces.
  const arrayPaddings = p.split(/\s+/g);
  if (arrayPaddings.length > 1) {
    return arrayPaddings
      .map(padding =>
        `${
          (isKeyOf(padding, sizes.paddings) && sizes.paddings[padding]) ||
          (isKeyOf(padding, sizes) && sizes[padding]) ||
          padding
        }`,
      )
      .join(' ');
  }

  const padding = arrayPaddings[0];

  return `${
    (isKeyOf(padding, sizes.paddings) && sizes.paddings[padding]) ||
    (isKeyOf(padding, sizes) && sizes[padding]) ||
    padding
  }`;
};

/** Iterates over the responsive sizes to apply the corresponding rules */
const responsiveRules = (theme, noPadding) => {
  const { responsive, sizes } = theme;

  return Object.keys(responsive).reduce((rules, size) => {
    const { breakpoint, maxBreakpoint, width } = isKeyOf(size, responsive)
      ? responsive[size]
      : { breakpoint: 0, maxBreakpoint: null, width: null };
    // By default breakpoint handle min-width media queries, but max-width can be used when needed
    const mediaQuery = `@media screen and (min-width: ${breakpoint}px) ${
      maxBreakpoint ? `and (max-width: ${maxBreakpoint}px)` : ''
    }`;
    return rules.concat(css`
      ${mediaQuery} {
        max-width: ${isKeyOf(size, responsive) ? responsive[size].containerMaxWidth : 'inherit'};
        padding: ${noPadding
          ? '0px'
          : calculatePaddings(
              isKeyOf(size, responsive) ? responsive[size].containerPadding : 'inherit',
              sizes,
            )};
        ${width && `width: ${width};`}
      }
    `);
  }, []);
};

// By default, Container is full-sized and center-aligned
const Container = styled.div`
  margin: 0 auto;

  ${({ noPadding = false, theme }) => responsiveRules(theme, noPadding)};

  ${borders};
  ${display};
  ${margins};
  ${paddings};

  width: 100%;
`;

Container.defaultProps = {
  noPadding: false,
};

Container.propTypes = {
  noPadding: PropTypes.bool,
};

export default Container;
