import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const LockUnlock = props => (
    <StyledIcon viewBox="0 0 8.4 12" {...props}>
      <g><path d="M7.2 5.4H2.4V3A1.8 1.8 0 0 1 6 3v.6h1.2V3a3 3 0 0 0-6 0v2.4A1.2 1.2 0 0 0 0 6.6v4.2A1.2 1.2 0 0 0 1.2 12h6a1.2 1.2 0 0 0 1.2-1.2V6.6a1.2 1.2 0 0 0-1.2-1.2zm-6 5.4V6.6h6v4.2z"/><circle cx="4.2" cy="8.7" r=".9"/></g>
    </StyledIcon>
  )

export default styled(LockUnlock)``
  