import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AlignRight = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M0 3h12v4H0V3zm4 6h8v4H4V9zm10-9h2v16h-2V0z"/></g>
    </StyledIcon>
  )

export default styled(AlignRight)``
  