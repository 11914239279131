import React from 'react'
import styled from 'styled-components'

const BurgerSVG = () => (
  <svg
    fill='none'
    height='24'
    viewBox='0 0 24 24'
    width='24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Burger'>
      <g id='Vector'>
        <path
          clipRule='evenodd'
          d='M1.5 19.5C1.5 18.6716 2.17157 18 3 18H21C21.8284 18 22.5 18.6716 22.5 19.5C22.5 20.3284 21.8284 21 21 21H3C2.17157 21 1.5 20.3284 1.5 19.5Z'
          fill='white'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M1.5 12C1.5 11.1716 2.17157 10.5 3 10.5H21C21.8284 10.5 22.5 11.1716 22.5 12C22.5 12.8284 21.8284 13.5 21 13.5H3C2.17157 13.5 1.5 12.8284 1.5 12Z'
          fill='white'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M1.5 4.5C1.5 3.67157 2.17157 3 3 3H21C21.8284 3 22.5 3.67157 22.5 4.5C22.5 5.32843 21.8284 6 21 6H3C2.17157 6 1.5 5.32843 1.5 4.5Z'
          fill='white'
          fillRule='evenodd'
        />
      </g>
    </g>
  </svg>
)

export default styled(BurgerSVG)``
