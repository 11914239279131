import React from 'react'

const ResearchReportSVG = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18 21C18 19.3431 19.3431 18 21 18C21.8284 18 22.5 17.3284 22.5 16.5C22.5 15.6716 21.8284 15 21 15C17.6863 15 15 17.6863 15 21C15 21.8284 15.6716 22.5 16.5 22.5C17.3284 22.5 18 21.8284 18 21Z'
      fill='#090E12'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M34 21C34 23.8682 33.0711 26.5195 31.4978 28.6694L41.4142 38.5858C42.1953 39.3668 42.1953 40.6332 41.4142 41.4142C40.6332 42.1953 39.3668 42.1953 38.5858 41.4142L28.6694 31.4978C26.5195 33.0711 23.8682 34 21 34C13.8203 34 8 28.1797 8 21C8 13.8203 13.8203 8 21 8C28.1797 8 34 13.8203 34 21ZM21 30C25.9706 30 30 25.9706 30 21C30 16.0294 25.9706 12 21 12C16.0294 12 12 16.0294 12 21C12 25.9706 16.0294 30 21 30Z'
      fill='#090E12'
    />
  </svg>
)

export default ResearchReportSVG
