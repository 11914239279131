import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Desktop = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M14.667 1H1.333C.598 1 0 1.597 0 2.333V11c0 .735.598 1.333 1.333 1.333h6v1.334H4.667V15h6.666v-1.333H8.667v-1.334h6c.735 0 1.333-.598 1.333-1.333V2.333C16 1.597 15.402 1 14.667 1zm0 1.333L14.665 9H1.333V2.333h13.334zM1.333 11v-.667h13.332V11H1.333z"/></g>
    </StyledIcon>
  )

export default styled(Desktop)``
  