const KEY_ENTER = 13;
const KEY_TAB = 9;

const SIZES = {
  NORMAL: 'normal',
  SMALL: 'small',
};

const SIDE = {
  LEFT: 'left',
  RIGHT: 'right',
};

const STATUS = {
  ERROR: 'error',
  INFO: 'info',
  INFO_ON_FOCUS: 'info-on-focus',
};

const ERROR_MSG_NS = `${STATUS.ERROR}-msg`;
const INFO_MSG_NS = `${STATUS.INFO}-msg`;

export { ERROR_MSG_NS, KEY_ENTER, KEY_TAB, INFO_MSG_NS, SIDE, SIZES, STATUS };
