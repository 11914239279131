import { map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

const List = styled.ul`
  display: none;
`
const Item = styled.li`
  display: none;
  font-size: 0px;
`

export default function Emails({ data }) {
  return (
    <List>
      {map(data, (email, i) => (
        <Item key={i + '-emails-item'}>{email}</Item>
      ))}
    </List>
  )
}
