import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const BoxedContentPreview = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M2.4 3H6v1.2H2.4zm0 2.4H6v1.2H2.4zm0 2.4h7.2V9H2.4z"/><path d="M10.8 0H1.2A1.2 1.2 0 0 0 0 1.2v9.6A1.2 1.2 0 0 0 1.2 12h9.6a1.2 1.2 0 0 0 1.2-1.2V1.2A1.2 1.2 0 0 0 10.8 0zm0 10.8H1.2V1.2h9.6z"/><path d="M7.2 3h2.4v3.6H7.2z"/></g>
    </StyledIcon>
  )

export default styled(BoxedContentPreview)``
  