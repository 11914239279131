import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Zip = props => (
    <StyledIcon  {...props}>
      <g><path d="M8.4 4.2h1.2v1.2H8.4zM7.2 5.4h1.2v1.2H7.2zm1.2 1.2h1.2v1.2H8.4z"/><path d="M10.8 1.8h-5L4.86.48A1.21 1.21 0 0 0 3.9 0H1.2A1.2 1.2 0 0 0 0 1.2V9a1.2 1.2 0 0 0 1.2 1.2h9.6A1.2 1.2 0 0 0 12 9V3a1.2 1.2 0 0 0-1.2-1.2zM8.4 9V7.8H7.2V9h-6V1.2h2.7l1 1.32a1.21 1.21 0 0 0 1 .48h1.3v1.2h1.2V3h2.4v6z"/></g>
    </StyledIcon>
  )

export default styled(Zip)``
  