import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const SocialContent = props => (
    <StyledIcon  {...props}>
      <g><path d="M15.29 20.83h1.44v-2.5h-2.4c-1.8 0-2.6.79-2.6 2.31v1.69h-2v2h2v6h3v-6h1.82l.18-2h-2v-.83c0-.5.1-.67.56-.67zm25.39-1.31a4.79 4.79 0 0 1-1.56.61 2.44 2.44 0 0 0-1.8-.8 2.51 2.51 0 0 0-2.39 3.1 6.89 6.89 0 0 1-5.07-2.64 2.58 2.58 0 0 0 .76 3.38 2.47 2.47 0 0 1-1.11-.32 2.52 2.52 0 0 0 2 2.51 2.44 2.44 0 0 1-1.11 0 2.47 2.47 0 0 0 2.29 1.75A4.84 4.84 0 0 1 29 28.2a6.91 6.91 0 0 0 3.77 1.13 7.06 7.06 0 0 0 7-7.51 5.07 5.07 0 0 0 1.23-1.3 4.84 4.84 0 0 1-1.41.39 2.56 2.56 0 0 0 1.09-1.39z"/><path d="M35 11.33a13 13 0 1 0 13 13 13 13 0 0 0-13-13zm0 24a11 11 0 1 1 11-11 11 11 0 0 1-11 11z"/><path d="M13 35.33a11 11 0 1 1 9.26-16.83 13.67 13.67 0 0 1 1.08-1.95 13 13 0 1 0 0 15.57 14.23 14.23 0 0 1-1.08-1.95A11 11 0 0 1 13 35.33z"/></g>
    </StyledIcon>
  )

export default styled(SocialContent)``
  