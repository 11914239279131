import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Restart = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8 0a7.943 7.943 0 0 0-5.65 2.35L.8.8v4.8h4.8L3.48 3.48A6.351 6.351 0 0 1 8 1.6c3.529 0 6.4 2.871 6.4 6.4 0 3.529-2.871 6.4-6.4 6.4A6.407 6.407 0 0 1 1.6 8H0c0 4.412 3.589 8 8 8s8-3.588 8-8-3.589-8-8-8z"/></g>
    </StyledIcon>
  )

export default styled(Restart)``
  