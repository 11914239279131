import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Italic = props => (
    <StyledIcon viewBox="0 0 10.8 12" {...props}>
      <g><path d="M10.8 1.2V0h-6v1.2h2.08l-4.27 9.6H0V12h6v-1.2H3.92l4.27-9.6h2.61z"/></g>
    </StyledIcon>
  )

export default styled(Italic)``
  