import React from 'react'
import { chunk, floor, last, sum } from 'lodash'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { gridHelper, styleForMobile, styleForTablet } from '@utils'

export const GridSubSectionContainer = styled.div`
  ${({ gridConfig, theme }) => css`
    ${gridHelper(12, ...gridConfig.desktop)}
    grid-column-gap: ${theme.sizes.s6};
    grid-row-gap: ${theme.sizes.s4};

    ${styleForTablet} {
      ${gridHelper(6, ...gridConfig.tablet)}
      grid-column-gap: ${theme.sizes.s6};
      grid-row-gap: ${theme.sizes.s8};
    }

    ${styleForMobile} {
      ${gridHelper(4, ...gridConfig.mobile)}
      grid-row-gap: ${theme.sizes.s7};
    }
  `}
`

// Setting a props based grid div, could be used by any section able to display children using a number of element per line
// Todo: see if we can reuse that on every card mosaic (blog/resources home/categories...)
const GridLayout = ({
  desktop = 4,
  tablet = 2,
  mobile = 1,
  isCentered = false,
  children,
  ...rest
}) => {
  const numberElement = children?.length

  const buildGridConfig = (elementPerLine, lineWidth = 12) => {
    const itemWidth = lineWidth / elementPerLine
    const gridConfig = chunk(
      Array(numberElement).fill(itemWidth),
      elementPerLine,
    )

    if (isCentered) {
      const lastLine = last(gridConfig)
      const emptyColumns = Array(floor((lineWidth - sum(lastLine)) / 2)).fill(
        '.',
      )
      // Building last line centering items
      gridConfig[gridConfig.length - 1] = [
        ...emptyColumns,
        ...lastLine,
        ...emptyColumns,
      ]
    }

    return gridConfig
  }

  // // Automatically use a grid configuration for each support based on number of columns
  const gridConfig = {
    gridConfig: {
      desktop: buildGridConfig(desktop, 12),
      tablet: buildGridConfig(tablet, 6),
      mobile: buildGridConfig(mobile, 4),
    },
    numberElement,
  }

  return (
    <GridSubSectionContainer {...gridConfig} {...rest}>
      {children}
    </GridSubSectionContainer>
  )
}

GridLayout.propTypes = {
  // Number of element per line
  desktop: PropTypes.number,
  tablet: PropTypes.number,
  mobile: PropTypes.number,
  // Will align the last line elements to the center of the grid
  isCentered: PropTypes.bool,
}

export default styled(GridLayout)``
