const getSelectedOptionsString = (options) =>
  options.reduce((acc, opt) => {
    if (!opt.isSelected) {
      return acc
    }
    if (!acc) {
      return opt.id
    }
    return `${acc},${opt.id}`
  }, '')

export { getSelectedOptionsString }
