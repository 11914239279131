import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const IpPools = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g fillRule="nonzero">
        <path d="M4.83,10.17a2,2,0,0,1-2-2,.67.67,0,1,0-1.33,0A3.33,3.33,0,0,0,4.83,11.5a.67.67,0,1,0,0-1.33Z" />
        <path d="M2.88,6.65l0,0c1.58-1.76,2.35-3.07,2.35-4A2.63,2.63,0,0,0,0,2.62c0,.94.77,2.25,2.35,4A.38.38,0,0,0,2.88,6.65Zm-1-4a.74.74,0,0,1,.74-.74.75.75,0,0,1,.76.74.76.76,0,0,1-.76.76A.75.75,0,0,1,1.88,2.62Z" />
        <path d="M8.62,3A3.37,3.37,0,0,0,5.25,6.38c0,1.21,1,2.89,3.1,5.13a.37.37,0,0,0,.53,0h0C11,9.27,12,7.59,12,6.38A3.38,3.38,0,0,0,8.62,3Zm0,4.5A1.13,1.13,0,1,1,9.75,6.38,1.12,1.12,0,0,1,8.62,7.5Z" />
      </g>
    </StyledIcon>
  )

export default styled(IpPools)``
