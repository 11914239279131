import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Link = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M1.044 11.204l-.268-.267A2.64 2.64 0 0 1 0 9.06c0-.709.275-1.376.776-1.876L7.197.75c1-1.001 2.746-1.002 3.747 0l.268.269-1.606 1.608-.267-.268a.375.375 0 0 0-.535 0L2.382 8.792a.379.379 0 0 0 0 .537l.267.267-1.605 1.608zm13.913-6.432l.269.268a2.66 2.66 0 0 1 0 3.751l-6.423 6.432A2.63 2.63 0 0 1 6.93 16a2.63 2.63 0 0 1-1.873-.777l-.268-.268 1.605-1.608.268.268a.378.378 0 0 0 .536 0l6.422-6.432a.38.38 0 0 0 0-.535l-.268-.269 1.605-1.607zM13.624.752L15.23 2.36 9.868 7.72 8.263 6.114l5.361-5.36zM6.132 8.257l1.605 1.606-5.36 5.36-1.605-1.606 5.36-5.36z"/></g>
    </StyledIcon>
  )

export default styled(Link)``
  