import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const List1 = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M1.8 0a1.8 1.8 0 1 0 1.8 1.8A1.8 1.8 0 0 0 1.8 0zm0 2.4a.6.6 0 1 1 .6-.6.6.6 0 0 1-.6.6zm0 1.8A1.8 1.8 0 1 0 3.6 6a1.8 1.8 0 0 0-1.8-1.8zm0 2.4a.6.6 0 1 1 .6-.6.6.6 0 0 1-.6.6zm0 1.8a1.8 1.8 0 1 0 1.8 1.8 1.8 1.8 0 0 0-1.8-1.8zm0 2.4a.6.6 0 1 1 .6-.6.6.6 0 0 1-.6.6zm3-9.6H12v1.2H4.8zm0 4.2H12v1.2H4.8zm0 4.2H12v1.2H4.8z"/></g>
    </StyledIcon>
  )

export default styled(List1)``
  