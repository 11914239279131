const getCodeEditor = (colors) => ({
  badge: {
    background: 'transparent',
    backgroundActive: colors.primary60,
    backgroundHover: 'transparent',
    border: colors.neutral0,
    borderActive: 'transparent',
    borderHover: colors.primary60,
    color: colors.neutral0,
    colorActive: colors.neutral0,
    colorHover: colors.neutral0,
  },
  iconCopy: '#888888',
  iconCopyHover: '#888888',
})

export default getCodeEditor
