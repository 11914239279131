import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const DividerDashed = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M0 8h2v1H0V8zm3.5 0h2v1h-2V8zM7 8h2v1H7V8zm3.5 0h2v1h-2V8zM14 8h2v1h-2V8z"/></g>
    </StyledIcon>
  )

export default styled(DividerDashed)``
  