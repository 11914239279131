import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Web = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="m14.50001,15l-8.5,0l0,-11l9,0l0,10.5c0,0.275 -0.225,0.5 -0.5,0.5m-13.5,-0.5l0,-10.5l4,0l0,11l-3.5,0c-0.275,0 -0.5,-0.225 -0.5,-0.5m0.5,-13.5l13,0c0.275,0 0.5,0.224 0.5,0.5l0,1.5l-14,0l0,-1.5c0,-0.276 0.225,-0.5 0.5,-0.5m13,-1l-13,0c-0.827,0 -1.5,0.673 -1.5,1.5l0,13c0,0.827 0.673,1.5 1.5,1.5l13,0c0.827,0 1.5,-0.673 1.5,-1.5l0,-13c0,-0.827 -0.673,-1.5 -1.5,-1.5"/></g>
    </StyledIcon>
  )

export default styled(Web)``
