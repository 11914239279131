import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const FolderFavorite = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M6 5.14c-.46-1.61-2.1-1.08-2.1.15S6 7.8 6 7.8c.17-.1 2.1-1.12 2.1-2.51-.04-1.23-1.61-1.76-2.1-.15z"/><path d="M10.8 1.8h-5L4.86.48A1.21 1.21 0 0 0 3.9 0H1.2A1.2 1.2 0 0 0 0 1.2V9a1.2 1.2 0 0 0 1.2 1.2h9.6A1.2 1.2 0 0 0 12 9V3a1.2 1.2 0 0 0-1.2-1.2zM1.2 9V1.2h2.7l1 1.32a1.21 1.21 0 0 0 1 .48h5v6z"/></g>
    </StyledIcon>
  )

export default styled(FolderFavorite)``
  