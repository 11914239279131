import styled from 'styled-components'
import { isNil } from 'lodash'
import { margins, paddings } from 'component-library/helpers'
import StyledSkeleton from './StyledSkeleton'

const WIDTH_FOR_BODY = '167px'
const WIDTH_FOR_PARAGRAPH = '252px'

const PARAGRAPH_LINES = 3

const calculateWidth = ({ theme, lines, width }) => {
  if (!isNil(width)) {
    return theme.sizes.widths[width] || theme.sizes[width] || width
  }

  return lines >= PARAGRAPH_LINES ? WIDTH_FOR_PARAGRAPH : WIDTH_FOR_BODY
}

const WrapperParagraph = styled.div`
  display: flex;
  flex-direction: column;
  width: ${calculateWidth};

  // Display the last line at 75%, if there's more than one line
  & ${StyledSkeleton}:nth-child(n+2):last-child {
    max-width: 75%;
  }

  ${margins};
  ${paddings};
`

export default WrapperParagraph
