import styled, { css } from 'styled-components'

import ParagraphWithAnySection from '@components/ParagraphWithAny'
import { DivText } from '@components/ParagraphWithAny/styles'
import { BottomSectionChildren } from '@styles/BottomSectionChildren'
import { styleForMobile, styleForTablet } from '@utils'
import { gridHelper } from '@utils/GridHelper'

export const StyledParagraphWithAnySection = styled(ParagraphWithAnySection)`
  ${({ isTextRight, isTextTop, theme }) => css`
    & > div {
      ${isTextTop && gridHelper(12, [9, '.', '.', '.'])};
      row-gap: 120px;

      ${styleForTablet} {
        ${gridHelper(6, isTextTop ? [6] : [3, 3])};
        row-gap: ${theme.sizes.s15};

        ${DivText} {
          ${!isTextTop &&
          `margin-${isTextRight ? 'left' : 'right'}: ${theme.sizes.s6};`}
        }
      }

      ${styleForMobile} {
        ${isTextTop && gridHelper(4, [4])};
        row-gap: ${theme.sizes.s6};

        ${BottomSectionChildren} {
          margin-top: ${theme.sizes.s6};
        }
      }
    }
  `}
`
