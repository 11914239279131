import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const BorderSolid = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M1 1v14h14V1H1zM0 0h16v16H0V0z"/></g>
    </StyledIcon>
  )

export default styled(BorderSolid)``
  