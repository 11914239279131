import {
  animations,
  colors,
  colorsVariationsFactory,
  componentsFactory,
  darkComponentsFactory,
  fonts,
  globals,
  responsive,
  sizes,
} from '../styles'

const components = componentsFactory({ colors: colorsVariationsFactory({ colors }), globals })

const mailjetTheme = {
  animations,
  colors,
  colorsVariationsFactory,
  components,
  componentsFactory,
  darkComponentsFactory,
  fonts,
  globals,
  responsive,
  sizes,
}

export { mailjetTheme }

export default mailjetTheme
