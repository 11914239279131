import { useEffect, useRef, useState } from 'react'
import Breakpoints from '@theme/Breakpoints'
import { useWindowWidth } from './index'
// This custom hook is meant to provide an easy to use Carousel state management/events handlers.
const useCarouselEvents = (
  numberElements,
  isContentCentered = false,
  gutterWidth = 16,
) => {
  const [position, setPosition] = useState(0)
  // Used to disable carousel buttons
  const [canMoveLeft, setCanMoveLeft] = useState(false)
  const [canMoveRight, setCanMoveRight] = useState(true)
  // State managing last left position
  const [lastLeftPos, setLastLeftPos] = useState(0)
  const [isSliding, setIsSliding] = useState(false)
  const [positionPointerStart, setPositionPointerStart] = useState(0)

  // A ref is used to calculated inner width and determine possible position based on number of elements.
  const carouselRef = useRef(null)
  // Card width is calculated by dividing total carousel elements width minus gaps by number of elements
  const getCardWidth = () =>
    (carouselRef?.current?.scrollWidth - (numberElements - 1) * gutterWidth) /
    numberElements
  // Max left position is meant for the last card to meet container inner right border.
  // Formula is: parent width - parent paddings - carousel inner width.
  const maxLeftPos =
    carouselRef?.current?.parentElement?.offsetWidth -
    48 -
    carouselRef?.current?.scrollWidth
  const maxPosition = Math.round(Math.abs(maxLeftPos) / getCardWidth())
  const maxPositionCentered = numberElements - 1

  const getLeftPos = () => parseInt(carouselRef?.current?.style.left) || 0
  const setLeftPos = (posLeft) =>
    carouselRef?.current?.style &&
    (carouselRef.current.style.left = posLeft + 'px')

  const windowWidth = useWindowWidth()
  const { breakpoint: desktopBreakpoint } = Breakpoints.desktop
  // Small devices need sliding distance to be proportionally reduced from their screen width.
  // We consider that anything over desktop breakpoint can use a 1 to 1 factor.
  const deviceEventFactor =
    1 / (windowWidth > desktopBreakpoint ? 1 : windowWidth / desktopBreakpoint)
  // Reset position to zero when window changes
  useEffect(() => {
    if (position !== 0 || lastLeftPos !== 0 || positionPointerStart !== 0) {
      setPosition(0)
      setLastLeftPos(0)
      setPositionPointerStart(0)
      setCanMoveLeft(false)
      setCanMoveRight(true)
    }
  }, [windowWidth])

  useEffect(() => {
    if (isSliding) return
    const gapWidth = position * gutterWidth
    const newLeftPosition = -position * getCardWidth() - gapWidth
    if (
      (!isContentCentered && position >= maxPosition) ||
      position >= maxPositionCentered
    ) {
      // Last position is different from CarouselQuotes and CarouselCards
      if (isContentCentered) {
        setLeftPos(-maxPositionCentered * (getCardWidth() + gutterWidth))
      } else {
        setLeftPos(maxLeftPos)
      }
      setCanMoveRight(false)
      setCanMoveLeft(true)
      return
    } else if (position === 0) {
      setCanMoveLeft(false)
      setCanMoveRight(true)
    } else {
      setCanMoveLeft(true)
      setCanMoveRight(true)
    }
    setLeftPos(newLeftPosition)
  }, [position, isSliding])

  const moveRight = () => {
    if (position <= numberElements) {
      setPosition(position + 1)
    }
  }

  const moveLeft = () => {
    if (position > 0) {
      setPosition(position - 1)
    }
  }

  const appContainer =
    typeof window !== `undefined` && document.getElementById('___gatsby')
  // Detect end of sliding and cleaning events
  const onMouseUp = () => {
    slideEnd()
    window.removeEventListener('mouseup', onMouseUp)
    appContainer.removeEventListener('mouseleave', onMouseUp)
  }

  const getPosXForEvent = (e) =>
    e?.touches?.[0]?.clientX || e?.nativeEvent?.clientX

  const slideStart = (e) => {
    // Starting by saving last position
    setLastLeftPos(getLeftPos())
    setPositionPointerStart(getPosXForEvent(e))
    setIsSliding(true)
    // Mouse events that trigger the end of sliding
    if (e?.type.toLowerCase().includes('mouse')) {
      window.addEventListener('mouseup', onMouseUp)
      appContainer.addEventListener('mouseleave', onMouseUp)
    }
  }
  // Managing to set the closest position at the end of sliding event
  const slideEnd = () => {
    const lastPosition = isContentCentered ? maxPositionCentered : maxPosition
    const leftPos = getLeftPos()
    const closestPosition =
      -leftPos > getCardWidth()
        ? Math.round(Math.abs(leftPos) / getCardWidth())
        : 0
    setPosition(closestPosition > lastPosition ? lastPosition : closestPosition)
    setIsSliding(false)
  }

  const sliding = (e) => {
    e.stopPropagation()
    // Get first child left border position dynamically
    const posX = getPosXForEvent(e)

    if (!isSliding || posX === positionPointerStart) return

    const newLeftPos =
      lastLeftPos + (posX - positionPointerStart) * deviceEventFactor
    setLeftPos(newLeftPos)
  }

  // These props should be spread to the carousel component.
  // It contains every events supported and parameters required.
  const carouselProps = {
    isSliding,
    onTouchStart: slideStart,
    onMouseDown: slideStart,
    onTouchMove: sliding,
    onMouseMove: sliding,
    onTouchEnd: slideEnd,
    ref: carouselRef,
  }

  return [
    canMoveLeft,
    canMoveRight,
    moveLeft,
    moveRight,
    carouselProps,
    position,
  ]
}

export default useCarouselEvents
