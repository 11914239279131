import { isArray, isNumber } from 'lodash'

const errorMessage =
  "gridHelper([number | '.', number | '.'] childrenWidth, string height, number numberColumns) - wrong parameter"

const createColumnWidthLine = (columnsWidth) => {
  if (!isNumber(columnsWidth)) throw Error(errorMessage)
  return `repeat(${columnsWidth}, 1fr)`
}

const createRowHeightColumn = (numberRows) => {
  if (!isNumber(numberRows)) throw Error(errorMessage)
  return `repeat(${numberRows}, auto)`
}

export const gridHelper = (numberColumns = 12, ...childrenWidth) => {
  if (!isNumber(numberColumns) || !isArray(childrenWidth))
    throw Error(errorMessage)
  const gridTemplateColumns = createColumnWidthLine(numberColumns)
  const gridTemplateRows = createRowHeightColumn(childrenWidth.length)
  let childNumber = 1
  let gridCursor = 1
  let childrenSetup = ''
  for (let j = 0; j < childrenWidth.length; j++) {
    gridCursor = 1
    for (let i = 0; i < childrenWidth[j].length; i++) {
      //  TODO check if childrenWidth[i] is number or '.'
      if (childrenWidth[j][i] === '.' || childrenWidth[j][i] === null) {
        gridCursor++
      } else {
        childrenSetup =
          childrenSetup +
          `
          & > :nth-child(${childNumber}){
            grid-column: ${gridCursor} / ${gridCursor + childrenWidth[j][i]};
            grid-row: ${j + 1};
          };
        `
        gridCursor = gridCursor + childrenWidth[j][i]
        childNumber++
      }
    }
  }

  return `
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: ${gridTemplateColumns};
    grid-template-rows: ${gridTemplateRows};
    ${childrenSetup}
  `
}
