import styled from 'styled-components'
import PropTypes from 'prop-types'
import { shouldReturnWhiteOrBlackText } from '../../../helpers'

const IconWrapper = styled.div`
  cursor: ${({ disabled, onClick }) =>
    disabled ? 'not-allowed' : !!onClick ? 'pointer' : 'default'};
  fill: ${({ backgroundColor, theme }) =>
    !!backgroundColor
      ? shouldReturnWhiteOrBlackText(theme.colors[backgroundColor] || backgroundColor)
      : theme.components.tag.icon};
  user-select: none;
  display: inline-flex;
  padding-left: ${({ theme }) => theme.sizes.s3};

  &:hover {
    fill: ${({ disabled, theme }) =>
      disabled ? theme.components.tag.text : theme.components.tag.iconHover};
  }
`

IconWrapper.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

export default IconWrapper
