import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const CircleShape = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12zm0 2A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"/></g>
    </StyledIcon>
  )

export default styled(CircleShape)``
  