import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Generate = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M2.667 6H8v1.333H2.667V6zm0 2.667h4V10h-4V8.667zM1.333 1.333v12h5.334v1.334H1.333A1.335 1.335 0 0 1 0 13.333v-12C0 .6.598 0 1.333 0h7.61L12 3.057v3.61h-1.333V4H8V1.333H1.333zM12 8c2.206 0 4 1.794 4 4s-1.794 4-4 4a3.972 3.972 0 0 1-2.829-1.171L8 16v-3.333h3.333l-1.218 1.218.032.036c.47.468 1.14.746 1.853.746A2.67 2.67 0 0 0 14.667 12 2.67 2.67 0 0 0 12 9.333 2.67 2.67 0 0 0 9.333 12H8c0-2.206 1.794-4 4-4z"/></g>
    </StyledIcon>
  )

export default styled(Generate)``
  