import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const EmailFullView = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M16 0v16h-3.2V0H16zM9.6 0v16H6.4V0h3.2zM3.2 0v16H0V0h3.2z"/></g>
    </StyledIcon>
  )

export default styled(EmailFullView)``
  