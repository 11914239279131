import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Close = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M14.4 0L8 6.4 1.601 0 0 1.6l6.4 6.399-6.4 6.4L1.601 16 8 9.6l6.4 6.4 1.6-1.601-6.4-6.4L16 1.6z"/></g>
    </StyledIcon>
  )

export default styled(Close)``
  