import React from 'react'
import styled from 'styled-components'

import { CustomSkeleton } from '../../Skeleton'

const StyledTd = styled.td`
  ${({ theme: { sizes } }) => `
    padding: ${sizes.s4} ${sizes.s5};
  `}
`

const LoadingHeader = () => (
  <StyledTd>
    <CustomSkeleton />
  </StyledTd>
)

export default LoadingHeader
