import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const RectangleRoundedShape = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M3 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3zm0-2h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3z"/></g>
    </StyledIcon>
  )

export default styled(RectangleRoundedShape)``
  