import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Back = props => (
    <StyledIcon viewBox="0 0 12 8" {...props}>
      <g><path d="M12 3.15H3.15L5.04 1.2 3.88 0 0 4l3.88 4 1.16-1.2-1.89-1.95H12"/></g>
    </StyledIcon>
  )

export default styled(Back)``
  