import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const RectangleShape = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M2 2v12h12V2H2zM0 0h16v16H0V0z"/></g>
    </StyledIcon>
  )

export default styled(RectangleShape)``
  