import styled, { css } from 'styled-components'

import { styleForDesktop } from '@utils'

export const NavigationContainer = styled.nav`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    height: 52px;
    justify-content: space-between;
    transition: height 0.35s;

    ${styleForDesktop} {
      box-sizing: border-box;
      margin: auto;
      max-width: 1280px;
      padding: 0 ${theme.sizes.s5};
    }
  `}
`
