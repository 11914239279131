import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const List4 = props => (
    <StyledIcon viewBox="0 0 10.8 12" {...props}>
      <g><path d="M9.6 3.6H1.2A1.2 1.2 0 0 1 0 2.4V1.2A1.2 1.2 0 0 1 1.2 0h8.4a1.2 1.2 0 0 1 1.2 1.2v1.2a1.2 1.2 0 0 1-1.2 1.2zM1.2 1.2v1.2h8.4V1.2zm8.4 6.6H1.2A1.2 1.2 0 0 1 0 6.6V5.4a1.2 1.2 0 0 1 1.2-1.2h8.4a1.2 1.2 0 0 1 1.2 1.2v1.2a1.2 1.2 0 0 1-1.2 1.2zM1.2 5.4v1.2h8.4V5.4zM9.6 12H1.2A1.2 1.2 0 0 1 0 10.8V9.6a1.2 1.2 0 0 1 1.2-1.2h8.4a1.2 1.2 0 0 1 1.2 1.2v1.2A1.2 1.2 0 0 1 9.6 12zM1.2 9.6v1.2h8.4V9.6z"/></g>
    </StyledIcon>
  )

export default styled(List4)``
  