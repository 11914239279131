import styled from 'styled-components'
import Loader from '../../Loader'

const StyledLoader = styled(Loader)`
  border-color: ${({ theme }) => theme.components.linkButton.icon};
  border-top-color: ${({ theme }) => theme.components.linkButton.backgroundColor};
  margin-right: ${({ theme }) => theme.sizes.s3};
`

export default StyledLoader
