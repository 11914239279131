import React from 'react'
import { range } from 'lodash'
import StyledPaginationButton from './components/StyledPaginationButton'
import NavigationArrow from './components/NavigationArrow'
import PaginationNav from './components/PaginationNav'

const paginationButtonClassName = {
  IS_SELECTED: 'is-selected',
  IS_NOT_TOGGLE: 'is-not-toggle',
}

const FIRST_PAGE = 1
const SKIP_TO_END_MIN_PAGES = 5
const SKIP_TO_FIRST_PAGE_AT = 6

const PaginationButton = ({ children, isSelected, onClick }) => {
  const className = isSelected
    ? `${paginationButtonClassName.IS_SELECTED} ${paginationButtonClassName.IS_NOT_TOGGLE}`
    : `${paginationButtonClassName.IS_NOT_TOGGLE}`

  return (
    <StyledPaginationButton className={className} mode="icon" onClick={onClick} small>
      {children}
    </StyledPaginationButton>
  )
}

const PaginationNumbers = ({
  goTo,
  pagination: { currentPage, firstPageDisplayed, lastPage, lastPageDisplayed },
}) => {
  const pagesDisplayed = range(firstPageDisplayed, lastPageDisplayed + 1)

  const PaginationNavButton = ({ pageNumber, children, isSelected }) => (
    <PaginationButton onClick={() => pageNumber && goTo({ pageNumber })} isSelected={isSelected}>
      {children}
    </PaginationButton>
  )

  return (
    <PaginationNav>
      {FIRST_PAGE !== firstPageDisplayed && currentPage >= SKIP_TO_FIRST_PAGE_AT && (
        <PaginationNavButton pageNumber={1}>
          <NavigationArrow />
        </PaginationNavButton>
      )}
      {FIRST_PAGE !== currentPage && (
        <PaginationNavButton pageNumber={currentPage - 1}>&lt;</PaginationNavButton>
      )}
      {pagesDisplayed.map(pageNumber => {
        const isCurrentPage = pageNumber === currentPage
        return (
          <PaginationNavButton
            isSelected={isCurrentPage}
            key={`page-${pageNumber}`}
            pageNumber={pageNumber}
          >
            {pageNumber}
          </PaginationNavButton>
        )
      })}
      {lastPage !== currentPage && (
        <PaginationNavButton pageNumber={currentPage + 1}>&gt;</PaginationNavButton>
      )}
      {lastPage !== lastPageDisplayed && lastPage > SKIP_TO_END_MIN_PAGES && (
        <PaginationNavButton pageNumber={lastPage}>
          <NavigationArrow isBackwards />
        </PaginationNavButton>
      )}
    </PaginationNav>
  )
}

const Pagination = ({
  goTo,
  maxSidePageDisplayed = 2,
  onChangePagination,
  pagination = {
    currentPage: 1,
    lastPage: 1,
  },
}) => {
  const { currentPage, lastPage } = pagination
  const firstPageCalculated = currentPage - maxSidePageDisplayed
  const lastPageCalculated = currentPage + maxSidePageDisplayed
  const firstPageDisplayed = firstPageCalculated > 0 ? firstPageCalculated : 1
  const lastPageDisplayed = lastPageCalculated > lastPage ? lastPage : lastPageCalculated
  const generatedPagination = {
    ...pagination,
    firstPageDisplayed,
    lastPageDisplayed,
  }

  return firstPageDisplayed !== lastPageDisplayed ? (
    <PaginationNumbers
      goTo={goTo}
      pagination={generatedPagination}
    />
  ) : null
}

export default Pagination

