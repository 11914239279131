import styled, { css } from 'styled-components'

import { ButtonSecondary } from '@components/Button'
import Image from '@components/Image'
import { Header1, Header5Bold } from '@typography'

export const ColumnsDiv = styled.div`
  ${({ hasButton, theme }) => css`
    ${hasButton && `padding-bottom: ${theme.sizes.s6};`}

    ${Header1}, ${Header5Bold} {
      margin-bottom: ${theme.sizes.s3};
    }
  `}
`

export const UList = styled.ul`
  ${({ theme }) => css`
    color: ${theme.colors.neutral70};
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 14px;

    li:not(:last-child) {
      padding-bottom: ${theme.sizes.s3};
    }

    li p {
      color: ${theme.colors.neutral70};
    }
  `}
`

export const ColumnsIcon = styled(Image)`
  ${({ theme }) => css`
    height: ${theme.sizes.s8};
    margin-bottom: ${theme.sizes.s5};
    width: ${theme.sizes.s8};

    svg * {
      fill: ${theme.colors.neutral100};
    }
  `}
`

export const ColumnsButton = styled(ButtonSecondary)``
