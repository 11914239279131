// This function is used to handle downloads for the research reports
export const extractFilenameFromLink = (link) => link?.split('/')?.pop()

export const handlePDFDownload = async (pdfFileUrl, event) => {
  try {
    const response = await fetch(pdfFileUrl)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    // Convert the response to a blob (Binary Large OBject)
    const blob = await response.blob()

    // Create a URL representing the blob
    const url = URL.createObjectURL(blob)

    // Create a new anchor element
    const link = document.createElement('a')

    // Set the href of the anchor element to the blob URL
    link.href = url

    // Set the download attribute of the anchor element to the provided file name
    link.download = extractFilenameFromLink(pdfFileUrl)

    // Click the anchor element to trigger the download
    link.click()

    // Revoke the blob URL to release memory
    URL.revokeObjectURL(url)
  } catch (error) {
    // If any error occurs, log it to the console
    console.error('Error downloading PDF:', error)
  }
}
