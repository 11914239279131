import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Edit = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M15.058.942A3.2 3.2 0 0 0 12.78 0c-.86 0-1.67.334-2.277.943l-9.128 9.128a.796.796 0 0 0-.21.372L.023 15.007a.8.8 0 0 0 .97.97l4.565-1.142a.796.796 0 0 0 .371-.21l9.128-9.129A3.204 3.204 0 0 0 16 3.22c0-.86-.334-1.67-.943-2.277zM5.66 11.47l5.978-5.978.581.58-6.364 6.365-.195-.967zm-1.13-1.132l-.968-.193L9.928 3.78l.58.581-5.978 5.977zM2.531 11.57l1.581.316.317 1.581-2.53.633.632-2.53zm8.527-8.922l.576-.574c.613-.612 1.68-.612 2.291 0l-.001-.001c.612.612.612 1.68 0 2.291l-.575.576-2.289-2.29z"/></g>
    </StyledIcon>
  )

export default styled(Edit)``
  