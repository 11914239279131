import React from 'react'
import styled from 'styled-components'

const NewsletterSVG = ({ stroke = '#FFFFFF', ...props }) => (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...props}
  >
    <title>04-Icons/24px/Newsletter</title>
    <g
      id='04-Icons/24px/Newsletter'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <path
        d='M20,5.68434189e-14 L20,7.59 L24,10.4898381 L24,24 L0,24 L0,10.4898381 L4,7.589 L4,5.68434189e-14 L20,5.68434189e-14 Z M2,12.684 L2,22 L19.08,22 L2,12.684 Z M22,12.683 L14.086,17 L22,21.316 L22,12.683 Z M18,2 L6,2 L6,12.589 L11.999,15.861 L18,12.588 L18,2 Z M16,9 L16,11 L8,11 L8,9 L16,9 Z M16,5 L16,7 L8,7 L8,5 L16,5 Z'
        id='Newsletter'
        fill={stroke}
      ></path>
    </g>
  </svg>
)

export default styled(NewsletterSVG)``
