import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Boxed = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M4 2v1.6H1.6V6H0V3.6A1.6 1.6 0 0 1 1.6 2H4zm10.4 0A1.6 1.6 0 0 1 16 3.6V6h-1.6V3.6H12V2h2.4zM1.6 14.8A1.6 1.6 0 0 1 0 13.2v-2.4h1.6v2.4H4v1.6H1.6zm12.8-1.6v-2.4H16v2.4a1.6 1.6 0 0 1-1.6 1.6H12v-1.6h2.4zM12 6v4.8H4V6h8z"/></g>
    </StyledIcon>
  )

export default styled(Boxed)``
  