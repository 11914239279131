import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const EyeSolid = props => (
    <StyledIcon viewBox="0 0 16 12" {...props}>
      <g>
        <path d="M8 12c4.707 0 7.744-5.284 7.871-5.508a1 1 0 0 0 .001-.98C15.746 5.287 12.731 0 8 0 3.245 0 .251 5.289.126 5.514a.998.998 0 0 0 .002.975C.254 6.713 3.269 12 8 12zM8 2c2.839 0 5.036 2.835 5.818 4-.784 1.166-2.981 4-5.818 4-2.841 0-5.038-2.838-5.819-4.001C2.958 4.835 5.146 2 8 2z" />
        <circle cx="8" cy="6" r="2" />
      </g>
    </StyledIcon>
  )

export default styled(EyeSolid)``
