import React from 'react'
import styled from 'styled-components'

const CrossThickSVG = ({ ...props }) => (
  <svg viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g>
      <path
        className='st0'
        d='M38,12.8L35.2,10L24,21.2L12.8,10L10,12.8L21.2,24L10,35.2l2.8,2.8L24,26.8L35.2,38l2.8-2.8L26.8,24L38,12.8z'
      />
    </g>
  </svg>
)

export default styled(CrossThickSVG)``
