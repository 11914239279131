import React from 'react'
import RemoteMailjetSVG from './RemoteSVG/mj_logo_only_icon_color'

const MailjetSVG = () => {
  const svgString = RemoteMailjetSVG

  return <div dangerouslySetInnerHTML={{ __html: svgString }} />
}

export { MailjetSVG, RemoteMailjetSVG as MailjetSVGString }
