import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Add = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M18.182 6.869h-9.05v-9.05L6.87-2.182v9.05h-9.05v2.264h9.05v9.05h2.263V9.13l9.05.001z"/></g>
    </StyledIcon>
  )

export default styled(Add)``
  