import React from 'react'
import { filter, get, indexOf, map } from 'lodash'

import Accordion from './Accordion'
import AccordionWrapper from './styles/AccordionWrapper'

class DefaultAccordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // If multi then keep all opened values. Otherwise keep only the first one.
      openedAccordions: props.multi
        ? map(filter(props.content, 'isOpened'), 'id')
        : [get(filter(props.content, 'isOpened'), '[0].id', null)],
    }
  }

  onAccordionClick = (id) => {
    const { multi } = this.props
    const { openedAccordions } = this.state

    if (multi) {
      // If multi selection is enabled, then we need to filter the clicked value or add it.
      this.setState({
        openedAccordions: this.isAccordionOpen(id)
          ? filter(openedAccordions, accordionId => accordionId !== id)
          : [...openedAccordions, id],
      })
    } else {
      // No multi selection, then work only on a single value array.
      this.setState({ openedAccordions: this.isAccordionOpen(id) ? [] : [id] })
    }
  }

  isAccordionOpen = (id) => {
    const { openedAccordions } = this.state

    return indexOf(openedAccordions, id) !== -1
  }

  render() {
    const { className, content, locator, noPadding, reducedBorders, _ref, small, style } = this.props

    return (
      <AccordionWrapper
        ref={_ref}
        className={className}
        reducedBorders={reducedBorders}
        style={style}
      >
        {map(content, elem => (
          <Accordion
            className={locator}
            key={elem.id}
            {...elem}
            isOpened={this.isAccordionOpen(elem.id)}
            noPadding={noPadding}
            onClick={() => this.onAccordionClick(elem.id)}
            small={small}
          />
        ))}
      </AccordionWrapper>
    )
  }
}

export default DefaultAccordion