import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const GeoTagDisabled = (props) => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <circle cx="6.815" cy="6.815" fillRule="nonzero" r="1.947"/>
      <path
        d="M 6.815 0 C 10.611 0 13.629 3.018 13.629 6.815 C 13.629 7.731 13.462 8.623 13.128 9.444 L 11.577 7.892 C 11.646 7.577 11.682 7.251 11.682 6.912 C 11.682 4.186 9.54 2.044 6.815 2.044 C 4.089 2.044 1.947 4.089 1.947 6.815 C 1.947 8.08 2.451 9.262 3.302 10.204 L 3.505 10.417 L 3.505 10.417 L 6.815 13.337 L 7.115 13.071 L 8.508 14.464 C 7.948 14.97 7.527 15.35 7.496 15.382 C 7.107 15.674 6.523 15.674 6.133 15.284 C 6.036 15.187 2.044 11.682 2.044 11.585 C 0.681 10.417 0 8.664 0 6.815 C 0 3.018 3.018 0 6.815 0 Z"
        fillRule="nonzero"/>
      <path
        d="M 10.501 10.248 C 10.501 10.248 12.487 12.177 12.477 12.167 C 12.467 12.157 14.453 10.248 14.453 10.248 C 14.431 10.242 15.392 11.198 15.44 11.217 C 15.487 11.237 13.505 13.134 13.505 13.134 L 15.44 15.085 C 15.44 15.085 14.463 16.117 14.453 16.076 C 14.443 16.036 12.477 14.147 12.477 14.147 C 12.477 14.147 10.501 16.087 10.501 16.076 C 10.501 16.065 9.478 15.085 9.478 15.085 L 11.464 13.134 L 9.478 11.217 Z"
        fillRule="nonzero"/>
    </StyledIcon>
  );

export default styled(GeoTagDisabled)``
