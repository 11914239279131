import { createGlobalStyle } from 'styled-components'

const EditorGlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Inconsolata&display=swap');

  .code-editor {
    overflow: auto;
     ${({ plainPage }) =>
       plainPage &&
       `
      height: 100%;
      `}

    .CodeMirror {
      ${({ plainPage }) => (plainPage ? `height: 100%;` : `height: auto;`)}
      width: auto;

      .CodeMirror-scroll {
        font-family: Monaco, Menlo, 'Ubuntu Mono', Consolas, source-code-pro, monospace !important;
        font-size: 14px;
        overflow-x: scroll !important;
      }
    }
    ${({ theme }) => `
      .cm-s-material.CodeMirror {
        background-color: ${theme.colors.darksteel} !important;
      }
      .cm-s-material .CodeMirror-gutters {
        background-color: ${theme.colors.darksteel} !important;
        border-right: 1px solid ${theme.colors.darksteel};
      }
    `};

    .cm-s-material .CodeMirror-guttermarker,
    .cm-s-material .CodeMirror-guttermarker-subtle,
    .cm-s-material .CodeMirror-linenumber {
      color: #8f908a !important;
    }
    .cm-s-material .CodeMirror-linenumber {
      padding-left: 0 !important;
      font-family: Inconsolata;
    }
    .cm-matchhighlight {
      box-shadow: inset 0 0 0 1px hsl(220, 14%, 71%);
      border-radius: 3px;
    }
  }
`

export default EditorGlobalStyle
