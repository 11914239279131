import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Unpublish = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M9.63 4.5H1.38A1.32 1.32 0 0 0 0 5.75v5A1.32 1.32 0 0 0 1.38 12h8.25A1.32 1.32 0 0 0 11 10.75v-5A1.32 1.32 0 0 0 9.63 4.5zM8.25 5.82L5.5 7.59 2.75 5.82zm-6.87 4.86V6.26L5.1 8.68a.74.74 0 0 0 .8 0l3.72-2.42v4.41zM12 .67l-1.08 1.08L12 2.83l-.67.67-1.08-1.08L9.17 3.5l-.67-.67 1.08-1.08L8.5.67 9.17 0l1.08 1.08L11.33 0z"/></g>
    </StyledIcon>
  )

export default styled(Unpublish)``
  