import styled from 'styled-components'

const AlignmentWrapper = styled.div`
  ${({ horizontalAlign }) =>
    horizontalAlign &&
    `
    display: flex;
    align-items: center;
  `}
`

export default AlignmentWrapper
