import { clamp, isNumber, range } from 'lodash'
/**
 * Get the range around the current page. Returns an array of numbers.
 * @param {number} currentPage - the current page of the pagination.
 * @param {number} lastPage - the last page of the pagination.
 * @param {number} offset - the offset to put on the left and right of the currentPage.
 * @returns {array} - returns the range of pages to display in UI.
 */
const getRange = (currentPage = 1, lastPage = 1, offset = 0) => {
  if (!isNumber(currentPage) || !isNumber(lastPage) || !isNumber(offset)) {
    throw new Error('getRangeFromCurrentPage() expects numbers.')
  }
  const start = clamp(currentPage - offset, 1, lastPage - offset)
  const end = clamp(currentPage + offset, 1, lastPage)
  return range(start, end + 1)
}

export default getRange
