import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Smtp = props => (
    <StyledIcon viewBox="0 0 26 24" {...props}>
      <g><path d="M18.131 11.08a2.314 2.314 0 012.305 2.32v9.28c0 1.28-1.033 2.32-2.305 2.32H4.304A2.314 2.314 0 012 22.68V13.4c0-1.28 1.032-2.32 2.304-2.32zm0 3.414l-6.244 4.49a1.141 1.141 0 01-1.339 0l-6.244-4.49v8.186h13.83l-.003-8.186zM15.687 13.4H6.748l4.47 3.215 4.47-3.215zM26 2l-3.26 9.842-1.629-4.921-4.888-1.64L26 2z"/></g>
    </StyledIcon>
  )

export default styled(Smtp)``