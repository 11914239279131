import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Flickr = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><circle cx="12.235" cy="7.765" r="3.765"/><circle cx="3.765" cy="7.765" r="3.765"/></g>
    </StyledIcon>
  )

export default styled(Flickr)``
  