import * as React from 'react'
import { drop } from 'lodash'

import Placeholder from '../styles/Placeholder'


/**
 * An abstract class containing all logic to handle option selection.
 */
class AbstractSelectionSelect extends React.Component {
  static defaultProps = {
    onDelete: () => undefined,
    renderSelectedArray: [],
    selected: [],
    sorted: true,
  }

  /**
   * Default render for the placeholder.
   */
  renderPlaceholder = () => {
    const { placeholder } = this.props

    return [<Placeholder key="placeholder">{placeholder}</Placeholder>]
  }

  /**
   * Render selected values. Has to be overridden in children.
   */
  // eslint-disable-next-line no-unused-vars
  renderSelected = (_p) => {
    // To be overrode in children.
    throw new Error(
      'The method renderSelected of AbstractSelectionSelect has to be overridden in children.',
    )
  }

  render() {
    const {
      options,
      selected = [],
      renderPlaceholder = this.renderPlaceholder,
      renderSelectedArray,
      selectToGenerate,
      ...rest
    } = this.props

    const Selected = selectToGenerate[0]

    const props = {
      ...rest,
      selectToGenerate: drop(selectToGenerate),
      selected,
      options,
      // If at least one value is selected, render it.
      // Otherwise, call the renderPlaceholder function in props or local one.
      renderSelectedArray:
        selected.length > 0
          ? [this.renderSelected, ...renderSelectedArray]
          : renderPlaceholder
          ? [renderPlaceholder, ...renderSelectedArray]
          : [this.renderPlaceholder, ...renderSelectedArray],
    }

    return <Selected {...props} />
  }
}

export default AbstractSelectionSelect
