import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Question = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8.8 10.4H7.2V8H8a1.601 1.601 0 0 0 0-3.2 1.6 1.6 0 0 0-1.6 1.6H4.8c0-1.764 1.436-3.2 3.2-3.2 1.764 0 3.2 1.436 3.2 3.2a3.21 3.21 0 0 1-2.4 3.1v.9zM8 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm0 3c-4.411 0-8-3.588-8-8 0-4.41 3.589-8 8-8s8 3.589 8 8c0 4.412-3.589 8-8 8zM8 1.6A6.407 6.407 0 0 0 1.6 8c0 3.529 2.871 6.4 6.4 6.4 3.529 0 6.4-2.871 6.4-6.4 0-3.529-2.871-6.4-6.4-6.4z"/></g>
    </StyledIcon>
  )

export default styled(Question)``
  