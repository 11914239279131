import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ProductPlaceholder = props => (
    <StyledIcon viewBox="0 0 40 40" {...props}>
      <g><path d="M30.5 39.167c.376.006.748-.08 1.083-.25l.417.666a2.875 2.875 0 0 1-1.5.417h-1.833v-.833H30.5zM37.167 0A2.809 2.809 0 0 1 40 2.833V30.5a2.809 2.809 0 0 1-2.833 2.833h-3.834v3H32.5v-3h-23A2.808 2.808 0 0 1 6.667 30.5v-23H3.583v-.833h3.084V2.833A2.808 2.808 0 0 1 9.5 0h27.667zm2 30.5V2.833a2.025 2.025 0 0 0-2-2H9.5a2.025 2.025 0 0 0-2 2V30.5a2.025 2.025 0 0 0 2 2h27.667a2.025 2.025 0 0 0 2-2zm-23.584 8.667h3.25V40h-3.25v-.833zM0 27.75h.833V31H0v-3.25zm9.083 11.417h3.25V40h-3.25v-.833zM0 21.25h.833v3.25H0v-3.25zm0 16.333V34.25h.833v3.25L0 37.583zM0 14.75h.833V18H0v-3.25zM0 9.5c.01-.5.154-.99.417-1.417l.666.417c-.17.305-.256.65-.25 1v2H0v-2zM2.583 40l.084-.833h3.166V40h-3.25zm19.584-.833h3.25V40h-3.25v-.833zm3.216-29.75a.5.5 0 0 1 1 0 3.208 3.208 0 1 1-6.416 0 .5.5 0 1 1 1 0 2.208 2.208 0 1 0 4.416 0zm3.709 16.25a.5.5 0 0 1-.5.5H17.758a.5.5 0 0 1-.5-.5v-7.818l-.954.955a.5.5 0 0 1-.708 0l-3.608-3.618a.5.5 0 0 1 0-.706l5.417-5.417a.5.5 0 0 1 .353-.146h10.834a.5.5 0 0 1 .353.146l5.417 5.417a.5.5 0 0 1 0 .707l-3.617 3.617a.5.5 0 0 1-.708-.001l-.945-.95v7.814zm-1-.5v-8.525a.5.5 0 0 1 .854-.353l1.446 1.453 2.91-2.909-4.917-4.916h-10.42l-4.917 4.917 2.902 2.908 1.455-1.454a.5.5 0 0 1 .853.354v8.525h9.834z"/></g>
    </StyledIcon>
  )

export default styled(ProductPlaceholder)``
  