import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ExternalLink = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g>
        <path d="m8.293 2.293-3.707 3.707 1.414 1.414 3.707-3.707 2.293 2.293v-6h-6z" />
        <path d="m10 10h-8v-8h2v-2h-2a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-2h-2z" />
      </g>
    </StyledIcon>
  )

export default styled(ExternalLink)``
