import React from 'react'
import styled from 'styled-components'

import { ParagraphSkeleton } from '../../Skeleton'
import Cell from './Cell'

const StyledCell = styled(Cell)`
  vertical-align: middle !important;
  ${({ theme }) => `
    & > *:not(:last-child) {
        margin-bottom: ${theme.sizes.s3};
    }
  `};
`

const PlaceholderRow = () => (
  <tr>
    <StyledCell>
      <ParagraphSkeleton lines={2} />
    </StyledCell>
  </tr>
)

const InfiniteLoadingRows = () => (
  <>
    <PlaceholderRow />
    <PlaceholderRow />
  </>
)

export default InfiniteLoadingRows
