import styled from 'styled-components'
import { resetCSS } from '../../../helpers'
import commonStyle from '../helpers/commonStyle'

const Caption = styled.p.attrs(resetCSS)`
  ${commonStyle};

  color: ${({ color, theme }) =>
    color ? theme.colors[color] || color : theme.components.typography.caption.text};
  font-family: ${({ theme }) => theme.fonts.caption.fontFamily};
  font-size: ${({ theme }) => theme.fonts.caption.fontSize};
  font-weight: ${({ theme }) => theme.fonts.caption.fontWeight};
  line-height: ${({ theme }) => theme.fonts.caption.lineHeight};
`

export default Caption
