import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const UserCheck = props => (
    <StyledIcon viewBox="0 0 18 14" {...props}>
      <g fillRule="nonzero">
        <path d="M7 14a5.97 5.97 0 0 1 1.522-3.984C8.348 10.011 8.18 10 8 10c-3.192 0-5.539.795-6.837 1.382A1.989 1.989 0 0 0 0 13.2V16h7.349A5.97 5.97 0 0 1 7 14z" />
        <circle cx="8" cy="4" r="4" />
        <path d="M13 16a.997.997 0 0 1-.707-.293l-2-2 1.414-1.414L13 13.586l3.293-3.293 1.414 1.414-4 4A.997.997 0 0 1 13 16z" />
      </g>
    </StyledIcon>
  )

export default styled(UserCheck)``
