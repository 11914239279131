import styled, { css } from 'styled-components'
import { StyledIcon } from 'component-library/icons'
import { SIDE, SIZES } from '../config'

const BaseSide = styled.div`
  display: flex;

  ${({ side, size, theme: { sizes, components } }) => css`
    color: ${components.input.text};
    & > ${StyledIcon} {
      ${side === SIDE.RIGHT &&
      `
          margin-right: ${size === SIZES.SMALL ? sizes.s3 : sizes.s4};
    `}

      ${side === SIDE.LEFT && `margin-left: ${sizes.s12};`}

    fill: currentColor;
    }
  `}
`

export default BaseSide
