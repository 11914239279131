import styled, { css } from 'styled-components'
import {
  gridHelper,
  styleForDesktop,
  styleForMobile,
  styleForTablet,
  styleForTabletAndMobile,
} from '@utils'
import StyledDiv from '@components/Breadcrumb/styles/StyledDiv'

export const BreadcrumbAndSearchBar = styled.div`
  ${({ theme }) => css`
    ${gridHelper(12, [7, 1, 4])}
    padding-top: ${theme.sizes.s8};

    ${StyledDiv} {
      max-width: 848px;
    }

    & > div:last-child {
      ${styleForDesktop} {
        display: flex;
        justify-content: flex-end;

        & > div {
          width: 360px;
        }
      }

      ${styleForTablet} {
        width: 400px;
      }

      ${styleForMobile} {
        max-width: 100%;
        width: 384px;
      }
    }

    ${styleForTabletAndMobile} {
      display: flex;
      flex-direction: column;

      & > div:last-child {
        align-self: center;
      }
    }

    ${styleForTablet} {
      padding-top: ${theme.sizes.s7};

      & > div:first-child {
        padding-bottom: ${theme.sizes.s6};
      }
    }

    ${styleForMobile} {
      padding-top: ${theme.sizes.s6};

      & > div:nth-child(2) {
        padding-bottom: ${theme.sizes.s5};
      }
    }
  `}
`
