// @noSnapshot
import React from 'react'
import { noop } from 'lodash'
import styled from 'styled-components'
import { useDropdown, useRegisterComponent } from '../../hooks'
import StickyPortal from '../../../StickyPortal'
import Options from '../../../Options'
import OptionsProvider from '../../../Options/hooks/OptionsProvider'

const OptionPanelWrapper = styled.div`
  position: absolute;
  left: ${({ alignOptions }) => (alignOptions === 'left' ? '0' : 'auto')};
  right: ${({ alignOptions }) => (alignOptions === 'right' ? '0' : 'auto')};
  ${({ limitOptionsHeight }) =>
    limitOptionsHeight &&
    `
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
  `}
`

const CustomOptions = ({
  alignOptions,
  children,
  limitOptionsHeight,
  onChange = noop,
  stayOpenOnClick,
  withTextWrapEllipsis,
  ...props
}) => {
  const { disabled, isOpen, setIsOpen } = useDropdown()
  const component = useRegisterComponent()

  function onOptionClick(id) {
    !stayOpenOnClick && setIsOpen(false)
    onChange?.(id)
  }

  return (
    <StickyPortal isOpened={isOpen && !disabled} position="bottom">
      <OptionsProvider onOptionClick={onOptionClick} withTextWrapEllipsis={withTextWrapEllipsis}>
        <OptionPanelWrapper
          alignOptions={alignOptions}
          limitOptionsHeight={limitOptionsHeight}
          ref={component}
        >
          <Options {...props}>{children}</Options>
        </OptionPanelWrapper>
      </OptionsProvider>
    </StickyPortal>
  )
}

export default CustomOptions
