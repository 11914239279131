import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ThreeColSide = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M3 0h1v16H3V0zm9 0h1v16h-1V0zM1 1v14h14V1H1zm0-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z"/></g>
    </StyledIcon>
  )

export default styled(ThreeColSide)``
  