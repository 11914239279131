import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { isNil, reduce } from 'lodash'

import genericTableStyle from '../../helpers/genericTableStyle'
import { transformControls } from '../../helpers'
import { useIsInfinite, useIsSelectable, useTableContext } from '../../hooks'
import { CONTROLS_CLASS_SUFFIX } from '../../config'
import visuallyHidden from '../../../VisuallyHidden'
import Cell from '../../components/Cell'
import OverflowDiv from '../../components/OverflowDiv'
import BackToTop from '../../../BackToTop'

const StyledTable = styled.table.attrs(
  ({ name }) => ({
    role: 'region',
    id: name,
    'aria-live': 'polite',
  }),
)`
  ${genericTableStyle};
  ${({ theme }) => `
    border-bottom:
      ${theme.globals.borderWidth.borderWidth1} solid ${theme.components.datatable.borderColor};
  `}

  ${Cell} {
    vertical-align: top;
    border-left: none;
    border-right: none;

    ${({ isSelectable, theme }) =>
      isSelectable &&
      `
      &:nth-child(2) {
        padding-left: ${theme.sizes.s4};
      };
    `};

    &:last-of-type {
      width: 100%;
      text-align: right;
    }
  }

  // Hide all declared headers (not needed for DataList but mandatory for accesibility)
  thead {
    ${visuallyHidden};
  }
`

function Table({
  children,
  className,
  'data-testid': dataTestId,
  scrollRef = { current: null },
}) {
  const isInfinite = useIsInfinite()
  const isSelectable = useIsSelectable()
  const { name: tableName } = useTableContext()
  const tableRef = useRef(null)
  const [controlsHeight, setControlsHeight] = useState(0)
  const controlsElement = document.querySelectorAll(
    `.${tableName}${CONTROLS_CLASS_SUFFIX}`,
  )
  const BACK_TO_TOP_PX = 24
  const [backValueFromTop, setBackValueFromTop] = useState(BACK_TO_TOP_PX)

  const { current: currentScroll } = scrollRef

  useEffect(() => {
    const { current } = tableRef
    const scrollFunction = () => {
      transformControls(current, controlsElement, controlsHeight, currentScroll)
      setBackValueFromTop(controlsHeight + BACK_TO_TOP_PX)
    }

    if (isNil(currentScroll) && document) {
      document.addEventListener('scroll', scrollFunction)
      return () => document.removeEventListener('scroll', scrollFunction)
    }

    currentScroll?.addEventListener('scroll', scrollFunction)
    return () => currentScroll?.removeEventListener('scroll', scrollFunction)
  }, [tableRef, controlsHeight, currentScroll, currentScroll])

  useEffect(() => {
    setControlsHeight(reduce(controlsElement, (acc, value) => acc + value.offsetHeight, 0))
  }, [controlsElement])

  return (
    <>
      <OverflowDiv className={className} isInfinite={isInfinite} ref={tableRef}>
        <StyledTable data-testid={dataTestId} isSelectable={isSelectable} name={tableName}>
          {children}
        </StyledTable>
      </OverflowDiv>
      {isInfinite ? <BackToTop top={`${backValueFromTop}px`} /> : null}
    </>
  )
}

export default styled(Table)``
