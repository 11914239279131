import styled from 'styled-components'
import { resetCSS } from '../../../helpers'
import { CaptionMedium } from '../../Typography'

const getColor = (selected, theme) =>
  selected ? theme.components.codeEditor.badge.colorActive : theme.components.codeEditor.badge.color

const getBorderColor = (selected, theme) =>
  selected
    ? theme.components.codeEditor.badge.borderActive
    : theme.components.codeEditor.badge.border

const getBackgroundColor = (selected, theme) =>
  selected
    ? theme.components.codeEditor.badge.backgroundActive
    : theme.components.codeEditor.badge.background

const Badge = styled(CaptionMedium).attrs(resetCSS)`
  ${({ selected, theme }) => `
    margin: ${theme.sizes.s2} ${theme.sizes.s3} ${theme.sizes.s2} 0;
    padding: 0px ${theme.sizes.s3};
    border: ${theme.sizes.s0} solid ${getBorderColor(selected, theme)};
    border-radius: ${theme.sizes.s8};
    color: ${getColor(selected, theme)};
    background-color: ${getBackgroundColor(selected, theme)};
    cursor: pointer;

    &:hover:not(:active):not(:focus) {
      background-color: ${!selected ? theme.components.codeEditor.badge.backgroundHover : null};
      border-color: ${!selected ? theme.components.codeEditor.badge.borderHover : null};
      color: ${!selected ? theme.components.codeEditor.badge.colorHover : null};
    }
  `}
`

export default Badge