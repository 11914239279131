import * as React from 'react';
import cx from 'classnames';
import ReactResizeDetector from 'react-resize-detector';

import { Lock } from 'component-library/icons';
import AccordionButton from './styles/AccordionButton';
import AccordionContent from './styles/AccordionContent';
import AccordionIcon from './styles/AccordionIcon';
import AccordionIconTooltipContainer from './styles/AccordionIconTooltipContainer';
import AccordionLeftPartContainer from './styles/AccordionLeftPartContainer';
import AccordionRightPartContainer from './styles/AccordionRightPartContainer';
import AccordionText from './styles/AccordionText';
import ContentSpacer from './styles/ContentSpacer';

import Badge from '../../Badge';
import Tooltip from '../../Tooltip';

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calculatedHeight: '0',
    };
    this._content = null;
  }

  componentDidMount() {
    this.calculateHeight();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      this.calculateHeight();
    }
  }

  setRef = (ref) => {
    this._content = ref;
    this.calculateHeight();
  }

  isOpened = () => {
    const { isOpened, locked } = this.props;
    return isOpened && !locked;
  }

  calculateHeight = () => {
    const calculatedHeight = this._content ? `${this._content.scrollHeight}px` : '0';
    this.setState({ calculatedHeight });
  }

  toggle = () => {
    const { id, onClick } = this.props;
    onClick && onClick(id);
  }

  renderButton = () => {
    const {
      badge,
      badgeColor,
      content,
      'data-testid': dataTestId,
      iconColor,
      label,
      locked,
      small,
      unExpandableLink,
    } = this.props;
    const isOpened = this.isOpened();

    const buttonClassNames = cx({
      'is-opened': isOpened,
    });

    const unExpandable = !content;

    return (
      <AccordionButton
        as={unExpandable && !!unExpandableLink && 'a'}
        className={buttonClassNames}
        data-testid={dataTestId}
        href={unExpandable && unExpandableLink}
        iconColor={iconColor}
        isLink={!!unExpandableLink}
        locked={locked}
        onClick={!locked ? this.toggle : null}
        rel={unExpandable && !!unExpandableLink && 'noreferrer noopener'}
        small={small}
        unExpandable={unExpandable}
      >
        <AccordionLeftPartContainer>
          {this.renderIcon()}
          <AccordionText locked={locked}>{label}</AccordionText>
        </AccordionLeftPartContainer>
        <AccordionRightPartContainer>
          {badge && <Badge backgroundColor={badgeColor || 'lightelectric'} message={badge} />}
          {this.renderRightPart()}
        </AccordionRightPartContainer>
      </AccordionButton>
    );
  }

  renderContent = () => {
    const { content, noPadding, small } = this.props;
    const isOpened = this.isOpened();
    const { calculatedHeight } = this.state;
    return (
      <AccordionContent calculatedHeight={calculatedHeight} isOpened={isOpened}>
        <ContentSpacer isOpened={isOpened} noPadding={noPadding} ref={this.setRef} small={small}>
          <ReactResizeDetector handleHeight onResize={this.calculateHeight}>
            {content || <div />}
          </ReactResizeDetector>
        </ContentSpacer>
      </AccordionContent>
    );
  }

  renderIcon = () => {
    const { icon: Icon, tooltipDirection, tooltipMessage } = this.props;

    if (!Icon) {
      return null;
    }

    if (!!tooltipMessage) {
      return (
        <AccordionIconTooltipContainer>
          <Tooltip direction={tooltipDirection} message={tooltipMessage}>
            <Icon />
          </Tooltip>
        </AccordionIconTooltipContainer>
      );
    }

    return <Icon />;
  }

  renderRightPart = () => {
    const { content, locked, lockedMessage, lockedMessageDirection } = this.props;
    const isOpened = this.isOpened();

    if (!locked) {
      return !!content && <AccordionIcon isOpened={isOpened} size="s3" />;
    }

    if (lockedMessage) {
      return (
        <Tooltip direction={lockedMessageDirection} message={lockedMessage}>
          <Lock />
        </Tooltip>
      );
    }
    return <Lock />;
  }

  render() {
    const { className, content, locator } = this.props;
    const newClassNames = cx(className, locator);

    return (
      <div className={newClassNames}>
        {this.renderButton()}
        {!!content && this.renderContent()}
      </div>
    );
  }
}

export default Accordion;
