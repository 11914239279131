import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Lightbulb = (props) => (
    <StyledIcon viewBox="0 0 12 16" {...props}>
      <g fillRule="nonzero">
        <path d="M12 6c0-3.3-2.7-6-6-6S0 2.7 0 6c0 2.2 1.2 4.2 3 5.2V13h6v-1.8c1.8-1 3-3 3-5.2zM5 16h2c1.1 0 2-.9 2-2H3c0 1.1.9 2 2 2z" />
      </g>
    </StyledIcon>
  );

export default styled(Lightbulb)``
