import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Jet = props => (
    <StyledIcon viewBox="2 4 24 20" {...props}>
      <g><path d="M11.667 18.67c-4.3 1.93-6.621 2.97-7.448 3.116l3.99-6.69h11.473a1551.532 1551.532 0 00-8.015 3.574zM4.271 6.223c.873.18 3.196 1.223 7.428 3.122 2.083.935 4.704 2.109 7.982 3.556H8.21L4.27 6.223zm21.073 6.77a1261.578 1261.578 0 01-12.756-5.655C6.722 4.708 5.144 4 4.046 4c-.943 0-1.407.575-1.56.763a2.212 2.212 0 00-.176 2.513L6.32 14l-4.01 6.722a2.21 2.21 0 00.178 2.513c.152.19.616.765 1.56.765 1.097 0 2.668-.706 8.508-3.325 3.011-1.351 7.126-3.198 12.79-5.669a1.101 1.101 0 00-.002-2.013z"/></g>
    </StyledIcon>
  )

export default styled(Jet)``