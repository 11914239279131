import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ChevronDown = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8.04 9.475L1.672 3 0 4.721 8.04 13 16 4.721 14.328 3z"/></g>
    </StyledIcon>
  )

export default styled(ChevronDown)``
  