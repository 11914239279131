export default `
  .c-g {
    animation: none;
    animation-delay: initial;
    animation-direction: normal;
    animation-duration: initial;
    animation-fill-mode: none;
    animation-iteration-count: 1;
    animation-name: none;
    animation-play-state: running;
    animation-timing-function: ease;
    backface-visibility: visible;
    background: 0;
    background-attachment: scroll;
    background-clip: border-box;
    background-color: transparent;
    background-image: none;
    background-origin: padding-box;
    background-position: 0 0;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: repeat;
    background-size: auto auto;
    border-style: none;
    border-width: medium;
    border-color: inherit;
    border-bottom: 0;
    border-bottom-color: inherit;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-style: none;
    border-bottom-width: medium;
    border-collapse: separate;
    border-image: none;
    border-left: 0;
    border-left-color: inherit;
    border-left-style: none;
    border-left-width: medium;
    border-radius: 0;
    border-right: 0;
    border-right-color: inherit;
    border-right-style: none;
    border-right-width: medium;
    border-spacing: 0;
    border-top: 0;
    border-top-color: inherit;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-style: none;
    border-top-width: medium;
    bottom: auto;
    box-shadow: none;
    box-sizing: content-box;
    caption-side: top;
    clear: none;
    clip: auto;
    color: inherit;
    columns: auto;
    column-count: auto;
    column-fill: balance;
    column-gap: normal;
    column-rule: medium none currentColor;
    column-rule-color: currentColor;
    column-rule-style: none;
    column-rule-width: unset;
    column-span: 1;
    column-width: auto;
    content: normal;
    counter-increment: none;
    counter-reset: none;
    direction: ltr;
    empty-cells: show;
    float: none;
    font: normal;
    font-family: inherit;
    font-size: medium;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    height: initial;
    hyphens: none;
    left: auto;
    letter-spacing: normal;
    line-height: normal;
    list-style: none;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: disc;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0;
    opacity: 1;
    orphans: 0;
    outline: 0;
    outline-color: invert;
    outline-style: none;
    outline-width: medium;
    overflow: visible;
    overflow-x: visible;
    overflow-y: visible;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    page-break-after: auto;
    page-break-before: auto;
    page-break-inside: auto;
    perspective: none;
    perspective-origin: 50% 50%;
    position: static;
    right: auto;
    tab-size: 8;
    table-layout: auto;
    text-align: inherit;
    text-align-last: auto;
    text-decoration: none;
    text-decoration-color: inherit;
    text-decoration-line: none;
    text-decoration-style: solid;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    top: auto;
    transform: none;
    transform-style: flat;
    transition: none;
    transition-delay: 0s;
    transition-duration: 0s;
    transition-property: none;
    transition-timing-function: ease;
    unicode-bidi: normal;
    vertical-align: baseline;
    visibility: visible;
    white-space: normal;
    widows: 0;
    width: initial;
    word-spacing: normal;
  }
`
