import { css } from 'styled-components'

import { display, margins, paddings, sizes } from '../../../helpers'
import paddingButtonStyle from './paddingButtonStyle'

const getTransitionValues = ({ theme }) =>
  `${theme.animations.velocities.v3} ${theme.animations.easing.standard}`

const genericButtonStyle = css`
  transition: background-color ${getTransitionValues}, color ${getTransitionValues},
    fill ${getTransitionValues};

  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;

  // Put the label fonts part for enabling deep inheritance of Button.
  font-family: ${({ theme }) => theme.fonts.body2Medium.fontFamily};
  font-weight: ${({ theme }) => theme.fonts.body2Medium.fontWeight};
  line-height: ${({ theme }) => theme.fonts.body2Medium.lineHeight};
  font-size: ${({ theme }) => theme.fonts.body2Medium.fontSize};
  text-align: center;

  ${({ $small, theme }) => paddingButtonStyle($small, theme)};

  border-radius: ${({ theme }) => theme.globals.borderRadius.borderRadius1};

  box-shadow: none;
  user-select: none;
  border: 0;

  min-width: ${({ $small, theme }) => ($small ? theme.sizes.s6 : theme.sizes.s9)};

  :focus:not(:focus-visible) {
    outline: none;
  }

  // When the button is loading.
  &.is-loading {
    cursor: not-allowed;
  }

  // When the button is disabled.
  &.is-disabled {
    opacity: ${({ theme }) => theme.globals.opacity.o1};
    cursor: not-allowed;
  }

  // Target IE only
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    height: inherit;
    max-height: ${({ theme }) => theme.sizes.s18};
  }

  ${display};
  ${margins};
  ${paddings};
  ${sizes};
`

export default genericButtonStyle
