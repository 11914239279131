import React from 'react'
import styled from 'styled-components'
import { resetCSS } from '../../../helpers'
import commonStyle from '../helpers/commonStyle'

const Body1 = styled.p.attrs(resetCSS)`
  ${commonStyle};

  color: ${({ color, theme }) =>
    color ? theme.colors[color] || color : theme.components.typography.body1.text};
  font-family: ${({ theme }) => theme.fonts.body1.fontFamily};
  font-size: ${({ theme }) => theme.fonts.body1.fontSize};
  font-weight: ${({ theme }) => theme.fonts.body1.fontWeight};
  line-height: ${({ theme }) => theme.fonts.body1.lineHeight};
`

const Body1ForDocs = (props) => <Body1 {...props} />

export { Body1ForDocs }

export default Body1
