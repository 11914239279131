import {
  animations,
  colors,
  colorsVariationsFactory,
  componentsFactory,
  darkComponentsFactory,
  fonts,
  globals,
  responsive,
  sizes,
} from '../styles'

const components = darkComponentsFactory({ colors: colorsVariationsFactory({ colors }), globals })

const darkTheme = {
  animations,
  colors,
  colorsVariationsFactory,
  components,
  componentsFactory,
  darkComponentsFactory,
  fonts,
  globals,
  responsive,
  sizes,
}

export default darkTheme
