import React, { useContext } from 'react'

import GlobalContext from '@components/Layout/GlobalContext'
import NavigationContext from '@components/Navigation/NavigationContext'
import ExternalLinkSVG from '@icons/ExternalLinkSVG'
import { StyledLink, ExternalLink } from '@styles/StyledLink'
import { sanitizeTrailingSlash } from '@utils'
import trackClick from '@utils/GA4/trackClick'

// to capitalize every word in a sentence
const capitalizeEachWord = (labelText) =>
  !labelText
    ? ''
    : labelText
        .replace(/\b(\w)/g, (char) => char.toUpperCase())
        .replace(/-\b(\w)/g, '-$1')

export const withLink = (Component) => (props) => {
  const { icon, isNotCapitalized, fileName, onClick, to } = props
  let { children } = props
  const { locale } = useContext(GlobalContext)
  const isEnglish = locale.split('-')[1] === 'US'
  if (children && typeof children === 'string') {
    // capitalize only first word if isNotCapitalized, capitalize each word otherwise
    children = !isEnglish
      ? children.toString()
      : isNotCapitalized
      ? children.toString().split(' ')[0].charAt(0).toUpperCase() +
        children.toString().slice(1).toLowerCase()
      : capitalizeEachWord(children.toString())
  }
  const { isNavigationChild } = useContext(NavigationContext)
  const { isAllowingPerformanceScript } = useContext(GlobalContext)
  // Track CTA click
  const trackClickCTA = (e) => {
    // Avoid tracking navbar CTA click
    if (isNavigationChild) return
    // Still run original onClick if provided
    if (onClick) onClick(e)
    // As for now, the only case this should not run is on button icon
    if (!icon && isAllowingPerformanceScript) {
      trackClick({
        fileName,
        text: children,
        to,
      })
    }
  }
  // Add click tracking for every CTA
  props = {
    ...props,
    onClick: trackClickCTA,
  }

  if (!to) {
    return <Component {...props} />
  }

  const isInternalLink = ['/', '#'].includes(to[0])
  // Add External link icon for menu external links
  props = {
    ...props,
    children:
      isNavigationChild && !isInternalLink ? (
        <>
          {children} <ExternalLinkSVG />
        </>
      ) : (
        children
      ),
  }

  return (
    // Link is internal if first char is '/' or '#'
    isInternalLink ? (
      <StyledLink to={sanitizeTrailingSlash(to)}>
        <Component {...props} />
      </StyledLink>
    ) : (
      <ExternalLink href={to}>
        <Component {...props} />
      </ExternalLink>
    )
  )
}
