import React from 'react'
import { I18nextProvider } from 'react-i18next'

import { i18n, i18nConfig } from './i18n'
import { useTranslationsContext } from './context'

const withTranslations = WrappedComponent => {
  const SuspenseWrapper = props => {
    const locale = useTranslationsContext()

    i18nConfig(locale)

    return (
      <I18nextProvider i18n={i18n}>
        <WrappedComponent {...props} />
      </I18nextProvider>
    )
  }

  SuspenseWrapper.displayName = `withTranslations(${getDisplayName(WrappedComponent)})`

  return SuspenseWrapper
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withTranslations
