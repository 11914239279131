import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Text = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M3.2 4h9.6v1.6H3.2V4zm0 3.2h9.6v1.6H3.2V7.2zm0 3.2h7.2V12H3.2v-1.6zM14.4 0H1.6C.718 0 0 .718 0 1.6v12.8c0 .882.718 1.6 1.6 1.6h12.8c.882 0 1.6-.718 1.6-1.6V1.6c0-.882-.718-1.6-1.6-1.6zm-.002 14.4H1.6V1.6h12.8l-.002 12.8z"/></g>
    </StyledIcon>
  )

export default styled(Text)``
  