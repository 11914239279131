import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const TemplatingLanguageContent = props => (
    <StyledIcon  {...props}>
      <g><path d="M6.64 18.86v-3.3q0-4.59 3.49-5.69L9.49 8A8.27 8.27 0 0 0 5 10.67a7.57 7.57 0 0 0-1.5 4.81v3.39q0 3.94-3.54 3.95v2.36q3.54 0 3.54 4v3.25A7.65 7.65 0 0 0 5 37.32 8.35 8.35 0 0 0 9.49 40l.64-1.87q-3.45-1.08-3.49-5.56v-3.36c0-2.49-.94-4.22-2.8-5.2 1.86-1.01 2.8-2.69 2.8-5.15zm8.18 11.77a1.81 1.81 0 0 0-1.42.52 1.85 1.85 0 0 0-.48 1.3 1.77 1.77 0 0 0 .48 1.26 1.87 1.87 0 0 0 1.42.5 2 2 0 0 0 1.45-.5 1.73 1.73 0 0 0 .49-1.26 1.81 1.81 0 0 0-.49-1.3 1.89 1.89 0 0 0-1.45-.52zm9.01 0a1.84 1.84 0 0 0-1.43.52 1.89 1.89 0 0 0-.47 1.3 1.8 1.8 0 0 0 .47 1.26 1.91 1.91 0 0 0 1.43.5 2 2 0 0 0 1.45-.5 1.73 1.73 0 0 0 .49-1.26 1.81 1.81 0 0 0-.49-1.3 1.89 1.89 0 0 0-1.45-.52zm9.01 0a1.71 1.71 0 0 0-1.9 1.82 1.76 1.76 0 0 0 .47 1.26 1.91 1.91 0 0 0 1.43.5 2 2 0 0 0 1.45-.5 1.73 1.73 0 0 0 .49-1.26 1.81 1.81 0 0 0-.49-1.3 1.89 1.89 0 0 0-1.45-.52zm11.62-11.71V15.5A7.53 7.53 0 0 0 43 10.65 8.37 8.37 0 0 0 38.51 8l-.64 1.87q3.5 1.1 3.49 5.69v3.31q0 3.81 3 5.14-3 1.36-3 5.13v3.59q-.11 4.34-3.5 5.4l.65 1.87a8.4 8.4 0 0 0 4.43-2.66 7.46 7.46 0 0 0 1.52-4.9v-3.3q0-4 3.54-4v-2.32q-3.54 0-3.54-3.9z"/></g>
    </StyledIcon>
  )

export default styled(TemplatingLanguageContent)``
  