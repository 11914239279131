import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Agenda = props => (
    <StyledIcon viewBox="0 0 10.8 12" {...props}>
      <g><path d="M4.2 8.4A1.68 1.68 0 0 1 6 6.6a1.68 1.68 0 0 1 1.8 1.8z"/><circle cx="6" cy="4.8" r="1.2"/><path d="M9.6 0H2.4a1.2 1.2 0 0 0-1.2 1.2v1.2H0v1.2h1.2v1.8H0v1.2h1.2v1.8H0v1.2h1.2v1.2A1.2 1.2 0 0 0 2.4 12h7.2a1.2 1.2 0 0 0 1.2-1.2V1.2A1.2 1.2 0 0 0 9.6 0zM2.4 10.8V9.6H3V8.4h-.6V6.6H3V5.4h-.6V3.6H3V2.4h-.6V1.2h7.2v9.6z"/></g>
    </StyledIcon>
  )

export default styled(Agenda)``
  