import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { CarretDown } from '../../../../icons'

// Only apply default color when color is not overridden.
const calculateOverriddenColor = ({ color }) =>
  color
    ? css``
    : css`
        fill: ${({ locked, theme }) =>
          locked ? theme.components.accordion.iconLocked : theme.components.accordion.icon};
      `

const AccordionIcon = styled(CarretDown)`
  ${calculateOverriddenColor};
  ${({ isOpened, theme }) => `
    transform: rotate(${isOpened ? 180 : 0}deg);
    transition: transform ${theme.animations.velocities.v4} ${theme.animations.easing.standard} 0s;
  `}
`

AccordionIcon.propTypes = {
  // The icon color.
  color: PropTypes.string,
  // Indicate if the icon is locked or not.
  locked: PropTypes.bool,
}

export default AccordionIcon
