import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const GeoTagPair = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g fillRule="evenodd" id="Icon/top-dropdown/IP-monitoring" stroke="none" strokeWidth="1">
                <g fillRule="nonzero" id="pin-copy" transform="translate(0.000000, 1.000000)">
                    <path
                        d="M10.3573668,0 C9.17876176,0 7.98534483,0.347730061 7,0.988957055 C9.59561129,1.78395092 11.7680251,3.91180368 11.7680251,7.01840491 C11.7680251,8.73153374 10.6126959,10.6705276 9.32335423,12.306454 C9.57868339,12.5546503 9.77335423,12.7358528 9.85658307,12.8117791 C9.98918495,12.9323681 10.1690439,13 10.3573668,13 C10.5456897,13 10.7255486,12.9323681 10.8581505,12.8117791 C11.384326,12.3313374 16,8.0392638 16,5.10429448 C16,1.90900613 13.1314263,0 10.3573668,0 Z"
                        id="Path"/>
                    <path
                        d="M5.5,2 C2.7960625,2 0,3.90900613 0,7.10429448 C0,10.0392638 4.499,14.3313374 5.011875,14.8117791 C5.141125,14.9323681 5.3164375,15 5.5,15 C5.6835625,15 5.858875,14.9323681 5.988125,14.8117791 C6.501,14.3313374 11,10.0392638 11,7.10429448 C11,3.90900613 8.2039375,2 5.5,2 Z M5.5,8.82822086 C4.67125,8.82822086 4,8.15697086 4,7.32822086 C4,6.49947086 4.67125,5.82822086 5.5,5.82822086 C6.32875,5.82822086 7,6.49947086 7,7.32822086 C7,8.15697086 6.32875,8.82822086 5.5,8.82822086 Z"
                        id="Shape"/>
                </g>
            </g>
    </StyledIcon>
  )

export default styled(GeoTagPair)``
