import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const MoveDown = props => (
    <StyledIcon viewBox="0 0 5 12" {...props}>
      <g><path d="M2.49 4.8a2.43 2.43 0 0 0 2.44-2.43A2.44 2.44 0 0 0 2.44 0 2.43 2.43 0 0 0 0 2.43 2.44 2.44 0 0 0 2.49 4.8zm0-3.6A1.22 1.22 0 0 1 3.7 2.39 1.22 1.22 0 0 1 2.48 3.6a1.22 1.22 0 0 1-1.25-1.19A1.22 1.22 0 0 1 2.45 1.2zm.63 4.79l-1.23.02.03 2.4-1.85.02L2.57 12 5 8.37l-1.85.02-.03-2.4z"/></g>
    </StyledIcon>
  )

export default styled(MoveDown)``
  