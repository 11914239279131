import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Redo = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M6.997 1c3.139 0 5.8 2.078 6.685 4.93l1.106-1.917 1.212.7-2.45 4.244-4.242-2.45.7-1.213 2.427 1.401c-.59-2.459-2.8-4.295-5.438-4.295A5.605 5.605 0 0 0 1.4 8c0 3.088 2.512 5.6 5.598 5.6V15C3.14 15 0 11.86 0 8s3.139-7 6.997-7z"/></g>
    </StyledIcon>
  )

export default styled(Redo)``
  