import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Eye = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8 5.143C6.396 5.143 5.09 6.425 5.09 8c0 1.576 1.306 2.857 2.91 2.857S10.91 9.576 10.91 8c0-1.575-1.306-2.857-2.91-2.857zm0 4.286c-.802 0-1.455-.641-1.455-1.429 0-.787.653-1.429 1.455-1.429S9.455 7.213 9.455 8c0 .788-.653 1.429-1.455 1.429zm7.867-1.84C15.733 7.401 12.525 3 8 3S.267 7.401.133 7.59a.701.701 0 0 0 0 .821C.267 8.6 3.475 13 8 13s7.733-4.401 7.867-4.589a.701.701 0 0 0 0-.822zM8 11.571c-3.03 0-5.49-2.553-6.35-3.572C2.508 6.981 4.96 4.43 8 4.43c3.03 0 5.49 2.554 6.35 3.572-.858 1.019-3.31 3.57-6.35 3.57z"/></g>
    </StyledIcon>
  )

export default styled(Eye)``
  