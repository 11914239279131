import styled, { css } from 'styled-components'

const innerLabelStyled = ({ theme }) => css`
  margin-right: ${theme.sizes.s3};
  color: ${theme.components.select.innerLabel};
`

const InnerLabel = styled.span`
  ${innerLabelStyled};
`

export default InnerLabel
