import styled from 'styled-components'
import PropTypes from 'prop-types'
import StyledSkeleton from '../styles/StyledSkeleton'

const TitleSkeleton = styled(StyledSkeleton)`
  ${({ theme, height, width }) => `
    height: ${theme.sizes[height] || height || theme.sizes.s5};
    width: ${theme.sizes[width] || width || theme.sizes.s14};
  `}
`

TitleSkeleton.propTypes = {
  /** Height of the Title. */
  height: PropTypes.string,
  /** Width of the Title. */
  width: PropTypes.string,
}

export default TitleSkeleton
