import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Dislike = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M15.823 10.631a.763.763 0 0 1-.585.274h-4.571v3.047c0 .421-.34.762-.762.762H7.619a.761.761 0 0 1-.7-.46L4.83 9.38h-.26c0 .42-.34.762-.761.762H.762A.762.762 0 0 1 0 9.38V1.76C0 1.342.34 1 .762 1H3.81c.42 0 .761.341.761.762v.398l2.765-1.106A.761.761 0 0 1 7.62 1h6.095c.368 0 .684.264.75.626l1.524 8.38a.765.765 0 0 1-.165.625zM3.048 2.524H1.524v6.095h1.524V2.524zm10.03 0H7.766L4.571 3.802v4.055h.762a.76.76 0 0 1 .7.462l2.088 4.871h1.022v-3.047c0-.42.34-.762.762-.762h4.42L13.08 2.524z"/></g>
    </StyledIcon>
  )

export default styled(Dislike)``
  