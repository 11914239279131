import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const hoverAttributes = ({ disabled, readOnly, checked, theme }) => {
  if (readOnly) {
    return css`
      cursor: default;
    `;
  }

  if (disabled) {
    return css`
      cursor: not-allowed;
    `;
  }

  return css`
    &:not(:active) {
      border-color: ${checked ? theme.components.checkbox.borderChecked : theme.components.checkbox.borderHover};
    }
    cursor: pointer;
  `;
};

/**
 * Calculate border color according to checkbox state.
 */
const borderColor = (checked, colors) => css`
  &:focus {
    border-color: ${colors.borderFocus};
  }

  &:not(:focus) {
    border-color: ${checked ? colors.borderChecked : colors.border};
  }
`;

/**
 * Calculate background color according to checkbox state.
 */
const backgroundColor = (checked, colors) => (checked ? colors.backgroundChecked : colors.background);

/**
 * Calculate border radius according to checkbox type.
 */
const borderRadius = (theme, type) => (type === 'checkbox' ? theme.globals.borderRadius.borderRadius1 : '50%');

/**
 * Calculate border width according to checkbox type.
 */
const borderWidth = (width) => css`
  &:focus {
    border-width: ${width.borderFocusWidth};
  }

  &:not(:focus) {
    border-width: ${width.borderWidth};
  }
`;

/**
 * Component to display custom input Checked ( checkbox | radio )
 */
const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-top: ${({ theme }) => theme.sizes.s1};
  margin-right: ${({ theme }) => theme.sizes.s3};
  position: relative;
  width: ${({ theme }) => theme.sizes.s4};
  height: ${({ theme }) => theme.sizes.s4};
  background-color: ${({ checked, theme }) => backgroundColor(checked, theme.components.checkbox)};
  border-style: solid;
  border-width: ${({ theme }) => theme.globals.borderWidth.borderWidth1};
  ${({ checked, theme }) => borderColor(checked, theme.components.checkbox)};
  ${({ theme }) => borderWidth(theme.components.checkbox)};
  border-radius: ${({ theme, type }) => borderRadius(theme, type)};
  box-sizing: border-box;

  ${({ hovered, ...rest }) => (hovered ? hoverAttributes(rest) : css``)};

  &:hover {
    ${(props) => hoverAttributes(props)};
  }
`;

ContainerIcon.defaultProps = {
  checked: false,
  disabled: false,
  readOnly: false,
};

ContainerIcon.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  hovered: PropTypes.bool,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  theme: PropTypes.object.isRequired,
};

export default ContainerIcon;
