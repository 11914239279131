import styled from 'styled-components'
import { resetCSS } from '../../../helpers'
import commonStyle from '../helpers/commonStyle'

// TODO: v7: use styled.h4 in breaking change
const Header4 = styled.h2.attrs(resetCSS)`
  ${commonStyle};

  color: ${({ color, theme }) =>
    color ? theme.colors[color] || color : theme.components.typography.header4.text};
  font-family: ${({ theme }) => theme.fonts.header4.fontFamily};
  font-size: ${({ theme }) => theme.fonts.header4.fontSize};
  font-weight: ${({ theme }) => theme.fonts.header4.fontWeight};
  line-height: ${({ theme }) => theme.fonts.header4.lineHeight};
`

export default Header4
