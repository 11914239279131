import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const LockLock = props => (
    <StyledIcon viewBox="0 0 8.4 12" {...props}>
      <g><circle cx="4.2" cy="8.7" r=".9"/><path d="M7.2 5.4V3a3 3 0 0 0-6 0v2.4A1.2 1.2 0 0 0 0 6.6v4.2A1.2 1.2 0 0 0 1.2 12h6a1.2 1.2 0 0 0 1.2-1.2V6.6a1.2 1.2 0 0 0-1.2-1.2zM2.4 3A1.8 1.8 0 0 1 6 3v2.4H2.4zm-1.2 7.8V6.6h6v4.2z"/></g>
    </StyledIcon>
  )

export default styled(LockLock)``
  