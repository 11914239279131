import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Campaign = props => (
    <StyledIcon viewBox="2 0 24 26" {...props}>
      <g><path d="M25.31 11.713a1.2 1.2 0 01.69 1.086v10.8A2.4 2.4 0 0123.601 26h-19.2A2.402 2.402 0 012 23.6V12.8a1.2 1.2 0 011.968-.922l6.867 5.722h6.331l6.867-5.723a1.201 1.201 0 011.277-.164zm-1.708 3.649l-5.232 4.36a1.207 1.207 0 01-.77.278h-7.2c-.28 0-.552-.098-.768-.277L4.4 15.362V23.6h19.203v-8.238zM16.401 10.4v2.4H9.2v-2.4h7.2zM23.6 2v8.4h-2.4v-6h-14.4v6H4.4V2h19.2zm-4.8 4.8v2.4H9.2V6.8h9.6z"/></g>
    </StyledIcon>
  )

export default styled(Campaign)``
  