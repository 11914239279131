import React from 'react'
import styled from 'styled-components'

const ArticleCrossSVG = (props) => (
  <svg
    width='14px'
    height='14px'
    viewBox='0 0 14 14'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Cross</title>
    <g
      id='Page-1'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='square'
    >
      <path
        d='M11,3 L3,11 M3,3 L11,11'
        id='Cross'
        stroke='#131B20'
        strokeWidth='3'
      ></path>
    </g>
  </svg>
)

export default styled(ArticleCrossSVG)``
