const getCheckbox = (colors) => ({
  background: 'transparent',
  backgroundChecked: 'transparent',
  backgroundWrapper: 'transparent',
  border: colors.neutral60,
  borderChecked: colors.primary70,
  borderError: '#F86469',
  borderFocus: colors.secondary35,
  borderFocusWidth: '1px',
  borderHover: colors.primary70,
  borderWidth: '1px',
  checkmarkColor: colors.primary70,
})

export default getCheckbox
