import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Star = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8,1.12977573 L10.0191083,5.22093591 L14.5339716,5.87698395 L11.2669858,9.06150803 L12.0382165,13.5581282 L8,11.4351121 L3.96178349,13.5581282 L4.73301422,9.06150803 L1.46602844,5.87698395 L5.98089175,5.22093591 L8,1.12977573 Z"/></g>
    </StyledIcon>
  )

export default styled(Star)``
  