import styled from 'styled-components'

const getPaddings = ({ small, theme }) => {
  const { s3, s4 } = theme.sizes

  return `padding: ${small ? `0px ${s3}` : `0px ${s4}`}`
}

const ContentWrapper = styled.div`
  > * {
    margin-top: ${({ multi, theme }) => multi && theme.sizes.s2};
    margin-right: ${({ theme }) => theme.sizes.s2};
  }

  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;

  /* -s2 margin-top because of responsive mode. */
  margin-top: -${({ multi, theme }) => (multi ? theme.sizes.s2 : '0')};

  ${getPaddings};
`

export default ContentWrapper
