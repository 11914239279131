// @noSnapshot
import React from 'react'
import styled, { withTheme } from 'styled-components'
import { deprecated } from '../../../helpers'
import SmallText from './SmallText'

class TypoWrapper extends React.Component{
  render() {
    const { className, style, children } = this.props
    return (
      <SmallText className={className} style={style}>
        {children}
      </SmallText>
    )
  }
}

const SmallTextBold = styled(TypoWrapper)`
  color: ${({ color, theme }) =>
    color ? theme.colors[color] || color : theme.components.typography.smallText.text};
  font-weight: ${({ theme }) => theme.fonts.smallTextBold.fontWeight};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
`

export default deprecated(
  withTheme(SmallTextBold),
  'SmallTextBold',
  'Please use CaptionMedium instead.',
)
