import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const FlipUp = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M14.4 8h-8c-.882 0-1.6.718-1.6 1.6v4.8c0 .882.718 1.6 1.6 1.6h8c.882 0 1.6-.718 1.6-1.6V9.6c0-.882-.718-1.6-1.6-1.6zm-8 6.4V9.6h8v4.8h-8zm5.418-12C14.437 2.4 16 3.895 16 6.4v.8h-1.6v-.8c0-.594 0-2.4-2.582-2.4h-.287l1.035 1.035-1.132 1.13L8.47 3.2 11.434.235l1.132 1.13L11.53 2.4h.287zM1.6 1.6v11.2h1.6v1.6H1.6c-.882 0-1.6-.718-1.6-1.6V1.6C0 .718.718 0 1.6 0h4c.882 0 1.6.718 1.6 1.6v4.8H5.6V1.6h-4z"/></g>
    </StyledIcon>
  )

export default styled(FlipUp)``
  