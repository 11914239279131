import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const DividerSolid = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M0 7.5h16v1H0z"/></g>
    </StyledIcon>
  )

export default styled(DividerSolid)``
  