import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Twitter = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M16 3.038a6.553 6.553 0 0 1-1.885.517 3.297 3.297 0 0 0 1.443-1.815 6.616 6.616 0 0 1-2.086.797A3.283 3.283 0 0 0 7.88 5.53 9.318 9.318 0 0 1 1.115 2.1 3.259 3.259 0 0 0 .67 3.75c0 1.14.58 2.144 1.46 2.732A3.282 3.282 0 0 1 .644 6.07v.041a3.286 3.286 0 0 0 2.632 3.22 3.324 3.324 0 0 1-.864.115 3.15 3.15 0 0 1-.618-.061 3.284 3.284 0 0 0 3.065 2.28 6.584 6.584 0 0 1-4.076 1.403c-.265 0-.526-.016-.783-.045A9.276 9.276 0 0 0 5.031 14.5c6.038 0 9.338-5.002 9.338-9.34l-.01-.426A6.555 6.555 0 0 0 16 3.038z"/></g>
    </StyledIcon>
  )

export default styled(Twitter)``
  