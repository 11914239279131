import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ArrowRight = props => (
    <StyledIcon viewBox="0 0 12 9" {...props}>
      <g><path d="M7.6,0,6,1.2,8.3,3.6H0v2H8.3L6,7.6,7.5,9,12,4.5,7.5,0"/></g>
    </StyledIcon>
  )

export default styled(ArrowRight)``
