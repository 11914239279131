import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Settings = props => (
    <StyledIcon viewBox="0 0 12 10.8" {...props}>
      <g><path d="M10.2 3.6a1.8 1.8 0 1 0-1.69-2.4H0v1.2h8.51a1.8 1.8 0 0 0 1.69 1.2zm0-2.4a.6.6 0 1 1-.6.6.6.6 0 0 1 .6-.6zm-6 2.4a1.8 1.8 0 0 0-1.69 1.2H0V6h2.51a1.79 1.79 0 0 0 3.38 0H12V4.8H5.89A1.8 1.8 0 0 0 4.2 3.6zm0 2.4a.6.6 0 1 1 .6-.6.6.6 0 0 1-.6.6zm6 1.2a1.8 1.8 0 0 0-1.69 1.2H0v1.2h8.51a1.8 1.8 0 1 0 1.69-2.4zm0 2.4a.6.6 0 1 1 .6-.6.6.6 0 0 1-.6.6z"/></g>
    </StyledIcon>
  )

export default styled(Settings)``
  