import React from 'react'
import { Error } from 'component-library/icons'
import ErrorMsgStyled from '../styles/ErrorMsg'

import { ERROR_MSG_NS } from '../config'
import { useInfoStatus, useInputId } from '../hooks'

const ErrorMsg = ({
  children,
  ...rest
}) => {
  const { inputId } = useInputId()
  const { isError } = useInfoStatus()

  return (
    <>
      <ErrorMsgStyled
        {...rest}
        className={!isError ? 'isHidden' : ''}
        id={`${inputId}-${ERROR_MSG_NS}`}
      >
        <Error mr="s3" />
        {children}
      </ErrorMsgStyled>
    </>
  )
}

export default ErrorMsg
