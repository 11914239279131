import styled from 'styled-components'
import LinkStyle from './LinkStyle'
import { Body2 } from '../../Typography'


const getTransitionValues = ({ theme }) =>
  `${theme.animations.velocities.v3} ${theme.animations.easing.standard}`

const LinkButtonStyled = styled(Body2.withComponent('button'))`
  ${LinkStyle};
  background-color: ${({ theme }) => theme.components.linkButton.backgroundColor};
  fill: ${({ theme }) => theme.components.linkButton.icon};
  display: flex;
  align-items: center;
  &:hover {
    fill: ${({ theme, disabled }) => !disabled && theme.components.linkButton.iconHover};
    transition: fill ${getTransitionValues}, color ${getTransitionValues};
  }

  &:focus,
  &:active {
    fill: ${({ theme, disabled }) => !disabled && theme.components.linkButton.iconFocus};
    transition: fill ${getTransitionValues}, color ${getTransitionValues};
  }

  ${({ theme, disabled }) =>
    disabled &&
    `
    opacity: ${theme.globals.opacity.o1};
    cursor: not-allowed;
    &:hover {
      text-decoration: none;
    }
  `};
`

export default LinkButtonStyled
