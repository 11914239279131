import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const UserBlocked = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M5.121 10.244a2.563 2.563 0 0 0 2.56-2.56 2.563 2.563 0 0 0-2.56-2.56 2.563 2.563 0 0 0-2.56 2.56 2.563 2.563 0 0 0 2.56 2.56zm0-3.84a1.28 1.28 0 1 1 0 2.56 1.28 1.28 0 0 1 0-2.56zm0 4.48c-3.015 0-5.12 1.579-5.12 3.84v.64h10.24v-.64c0-2.261-2.105-3.84-5.12-3.84zm-3.738 3.2c.383-1.157 1.814-1.92 3.739-1.92 1.925 0 3.355.763 3.739 1.92H1.383zM11.52.004a3.844 3.844 0 0 0-3.84 3.84 3.844 3.844 0 0 0 3.84 3.84 3.844 3.844 0 0 0 3.84-3.84 3.844 3.844 0 0 0-3.84-3.84zm0 1.28c.474 0 .911.138 1.293.362l-3.49 3.49a2.526 2.526 0 0 1-.362-1.293 2.562 2.562 0 0 1 2.559-2.559zm0 5.12c-.474 0-.911-.138-1.293-.362l3.49-3.49c.225.381.362.819.362 1.293a2.561 2.561 0 0 1-2.559 2.559z"/></g>
    </StyledIcon>
  )

export default styled(UserBlocked)``
  