import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Upload = props => (
    <StyledIcon viewBox="0 0 8.65 12" {...props}>
      <g><path d="M3.71 2.37v5.92h1.23V2.37l1.42 1.41.87-.87L4.33 0 1.42 2.91l.87.87z"/><path d="M7.42 4.58H6.8v1.24h.62v4.94H1.24V5.82h.62V4.58h-.62A1.24 1.24 0 0 0 0 5.82v4.94A1.24 1.24 0 0 0 1.24 12h6.18a1.24 1.24 0 0 0 1.24-1.24V5.82a1.24 1.24 0 0 0-1.24-1.24z"/></g>
    </StyledIcon>
  )

export default styled(Upload)``
  