import React, { useEffect } from 'react'
import styled from 'styled-components'

import { get, isNil, isUndefined } from 'lodash'

import { ArrowDown, ArrowUp, CarretDouble } from 'component-library/icons'
import { useSortedHeaders, useTableContext } from '../hooks'
import CaptionMedium from '../../Typography/styles/CaptionMedium'
import visuallyHidden from '../../VisuallyHidden'
import SortButton from './SortButton'
import { SortOrder } from '../config'

function HeaderCell({
  children,
  className,
  id,
  isSortable = false,
  onSort,
}) {
  const [sortedHeaders, setSortedHeaders] = useSortedHeaders()
  const { name: tableName } = useTableContext()

  const sortDir = get(sortedHeaders, id)

  useEffect(() => {
    if (!isUndefined(sortDir)) {
      onSort?.(sortedHeaders)
    }
  }, [sortDir])

  const sortColumn = () => {
    setSortedHeaders({ id, sortDir })
  }

  const sortDirValue = sortDir || SortOrder.NONE
  const hasChild = !isNil(children) && children !== ''

  return (
    <HeaderCellStyled aria-sort={isSortable ? sortDirValue : undefined} className={className}>
      {hasChild && isSortable ? (
        <SortButton aria-controls={tableName} as="button" mode={sortDirValue} onClick={sortColumn}>
          {children}
          <span lang="en" style={visuallyHidden}>{`sort in ${sortDirValue} order`}</span>
          {sortDirValue === SortOrder.NONE && (
            <CarretDouble color="currentColor" ml="s3" size="10px" />
          )}
          {sortDirValue === SortOrder.ASC && <ArrowUp color="currentColor" ml="s3" size="10px" />}
          {sortDirValue === SortOrder.DESC && (
            <ArrowDown color="currentColor" ml="s3" size="10px" />
          )}
        </SortButton>
      ) : (
        hasChild && children
      )}
    </HeaderCellStyled>
  )
}

const HeaderCellStyled = styled(CaptionMedium).attrs(() => ({ as: 'th' }))`
  box-sizing: border-box;
  display: table-cell;
  text-align: left;
  ${({ theme }) => `
    height: ${theme.sizes.s18};
    padding: ${theme.sizes.s3} ${theme.sizes.s5};
  `};

  background-color: white;
  background-clip: padding-box; /* Prevents bug on FF : background is painted over the border */
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: 0px;
    display: block;
    width: 100%;
    height: 4px;
    background: transparent;
  }

  &.isScrolling:after {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &.isScrolling {
    z-index: 2;
  }
`

export const DocHeaderCell = HeaderCell
export default styled(HeaderCell)``
