import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Alert = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M7.2 3.2h1.6v5.6H7.2V3.2zm.8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM8 16c-4.411 0-8-3.588-8-8 0-4.411 3.589-8 8-8s8 3.589 8 8c0 4.412-3.589 8-8 8zM8 1.6A6.407 6.407 0 0 0 1.6 8c0 3.529 2.871 6.4 6.4 6.4 3.529 0 6.4-2.871 6.4-6.4 0-3.529-2.871-6.4-6.4-6.4z"/></g>
    </StyledIcon>
  )

export default styled(Alert)``
  