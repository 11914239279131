import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const CancelCircle = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M6 12a6 6 0 1 1 6-6 6 6 0 0 1-6 6zM6 1a5 5 0 1 0 5 5 5 5 0 0 0-5-5z"/><path d="M3.64 7.648l4.003-4.003.707.707-4.002 4.003z"/><path d="M3.64 4.352l.708-.707L8.35 7.648l-.707.707z"/></g>
    </StyledIcon>
  )

export default styled(CancelCircle)``
  