import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const ShapeSquare = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g>
        <path
          d="M13.57,9.47V6.53a3.33,3.33,0,1,0-4.1-4.1H6.53a3.33,3.33,0,1,0-4.1,4.1V9.47a3.33,3.33,0,1,0,4.1,4.1H9.47a3.33,3.33,0,1,0,4.1-4.1Zm-.9-7.67h0a1.54,1.54,0,1,1-1.54,1.53A1.54,1.54,0,0,1,12.67,1.8ZM1.8,3.33A1.54,1.54,0,0,1,3.33,1.8h0A1.54,1.54,0,1,1,1.8,3.33ZM3.33,14.2a1.54,1.54,0,0,1,0-3.07h0a1.54,1.54,0,1,1,0,3.07Zm6.14-2.43H6.53a3.37,3.37,0,0,0-2.3-2.3V6.53a3.37,3.37,0,0,0,2.3-2.3H9.47a3.37,3.37,0,0,0,2.3,2.3V9.47A3.37,3.37,0,0,0,9.47,11.77Zm3.2,2.43a1.54,1.54,0,1,1,0-3.07h0a1.54,1.54,0,0,1,0,3.07Z"
          fillRule="nonzero"
        />
      </g>
    </StyledIcon>
  )

export default styled(ShapeSquare)``
