import styled from 'styled-components'

import CaptionMedium from '../../Typography/styles/CaptionMedium'
import { SortOrder } from '../config'

const SortButton = styled(CaptionMedium)`
  display: flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  ${({ mode, theme }) => `
    color: ${
      mode === SortOrder.ASC || mode === SortOrder.DESC
        ? theme.colors.lagoon
        : theme.colors.darkgrey
    };
    &:hover {
      color: ${theme.colors.lagoon};
    }
  `};
`
export default SortButton
