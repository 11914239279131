import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const PremiumDiamon = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M12.3 1.9c-.2-.2-.5-.4-.9-.4H4.6c-.3 0-.6.1-.9.4L.4 5.4c-.5.5-.5 1.3 0 1.8l6.8 7c.5.5 1.3.5 1.7 0l6.8-7c.5-.5.5-1.3 0-1.8l-3.4-3.5zm-4.8 10l-5-5.1h5v5.1zm1 0V6.8h5l-5 5.1zm-6-6.2l2.3-2.4h6.4l2.3 2.4h-11z"/></g>
    </StyledIcon>
  )

export default styled(PremiumDiamon)``
  