import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Xing = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M5.183 3.583l1.584 2.762c-.064.116-.892 1.584-2.482 4.404-.174.296-.383.444-.628.444H1.35a.323.323 0 0 1-.3-.164.337.337 0 0 1 0-.348l2.444-4.326c.007 0 .007-.004 0-.01L1.938 3.65c-.077-.141-.08-.26-.01-.357.059-.097.162-.145.31-.145h2.308c.257 0 .47.145.637.435zm9.368-3.08l-5.099 9.02v.01l3.245 5.939c.07.129.074.248.01.357-.065.097-.168.145-.31.145H10.09c-.27 0-.483-.145-.637-.435L6.178 9.532 11.306.435c.161-.29.367-.435.619-.435h2.327c.142 0 .241.049.3.145.07.103.07.222 0 .357z"/></g>
    </StyledIcon>
  )

export default styled(Xing)``
  