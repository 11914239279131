import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Tag = props => (
    <StyledIcon viewBox="0 0 12.66 14" {...props}>
      <g><path d="M7 14a.58.58 0 0 1-.41-.17L.17 7.41A.58.58 0 0 1 0 7V2.92a.58.58 0 0 1 .58-.58h4.09a.58.58 0 0 1 .41.17l6.42 6.41a.58.58 0 0 1 0 .82l-4.09 4.09A.58.58 0 0 1 7 14zM1.17 6.76L7 12.59l3.26-3.26L4.43 3.5H1.17z"/><circle cx="2.92" cy="5.25" r="1.17"/><path d="M11.84 7.41L5.59 1.17H1.75V0h4.08a.58.58 0 0 1 .41.17l6.42 6.42z"/></g>
    </StyledIcon>
  )

export default styled(Tag)``
  