import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Facebook = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M12.161.003L10.002 0C7.577 0 6.01 1.546 6.01 3.938v1.815H3.84a.333.333 0 0 0-.34.327v2.63c0 .18.152.326.34.326h2.17v6.638c0 .18.152.326.34.326h2.83c.188 0 .34-.146.34-.326V9.036h2.537c.188 0 .34-.146.34-.326V6.08a.32.32 0 0 0-.099-.231.347.347 0 0 0-.24-.096H9.521V4.214c0-.74.183-1.115 1.186-1.115h1.454a.334.334 0 0 0 .339-.327V.33a.333.333 0 0 0-.339-.327z"/></g>
    </StyledIcon>
  )

export default styled(Facebook)``
  