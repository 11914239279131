const getTextArea = (colors) => ({
  background: '#FFFFFF',
  border: colors.neutral60,
  borderError: '#F86469',
  borderFocus: colors.secondary50,
  borderHover: '#555555',
  placeholder: '#a2a2a2',
  text: '#555555',
})

export default getTextArea
