import React from 'react'
import styled from 'styled-components'

const EarthSVG = (props) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g id='Language'>
      <g id='Vector'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15 9L1 9L1 7L15 7V9Z'
          fill='#4B575E'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.17397 3.38659C6.76933 4.5196 6.5 6.1506 6.5 8C6.5 9.8494 6.76933 11.4804 7.17397 12.6134C7.37785 13.1843 7.5973 13.5754 7.79094 13.8048C7.89634 13.9296 7.96681 13.9768 8 13.9937C8.03319 13.9768 8.10366 13.9296 8.20906 13.8048C8.4027 13.5754 8.62215 13.1843 8.82603 12.6134C9.23067 11.4804 9.5 9.8494 9.5 8C9.5 6.1506 9.23067 4.5196 8.82603 3.38659C8.62215 2.81573 8.4027 2.42458 8.20906 2.19525C8.10366 2.07042 8.03319 2.02321 8 2.00633C7.96681 2.02321 7.89634 2.07042 7.79094 2.19525C7.5973 2.42458 7.37785 2.81573 7.17397 3.38659ZM6.26283 0.904942C6.66346 0.430475 7.24631 0 8 0C8.75369 0 9.33654 0.430475 9.73717 0.904942C10.1417 1.38405 10.461 2.01803 10.7095 2.71392C11.2097 4.1144 11.5 5.98341 11.5 8C11.5 10.0166 11.2097 11.8856 10.7095 13.2861C10.461 13.982 10.1417 14.616 9.73717 15.0951C9.33654 15.5695 8.75369 16 8 16C7.24631 16 6.66346 15.5695 6.26283 15.0951C5.85829 14.616 5.53902 13.982 5.29049 13.2861C4.79032 11.8856 4.5 10.0166 4.5 8C4.5 5.98341 4.79032 4.1144 5.29049 2.71392C5.53902 2.01803 5.85829 1.38405 6.26283 0.904942Z'
          fill='#4B575E'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z'
          fill='#4B575E'
        />
      </g>
    </g>
  </svg>
)

export default styled(EarthSVG)``
