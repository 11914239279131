import styled from 'styled-components'
import Panel from '../../Panel'

const DividerStyle = styled(Panel)`
  background-color: ${({ theme }) => theme.components.optionPanel.divider.color};
  margin-bottom: ${({ theme }) => theme.sizes.s2};
  margin-top: ${({ theme }) => theme.sizes.s2};
`

export default DividerStyle
