import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const TextContent = props => (
    <StyledIcon  {...props}>
      <g><path d="M45.83 16.35V14.5h-7.8v1.85h3.12V38.5h-3.12v1.84h7.8V38.5h-3.12V16.35h3.12zM15.06 8L2.7 40.34h4.4l3.05-8.46H23.7l3.09 8.46h4.38L18.79 8zm-3.62 20.4l5.48-15.13 5.51 15.13z"/></g>
    </StyledIcon>
  )

export default styled(TextContent)``
  