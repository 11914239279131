import React from 'react'
import styled from 'styled-components'
import { resetCSS } from 'component-library/helpers'

const Cell = styled.td.attrs(resetCSS)`
  box-sizing: border-box;
  vertical-align: top;

  ${({ expand, noBorder, theme }) => `
    padding: ${theme.sizes.s4} ${theme.sizes.s5};

    ${noBorder ? 'border-top' : 'border'}: ${theme.globals.borderWidth.borderWidth1} solid ${
    theme.components.datatable.borderColor
  };
    border-bottom: none;

    ${
      expand &&
      `
      width: 100%;
    `
    };
  `}

  &:first-child {
    ${({ theme }) => `
      padding-left: ${theme.sizes.s3};
    `}
    ${({ isCheckbox }) =>
      isCheckbox &&
      `
      padding-right: 0px;
      border-right: none;
    `}
  }

  &:nth-child(1),
  &:nth-child(2) {
    border-left: none;
  }

  &:last-of-type {
    ${({ theme }) => `
      padding-right: ${theme.sizes.s3};
    `}
  }
`

const DocCell = (props) => <Cell {...props} />
export { DocCell }

export default Cell
