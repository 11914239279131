import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const BorderDashed = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M0 0h1v1H0V0zm2.5 0h1v1h-1V0zM5 0h1v1H5V0zm2.5 0h1v1h-1V0zM10 0h1v1h-1V0zm2.5 0h1v1h-1V0zM15 0h1v1h-1V0zM0 15h1v1H0v-1zm2.5 0h1v1h-1v-1zM5 15h1v1H5v-1zm2.5 0h1v1h-1v-1zm2.5 0h1v1h-1v-1zm2.5 0h1v1h-1v-1zm2.5 0h1v1h-1v-1zM1 2.5v1H0v-1h1zM1 5v1H0V5h1zm0 2.5v1H0v-1h1zM1 10v1H0v-1h1zm0 2.5v1H0v-1h1zm15-10v1h-1v-1h1zM16 5v1h-1V5h1zm0 2.5v1h-1v-1h1zm0 2.5v1h-1v-1h1zm0 2.5v1h-1v-1h1z"/></g>
    </StyledIcon>
  )

export default styled(BorderDashed)``
  