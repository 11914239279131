import React from 'react'
import RemoteMailgunSVG from './RemoteSVG/mg_logo_light'

const MailgunLogoSVG = () => {
  const svgString = RemoteMailgunSVG.replace('width="397" height="116"', '')

  return <div dangerouslySetInnerHTML={{ __html: svgString }} />
}

export { MailgunLogoSVG, RemoteMailgunSVG as MailgunLogoSVGString }
