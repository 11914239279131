import React from 'react'
import styled from 'styled-components'

const CloseSVG = ({ ...props }) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g id='close'>
      <g id='Vector'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.7071 2.29289C14.0976 2.68342 14.0976 3.31658 13.7071 3.70711L3.70711 13.7071C3.31658 14.0976 2.68342 14.0976 2.29289 13.7071C1.90237 13.3166 1.90237 12.6834 2.29289 12.2929L12.2929 2.29289C12.6834 1.90237 13.3166 1.90237 13.7071 2.29289Z'
          fill='#B7C1C7'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z'
          fill='#B7C1C7'
        />
      </g>
    </g>
  </svg>
)

export default styled(CloseSVG)``
