import { get } from 'lodash'

const getValueToDisplay = (object, path, section = '', defaultValue = null) => {
  if (process.env.NODE_ENV === 'development') {
    const valueToDisplay = get(object, path, defaultValue)
    if (!!valueToDisplay && typeof window !== 'undefined') {
      console.log('The value ' + path + ' for the ' + section + ' is undefined')
    }
    return valueToDisplay
  } else {
    return get(object, path, null)
  }
}

export { getValueToDisplay }
