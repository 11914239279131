import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const BarChart = (props) => (
    <StyledIcon viewBox="0 0 14 13" {...props}>
    <g fillRule="evenodd">
        <rect height="13" rx="1" width="2" />
        <rect height="8" rx="1" width="2" x="4" y="5" />
        <rect height="10" rx="1" width="2" x="8" y="3" />
        <rect height="5" rx="1" width="2" x="12" y="8" />
      </g>
    </StyledIcon>
  );

export default styled(BarChart)``
