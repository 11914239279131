import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const GridView = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M4.2 5.4h-3A1.2 1.2 0 0 1 0 4.2v-3A1.2 1.2 0 0 1 1.2 0h3a1.2 1.2 0 0 1 1.2 1.2v3a1.2 1.2 0 0 1-1.2 1.2zm-3-4.2v3h3v-3zm9.6 4.2h-3a1.2 1.2 0 0 1-1.2-1.2v-3A1.2 1.2 0 0 1 7.8 0h3A1.2 1.2 0 0 1 12 1.2v3a1.2 1.2 0 0 1-1.2 1.2zm-3-4.2v3h3v-3zM4.2 12h-3A1.2 1.2 0 0 1 0 10.8v-3a1.2 1.2 0 0 1 1.2-1.2h3a1.2 1.2 0 0 1 1.2 1.2v3A1.2 1.2 0 0 1 4.2 12zm-3-4.2v3h3v-3zm9.6 4.2h-3a1.2 1.2 0 0 1-1.2-1.2v-3a1.2 1.2 0 0 1 1.2-1.2h3A1.2 1.2 0 0 1 12 7.8v3a1.2 1.2 0 0 1-1.2 1.2zm-3-4.2v3h3v-3z"/></g>
    </StyledIcon>
  )

export default styled(GridView)``
  