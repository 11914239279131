import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const CriticalBounce = props => (
  <StyledIcon viewBox="0 0 20 20" {...props}>
    <g>
      <path
        d="M 3.478 6.408 L 3.478 0 L 16.522 0 L 16.522 6.408 L 20 8.926 L 20 20 L 0 20 L 0 8.926 L 3.478 6.408 Z M 4.348 0.868 L 4.348 10.784 L 10 13.863 L 15.652 10.784 L 15.652 0.868 L 4.348 0.868 Z M 18.752 9.095 L 16.522 7.481 L 16.522 10.31 L 18.752 9.095 Z M 3.478 7.481 L 1.248 9.095 L 3.478 10.31 L 3.478 7.481 Z M 19.13 9.878 L 10.907 14.357 L 19.13 18.836 L 19.13 9.878 Z M 17.859 19.132 L 0.87 9.878 L 0.87 19.132 L 17.859 19.132 Z M 10.707 6.404 L 12.5 8.198 L 11.786 8.912 L 9.992 7.118 L 8.255 8.855 L 7.557 8.157 L 9.293 6.42 L 7.5 4.626 L 8.214 3.912 L 10.008 5.706 L 11.745 3.969 L 12.442 4.668 L 10.707 6.404 Z"
        fillRule="evenodd"
      />
    </g>
  </StyledIcon>
)

export default styled(CriticalBounce)``
