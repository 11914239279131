import styled from 'styled-components'
import { resetCSS } from '../../../helpers'
import commonStyle from '../helpers/commonStyle'

// TODO: v7: use styled.p in breaking change
const Body1Medium = styled.h3.attrs(resetCSS)`
  ${commonStyle};

  color: ${({ color, theme }) =>
    color ? theme.colors[color] || color : theme.components.typography.body1Medium.text};
  font-family: ${({ theme }) => theme.fonts.body1Medium.fontFamily};
  font-size: ${({ theme }) => theme.fonts.body1Medium.fontSize};
  font-weight: ${({ theme }) => theme.fonts.body1Medium.fontWeight};
  line-height: ${({ theme }) => theme.fonts.body1Medium.lineHeight};
`

export default Body1Medium
