import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Sliders = props => (
    <StyledIcon viewBox="0 0 14 14" {...props}>
      <g>
        <path d="M12.857 1.714H9.43c-.515 0-.858.343-.858.857 0 .515.343.858.858.858h3.428c.514 0 .857-.343.857-.858 0-.514-.343-.857-.857-.857zM4.286 0c-1.115 0-2.057.771-2.4 1.714H.857c-.514 0-.857.343-.857.857 0 .515.343.858.857.858h1.029c.343.942 1.285 1.714 2.4 1.714 1.457 0 2.571-1.114 2.571-2.572C6.857 1.114 5.743 0 4.286 0zM.857 10.286h3.429c.514 0 .857-.343.857-.857 0-.515-.343-.858-.857-.858H.857c-.514 0-.857.343-.857.858 0 .514.343.857.857.857zM12.857 8.571H11.83a2.497 2.497 0 0 0-2.4-1.714c-1.458 0-2.572 1.114-2.572 2.572C6.857 10.886 7.971 12 9.43 12c1.114 0 2.057-.771 2.4-1.714h1.028c.514 0 .857-.343.857-.857 0-.515-.343-.858-.857-.858z" />
      </g>
    </StyledIcon>
  )

export default styled(Sliders)``
