import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const MailjetLogoText = props => (
    <StyledIcon viewBox="0 0 1680 500" {...props}>
      <image height="100%" width="100%" xlinkHref="https://assets.mailjet.com/lib/images/mailjetLogo/mj_logo_with_text_color.svg" />
    </StyledIcon>
  )

export default styled(MailjetLogoText)``
  