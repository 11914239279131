import React from 'react'
import { Caption } from '../../Typography'


const Counter = ({ current, max, ...rest }) => (
  <Caption ml="auto" {...rest}>
    {`${current}/${max}`}
  </Caption>
)

export default Counter
