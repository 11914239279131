import React from 'react'
import styled from 'styled-components'

import { useHeaderCellNumber, useIsSelectable } from '../hooks'

import Cell from './Cell'
import FullWidthCell from './FullWidthCell'

const StyledCell = styled(Cell)`
  border: none;
`

const FullWidth = ({ children, colSpan, ...rest }) => {
  const isSelectable = useIsSelectable()
  const [headerCellNumber] = useHeaderCellNumber()
  // remove the checkbox column if exists
  const colSpanValue =
    colSpan ?? (isSelectable && headerCellNumber ? headerCellNumber - 1 : headerCellNumber)

  return (
    <tr {...rest}>
      {isSelectable && <StyledCell />}
      <FullWidthCell colSpan={colSpanValue} isSelectable={isSelectable}>
        {children}
      </FullWidthCell>
    </tr>
  )
}

export const DocFullWidth = FullWidth
export default styled(FullWidth)``
