import { clamp, isNaN, toNumber } from 'lodash'

/**
 * Returns the percent a slider is filled according to the
 * minimum and maximum values and rounded to the closest step
 * @param {number} value
 * @param {number} min
 * @param {number} max
 * @param {number} step
 * @returns {number} returns calculated percent
 */
const calculatePercentFilled = (value, min = 0, max = 100, step = 1) => {
  if (
    isNaN(toNumber(value)) ||
    isNaN(toNumber(min)) ||
    isNaN(toNumber(max)) ||
    isNaN(toNumber(step))
  ) {
    throw new Error(`Numbers expected, whereas ${value}, ${min}, ${max} & ${step} were given.`)
  }
  const roundedValue = step !== 1 ? Math.round(value / step) * step : value
  return ((clamp(roundedValue, min, max) - min) / (max - min)) * 100
}

export default calculatePercentFilled
