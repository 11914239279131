import styled from 'styled-components'
import { resetCSS } from 'component-library/helpers'

const FullWidthCell = styled.td.attrs(resetCSS)`
  border: none;
  text-align: inherit;
  ${({ isSelectable, theme }) => `
    padding: ${theme.sizes.s4} ${theme.sizes.s3};
    padding-top: 0px;
    padding-left: ${isSelectable ? theme.sizes.s5 : theme.sizes.s3};
  `}
`

export default FullWidthCell
