import React from 'react'
import { noop } from 'lodash'
import styled, { css } from 'styled-components'

import { PageProvider } from './contexts'
import { getLastPage } from './helpers'
import {
  PaginationFirst,
  PaginationItemsPerPage,
  PaginationLast,
  PaginationLeftPart,
  PaginationNext,
  PaginationPrevious,
  PaginationRightPart,
  PaginationTogglePages,
} from './components'

const getLoadingStyle = ({ isDisabled, theme: { globals } }) => {
  if (isDisabled) {
    return css`
      opacity: ${globals.opacity.o1};
      * {
        pointer-events: none;
      }
    `
  }
  return css``
}

const Container = styled('nav')`
  ${getLoadingStyle};
  display: inline-flex;
  width: 100%;
`

const Pagination = ({
  currentPage,
  itemsPerPage = Infinity,
  onChange = noop,
  startingPage,
  total = 1,
  ...rest
}) => {
  const lastPage = getLastPage(total, itemsPerPage)
  const startingPageNumber = Math.abs(Number(startingPage))
  return (
    <PageProvider
      currentPage={currentPage}
      initialItemsPerPage={itemsPerPage}
      lastPage={lastPage}
      onChangePage={onChange}
      startingPage={startingPageNumber}
    >
      <Container {...rest} />
    </PageProvider>
  )
}



Pagination.First = PaginationFirst
Pagination.Last = PaginationLast
Pagination.LeftPart = PaginationLeftPart
Pagination.Next = PaginationNext
Pagination.Previous = PaginationPrevious
Pagination.RightPart = PaginationRightPart
Pagination.ItemsPerPage = PaginationItemsPerPage
Pagination.TogglePages = PaginationTogglePages

export default Pagination
