// @noSnapshot

import * as React from 'react'
import { isEmpty } from 'lodash'

import { CarretRight } from 'component-library/icons'
import { Caption } from '../../Typography'
import OptionBlock from '../styles/OptionBlock'
import OptionContainer from '../styles/OptionContainer'
import OptionLeftItems from '../styles/OptionLeftItems'
import OptionRightItems from '../styles/OptionRightItems'
import OptionsPanel from './OptionsPanel' // eslint-disable-line import/no-cycle
import OptionsPanelContainer from '../styles/OptionsPanelContainer'
import OptionText from '../styles/OptionText'
import OptionTooltip from '../styles/OptionTooltip'
import Tooltip from '../../Tooltip'

const tooltipStyle = { width: '100%' }


class Option extends React.PureComponent {
  static defaultProps = {
    isSelected: false,
    styleOption: {},
    subOptionsDirection: 'right',
  }

  state = {
    showSubOptions: false,
  }

  mouseEnter = () => {
    // Don't show the sub options if the item is disabled.
    if (!this.props.disabled) {
      this.setState({
        showSubOptions: true,
      })
    }
  }

  mouseLeave = () =>
    this.setState({
      showSubOptions: false,
    })

  renderContent = () => {
    const {
      deletion,
      disabled,
      ellipsis,
      label,
      leftIconColor,
      leftIconName: LeftIcon,
      leftIconOnClick,
      leftImage,
      options,
      readOnly,
      rightIconColor,
      rightIconName: RightIcon,
      rightIconOnClick,
      rightLabel,
      styleOption,
    } = this.props

    const hasSubOptions = options && !isEmpty(options)

    return (
      <>
        <OptionLeftItems>
          {/* Left icon. */}
          {LeftIcon && (
            <LeftIcon color={leftIconColor || 'inherit'} mt="s1" onClick={leftIconOnClick} />
          )}
          {/* Left image. */}
          {leftImage && leftImage}
        </OptionLeftItems>
        {/* Label. */}
        <OptionText
          deletion={deletion}
          ellipsis={ellipsis}
          style={{
            ...styleOption,
            cursor: disabled ? 'not-allowed' : readOnly ? 'default' : 'pointer',
          }}
        >
          {label}
        </OptionText>
        <OptionRightItems>
          {/* Right label. */}
          {rightLabel && <Caption mt="s1">{rightLabel}</Caption>}
          {/* Right icon. */}
          {RightIcon && (
            <RightIcon color={rightIconColor || 'inherit'} mt="s1" onClick={rightIconOnClick} />
          )}
          {hasSubOptions && <CarretRight size="s3" />}
        </OptionRightItems>
      </>
    )
  }

  render() {
    const {
      'data-testid': dataTestId,
      deletion,
      disabled,
      hasCheckedOption,
      link,
      isSelected,
      locator,
      onClick,
      options,
      readOnly,
      subOptionsDirection,
      tooltip,
    } = this.props
    const { showSubOptions } = this.state

    const hasSubOptions = options && !isEmpty(options)

    return (
      <OptionContainer hasSubOptions={hasSubOptions}>
        {/* Align icons to center if we have sub options to get the carret aligned. */}
        <OptionBlock
          aria-expanded={hasSubOptions ? this.state.showSubOptions : null}
          aria-haspopup={hasSubOptions}
          as={!!link && 'a'}
          className={locator}
          data-testid={dataTestId}
          deletion={deletion}
          disabled={disabled}
          hasCheckedOption={hasCheckedOption}
          hasSubOptions={hasSubOptions}
          href={link}
          isLink={!!link}
          isSelected={isSelected}
          onClick={!link ? onClick : null}
          onMouseOut={this.mouseLeave}
          onMouseOver={this.mouseEnter}
          readOnly={readOnly}
          rel={!!link && 'noreferrer noopener'}
        >
          {tooltip ? (
            <Tooltip {...tooltip} style={tooltipStyle}>
              <OptionTooltip di="f">{this.renderContent()}</OptionTooltip>
            </Tooltip>
          ) : (
            this.renderContent()
          )}
        </OptionBlock>
        {hasSubOptions && (
          <OptionsPanelContainer
            onMouseOut={this.mouseLeave}
            onMouseOver={this.mouseEnter}
            showSubOptions={showSubOptions}
            subOptionsDirection={subOptionsDirection}
          >
            <OptionsPanel noResultText="" options={options} />
          </OptionsPanelContainer>
        )}
      </OptionContainer>
    )
  }
}

export default Option
