import styled from 'styled-components'

import Copy from '../components/Copy'

const EditorWrapperStyled = styled.div`
  ${({ theme, plainPage }) => `
    position: relative;
    padding: ${theme.sizes.s4} ${theme.sizes.s8} ${theme.sizes.s4} ${theme.sizes.s4};
    background-color: ${theme.colors.darksteel};
    ${Copy} {
      position: absolute;
      top: ${theme.sizes.s4};
      right: ${theme.sizes.s4};
      z-index: 1;
    }
    ${plainPage && `height: 100%;`}
  `}
`

export default EditorWrapperStyled
