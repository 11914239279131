import * as React from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { find, forEach, get, isEmpty, map, some } from 'lodash'
import { Info } from 'component-library/icons'
import Divider from './Divider'
import Title from './Title'
import Option from './Option' // eslint-disable-line import/no-cycle
import OptionsWrapper from '../styles/OptionsWrapper'
import Checkbox from './Checkbox'

/**
 * A Panel containing a list of options. Can be useful for a dropdown or a select component.
 */
class OptionsPanel extends React.Component{
  static defaultProps = {
    optimize: false,
    selectedIcon: {
      name: 'Check',
      size: 's4',
    },
  }

  /**
   * Loop over options to verify if there is at least one 'option' type. Otherwise we'll
   * show the no result text.
   */
  static hasOption(options) {
    let hasOption = false

    forEach(options, o => {
      if (o.type === 'option' || o.type === 'checkbox') {
        hasOption = true
        // Break the loop
        return false
      }
    })

    return hasOption
  }

  state = {
    // Is there options in the panel ?
    hasOption: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { options } = nextProps

    if (!!options && options !== prevState.options) {
      return {
        // Verify if there is at least one 'option' type in options.
        hasOption: OptionsPanel.hasOption(options),
        options,
      }
    }
    if (options !== prevState.options) {
      // Copy props value.
      return {
        options,
      }
    }

    return null
  }

  /**
   * Render an option, according to its type.
   */
  renderOption = (opt, index) => {
    const {
      'data-testid': dataTestId,
      ellipsis,
      locator,
      onOptionClick,
      options,
      selectedIcon,
    } = this.props
    const selectedIconName = get(selectedIcon, 'name', null)
    const hasCheckedOption = find(options, { isSelected: true }) && selectedIconName

    switch (opt.type) {
      case 'divider':
        return <Divider key={`d-${index}`} />

      case 'option':
        return (
          <Option
            data-testid={dataTestId}
            ellipsis={ellipsis}
            hasCheckedOption={!!hasCheckedOption}
            key={opt.id}
            locator={locator ? `${locator}-option-${index}` : undefined}
            {...opt}
            leftIconOnClick={(e) => {
              if (!opt.disabled && !opt.readOnly && !!opt.leftIconOnClick) {
                e.stopPropagation()
                opt.leftIconOnClick(opt.parentId || opt.id)
              }
            }}
            leftImage={opt.leftImage}
            onClick={(e) => {
              if (!opt.disabled && !opt.readOnly) {
                e.stopPropagation()
                opt.onClick && opt.onClick(opt.parentId || opt.id)
                onOptionClick && onOptionClick(opt.parentId || opt.id)
              }
            }}
            options={opt.options}
            rightIconOnClick={(e) => {
              if (!opt.disabled && !opt.readOnly && !!opt.rightIconOnClick) {
                e.stopPropagation()
                opt.rightIconOnClick(opt.parentId || opt.id)
              }
            }}
            selectedIcon={selectedIcon}
            styleOption={opt.styleOption}
            subOptionsDirection={opt.subOptionsDirection}
            tooltip={opt.tooltip}
          />
        )

      case 'checkbox':
        return (
          <Checkbox
            data-testid={dataTestId}
            ellipsis={ellipsis}
            key={opt.id}
            locator={locator ? `${locator}-option-${index}` : undefined}
            {...opt}
            id={`${opt.id}`}
            onClick={(e) => {
              e.stopPropagation()

              if (!opt.disabled && !opt.readOnly) {
                opt.onClick && opt.onClick(opt.id)
                onOptionClick && onOptionClick(opt.id)
              }
            }}
            tooltip={opt.tooltip}
          />
        )

      case 'title':
        return <Title key={`${opt.label}--title`} label={opt.label} />

      default:
        return null
    }
  }

  render() {
    const { hasOption } = this.state
    const { className, locator, maxHeight, noResultText, options, _ref, style } = this.props

    const realOptions = hasOption
      ? options
      : [
          {
            id: 1,
            label: noResultText,
            leftIconColor: 'darkgrey',
            leftIconName: Info,
            readOnly: true,
            type: 'option',
          },
        ]

    const hasSubOptions = some(
      realOptions,
      realOption => realOption.options && !isEmpty(realOption.options),
    )
    // We can fix the height of the panel only when there is no suboptions. Otherwise, the suboptions panel will
    // trigger the scrollbar.
    const realMaxHeight = !!maxHeight && !hasSubOptions ? maxHeight : null

    const classes = cx(className, locator ? `${locator}-optionpanel` : undefined)

    return (
      <OptionsWrapper className={classes} maxHeight={realMaxHeight} ref={_ref} style={style}>
        {map(realOptions, this.renderOption)}
      </OptionsWrapper>
    )
  }
}

export default styled(React.forwardRef((props, ref) => <OptionsPanel _ref={ref} {...props} />))``
