import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Mobile = props => (
    <StyledIcon viewBox="0 0 10 16" {...props}>
      <g><path d="m2.08401,0c-1.149,0 -2.084,1.122 -2.084,2.5l0,0l0,11c0,1.379 0.935,2.5 2.084,2.5l0,0l5.833,0c1.148,0 2.083,-1.121 2.083,-2.5l0,0l0,-11c0,-1.378 -0.935,-2.5 -2.083,-2.5l0,0l-5.833,0zm-1.25,2.5c0,-0.827 0.56,-1.5 1.25,-1.5l0,0l5.833,0c0.689,0 1.25,0.673 1.25,1.5l0,0l0,9.501l-8.333,0l0,-9.501zm0,11l0,-0.5l8.333,0l0,0.5c0,0.826 -0.561,1.5 -1.25,1.5l0,0l-5.833,0c-0.69,0 -1.25,-0.674 -1.25,-1.5l0,0z"/></g>
    </StyledIcon>
  )

export default styled(Mobile)``
