import { isNumber } from 'lodash'
/**
 * Returns the last page number.
 * @param {number} total
 * @param {number} itemsPerPage
 * @returns {number} returns the last page number
 */
const getLastPage = (total, itemsPerPage = Infinity) => {
  if (!isNumber(total) || !isNumber(itemsPerPage)) {
    throw new Error(`<Pagination> expects numbers, whereas ${total} & ${itemsPerPage} were given.`)
  }
  return Math.ceil(total / itemsPerPage) || 1
}

export default getLastPage
