import styled, { css } from 'styled-components'

import { OnlyDesktop } from '@styles/DisplayOnMedia'
import { styleForTabletAndMobile } from '@utils'

// Button to open the lateral menu on tablet and mobile
const MenuButtonTabletAndMobile = styled.button`
  ${({ theme }) => css`
    display: none;

    ${styleForTabletAndMobile} {
      align-items: center;
      background-color: ${theme.colors.primary50};
      border-bottom: ${theme.sizes.s0} solid ${theme.colors.neutral20};
      border-left: unset;
      border-radius: 0;
      border-right: ${theme.sizes.s0} solid ${theme.colors.neutral20};
      border-top: unset;
      display: flex;
      height: 52px;
      justify-content: center;
      padding: 0;
      transition:
        border-color 150ms,
        background-color 150ms;
      width: 52px;

      &:focus:not(:active):not(.is-loading) {
        background-color: ${theme.colors.primary50};
        border-bottom: ${theme.sizes.s0} solid ${theme.colors.neutral20};
        border-left: unset;
        border-right: ${theme.sizes.s0} solid ${theme.colors.neutral20};
        border-top: unset;
        outline: none;
      }

      &:active:not(.is-disabled):not(.is-loading) {
        background-color: ${theme.colors.primary60};
        border-bottom: ${theme.sizes.s0} solid ${theme.colors.neutral20};
        border-left: unset;
        border-right: ${theme.sizes.s0} solid ${theme.colors.neutral20};
        border-top: unset;
      }

      &:hover:not(.is-loading):not(.is-disabled):not(:active) {
        background-color: ${theme.colors.primary35};
        border-bottom: ${theme.sizes.s0} solid ${theme.colors.neutral20};
        border-left: unset;
        border-right: ${theme.sizes.s0} solid ${theme.colors.neutral20};
        border-top: unset;
      }
    }
  `}
`

// Main bar
const CenterPartNavigation = styled.ul`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0;
    padding: 0;

    & > li {
      height: 100%;
      list-style: none;
      position: relative;
    }

    & > *:not(:last-child) {
      margin-right: ${theme.sizes.s3};
    }

    ${OnlyDesktop} {
      display: flex;
      height: 100%;
    }
  `}
`

export { CenterPartNavigation, MenuButtonTabletAndMobile }
