import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const LandscapeProgramming = props => (
    <StyledIcon viewBox="0 0 12 9.82" {...props}>
      <g><path d="M10 0H3.27a1.09 1.09 0 0 0-1.09 1.09v5.46a1.09 1.09 0 0 0 1.09 1.09h7.64A1.09 1.09 0 0 0 12 6.55V2zm.87 6.55h-7.6V1.09h6v1.64h1.64z"/><path d="M1.09 1.64H0v7.09a1.09 1.09 0 0 0 1.09 1.09h8.18V8.73H1.09z"/><path d="M5.71 2.98L4.33 4.36l1.38 1.38.58-.57-.8-.81.8-.8-.58-.58zm1.64.58l.8.8-.8.81.57.57 1.39-1.38-1.39-1.38-.57.58z"/></g>
    </StyledIcon>
  )

export default styled(LandscapeProgramming)``
  