import React from 'react'
import { find, map } from 'lodash'

// Get usable hreflang data from graphQl Hreflangs nodes and domain slug
export const formatHreflangs = (nodes, baseUrl) => {
  // Fix the 404 page hreflangs.
  // Gatsby automatically change hreflang of 404 page and replace by 404.html
  // We change again the hreflangs to have the correct one.
  if (nodes?.[0]?.slug?.includes('404.html')) {
    return [
      {
        href: baseUrl + '/404',
        hrefLang: 'en-US',
      },
      {
        href: baseUrl + '/fr/404',
        hrefLang: 'fr-FR',
      },
      {
        href: baseUrl + '/de/404',
        hrefLang: 'de-DE',
      },
      {
        href: baseUrl + '/es/404',
        hrefLang: 'es-ES',
      },
    ]
  } else {
    return map(nodes, ({ locale, slug }) => ({
      href:
        baseUrl + (slug.slice(-1) !== '/' && slug !== '/' ? slug + '/' : slug),
      hrefLang: locale,
    }))
  }
}

export const HeadInjector = ({ data, pageContext }) => {
  const { nodes } = { ...data?.allHreflangs }
  if (!nodes) {
    return
  }
  const { baseUrl } = pageContext.siteConfig
  const hrefLangs = formatHreflangs(nodes, baseUrl)

  const xDefault = find(
    hrefLangs,
    ({ hrefLang }) => hrefLang?.includes('en') || hrefLangs[0],
  )
  hrefLangs.unshift({ ...xDefault, hrefLang: 'x-default' })

  return map(hrefLangs, ({ href, hrefLang }) => (
    <link rel='alternate' key={hrefLang} href={href} hrefLang={hrefLang} />
  ))
}
