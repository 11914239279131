import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Bin = props => (
    <StyledIcon viewBox="0 0 12 12.67" {...props}>
      <g><path d="M1.33 11.33a1.33 1.33 0 0 0 1.33 1.33h6.67a1.33 1.33 0 0 0 1.33-1.33v-8H1.33zm1.34-6.66h6.66v6.67H2.67zM8 1.33V0H4v1.33H0v1.34h12V1.33z"/><path d="M4 6h1.33v4H4zm2.67 0H8v4H6.67z"/></g>
    </StyledIcon>
  )

export default styled(Bin)``
  