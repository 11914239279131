import { css } from 'styled-components'
import { styleForMobile } from '@utils'

/** Return a responsive rule that apply typography mobile configuration
 theme: the theme from styled compo instance
 typoName: name of props in theme component
 */
export const injectMobileStyle = (theme, typoName) => css`
  ${styleForMobile} {
    ${() => {
      const { fontSize, lineHeight } = theme.fonts[typoName].mobile
      return `
        font-size: ${fontSize};
        line-height: ${lineHeight};
      `
    }}
  }
`

// Enable use of color passed as a property
export const injectColor = ({ color, isDark = false, theme, typoName }) => {
  // Paragraph in light mode use neutral70 and neutral35 in dark mode
  const paragraphNames = ['body1', 'body2', 'body3', 'caption', 'caption2']
  const themeColor = paragraphNames.includes(typoName)
    ? isDark
      ? 'neutral35'
      : 'neutral70'
    : isDark
    ? 'neutral0'
    : 'neutral100'

  return (
    (color || themeColor) &&
    `color: ${color ? theme.colors[color] || color : theme.colors[themeColor]};`
  )
}

/** Return a typography font css configuration
 component: the typo component
 typoName: name of props in theme component
 color: font color
 */
export const injectFontStyle = (theme, typoName, color = 'neutral90') => css`
  font-family: ${theme.fonts[typoName].fontFamily};
  font-size: ${theme.fonts[typoName].fontSize};
  font-weight: ${theme.fonts[typoName].fontWeight};
  letter-spacing: ${theme.fonts[typoName].letterSpacing};
  line-height: ${theme.fonts[typoName].lineHeight};
`

// Enable use of display inline passed as a property
export const injectDisplayInline = ({ isInline }) =>
  isInline && `display: inline-flex;`

export const injectUnderline = ({ underline }) =>
  underline && `text-decoration: underline;`
