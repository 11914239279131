import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const CheckboxChecked = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M9.84 0H2.16A2.15 2.15 0 0 0 0 2.16v7.68A2.15 2.15 0 0 0 2.16 12h7.68A2.15 2.15 0 0 0 12 9.84V2.16A2.15 2.15 0 0 0 9.84 0zm-5 9.15L2.5 6.79l.73-.74 1.56 1.56L8.7 2.85l.8.66z"/></g>
    </StyledIcon>
  )

export default styled(CheckboxChecked)``
  