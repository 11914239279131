import styled from 'styled-components'
import { resetCSS } from '../../../helpers'
import commonStyle from '../helpers/commonStyle'

const Header1 = styled.h1.attrs(resetCSS)`
  ${commonStyle};

  color: ${({ color, theme }) =>
    color ? theme.colors[color] || color : theme.components.typography.header1.text};
  font-family: ${({ theme }) => theme.fonts.header1.fontFamily};
  font-size: ${({ theme }) => theme.fonts.header1.fontSize};
  font-weight: ${({ theme }) => theme.fonts.header1.fontWeight};
  line-height: ${({ theme }) => theme.fonts.header1.lineHeight};
`

export default Header1
