import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Image = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M15 16.001H1a1 1 0 0 1-1-1v-14a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1zm-13-2h12v-12H2v12zm3-7a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm5 0l3 6H3l3-4 1.412 1.882L10 7.001z"/></g>
    </StyledIcon>
  )

export default styled(Image)``
  