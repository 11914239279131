import styled from 'styled-components'
import Image from '@components/Image'
// Todo: if needed define a rule to get specific ImageInline sizes instead of default s16 height here.
const ImageInline = styled(Image)`
  ${({ theme }) => `
    & {
      display: inline-flex;
    }
    
    & > *  {
      display: inline-block;
      height: ${theme.sizes.s16};
      width: auto;
    }
  `}
`

export default ImageInline
