import styled from 'styled-components'
import { optionsPanelStyle, subOptionsPositionStyles } from '../styles'

const SubOptions = styled.div`
  ${({ alignOptions = 'right' }) => subOptionsPositionStyles[alignOptions]};
  ${optionsPanelStyle};

  display: none;
  position: absolute;
`

export default SubOptions
