import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Soundcloud = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M15.986 9.027c.074 1.122-.782 2.056-1.847 2.056H8.573a.257.257 0 0 1-.253-.262V4.56c0-.103.058-.197.149-.239a3.287 3.287 0 0 1 1.38-.302c1.857 0 3.366 1.541 3.404 3.458.288-.163.623-.249.978-.231.929.047 1.691.82 1.755 1.782zM7.828 4.423a.26.26 0 0 1 .254.264v6.126a.26.26 0 0 1-.254.263.26.26 0 0 1-.254-.263V4.687a.26.26 0 0 1 .254-.264zm-.842.652a.26.26 0 0 1 .255.263v5.475a.26.26 0 0 1-.255.263.26.26 0 0 1-.254-.263V5.338a.26.26 0 0 1 .254-.263zm-.841.297a.26.26 0 0 1 .254.263v5.178a.26.26 0 0 1-.254.263.26.26 0 0 1-.254-.263V5.635a.26.26 0 0 1 .254-.263zm-.842-.396a.26.26 0 0 1 .255.264v5.573a.26.26 0 0 1-.255.263.26.26 0 0 1-.254-.263V5.24a.26.26 0 0 1 .254-.264zm-.841.264a.26.26 0 0 1 .254.263v5.31a.26.26 0 0 1-.254.263.26.26 0 0 1-.254-.263v-5.31a.26.26 0 0 1 .254-.263zm-.842.527a.26.26 0 0 1 .255.264v4.782a.26.26 0 0 1-.255.263.26.26 0 0 1-.254-.263V6.03a.26.26 0 0 1 .254-.264zM2.78 6.79a.26.26 0 0 1 .254.263v3.76a.26.26 0 0 1-.254.263.26.26 0 0 1-.254-.263v-3.76a.26.26 0 0 1 .254-.263zm-.842.428a.26.26 0 0 1 .255.264v3.33a.26.26 0 0 1-.255.264.26.26 0 0 1-.254-.263V7.482a.26.26 0 0 1 .254-.264zm-.841.099a.26.26 0 0 1 .254.263v3.134a.26.26 0 0 1-.254.264.26.26 0 0 1-.254-.264V7.58a.26.26 0 0 1 .254-.263zm-.842.688a.26.26 0 0 1 .255.264v1.806a.26.26 0 0 1-.255.263.26.26 0 0 1-.254-.263V8.269a.26.26 0 0 1 .254-.264z"/></g>
    </StyledIcon>
  )

export default styled(Soundcloud)``
  