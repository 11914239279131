import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ServerCheck = props => (
    <StyledIcon viewBox="0 0 12 11.85" {...props}>
      <g><path d="M1.52 1.52h2.53v1.01H1.52zm0 3.04h2.53v1.01H1.52zm0 3.03h2.53V8.6H1.52z"/><circle cx="6.58" cy="2.02" r=".51"/><circle cx="8.1" cy="2.02" r=".51"/><circle cx="6.58" cy="5.06" r=".51"/><circle cx="8.1" cy="5.06" r=".51"/><path d="M6.07 9.11H1v-2h9.11V1a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v8.1a1 1 0 0 0 1 1h5.07zM1 1h8.1v2H1zm0 3h8.1v2H1zm8.11 7.85L7.24 9.97l.71-.71 1.16 1.16 2.17-2.17.72.71-2.89 2.89z"/></g>
    </StyledIcon>
  )

export default styled(ServerCheck)``
  