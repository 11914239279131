import React from 'react'
import styled from 'styled-components'

import Box from '../../Box'
import Cell from './Cell'
import Image from '../../Image'
import { Body2, Header4 } from '../../Typography'

import { useHeaderCellNumber } from '../hooks'

const DEFAULT_EMPTY_SRC = '//assets.mailjet.com/lib/images/mj_lost.png'

const StyledBox = styled(Box)`
  ${({
    theme: {
      globals: { imageSize },
    },
  }) => `
    ${Body2}, ${Header4} {
      width: ${imageSize.imageSize1.width};
    }
  `}
`

function EmptyState({
  className,
  emptySrc = DEFAULT_EMPTY_SRC,
  emptyTitle,
  emptySubtitle,
}) {
  const [headerCellNumber] = useHeaderCellNumber()

  return (
    <tr className={className}>
      <Cell colSpan={headerCellNumber}>
        <StyledBox mode="large">
          <Image alt="Empty state" src={emptySrc} />
          <Header4>{emptyTitle}</Header4>
          {typeof emptySubtitle === 'string' ? (
            <Body2 color="grey">{emptySubtitle}</Body2>
          ) : (
            <>{emptySubtitle}</>
          )}
        </StyledBox>
      </Cell>
    </tr>
  )
}

export default styled(EmptyState)``
