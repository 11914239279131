import styled, { css } from 'styled-components'
import ImageDiv from '@components/Image/styles/ImageDiv'
import { Body2, Header5Bold } from '@typography'
import { styleForTablet, styleForMobile } from '@utils'

export const StyledRichText = styled.div`
  ${({ theme }) => css`
    picture {
      height: 100%;
      width: 100%;
      * {
        padding: 0;
      }
      img {
        height: 100%;
        width: 100%;
      }
    }
    ${Body2} {
      a > *,
      b {
        color: ${theme.colors.neutral90};
      }
    }
    & > ${Body2}:not(:first-child) {
      padding-top: ${theme.sizes.s5};
    }

    ${Header5Bold}:not(:first-child) {
      padding-top: ${theme.sizes.s6};
    }

    ${ImageDiv} {
      margin-top: ${theme.sizes.s5};
    }

    ul {
      margin-top: ${theme.sizes.s5};
      padding-inline-start: ${theme.sizes.s6};

      ${styleForMobile} {
        margin-top: ${theme.sizes.s12};
      }
    }

    & > ${Body2} > div {
      margin-top: ${theme.sizes.s5};

      ${styleForTablet} {
        margin-top: ${theme.sizes.s3};
      }

      ${styleForMobile} {
        margin-top: 0;
      }
    }

    & > div {
      margin-top: ${theme.sizes.s8};

      ${styleForTablet} {
        margin-top: ${theme.sizes.s7};
      }

      ${styleForMobile} {
        margin-top: ${theme.sizes.s6};
      }
    }
  `}
`
