import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AxTesting = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M10.885 12.703h-5.78L4.004 16H.5L6.456 0h3.055L15.5 16h-3.505l-1.11-3.297zm-4.89-2.67h4L7.984 4.044l-1.99 5.989z"/></g>
    </StyledIcon>
  )

export default styled(AxTesting)``
  