import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const HorizontalMirror = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8.8 16H0V0h8.8v3.2H7.2V1.6H1.6v12.8h5.6V12h1.6v4zM16 8.8h-1.6V7.2H16v1.6zm0 2.4h-1.6V9.6H16v1.6zm0 2.4h-1.6V12H16v1.6zM11.2 16H9.6v-1.6h1.6V16zm2.4 0H12v-1.6h1.6V16zm2.4 0h-1.6v-1.6H16V16zm0-9.6h-1.6V4.8H16v1.6zM16 4h-1.6V2.4H16V4zm0-2.4h-1.6V0H16v1.6zm-2.4 0H12V0h1.6v1.6zm-2.4 0H9.6V0h1.6v1.6zM8 7.2V4.8L12 8l-4 3.2V8.8H4.8V7.2H8z"/></g>
    </StyledIcon>
  )

export default styled(HorizontalMirror)``
  