import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Globe = props => (
    <StyledIcon viewBox="0 0 24 24" {...props}>
      <g><path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm8.85 10.5H18c-.15-2.25-.6-4.35-1.2-6.15 2.1 1.35 3.6 3.6 4.05 6.15zM12 21c-.9 0-2.7-2.85-3-7.5h6c-.3 4.65-2.1 7.5-3 7.5zM9 10.5C9.3 5.85 10.95 3 12 3s2.7 2.85 3 7.5H9zM7.35 4.35C6.6 6.15 6.15 8.25 6 10.5H3.15c.45-2.55 1.95-4.8 4.2-6.15zm-4.2 9.15H6c.15 2.25.6 4.35 1.2 6.15-2.1-1.35-3.6-3.6-4.05-6.15zm13.5 6.15c.75-1.8 1.05-3.9 1.2-6.15h2.85c-.3 2.55-1.8 4.8-4.05 6.15z"  fillRule="evenodd"/></g>
    </StyledIcon>
  )

export default styled(Globe)``
