import React from 'react';
import { CancelCircle } from 'component-library/icons';
import IconWrapper from './styles/IconWrapper';
import RounderWrapper from './styles/RounderWrapper';
import TagLoader from './styles/TagLoader';
import TagText from './styles/TagText';

/**
 * Render a tag with a label.
 */
class Tag extends React.Component {
  static defaultProps = {
    autoContrast: false,
    noCancelButton: false,
  };

  handleClick = (e) => {
    e.stopPropagation();

    const { disabled, onClick } = this.props;

    !disabled && onClick && onClick(e);
  };

  handleIconClick = (e) => {
    e.stopPropagation();

    const { disabled, onIconClick } = this.props;

    !disabled && onIconClick && onIconClick(e);
  };

  render() {
    const {
      autoContrast,
      color,
      disabled,
      isLoading,
      label,
      locator,
      noCancelButton,
      onClick,
      style,
    } = this.props;

    return (
      <RounderWrapper
        className={locator}
        color={color}
        disabled={disabled}
        hasOnClick={!!onClick}
        onClick={this.handleClick}
        style={style}
      >
        {/* Label. */}
        <TagText
          aria-disabled={disabled}
          backgroundColor={autoContrast ? color : undefined}
          style={{ cursor: disabled ? 'not-allowed' : !!onClick ? 'pointer' : 'default' }}
        >
          {label}
        </TagText>

        {/* Icon. */}
        {!noCancelButton && (
          <IconWrapper
            backgroundColor={autoContrast && color}
            className={locator ? `${locator}-icon` : null}
            disabled={disabled}
            onClick={this.handleIconClick}
          >
            {isLoading ? <TagLoader color={color} /> : <CancelCircle />}
          </IconWrapper>
        )}
      </RounderWrapper>
    );
  }
}

export default Tag;
