const {
  renderRichText: originalRenderRichText,
} = require('gatsby-source-contentful/rich-text')
const { filter, reduce } = require('lodash')
const { BLOCKS } = require('@contentful/rich-text-types')

const defaultOptionRichText = () => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => {
      return children
    },
  },
})

const removeEmptyTag = (data) => {
  if (!data || (data.length === 1 && data[0] === '')) {
    return false
  }
  return filter(data, (val) => val !== '')
}

const addBreakPoint = (richText = {}, props = defaultOptionRichText()) => {
  if (!richText?.raw?.includes('\\\\n')) {
    return originalRenderRichText(richText, props)
  }

  const newRaw = richText?.raw.split('\\\\n').join('\\n') || undefined

  if (newRaw) {
    richText = {
      ...richText,
      raw: newRaw,
    }
  }
  return originalRenderRichText(richText, props)
}

const renderRichText = (text, props = null) => {
  const richtext =
    text && text.raw
      ? addBreakPoint(text, props || defaultOptionRichText())
      : text

  return reduce(
    richtext,
    (newArray, el) => {
      const children = el?.props?.children
      if (!Array.isArray(children)) {
        newArray.push(el)
      } else if (Array.isArray(children)) {
        const filteredArr = removeEmptyTag(children)
        if (filteredArr.length) {
          const newEl = {
            ...el,
            props: {
              ...el.props,
              children: filteredArr,
            },
          }
          newArray.push(newEl)
        }
      }
      return newArray
    },
    [],
  )
}

module.exports = {
  renderRichText,
}
