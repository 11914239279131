import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { ArrowBack } from 'component-library/icons'

import PaginationIcon from './PaginationIcon'
import { usePage } from '../contexts'

const PaginationFirst = ({ onClick, ...rest }) => {
  const { currentPage, goToFirstPage } = usePage()

  const onGoToFirstPage = () => {
    goToFirstPage()
    onClick?.()
  }

  return currentPage > 2 ? (
    <PaginationIcon icon={ArrowBack} onClick={onGoToFirstPage} {...rest} />
  ) : null
}

PaginationFirst.propTypes = {
  /**
   * The Button props.
   */
  '...rest': PropTypes.object,
  /**
   * Function called when the button-icon is clicked.
   */
  onClick: PropTypes.func,
}

export default styled(PaginationFirst)``
