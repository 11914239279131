import styled from 'styled-components'

import { display } from '../../../helpers'
import Button from '../../Button'

const ToggleButton = styled(Button)`
  display: flex;
  ${display};
`

export default ToggleButton
