import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const UserLocked = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M4 8a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3a1 1 0 1 0 1 1 1 1 0 0 0-1-1zm4 7H0v-.5c0-1.77 1.64-3 4-3s4 1.23 4 3zm-6.92-1h5.84C6.62 10.1 5.5 9.5 4 9.5s-2.62.6-2.92 1.5zM11.5 2a2 2 0 0 0-4 0 .5.5 0 0 0-.5.5V6a.5.5 0 0 0 .5.5h4A.5.5 0 0 0 12 6V2.5a.5.5 0 0 0-.5-.5zm-2-1a1 1 0 0 1 1 1h-2a1 1 0 0 1 1-1zM11 5.5H8V3h3z"/><circle cx="9.5" cy="4" r=".5"/></g>
    </StyledIcon>
  )

export default styled(UserLocked)``
  