import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const RepeatVerticaly = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M1 1v14h14V1H1zm0-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1z"/><path d="M2 2h5v5H2V2zm0 7h5v5H2V9z"/></g>
    </StyledIcon>
  )

export default styled(RepeatVerticaly)``
  