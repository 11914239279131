import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const GeoTag = props => (
    <StyledIcon viewBox="0 0 18 24" {...props}>
      <g><path d="M10.07 0C4.46 0 0 4.643 0 10.484c0 2.845 1.007 5.541 3.021 7.488.144.15 5.898 5.541 6.042 5.691.576.45 1.439.45 1.87 0 .144-.15 6.043-5.541 6.043-5.691 2.014-1.947 3.02-4.643 3.02-7.488C20.14 4.643 15.682 0 10.07 0zM10 13c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"  fillRule="evenodd"/></g>
    </StyledIcon>
  )

export default styled(GeoTag)``
