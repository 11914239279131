import styled from 'styled-components'
import { resetCSS } from '../../../helpers'

/**
 * Adjust the border color accordingly if it's focused and not readonly.
 */
const borderColor = (error, isFocus, readOnly, colors) => {
  if (error) {
    return colors.borderError
  }
  if (isFocus && !readOnly) {
    return colors.borderFocus
  }

  return colors.border
}

/*
  adjust the border Color in Hover accordingly if it's disabled focused and readonly.
 */
const borderColorHover = (disabled, error, isFocus, readOnly, colors) => {
  if (error) {
    return colors.borderError
  }
  if (isFocus && !readOnly) {
    return colors.borderFocus
  }
  if (disabled || readOnly) {
    return colors.border
  }

  return colors.borderHover
}

const Container = styled.div.attrs(resetCSS)`
  display: flex;
  flex-grow: 1;
  &:before {
    pointer-events: none;
    content: ' ';
    position: absolute;
    border-radius: ${({ theme }) => theme.globals.borderRadius.borderRadius1};
    border: ${({ theme }) => theme.globals.borderWidth.borderWidth1} solid
      ${({ theme }) => theme.components.textArea.border};
    border-color: ${({ error, isFocus, readOnly, theme }) =>
      borderColor(error, isFocus, readOnly, theme.components.textArea)};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  display: flex;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.components.textArea.background};
  width: auto;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};

  &:hover {
    &:before {
      border-color: ${({ disabled, error, isFocus, readOnly, theme }) =>
        borderColorHover(disabled, error, isFocus, readOnly, theme.components.textArea)};
    }
  }
`

export default Container
