import styled from 'styled-components'
import { resetCSS } from '../../../helpers'
import SecondaryButton from '../../Button/styles/SecondaryButton'

const SecondaryLink = styled(SecondaryButton).attrs(resetCSS)`
  text-decoration: none;

  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
  }
`

export default SecondaryLink
