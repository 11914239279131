import PropTypes from 'prop-types'
import styled from 'styled-components'
import { margins } from 'component-library/helpers'

import { Body2, Header4 } from '../Typography'
import BOX_MODES from './config/BoxModes'
import Button from '../Button'
import Image from '../Image'
import Link from '../Link'
import paddingButtonStyle from '../Button/helpers/paddingButtonStyle'

const Box = styled('div')`
  ${margins};
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  ${({ mode, theme }) => paddingButtonStyle(BOX_MODES[mode].small, theme)};

  ${({ mode, theme: { components, globals, sizes }, withBorder = false }) => `
    padding: ${sizes.paddings[BOX_MODES[mode].padding] || sizes[BOX_MODES[mode].padding]};
    border: ${
      withBorder
        ? `${globals.borderStyle.solid} ${globals.borderWidth.bW1} ${components.box.border}`
        : 'none'
    };
    ${Body2}, ${Header4} {
      margin-top: ${sizes.s3};
    }

    ${Link} {
      margin-top: ${sizes.s5};
    }

    ${Button} {
      margin-top: ${sizes.s5};
    }

    ${Image} {
      width: ${globals.imageSize[BOX_MODES[mode].imageSize].width};
      height: ${globals.imageSize[BOX_MODES[mode].imageSize].height};
    }
  `}
`


export default Box
