// @noSnapshot
import React from 'react'

import PropTypes from 'prop-types'

import LanguagesDesktop from './LanguagesDesktop'
import LanguagesMobile from './LanguagesMobile'

import { useLanguage, useNoResponsive } from '../hooks'

function Languages({ onChange }) {
  const [, , onLanguageChange] = useLanguage()

  const handleChange = (lang) => {
    onLanguageChange(lang)
    return onChange && onChange(lang)
  }

  const [hasNoResponsive] = useNoResponsive()

  return (
    <>
      <LanguagesDesktop handleChange={handleChange} hasNoResponsive />
      {!hasNoResponsive && <LanguagesMobile handleChange={handleChange} />}
    </>
  )
}

Languages.propTypes = {
  // Callback fired when language changed
  onChange: PropTypes.func,
}

export default Languages
