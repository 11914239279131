import * as React from 'react'
import styled from 'styled-components'
import ToolTipContent from './components/ToolTipContent'
import AnimContainer from './styles/AnimContainer'
import Wrapper from './styles/Wrapper'
import StickyPortal from '../StickyPortal'

let tooltipIdCounter = 0

const animaterStyle = { pointerEvents: 'none', width: '100%' }

class ToolTip extends React.Component{
  static defaultProps = {
    delay: 0,
    direction: 'top',
  }

  /**
   * Create an unique id so multiple tooltips can be used in the same view.
   */
  static uniqueID(prefix) {
    const id = `${++tooltipIdCounter}`
    return prefix ? prefix + id : id
  }

  // Store the timeout ID for tooltip activation.
  _activateTimeout = null

  state = {
    active: this.props.isActive || false,
    propsIsActive: this.props.isActive || false,
    id: this.props.id || ToolTip.uniqueID('tooltip-'),
    launchAnim: false,
  }

  componentDidUpdate(prevProps, prevState) {
    const { active } = this.state
    if (prevState.active !== active) {
      this.setState({ launchAnim: active })
    }
  }

  /**
   * Set tooltip as active.
   */
  handleMouseOver = () => {
    const { delay, isActive, message } = this.props

    if (!!message && !isActive && !this._activateTimeout) {
      this._activateTimeout = setTimeout(() => {
        this._activateTimeout = null
        this.setState({ active: true })
      }, delay)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isActive !== prevState.propsIsActive) {
      return {
        active: nextProps.isActive,
        propsIsActive: nextProps.isActive,
      }
    }
    if (!nextProps.message) {
      return { active: false }
    }

    return null
  }

  /**
   * Set tooltip as not active.
   */
  handleMouseLeave = () => {
    const { isActive } = this.props
    if (!isActive) {
      // Reset the timeout for tooltip activation.
      if (!!this._activateTimeout) {
        clearTimeout(this._activateTimeout)
        this._activateTimeout = null
      }

      // Close the modal.
      this.setState({ active: false })
    }
  }

  /**
   * Override the onClick function to pass the tooltipId as a parameter
   */
  onClickId = () => {
    const { id } = this.state
    const { onClick } = this.props
    onClick && onClick(id)
  }

  getDirection = () => {
    const { direction } = this.props
    if (this.isVerticalDirection()) {
      return this.isBottomDirection() ? '-y2' : 'y2'
    }
    if (this.isHorizontalDirection()) {
      return direction === 'right' ? '-x2' : 'x2'
    }
    return undefined
  }

  isBottomDirection = () => {
    const { direction } = this.props
    return direction === 'bottom' || direction === 'bottomLeft' || direction === 'bottomRight'
  }

  isHorizontalDirection = () => {
    const { direction } = this.props
    return direction === 'right' || direction === 'left'
  }

  isTopDirection = () => {
    const { direction } = this.props
    return direction === 'top' || direction === 'topLeft' || direction === 'topRight'
  }

  isVerticalDirection = () => this.isTopDirection() || this.isBottomDirection()

  /**
   * Add tooltip 'aria-describedby' attribute to child element.
   */
  addDescribedBy(tooltipID) {
    return React.Children.map(this.props.children, e =>
      React.cloneElement(e, {
        'aria-describedby': tooltipID,
      }),
    )
  }

  render() {
    const {
      _ref,
      className,
      'data-testid': dataTestId,
      direction,
      innerStyle,
      isOpened,
      locator,
      message,
      noWrap,
      refreshEventOff,
      refreshEventOn,
      style,
      zIndex,
    } = this.props
    const { active, id: tooltipID, launchAnim } = this.state

    const opened = isOpened || active
    const realLaunchAnim = isOpened || launchAnim

    return (
      <Wrapper
        className={className}
        data-testid={dataTestId}
        id={tooltipID}
        onClick={this.onClickId}
        onMouseOver={this.handleMouseOver}
        onPointerLeave={this.handleMouseLeave}
        role="tooltip"
        style={{ ...style, position: 'relative' }}
      >
        {!!message && (
          <StickyPortal
            isOpened={opened}
            position={direction ? (direction.split(/(?=[A-Z])/)[0]) : 'top'}
            refreshEventOff={refreshEventOff}
            refreshEventOn={refreshEventOn}
            zIndex={zIndex}
          >
            <AnimContainer
              direction={this.getDirection()}
              isActive={realLaunchAnim}
              style={animaterStyle}
            >
              <ToolTipContent
                active={opened}
                data-testid={dataTestId ? `${dataTestId}-message` : ''}
                direction={direction}
                locator={locator}
                message={message}
                noWrap={noWrap}
                ref={_ref}
                style={innerStyle}
              />
            </AnimContainer>
          </StickyPortal>
        )}

        {this.addDescribedBy(tooltipID)}
      </Wrapper>
    )
  }
}

export { ToolTip as DocToolTip }
export default styled(
  React.forwardRef((props, ref) => <ToolTip _ref={ref} {...props} />),
)``
