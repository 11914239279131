import styled from 'styled-components'

import InputStyled from './InputStyled'

const SearchSelectInputContainer = styled('div')`
  display: inline-flex;
  flex: 1 0 0;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.sizes.s1};
  padding-top: ${({ theme }) => theme.sizes.s1};

  ${InputStyled} {
    flex-grow: 1;
  }
`

export default SearchSelectInputContainer
