import styled from 'styled-components'
import LinkButton from '../../../LinkButton'

const StyledLinkButton = styled(LinkButton)`
  button {
    padding-left: ${({ theme }) => theme.sizes.s2};
    padding-right: ${({ theme }) => theme.sizes.s1};
  }
`

export default StyledLinkButton
