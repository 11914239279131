import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Tablet = props => (
    <StyledIcon viewBox="0 0 14 16" {...props}>
      <g><path d="m12.50004,0.00002c0.827,0 1.5,0.673 1.5,1.5l0,0l0,13c0,0.827 -0.673,1.5 -1.5,1.5l0,0l-11,0c-0.828,0 -1.5,-0.673 -1.5,-1.5l0,0l0,-13c0,-0.827 0.672,-1.5 1.5,-1.5l0,0l11,0zm0.5,13l-12,0l0,1.5c0,0.275 0.224,0.5 0.5,0.5l0,0l11,0c0.275,0 0.5,-0.225 0.5,-0.5l0,0l0,-1.5zm-0.5,-12l-11,0c-0.276,0 -0.5,0.224 -0.5,0.5l0,0l0,10.5l12,0l0,-10.5c0,-0.276 -0.225,-0.5 -0.5,-0.5l0,0zm-5.0004,2c0.276,0 0.5,0.224 0.5,0.5c0,0.276 -0.224,0.5 -0.5,0.5l0,0l-1,0c-0.276,0 -0.5,-0.224 -0.5,-0.5c0,-0.276 0.224,-0.5 0.5,-0.5l0,0l1,0z"/></g>
    </StyledIcon>
  )

export default styled(Tablet)``
