import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const LineChart = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g fillRule="nonzero">
        <path d="M3.937 5.579l.421.337.442-1.001L7.05 6.6a.75.75 0 0 0 1.074-.184l3.416-5.124L10.292.46 7.316 4.924 4.95 3.15a.75.75 0 0 0-1.136.296L3.012 5.25c.336.003.662.119.925.329zM2.474 8.25H1.68L.51 10.881l1.371.609 1.218-2.74z" />
        <path d="M6.75 10.5a.75.75 0 0 1-.469-.164L2.737 7.5H0V6h3a.75.75 0 0 1 .469.164L6.81 8.84l3.946-2.368.772 1.286-4.393 2.636a.75.75 0 0 1-.386.107z" />
      </g>
    </StyledIcon>
  )

export default styled(LineChart)``
