import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const BackArrow = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M7.89 11.047L4.933 7.881H16V5.119H4.934l2.955-3.166L6.067 0 0 6.5 6.067 13z"/></g>
    </StyledIcon>
  )

export default styled(BackArrow)``
  