import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Slack = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M4.941 5.502l1.603-.537.518 1.546-1.603.537z"/><path d="M4.941 5.502l1.603-.537.518 1.546-1.603.537z"/><path d="M11.5 4.35C10.26.22 8.48-.73 4.35.5S-.73 3.52.5 7.65s3 5.08 7.15 3.85 5.08-3.02 3.85-7.15zM9.42 7l-.78.26.27.81a.62.62 0 0 1-.39.79.54.54 0 0 1-.21 0 .64.64 0 0 1-.58-.42l-.27-.81-1.6.54.26.83a.62.62 0 0 1-.39.79.54.54 0 0 1-.21 0 .64.64 0 0 1-.58-.42l-.27-.81-.78.26a.54.54 0 0 1-.21 0 .64.64 0 0 1-.58-.42.62.62 0 0 1 .39-.79l.78-.26-.51-1.47-.76.26a.54.54 0 0 1-.21 0 .64.64 0 0 1-.58-.42.62.62 0 0 1 .38-.72l.78-.26-.27-.84a.62.62 0 0 1 1.18-.39l.27.81 1.6-.54L5.88 3a.62.62 0 0 1 1.18-.39l.27.81.78-.26A.62.62 0 1 1 8.5 4.3l-.78.26.52 1.54.76-.25A.62.62 0 0 1 9.42 7z"/></g>
    </StyledIcon>
  )

export default styled(Slack)``
  