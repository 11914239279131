import styled from 'styled-components'

import NewsletterForm from '@components/NewsletterSubscriber/styles/NewsletterForm'
import { ExternalLink } from '@styles/StyledLink'
import { Header4Bold } from '@typography'

export const TextPart = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  ${ExternalLink} {
    text-decoration: underline;
  }

  ${Header4Bold} {
    color: ${({ theme }) => theme.colors.textLight};
    padding-bottom: ${({ theme }) => theme.sizes.s4};
  }

  ${NewsletterForm} {
    max-width: 100%;
  }
`
