// Set a cookie for current domain level, default max age is 90 days
export const setDomainCookie = (
  cookieName,
  cookieValue,
  maxAge = 90 * 86400,
) => {
  document.cookie = `${cookieName}=${cookieValue};domain=${location.hostname.replace(
    /^www\./i,
    '',
  )};path=/;max-age=${maxAge}`
}
