import { useEffect, useState } from 'react'
import { get } from 'lodash'

const useMediaControls = (playerRef, setDuration, setCurrentTime) => {
  const [player, setPlayer] = useState()
  const [playing, setPlaying] = useState(player && !player.paused)

  useEffect(() => {
    if (playerRef) {
      setPlayer(get(playerRef, 'current.audioEl.current'))
    }
  }, [playerRef])

  useEffect(() => {
    if (player) {
      player.onplay = play
      player.onpause = pause
      player.ontimeupdate = () => setCurrentTime(player.currentTime)
    }
  }, [player])

  const play = () => {
    if (!player) return
    // Set duration when the player is ready to play
    setDuration(player.duration)
    player.play()
    setPlaying(true)
  }

  const pause = () => {
    if (!player) return
    player.pause()
    setPlaying(false)
  }

  const updateTime = (newTime) => {
    if (!player) return
    player.currentTime = newTime
  }

  return [playing, play, pause, updateTime]
}

export default useMediaControls
