import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Html = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M9.796 6.217l1.069 1.06L9.796 8.34l.771.767 1.841-1.828-1.84-1.827-.772.766zM7.615 5.45L5.774 7.278l1.84 1.828.771-.767-1.068-1.061 1.068-1.061-.77-.766zm-6.16-1.784H0v9.389a1.45 1.45 0 0 0 1.455 1.444h10.909v-1.444H1.454v-9.39zM13.392 1.5H4.364c-.803 0-1.455.649-1.455 1.444v7.223a1.45 1.45 0 0 0 1.455 1.444h10.181A1.45 1.45 0 0 0 16 10.167V4.09L13.392 1.5zm1.154 8.667H4.364V2.944h8v2.167h2.181l.001 5.056z" fill="#757575"/></g>
    </StyledIcon>
  )

export default styled(Html)``
  