import { useEffect } from 'react'
import { useTheme } from 'styled-components'

import injectStyleInHead from './helpers/injectStyleInHead'
import resetNavStyle from './styles/resetNavStyle'
import resetGlobalStyle from './styles/resetGlobalStyle'

const GLOBAL_STYLE_ID = 'helmet-global-style'
const NAV_STYLE_ID = 'helmet-nav-style'

/**
 * This provider injects global styles and CDN fonts as the very
 * first children of document.head.
 */
const HeadInjector = () => {
  const { globals = {} } = useTheme()
  const { global: globalStyle, nav } = globals?.cssClearer

  useEffect(() => {
    globalStyle && injectStyleInHead(resetGlobalStyle, GLOBAL_STYLE_ID)
    nav && injectStyleInHead(resetNavStyle, NAV_STYLE_ID)
  }, [globalStyle, nav])

  return null
}

export default HeadInjector
