import * as React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { noop } from 'lodash'

import LabelledWrapper from '../LabelledWrapper'
import TextAreaContainer from './components/TextAreaContainer'

class TextArea extends React.Component {
  state = {
    charNumber: this.props.defaultValue ? this.props.defaultValue.length : 0,
    focus: false,
    propDefaultValue: '',
    propValue: '',
  }

  onChange = (value) => {
    const { onChange: onChangeProp } = this.props

    this.setState(
      {
        charNumber: value.length,
      },
      () => onChangeProp && onChangeProp(value),
    )
  }

  onBlur = (e) => {
    const { onBlur } = this.props
    this.setState({
      focus: false,
    })

    onBlur && onBlur(e)
  }

  onFocus = (e) => {
    const { onFocus } = this.props
    this.setState({
      focus: true,
    })

    onFocus && onFocus(e)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { defaultValue, readOnly, value } = nextProps

    if (readOnly && value !== prevState.propValue) {
      return {
        charNumber: value ? value.length : 0,
        propValue: value,
      }
    }
    if (!readOnly && defaultValue !== prevState.propDefaultValue) {
      return {
        charNumber: defaultValue ? defaultValue.length : 0,
        propDefaultValue: defaultValue,
      }
    }

    return null
  }

  render() {
    const {
      className,
      error,
      errorMessage,
      id,
      information,
      informationMessage,
      label,
      labelRightChildren,
      locator,
      maxLength,
      optionalMessage,
      required,
      setRef,
      style,
      sublabel,
      textAreaRef,
    } = this.props

    const { charNumber, focus } = this.state

    setRef && setRef(this)

    return (
      <LabelledWrapper
        charNumber={charNumber}
        className={className}
        error={error}
        errorMessage={errorMessage}
        focus={focus}
        id={id}
        information={information}
        informationMessage={informationMessage}
        label={label}
        labelRightChildren={labelRightChildren}
        maxLength={maxLength}
        optionalMessage={optionalMessage}
        required={required}
        style={style}
        sublabel={sublabel}
      >
        <TextAreaContainer
          {...this.props}
          locator={locator}
          onBlur={this.onBlur}
          onChange={this.onChange}
          onFocus={this.onFocus}
          ref={textAreaRef}
        />
      </LabelledWrapper>
    )
  }
}

TextArea.defaultProps = {
  setRef: noop,
}

TextArea.propTypes = {
  /** Indicate that the text area will automatically resize itself when the text is longer than one line. */
  autoResize: PropTypes.bool,
  /**
   * Indicate if we have no limit to the maximum number of characters. maxLength is, in that case,
   * just an indicator.
   */
  canExceedMaxLength: PropTypes.bool,
  /** Data-testid */
  'data-testid': PropTypes.string,
  /** Specifies default value of element. */
  defaultValue: PropTypes.string,
  /** Is the text area in error ? */
  error: PropTypes.bool,
  /** The error message, in case the text area is in error. */
  errorMessage: PropTypes.string,
  /** The ID of the text area. */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** If the information message is always displayed or if it's only when the input is focused */
  information: PropTypes.bool,
  /** An information message to be displayed if we aren't in error. */
  informationMessage: PropTypes.string,
  /** The text area label. */
  label: PropTypes.string,
  /** Component placed to the right of the label */
  labelRightChildren: PropTypes.element,
  /** Locators for QAs. */
  locator: PropTypes.string,
  /** The max height of the text area, in pixels, when autoResize prop is on. */
  maxHeight: PropTypes.number,
  /**
   * The maximum number of characters for the indicator. If it is undefined, then no indicator
   * will be displayed.
   */
  maxLength: PropTypes.number,
  /** The min height of the text area, in pixels, when autoResize prop is on. */
  minHeight: PropTypes.number,
  /** Called when the text area looses focus. */
  onBlur: PropTypes.func,
  /** Called when a change happens in the value of the text area. */
  onChange: PropTypes.func,
  /** Called when the text area gains focus. */
  onFocus: PropTypes.func,
  /** Called when an text area is entered in the text area component. */
  onInput: PropTypes.func,
  /** Called when a key is down. */
  onKeyDown: PropTypes.func,
  /** Called when a key is up. */
  onKeyUp: PropTypes.func,
  /** Called when the text area changes its size in autoResize mode. */
  onResize: PropTypes.func,
  /** If this component is optional, this message will be rendered. */
  optionalMessage: PropTypes.string,
  /** Specifies placeholder of text area. */
  placeholder: PropTypes.string,
  /** Specifies text area field is read-only. */
  readOnly: PropTypes.bool,
  /** If this component is required, it will indicate it. */
  required: PropTypes.bool,
  /** Used to set a reference over this text area. */
  setRef: PropTypes.func,
  /** The inline style. */
  style: PropTypes.object,
  /** The text area sublabel. */
  sublabel: PropTypes.string,
  /** The text area forwarded ref. */
  textAreaRef: PropTypes.func,
  /** The text area type. */
  type: PropTypes.string,
  /** Override the inner value of the text area. */
  value: PropTypes.string,
}

const DefaultComponent = styled(
  React.forwardRef((props, ref) => <TextArea textAreaRef={ref} {...props} />),
)``

export { TextArea as BaseTextArea }
export default DefaultComponent
