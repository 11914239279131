import styled from 'styled-components'
import { resetCSS } from 'component-library/helpers'

import Button from '../../Button'

const PaginationNumbersWidth = '28px'
const PaginationNav = styled.nav.attrs(resetCSS)`
  align-items: baseline;
  display: flex;
  justify-content: space-between;

  ${({ isLoading, theme }) => `
    color: ${theme.colors.grey};
    opacity: ${isLoading ? theme.globals.opacity.o1 : theme.globals.opacity.o3};
    padding: ${theme.sizes.s12} ${theme.sizes.s5};
     ${Button} {
    font-weight: normal;
  }

  ${Button}:not(:last-of-type).is-not-toggle {
    margin-right: ${theme.sizes.s2};
  }

  ${Button}.is-selected {
    background-color:  ${theme.components.datatable.pagination.backgroundSelected};
    }

  ${Button}.is-selected.is-not-toggle {
    color: ${theme.components.datatable.pagination.colorSelected};
     width: ${PaginationNumbersWidth};
  }

  ${Button}:hover:not(.is-loading):not(.is-disabled):not(:active).is-selected,
  ${Button}:active:not(.is-disabled):not(.is-loading).is-selected,
  ${Button}:focus:not(:active):not(.is-loading).is-selected {
    background-color:  ${theme.components.datatable.pagination.backgroundSelected};
  }

  @media (max-width: ${theme.responsive.sm.breakpoint}px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  `}
`

export default PaginationNav
