import styled from 'styled-components'

const DivFillAvailableSpace = styled.div`
  display: flex;
  flex-grow: 1;
  main {
    width: 100%;
  }
`

export default DivFillAvailableSpace
