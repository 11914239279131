import React from 'react'
import styled from 'styled-components'

const ArrowNextSVG = (props) => (
  <svg
    fill='none'
    height='16'
    viewBox='0 0 16 16'
    width='16'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g id='Arrow-Next'>
      <g id='Vector'>
        <path
          clipRule='evenodd'
          d='M8.29289 2.29289C8.68342 1.90237 9.31658 1.90237 9.70711 2.29289L14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L9.70711 13.7071C9.31658 14.0976 8.68342 14.0976 8.29289 13.7071C7.90237 13.3166 7.90237 12.6834 8.29289 12.2929L12.5858 8L8.29289 3.70711C7.90237 3.31658 7.90237 2.68342 8.29289 2.29289Z'
          fill='#007874'
          fillRule='evenodd'
        />
        <path
          clipRule='evenodd'
          d='M1 8C1 7.44772 1.44772 7 2 7H14C14.5523 7 15 7.44772 15 8C15 8.55228 14.5523 9 14 9H2C1.44772 9 1 8.55228 1 8Z'
          fill='#007874'
          fillRule='evenodd'
        />
      </g>
    </g>
  </svg>
)

export default styled(ArrowNextSVG)``
