import React from 'react'
import styled from 'styled-components'

const DashSVG = () => (
  <svg
    width='16'
    height='20'
    viewBox='0 0 16 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <mask
      id='mask0_280_5162'
      maskUnits='userSpaceOnUse'
      x='0'
      y='2'
      width='16'
      height='16'
    >
      <rect y='2' width='16' height='16' fill='white' />
    </mask>
    <g mask='url(#mask0_280_5162)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 9V11H4V9H12Z'
        fill='#B3BDC4'
      />
    </g>
  </svg>
)

export default styled(DashSVG)``
