import React from 'react'
import styled from 'styled-components'

const BadgeCheckSVG = (props) => (
  <svg
    height='28'
    fill='none'
    viewBox='0 0 28 28'
    width='28'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Badge Check</title>
    <path
      clipRule='evenodd'
      d='M14.0001 23.3333C19.1547 23.3333 23.3334 19.1546 23.3334 14C23.3334 8.8453 19.1547 4.66663 14.0001 4.66663C8.84542 4.66663 4.66675 8.8453 4.66675 14C4.66675 19.1546 8.84542 23.3333 14.0001 23.3333ZM19.4063 12.4062C19.9758 11.8366 19.9758 10.9133 19.4063 10.3438C18.8368 9.77425 17.9134 9.77425 17.3439 10.3438L12.8334 14.8542L10.6563 12.6771C10.0868 12.1076 9.1634 12.1076 8.59388 12.6771C8.02437 13.2466 8.02437 14.17 8.59388 14.7395L11.8022 17.9478C12.3717 18.5173 13.2951 18.5173 13.8646 17.9478L19.4063 12.4062Z'
      fill='black'
      fillRule='evenodd'
    />
  </svg>
)

export default styled(BadgeCheckSVG)``
