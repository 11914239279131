import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const HtmlTag = props => (
    <StyledIcon viewBox="0 0 24 20" {...props}>
      <g><path d="M15.6.09c.75.284 1.2.995.9 1.848l-6 17.066c-.3.57-.9.996-1.5.996-.15 0-.3 0-.45-.142-.75-.285-1.2-.996-.9-1.85l6-17.066c.3-.71 1.05-1.137 1.95-.853zm3.45 4.692l4.5 4.267c.6.569.6 1.422 0 1.991l-4.5 4.267-2.1-1.991 3.45-3.271-3.45-3.272 2.1-1.99zm-14.1 0l2.1 1.991-3.45 3.272 3.45 3.27-2.1 1.992-4.5-4.267c-.6-.569-.6-1.422 0-1.991l4.5-4.267z"  fillRule="evenodd"/></g>
    </StyledIcon>
  )

export default styled(HtmlTag)``
