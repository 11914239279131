import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Hamburger = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M1.1 0h13.8a1.1 1.1 0 0 1 0 2.2H1.1a1.1 1.1 0 0 1 0-2.2zm0 6.9h13.8a1.1 1.1 0 0 1 0 2.2H1.1a1.1 1.1 0 0 1 0-2.2zm0 6.9h13.8a1.1 1.1 0 0 1 0 2.2H1.1a1.1 1.1 0 0 1 0-2.2z"/></g>
    </StyledIcon>
  )

export default styled(Hamburger)``
  