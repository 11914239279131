import { css } from 'styled-components'

/**
 * Inject this style into your component so it can support margins props :
 * - mb (margin-bottom)
 * - ml (margin-left)
 * - mr (margin-right)
 * - mt (margin-top)
 *
 * Negative values not supported. Correct values are for example : s4, 150px, 10%
 */

const margins = ({ mb, ml, mr, mt, theme }) => css`
  ${mb && `margin-bottom: ${theme.sizes.margins[mb] || theme.sizes[mb] || mb}`};
  ${ml && `margin-left: ${theme.sizes.margins[ml] || theme.sizes[ml] || ml}`};
  ${mr && `margin-right: ${theme.sizes.margins[mr] || theme.sizes[mr] || mr}`};
  ${mt && `margin-top: ${theme.sizes.margins[mt] || theme.sizes[mt] || mt}`};
`

export default margins
