import styled from 'styled-components'
import { CarretDown } from 'component-library/icons'

/**
 * The carret icon, for the select.
 */
const SelectCarret = styled(CarretDown)`
  ${({ isOpened, theme }) => `
    transform: rotate(${isOpened ? 180 : 0}deg);
    transition: transform ${theme.animations.velocities.v4} ${theme.animations.easing.standard} 0s;
  `}
`

export default SelectCarret
