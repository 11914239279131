import React from 'react'
import styled from 'styled-components'

const ArticleCheckSVG = (props) => (
  <svg
    width='14px'
    height='12px'
    viewBox='0 0 14 12'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <title>Check</title>
    <g
      id='Page-1'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
      strokeLinecap='square'
    >
      <polyline
        id='Check'
        stroke='#131B20'
        strokeWidth='3'
        points='3 6 6 9 11 3'
      ></polyline>
    </g>
  </svg>
)

export default styled(ArticleCheckSVG)``
