import styled from 'styled-components'

import { Body2 } from '../../Typography'

const LineNumbers = styled(Body2.withComponent('div'))`
  box-sizing: border-box;
  min-height: ${({ minH }) => minH};
  max-height: ${({ maxH }) => maxH};
  overflow: hidden;
  padding: ${({ theme }) => `${theme.sizes.s3} ${theme.sizes.s12}`};
  background-color: ${({ theme }) => theme.colors.lightsmoke};
`

export default LineNumbers
