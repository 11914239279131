import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const VideoToolbar = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M0 1.5v11.556A1.45 1.45 0 0 0 1.455 14.5h13.09A1.45 1.45 0 0 0 16 13.056V1.5H0zm5.818 2.889L6.91 2.944h4L9.82 4.39h-4zM1.455 2.944H5.09L4 4.39H1.455V2.944zm13.091 10.112H1.455V5.833h13.09l.001 7.223zm-2.91-8.667l1.091-1.445h1.818V4.39h-2.909zm-5.09 2.889l3.636 2.166-3.637 2.167V7.278z"/></g>
    </StyledIcon>
  )

export default styled(VideoToolbar)``
  