import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const SMS = props => (
    <StyledIcon viewBox="-1 0 16 13" {...props}>
      <g>
        <path d="M12.6100909,-0.875 L1.38990909,-0.875 C0.138304385,-0.875 -0.875,0.142068338 -0.875,1.39507196 L-0.875,9.23785486 L-0.869778435,9.39319498 C-0.790144252,10.5738484 0.190454581,11.5079268 1.38990909,11.5079268 L1.857,11.507 L1.85761962,14.6631794 L6.449,11.507 L12.6100909,11.5079268 C13.8616956,11.5079268 14.875,10.4908585 14.875,9.23785486 L14.875,1.39507196 C14.875,0.142068338 13.8616956,-0.875 12.6100909,-0.875 Z M1.38990909,0.875 L12.6100909,0.875 C12.8937419,0.875 13.125,1.10711716 13.125,1.39507196 L13.125,9.23785486 C13.125,9.52580966 12.8937419,9.75792682 12.6100909,9.75792682 L5.90473436,9.75792682 L3.607,11.336 L3.60761962,9.75792682 L1.38990909,9.75792682 C1.10625814,9.75792682 0.875,9.52580966 0.875,9.23785486 L0.875,1.39507196 C0.875,1.10711716 1.10625814,0.875 1.38990909,0.875 Z"/>
        <polygon points="11.6791279 2.00850123 11.6791279 3.75850123 2.09625694 3.75850123 2.09625694 2.00850123"/>
        <polygon points="11.6791279 4.51837581 11.6791279 6.26837581 2.09625694 6.26837581 2.09625694 4.51837581"/>
        <polygon points="10.1069365 6.92367229 10.1069365 8.67367229 2.09625694 8.67367229 2.09625694 6.92367229"/>
      </g>
    </StyledIcon>
  )

export default styled(SMS)``