const fontFamilyPrimary = 'Roboto'
const fontFamilySecondary = 'Inconsolata, Monaco, Consolas, "Courier New"'

const fonts = {
  global: {
    fontFamilyRegular: fontFamilyPrimary,
    fontFamilySecondary: fontFamilySecondary,
  },
  body1: {
    fontSize: '16px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 400,
    lineHeight: '24px',
  },
  body1Medium: {
    fontSize: '16px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '24px',
  },
  body2: {
    fontSize: '14px',
    fontFamily: fontFamilyPrimary,
    lineHeight: '20px',
    fontWeight: 400,
  },
  body2Medium: {
    fontSize: '14px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '20px',
  },
  caption: {
    fontSize: '12px',
    fontFamily: fontFamilyPrimary,
    lineHeight: '16px',
    fontWeight: 400,
  },
  captionMedium: {
    fontWeight: 500,
  },
  header1: {
    fontSize: '48px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 900,
    lineHeight: '56px',
  },
  header2: {
    fontSize: '32px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '40px',
  },
  header3: {
    fontSize: '24px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '40px',
  },
  header4: {
    fontSize: '20px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 400,
    lineHeight: '32px',
  },
  header4Medium: {
    fontWeight: 500,
  },
  label: {
    fontSize: '14px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '20px',
  },
  paragraph: {
    fontSize: '14px',
    fontFamily: fontFamilyPrimary,
    lineHeight: '20px',
    fontWeight: 400,
  },
  smallText: {
    fontSize: '12px',
    fontFamily: fontFamilyPrimary,
    lineHeight: '16px',
    fontWeight: 400,
  },
  smallTextBold: {
    fontWeight: 500,
  },
  subsubtitle: {
    fontSize: '16px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '24px',
  },
  subtitle: {
    fontSize: '20px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '32px',
  },
  title: {
    fontSize: '24px',
    fontFamily: fontFamilyPrimary,
    fontWeight: 500,
    lineHeight: '40px',
  },
}

export default fonts
