import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const MultiPlatform = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M9.5 5h1V3.5a1 1 0 0 0-1-1H5v1h4.5zm-3 4H7V8H2.5V7h-1v1a1 1 0 0 0 1 1h3v.5H4v1h3.5v-1h-1zm-2-3H7v1H4.5zm7 0h-3a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-5a.5.5 0 0 0-.5-.5zM11 7v3H9V7zM4 5.5v-5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5zM3 5H1V1h2z"/><circle cx="2" cy="4" r=".5"/></g>
    </StyledIcon>
  )

export default styled(MultiPlatform)``
  