import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ServerAdd = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M7 8.64A7.93 7.93 0 0 1 4.5 9C2.23 9 1 8.29 1 8v-.7a7.93 7.93 0 0 0 3.5.7A9.51 9.51 0 0 0 7 7.68v-1A7.93 7.93 0 0 1 4.5 7C2.23 7 1 6.29 1 6v-.7a7.93 7.93 0 0 0 3.5.7A7.93 7.93 0 0 0 8 5.3V6h1V2C9 .69 6.74 0 4.5 0S0 .69 0 2v6c0 1.31 2.26 2 4.5 2A9.59 9.59 0 0 0 7 9.69zM4.5 1C6.77 1 8 1.71 8 2S6.77 3 4.5 3 1 2.29 1 2s1.23-1 3.5-1zM1 3.3a7.93 7.93 0 0 0 3.5.7A7.93 7.93 0 0 0 8 3.3V4c0 .29-1.23 1-3.5 1S1 4.29 1 4zm11 6.2h-1.5V8h-1v1.5H8v1h1.5V12h1v-1.5H12v-1z"/></g>
    </StyledIcon>
  )

export default styled(ServerAdd)``
  