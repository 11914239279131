import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ListView = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M0 0h16v3.2H0V0zm0 6.4h16v3.2H0V6.4zm0 6.4h16V16H0v-3.2z"/></g>
    </StyledIcon>
  )

export default styled(ListView)``
  