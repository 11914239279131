import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { ArrowRight } from 'component-library/icons'

import PaginationIcon from './PaginationIcon'
import { usePage } from '../contexts'

const PaginationLast = ({ onClick, ...rest }) => {
  const { currentPage, lastPage, goToLastPage } = usePage()

  const onGoToLastPage = () => {
    goToLastPage()
    onClick?.()
  }

  return currentPage < lastPage - 1 ? (
    <PaginationIcon icon={ArrowRight} onClick={onGoToLastPage} {...rest} />
  ) : null
}

PaginationLast.propTypes = {
  /**
   * The Button props.
   */
  '...rest': PropTypes.object,
  /**
   * Function called when the button-icon is clicked.
   */
  onClick: PropTypes.func,
}

export default styled(PaginationLast)``
