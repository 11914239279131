import styled, { css } from 'styled-components'
import { isKeyOf } from '../../../helpers/typePredicates'

// Css variable to radius right side.
const rightRadius = css`
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
`

// Css variable to radius left side.
const leftRadius = css`
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
`

// Apply Round on Badge Panel.
const round = (roundLeft, roundRight) => {
  const arrayOfCSS = []
  if (roundRight) {
    arrayOfCSS.push(rightRadius)
  }
  if (roundLeft) {
    arrayOfCSS.push(leftRadius)
  }
  return arrayOfCSS
}

// BadgePanel is a Panel with side rounded.
const BadgePanel = styled.div`
  ${({ roundLeft, roundRight }) => round(roundLeft, roundRight)};
  background-color: ${({ backgroundColor, theme }) =>
    (isKeyOf(backgroundColor, theme.colors) && theme.colors[backgroundColor]) ||
    backgroundColor};
  width: ${({ theme, width }) =>
    (isKeyOf(width, theme.globals.panelSize) && theme.globals.panelSize[width]) ||
    (isKeyOf(width, theme.sizes.widths) && theme.sizes.widths[width]) ||
    (isKeyOf(width, theme.sizes) && theme.sizes[width]) ||
    width};
  height: ${({ height, theme }) =>
    (isKeyOf(height, theme.sizes.heights) && theme.sizes.heights[height]) ||
    (isKeyOf(height, theme.sizes) && theme.sizes[height]) ||
    height};
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${({ theme }) => theme.sizes.s3};
  z-index: 1;
`

export default BadgePanel