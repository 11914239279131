import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const FileUpload = props => (
    <StyledIcon viewBox="0 0 11.85 12" {...props}>
      <g><path d="M2 4.5h4v1H2zm0 2h3v1H2z"/><path d="M8 3v2.5h1V2.29L6.71 0H1a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h5v-1H1V1h5v2zm1.5 4.29L7.15 9.65l.7.7L9 9.21V12h1V9.21l1.15 1.14.7-.7L9.5 7.29z"/></g>
    </StyledIcon>
  )

export default styled(FileUpload)``
  