const { useContext } = require('react')
const { get } = require('lodash')

const GlobalContext = require('../../components/Layout/GlobalContext')

const sitesConfig = {
  mailjet: {
    name: 'Mailjet',
    abbreviation: 'MJ',
    siteArchitectureName: 'Mailjet Site Architecture',
    baseUrl: 'https://www.mailjet.com',
    colors: {
      primary: 'violet',
      secondary: 'tropical',
      strongBackground: 'secondary80',
    },
    environmentId: 'GATSBY_MAILJET_WEBSITE',
    twitterId: '@mailjet',
  },
  mailgun: {
    name: 'Mailgun',
    abbreviation: 'MG',
    siteArchitectureName: 'Mailgun Site Architecture',
    baseUrl: 'https://www.mailgun.com',
    colors: {
      primary: 'raspberry',
      secondary: 'ocean',
      strongBackground: 'secondary90',
    },
    environmentId: 'GATSBY_MAILGUN_WEBSITE',
    twitterId: '@Mail_Gun',
  },
}

sitesConfig.inboxReady = {
  ...sitesConfig.mailgun,
  name: 'Inbox Ready',
  abbreviation: 'IR',
  siteArchitectureName: 'Mailgun Site Architecture',
  baseUrl: 'https://www.mailgun.com',
  colors: {
    primary: 'grass',
    secondary: 'ocean',
    strongBackground: 'secondary90',
  },
}
// TODO: remove and move it in sitesConfig declaration when the font/colors/aliases are known
sitesConfig.sinch = {
  ...sitesConfig.mailgun,
  name: 'Sinch',
  abbreviation: 'SIN',
  siteArchitectureName: 'Sinch Site Architecture',
  baseUrl: 'https://www.sinch.com',
  colors: {
    primary: 'honey',
    secondary: 'ocean',
    strongBackground: 'secondary90',
  },
  environmentId: 'GATSBY_SINCH_WEBSITE',
}

// Mailjet is currently the default website
const findCurrentSiteName = ({
  GATSBY_MAILGUN_WEBSITE,
  GATSBY_SINCH_WEBSITE,
}) => (
  GATSBY_MAILGUN_WEBSITE === '1'
    ? 'mailgun'
    : GATSBY_SINCH_WEBSITE === '1'
    ? 'sinch'
    : 'mailjet'
)

const findCurrentSiteConfig = () => (
  process.env.GATSBY_MAILGUN_WEBSITE === '1'
    ? sitesConfig.mailgun
    : process.env.GATSBY_SINCH_WEBSITE === '1'
    ? sitesConfig.sinch
    : sitesConfig.mailjet
)

// Used in graphQL request to filter the pages based on which brand is built
const getCurrentSite = (config = findCurrentSiteConfig()) =>
  get(config, 'name') || 'no site'

const getCurrentSiteArchitecture = (config = findCurrentSiteConfig()) => (
  get(config, 'siteArchitectureName') || 'no site architecture'
)

const getCurrentSiteColors = (config = findCurrentSiteConfig()) => (
  get(config, 'colors') || get(sitesConfig, 'mailjet.colors')
)

const styleForMailgun = () => {
  const { siteConfig } = useContext(GlobalContext)
  return siteConfig.name === sitesConfig.mailgun.name
}
const styleForMailjet = () => {
  const { siteConfig } = useContext(GlobalContext)
  return siteConfig.name === sitesConfig.mailjet.name
}
const styleForInboxReady = () => {
  const { alternativeTheme } = useContext(GlobalContext)
  return alternativeTheme === 'inboxReady'
}
const styleForSinch = () => {
  const { siteConfig } = useContext(GlobalContext)
  return siteConfig.name === sitesConfig.sinch.name
}

module.exports = {
  findCurrentSiteConfig,
  findCurrentSiteName,
  getCurrentSite,
  getCurrentSiteArchitecture,
  getCurrentSiteColors,
  sitesConfig,
  styleForInboxReady,
  styleForMailgun,
  styleForMailjet,
  styleForSinch,
}
