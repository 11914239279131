import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Images = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M11.4 9.6H3a.6.6 0 0 1-.6-.6V.6A.6.6 0 0 1 3 0h8.4a.6.6 0 0 1 .6.6V9a.6.6 0 0 1-.6.6zM3.6 8.4h7.2V1.2H3.6z"/><path d="M9.6 12h-9a.6.6 0 0 1-.6-.6v-9h1.2v8.4h8.4z"/><circle cx="5.4" cy="3" r="1.2"/><path d="M8.4 4.2L6.85 6.53 6 5.4 4.2 7.8h6L8.4 4.2z"/></g>
    </StyledIcon>
  )

export default styled(Images)``
  