import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const UpDown = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8 14.35l3.157-3.157.843.843-4 4-4-4 .843-.843L8 14.35zM4 4l4-4 4 4-.843.843L8 1.687 4.843 4.843 4 4z"/></g>
    </StyledIcon>
  )

export default styled(UpDown)``
  