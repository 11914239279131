import styled from 'styled-components'

const Divider = styled.div`
  ${({ theme }) => `
    height: ${theme.sizes.s0}; 
    background-color: ${theme.components.optionPanel.divider.color};
    margin-bottom: ${theme.sizes.s2};
    margin-top: ${theme.sizes.s2};
  `};
  width: 100%;
`
export default Divider
