import styled from 'styled-components'
import RoundNotification from '../../RoundNotification'

const StyledRoundNotification = styled(RoundNotification)`
  position: absolute;
  right: ${({ theme }) => `-${theme.sizes.s0}`};
  top: ${({ theme }) => `-${theme.sizes.s0}`};
`

export default StyledRoundNotification
