import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const OpenDoor = props => (
    <StyledIcon viewBox="0 0 12 14" {...props}>
      <g>
        <path
          d="M11.375 0H.875A.877.877 0 0 0 0 .875V10.5c0 .35.175.7.525.787l6.125 2.625c.262.088.612.088.787-.087.263-.175.438-.438.438-.7v-1.75h3.5c.525 0 .875-.35.875-.875V.875C12.25.35 11.9 0 11.375 0zM10.5 9.625H7.875V3.5c0-.35-.175-.7-.525-.788L5.162 1.75H10.5v7.875z"
          fillRule="evenodd"
        />
      </g>
    </StyledIcon>
  )

export default styled(OpenDoor)``
