import React from 'react'
import styled from 'styled-components'

const QuoteSVG = (props) => (
  <svg
    fill="none"
    height="48"
    viewBox="0 0 48 48"
    width="48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="quote">
      <path
        d="M24 40C15.1634 40 8 32.8366 8 24C8 15.1634 15.1634 8 24 8C32.8366 8 40 15.1634 40 24V40H24Z"
        fill="#A89BFA"
        id="Vector"
      />
    </g>
  </svg>

)

export default styled(QuoteSVG)``
