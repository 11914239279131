import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Check = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M5.412 15L0 9.756l1.674-1.64 3.572 3.453L14.16 1 16 2.467z"/></g>
    </StyledIcon>
  )

export default styled(Check)``
  