import React from 'react'

const CaseStudiesSVG = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.1091 28.2468L11.2115 25.0299C7.99887 23.2776 6 19.9104 6 16.2509V14C6 11.7909 7.79086 10 10 10H14.5351C15.2267 8.8044 16.5194 8 18 8H30C31.4806 8 32.7733 8.8044 33.4649 10H38C40.2091 10 42 11.7909 42 14V16.2509C42 19.9104 40.0011 23.2776 36.7885 25.0299L30.8909 28.2468C29.5572 29.5154 27.8737 30.4196 26 30.8V35H27C29.2091 35 31 36.7909 31 39H32.5C33.3284 39 34 39.6716 34 40.5C34 41.3284 33.3284 42 32.5 42H15.5C14.6716 42 14 41.3284 14 40.5C14 39.6716 14.6716 39 15.5 39H17C17 36.7909 18.7909 35 21 35H22V30.8C20.1263 30.4196 18.4428 29.5154 17.1091 28.2468ZM14 14H10V16.2509C10 18.4466 11.1993 20.4669 13.1269 21.5183L14.0517 22.0227C14.0175 21.6865 14 21.3453 14 21V14ZM34.8731 21.5183L33.9483 22.0227C33.9825 21.6865 34 21.3453 34 21V14H38V16.2509C38 18.4466 36.8007 20.4669 34.8731 21.5183Z'
      fill='#090E12'
    />
  </svg>
)

export default CaseStudiesSVG
