import React, { useEffect, useState } from 'react'
import { flow, isString } from 'lodash'
import AnimContainer from '../styles/AnimContainer'
import ButtonLabel from '../styles/ButtonLabel'
import Container from '../styles/Container'
import ContentWrapper from '../styles/ContentWrapper'
import InnerLabel from '../styles/InnerLabel'
import LabelledWrapper from '../../LabelledWrapper'
import OptionPanelSelect from './OptionPanelSelect'
import SelectCarret from '../styles/SelectCarret'
import SelectContainer from '../styles/SelectContainer'
import SelectItemWrapper from '../styles/SelectItemWrapper'
import SelectLoader from '../styles/SelectLoader'
import StickyPortal from '../../StickyPortal'
import { getSelectedOptionsString } from '../helpers'


const ellipsisInnerStyle = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}
const ellipsisOuterStyle = { overflow: 'hidden' }

const Select = ({
  'data-testid-carret': dataTestIdCarret,
  'data-testid-loader': dataTestIdLoader,
  'data-testid': dataTestId,
  className,
  disabled,
  ellipsis,
  error,
  errorMessage,
  inline,
  innerLabel,
  isFocus = false,
  isLoading,
  isOpened = false,
  isPanelWidthCustom,
  isPortal = true,
  label,
  limitOptionsHeight,
  locator,
  multi,
  noResultText,
  open,
  optimize,
  options,
  panelStyle,
  renderSelectedArray = [],
  selectedIcon,
  setOptionPanelRef,
  setWrapperRef,
  showSelectCarret = true,
  small,
  style,
  toggle,
}) => {
  // Generate the container content by flowing through all renderSelected functions.
  const containerContent = flow(renderSelectedArray)([])
  const [launchAnim, setLaunchAnim] = useState(false)

  const selectedOptionsString = options ? getSelectedOptionsString(options) : options

  useEffect(() => {
    setLaunchAnim(isOpened)
  }, [isOpened])

  return (
    <LabelledWrapper
      className="select-labelled-wrapper"
      disabled={disabled}
      error={error}
      errorMessage={errorMessage}
      label={label}
    >
      <div ref={setWrapperRef}>
        <SelectContainer className={className} style={style}>
          <Container
            className={locator}
            data-testid={dataTestId}
            disabled={disabled}
            error={error}
            inline={inline}
            isFocus={isFocus}
            isLoading={isLoading}
            key="container"
            onClick={toggle}
            onKeyPress={open}
            small={small}
          >
            <input type="hidden" value={selectedOptionsString} />
            <ContentWrapper
              multi={multi}
              small={inline || small}
              style={ellipsis ? ellipsisOuterStyle : undefined}
            >
              {!!innerLabel && <InnerLabel>{innerLabel}</InnerLabel>}
              {!!containerContent[0] && isString(containerContent[0]) ? (
                <ButtonLabel style={ellipsis ? ellipsisInnerStyle : undefined}>
                  {containerContent[0]}
                </ButtonLabel>
              ) : (
                containerContent
              )}
            </ContentWrapper>
            {isLoading && (
              <SelectItemWrapper data-testid={dataTestIdLoader} inline={inline} mr="s4">
                <SelectLoader size="s4" />
              </SelectItemWrapper>
            )}
            {!isLoading && showSelectCarret && (
              <SelectItemWrapper
                data-testid={dataTestIdCarret}
                inline={inline}
                mr={small ? 's3' : 's4'}
              >
                <SelectCarret isOpened={isOpened} size="s3" />
              </SelectItemWrapper>
            )}
          </Container>

          {/* Options part. */}
          {/* The options stays in mailjet theme (even if the select is in darkTheme) */}
          <div style={{ position: 'relative' }}>
            {isPortal ? (
              <StickyPortal isOpened={isOpened} position="bottom">
                <AnimContainer isOpened={launchAnim} isPortal={isPortal}>
                  <OptionPanelSelect
                    ellipsis={ellipsis}
                    isPanelWidthCustom={isPanelWidthCustom}
                    limitOptionsHeight={limitOptionsHeight}
                    noResultText={noResultText}
                    optimize={optimize}
                    options={options}
                    panelStyle={panelStyle}
                    selectedIcon={selectedIcon}
                    setOptionPanelRef={setOptionPanelRef}
                  />
                </AnimContainer>
              </StickyPortal>
            ) : (
              <AnimContainer isOpened={isOpened} isPortal={isPortal}>
                <OptionPanelSelect
                  ellipsis={ellipsis}
                  isPanelWidthCustom={isPanelWidthCustom}
                  limitOptionsHeight={limitOptionsHeight}
                  noResultText={noResultText}
                  optimize={optimize}
                  options={options}
                  panelStyle={panelStyle}
                  selectedIcon={selectedIcon}
                  setOptionPanelRef={setOptionPanelRef}
                />
              </AnimContainer>
            )}
          </div>
        </SelectContainer>
      </div>
    </LabelledWrapper>
  )
}



export default Select
