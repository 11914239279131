import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ChevronLeft = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M6.525 7.96L13 14.328 11.279 16 3 7.96 11.279 0 13 1.672z"/></g>
    </StyledIcon>
  )

export default styled(ChevronLeft)``
  