import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const RoundCheckplain = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm-.6 12.6l-4-3L4.6 8 7 9.8l4.2-5.6 1.6 1.2-5.4 7.2z"/></g>
    </StyledIcon>
  )

export default styled(RoundCheckplain)``
  