import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ChevronUp = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8.04 6.525L1.672 13 0 11.279 8.04 3 16 11.279 14.328 13z"/></g>
    </StyledIcon>
  )

export default styled(ChevronUp)``
  