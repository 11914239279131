import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Link as RouterLinkCompat } from 'react-router-dom-v5-compat'
import styled from 'styled-components'
import cx from 'classnames'
import { noop } from 'lodash'
import BigLink from './styles/BigLink'
import ChildrenContainer from './styles/ChildrenContainer'
import SmallLink from './styles/SmallLink'
import PrimaryLink from './styles/PrimaryLink'
import TertiaryLink from './styles/TertiaryLink'
import SecondaryLink from './styles/SecondaryLink'
import ButtonLoader from './styles/ButtonLoader'
import IconButton from '../Button/styles/IconButton'

/**
 * An HTML link. Add automatically 'noreferrer' and 'noopener' to rel if target is '_blank'.
 */
export function UnstyledLink({
  buttonMode = 'primary',
  children,
  className,
  'data-testid': dataTestId,
  disabled = false,
  href,
  icon: Icon,
  iconSize,
  isLoading,
  locator,
  mode = 'link',
  noAutoRel = false,
  onClick = noop,
  rel,
  size,
  target,
  useRouter = false,
  ...rest
}) {
  const isIconMode = mode === 'icon'

  const newClassNames = cx(className, locator, {
    'is-disabled': disabled,
    'is-loading': isLoading,
  })

  // If target is '_blank' (a new tab), we had the noreferrer and noopener properties to the rel.
  const realRel =
    !noAutoRel && target && target === '_blank'
      ? `${!!rel ? `${rel} ` : ''}noreferrer noopener`
      : rel

  // use the size in props, or put small size by default pour link mode, big size for button mode.
  const realSize = size || (mode === 'link' ? 'small' : 'big')

  const realChildren = Icon ? (
    <ChildrenContainer>
      {Icon && <Icon mr={isIconMode ? '0' : 's3'} size={iconSize || (isIconMode ? 's4' : 's3')} />}
      {children}
    </ChildrenContainer>
  ) : (
    children
  )

  const realHref = disabled ? undefined : href
  const realAs =
    !disabled && useRouter ? (useRouter === 'v5-compat' ? RouterLinkCompat : RouterLink) : 'a'

  const sharedProps = {
    ...rest,
    'data-testid': dataTestId,
    as: realAs,
    className: newClassNames,
    icon: Icon,
    onClick: disabled ? noop : onClick,
    rel: realRel,
    target: target,
  }

  if ([true, 'v5', 'v5-compat'].includes(useRouter)) {
    sharedProps.to = realHref
  } else {
    sharedProps.href = realHref
  }

  if (mode !== 'link' && realSize === 'small') {
    sharedProps.$small = true
  }

  if (mode === 'link') {
    switch (realSize) {
      case 'small':
        return <SmallLink {...sharedProps}>{realChildren}</SmallLink>

      case 'big':
        return <BigLink {...sharedProps}>{realChildren}</BigLink>
    }
  } else if (mode === 'button') {
    switch (buttonMode) {
      case 'primary':
        return (
          <PrimaryLink {...sharedProps}>
            {isLoading && <ButtonLoader size="s4" />}
            {realChildren}
          </PrimaryLink>
        )
      case 'secondary':
        return (
          <SecondaryLink {...sharedProps}>
            {isLoading && <ButtonLoader size="s4" />}
            {realChildren}
          </SecondaryLink>
        )
      case 'tertiary':
        return (
          <TertiaryLink {...sharedProps}>
            {isLoading && <ButtonLoader size="s4" />}
            {realChildren}
          </TertiaryLink>
        )
    }
  } else if (isIconMode) {
    return (
      <IconButton {...sharedProps}>
        {isLoading ? <ButtonLoader size="s4" /> : realChildren}
      </IconButton>
    )
  }

  return null
}

export const Link = styled(UnstyledLink)``
export default Link
