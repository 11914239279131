import { merge } from 'lodash'

const Fonts = {
  header1: {
    fontFamily: 'DM Sans',
    fontSize: '64px',
    fontWeight: 400,
    lineHeight: '72px',
    mobile: {
      fontSize: '40px',
      lineHeight: '48px',
    },
  },
  header2: {
    fontFamily: 'DM Sans',
    fontSize: '40px',
    fontWeight: 400,
    lineHeight: '48px',
    mobile: {
      fontSize: '32px',
      lineHeight: '40px',
    },
  },
  header3: {
    fontFamily: 'DM Sans',
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: '40px',
    mobile: {
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  header3Bold: {
    fontFamily: 'DM Sans',
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '40px',
    mobile: {
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  header4: {
    fontFamily: 'DM Sans',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '32px',
    mobile: {
      fontSize: '20px',
      lineHeight: '28px',
    },
  },
  header4Bold: {
    fontFamily: 'DM Sans',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    mobile: {
      fontSize: '20px',
      lineHeight: '28px',
    },
  },
  header5Bold: {
    fontFamily: 'DM Sans',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    mobile: {
      fontSize: '18px',
      lineHeight: '28px',
    },
  },
  header6: {
    fontFamily: 'DM Sans',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '32px',
    mobile: {
      fontSize: '18px',
      lineHeight: '28px',
    },
  },
  body1: {
    fontFamily: 'DM Sans',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '28px',
    mobile: {
      fontSize: '18px',
      lineHeight: '28px',
    },
  },
  body1Bold: {
    fontFamily: 'DM Sans',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    mobile: {
      fontSize: '18px',
      lineHeight: '28px',
    },
  },
  body2: {
    fontFamily: 'DM Sans',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    mobile: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  body2Bold: {
    fontFamily: 'DM Sans',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    mobile: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  body3: {
    fontFamily: 'DM Sans',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    mobile: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  body3Bold: {
    fontWeight: 600,
  },
  caption1: {
    fontFamily: 'DM Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '20px',
    mobile: {
      fontSize: '12px',
      lineHeight: '20px',
    },
  },
  caption2: {
    fontFamily: 'DM Sans',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    mobile: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  monospace: {
    fontFamily: 'DM Mono',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    mobile: {
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
}

const getFonts = ({ fonts }) => merge({}, Fonts, fonts)

export default getFonts
