import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const DividerContent = props => (
    <StyledIcon  {...props}>
      <g><path d="M46.74 23.33H1a1 1 0 0 0 0 2h45.74a1.16 1.16 0 0 0 1.26-1 1.16 1.16 0 0 0-1.26-1zM45 7.17a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h42m0-2H3a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h42a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3zm0 28a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h42m0-2H3a3 3 0 0 0-3 3v6a3 3 0 0 0 3 3h42a3 3 0 0 0 3-3v-6a3 3 0 0 0-3-3z"/></g>
    </StyledIcon>
  )

export default styled(DividerContent)``
  