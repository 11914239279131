import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Page = props => (
    <StyledIcon viewBox="0 0 10 14" {...props}>
      <g fillRule="nonzero">
        <path d="M9.751.75h-9a.709.709 0 0 0-.75.75V12a.709.709 0 0 0 .75.75h6L10.501 9V1.5a.709.709 0 0 0-.75-.75zm-8.25 1.5h7.5v6h-3v3h-4.5v-9z" />
      </g>
    </StyledIcon>
  )

export default styled(Page)``
