import styled from 'styled-components'
import PropTypes from 'prop-types'

const RounderWrapper = styled.div`
  background-color: ${({ color, theme }) =>
    color ? theme.colors[color] || color : theme.components.tag.background};
  border-radius: ${({ theme }) => theme.globals.borderRadius.borderRadius1};
  cursor: ${({ disabled, hasOnClick }) =>
    disabled ? 'not-allowed' : hasOnClick ? 'pointer' : 'default'};
  opacity: ${({ disabled, theme }) =>
    disabled ? theme.globals.opacity.o1 : theme.globals.opacity.o3};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.sizes.s2} ${theme.sizes.s3}`};
`

RounderWrapper.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  hasOnClick: PropTypes.bool,
}

export default RounderWrapper
