import PropTypes from 'prop-types'
import styled from 'styled-components'
import Loader from '../../Loader'

const TagLoader = styled(Loader)`
  border-color: ${({ theme }) => theme.components.tag.loader};
  border-top-color: ${({ color, theme }) =>
    color ? theme.colors[color] || color : theme.components.tag.loaderBackground};
`

TagLoader.propTypes = {
  color: PropTypes.string,
}

export default TagLoader
