import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Tiles = (props) => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g>
        <path d="M6,9 L1,9 C0.448,9 0,8.552 0,8 L0,1 C0,0.448 0.448,0 1,0 L6,0 C6.552,0 7,0.448 7,1 L7,8 C7,8.552 6.552,9 6,9 Z M6,16 L1,16 C0.448,16 0,15.552 0,15 L0,12 C0,11.448 0.448,11 1,11 L6,11 C6.552,11 7,11.448 7,12 L7,15 C7,15.552 6.552,16 6,16 Z M15,6 L10,6 C9.448,6 9,5.552 9,5 L9,1 C9,0.448 9.448,0 10,0 L15,0 C15.552,0 16,0.448 16,1 L16,5 C16,5.552 15.552,6 15,6 Z M15,16 L10,16 C9.448,16 9,15.552 9,15 L9,9 C9,8.448 9.448,8 10,8 L15,8 C15.552,8 16,8.448 16,9 L16,15 C16,15.552 15.552,16 15,16 Z" />
      </g>
    </StyledIcon>
  );

export default styled(Tiles)``
