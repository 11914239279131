import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Books = props => (
    <StyledIcon viewBox="0 0 24 20" {...props}>
      <g><path d="M22.997 15.297l.908 2.71c.303.902-.151 1.655-.908 1.956l-5.749 1.957c-1.21.301-1.816-.301-1.967-.903l-.908-2.86 8.624-2.86zm-13.92 2.107v3.01c0 .904-.604 1.506-1.512 1.506H1.513C.605 21.92 0 21.318 0 20.415v-3.01h9.078zM16.19.094c.908-.3 1.664.15 1.967.903l3.782 11.44-8.472 2.86-3.934-11.44c-.454-1.354.605-1.806.908-1.956zM7.565.847c.908 0 1.513.602 1.513 1.505v12.042H0V2.352C0 1.45.605.847 1.513.847zm8.17 2.559l-2.875.903 1.967 5.72 2.875-.903-1.967-5.72zm-9.683.451H3.026v6.021h3.026v-6.02z"  fillRule="evenodd"/></g>
    </StyledIcon>
  )

export default styled(Books)``
