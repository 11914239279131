import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

// Original source: https://codepen.io/silvandiepen/pen/rZaOGR?editors=1100 (the first burger)
const BurgerCrossAnimation = styled.label`
  ${({ theme }) => css`
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;

    input {
      height: 0;
      position: absolute;
      visibility: hidden;
      width: 0;
    }

    input:checked + span {
      height: 0;
      width: 0;

      & {
        margin-right: ${theme.sizes.s5};
      }

      &:after,
      &:before {
        margin-top: -0.5875rem;
        top: 0px;
        width: ${theme.sizes.s5};
      }

      &:after {
        transform: translateY(0.525rem) rotate(-45deg);
      }
      &:before {
        transform: translateY(0.525rem) rotate(45deg);
      }
    }

    span {
      cursor: pointer;
      position: absolute;
      text-indent: -999em;
      user-select: none;

      // Set styles to bars
      &,
      &:after,
      &:before {
        background-color: white;
        border-radius: ${theme.globals.borderRadius.bR1};
        display: block;
        height: 3px;
        margin-right: 0px;
        opacity: 1;
        transition: 0.3s;
        width: 21px;
      }
      // Bottom and top bar
      &:after,
      &:before {
        content: '';
        position: absolute;
      }

      // Bottom bar
      &:after {
        top: 7.7px;
      }

      // Top bar
      &:before {
        top: -7.7px;
      }
    }
  `}
`

// On mobile only
const BurgerCrossAnimatedButton = ({ isOpen, onChange }) => (
  <BurgerCrossAnimation className='burger' htmlFor='input'>
    <input id='input' checked={isOpen} onChange={onChange} type='checkbox' />
    <span />
  </BurgerCrossAnimation>
)

BurgerCrossAnimatedButton.propTypes = {
  isOpen: PropTypes.bool,
  onChange: PropTypes.func,
}

export default styled(BurgerCrossAnimatedButton)``
