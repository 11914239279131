import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '../../Button'

const StyledButton = styled(Button)`
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  z-index: ${({ zIndex }) => zIndex};
  position: ${({ isAnchoredToRef }) => (isAnchoredToRef ? 'absolute' : 'fixed')};
  top: ${({ theme, top }) =>
    top ? theme.sizes.sizes[top] || theme.sizes[top] || top : theme.sizes.s5};
  // Center the button horizontally
  left: 50%;
  transform: translateX(-50%);
  fill: ${({ theme }) => theme.components.backToTop.color};
  background-color: ${({ theme }) => theme.components.backToTop.background};
  box-shadow: ${({ theme }) => theme.globals.shadows.shadow1};

  &:hover:not(.is-loading):not(.is-disabled):not(:active):not(:focus) {
    fill: ${({ theme }) => theme.components.backToTop.colorHover};
    background-color: ${({ theme }) => theme.components.backToTop.backgroundHover};
    box-shadow: ${({ theme }) => theme.globals.shadows.shadow2};
  }

  &:active:not(.is-loading):not(.is-disabled),
  &:focus:not(.is-loading):not(.is-disabled) {
    fill: ${({ theme }) => theme.components.backToTop.colorActive};
    background-color: ${({ theme }) => theme.components.backToTop.backgroundActive};
    box-shadow: ${({ theme }) => theme.globals.shadows.shadow2};
  }
`

StyledButton.propTypes = {
  hidden: PropTypes.bool,
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  zIndex: PropTypes.number,
}

export default StyledButton
