import styled, { css } from 'styled-components'
import { resetCSS, hexToRGB } from '../../../helpers'


/**
 * Adjust the border color along with some states/props of the Input
 */

const borderColor = ({
  cantTypeWhenLoading,
  error,
  isFocus,
  isLoading,
  readOnly,
  theme,
}) => {
  const colors = theme.components.input
  let color = colors.border
  if (error) {
    color = colors.borderError
  } else if (isFocus && !readOnly) {
    color = colors.borderFocus
  } else if (isLoading && cantTypeWhenLoading) {
    color = hexToRGB(colors.border, theme.globals.opacity.o1)
  }

  return css`
    border-color: ${color};
  `
}

const borderColorHover = ({
  theme,
  cantTypeWhenLoading,
  disabled,
  error,
  isFocus,
  isLoading,
  readOnly,
}) => {
  const colors = theme.components.input
  let color
  if (error) {
    color = colors.borderError
  } else if (isFocus && !readOnly && !isLoading) {
    color = colors.borderFocus
  } else if (isLoading && cantTypeWhenLoading) {
    color = hexToRGB(colors.border, theme.globals.opacity.o1)
  } else if (disabled || readOnly || isLoading) {
    color = colors.border
  } else color = colors.borderHover
  return css`
    border-color: ${color};
  `
}

const BaseWrapper = styled.div.attrs(resetCSS)`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    border-radius: ${theme.globals.borderRadius.borderRadius1};
    background-color: ${theme.components.input.background};

    &:not(:last-child) {
      margin-bottom: ${theme.sizes.margins.m3};
    }
  `}

  ${borderColor};
  &:hover {
    ${borderColorHover};
  }
`

export default BaseWrapper
