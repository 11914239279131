import styled, { css } from 'styled-components'

import { ChevronSVG, HomeSVG } from '@icons'
import { StyledLink } from '@styles/StyledLink'
import { Body3Bold } from '@typography'
import { styleForMobile, styleForTablet } from '@utils'

const StyledDiv = styled.div`
  ${({ isDark = false, theme }) => css`
    align-items: center;
    color: ${isDark ? theme.colors.neutral0 : theme.colors.neutral90};
    display: flex;
    max-width: 100%;

    & > *:not(:first-child) {
      clear: both;
      color: ${isDark ? theme.colors.neutral0 : theme.colors.neutral90};
      display: inline-block;
      padding-left: ${theme.sizes.s3};
      white-space: nowrap;
    }

    & * {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & > :last-child ${Body3Bold}, & > ${Body3Bold}:last-child {
      color: ${isDark ? theme.colors.neutral35 : theme.colors.neutral70};
    }

    ${ChevronSVG} {
      height: ${theme.sizes.s4};
      min-width: ${theme.sizes.s4};
      width: ${theme.sizes.s4};
      * {
        fill: ${isDark ? theme.colors.neutral0 : theme.colors.neutral50};
      }
    }

    ${HomeSVG} {
      min-width: ${theme.sizes.s5};
    }

    svg * {
      fill: ${isDark ? theme.colors.neutral0 : theme.colors.neutral90};
    }

    ${styleForTablet} {
      max-width: ${theme.responsive.tablet.containerMaxWidth};
    }

    ${styleForMobile} {
      & > :last-child ${Body3Bold}, & > ${Body3Bold}:last-child {
        color: ${isDark ? theme.colors.neutral0 : theme.colors.neutral90};
      }

      ${StyledLink} > p {
        color: ${isDark ? theme.colors.neutral0 : theme.colors.neutral90};
      }

      & > p {
        color: ${isDark ? theme.colors.neutral0 : theme.colors.neutral90};
      }
    }
  `}
`

export default StyledDiv
