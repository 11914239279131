import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const BorderDouble = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M3 3v10h10V3H3zM2 2h12v12H2V2zM1 1v14h14V1H1zM0 0h16v16H0V0z"/></g>
    </StyledIcon>
  )

export default styled(BorderDouble)``
  