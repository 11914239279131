import styled from 'styled-components'
import { Body2 } from '../../Typography'

import { SIZES } from '../config'

const inputPadding = (theme, size) => {
  switch (size) {
    case SIZES.SMALL:
      return `${theme.sizes.s2} ${theme.sizes.s3}`
    default:
      return `${theme.sizes.s3} ${theme.sizes.s4}`
  }
}

const inputLineHeight = (lineHeight, borderSize) => `
  calc(${lineHeight} - ${borderSize} * 2)
`

const BaseInput = styled(Body2)`
  ${({ theme, cantTypeWhenLoading, isLoading, size }) => `
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    line-height: ${inputLineHeight(
      theme.fonts.body2.lineHeight,
      theme.globals.borderWidth.borderWidth1,
    )};
    padding: ${inputPadding(theme, size)};
    border-radius: ${theme.globals.borderRadius.borderRadius1};
    color: ${theme.components.input.text};
    background-color: ${theme.components.input.background};

    ${isLoading && cantTypeWhenLoading ? `opacity: ${theme.globals.opacity.o1};` : ''};

    &:disabled {
      background: inherit;
      cursor: not-allowed;
    }

    ::-webkit-input-placeholder,
    :-ms-input-placeholder {
      color: ${theme.components.input.placeholder};
    }

    ::placeholder {
      color: ${theme.components.input.placeholder};
      opacity: 1;
    }

    ::-ms-clear {
      width: 0;
      height: 0;
      display: none;
    }
    ::-ms-reveal {
      width: 0;
      height: 0;
      display: none;
    }
  `}
`

export default BaseInput
