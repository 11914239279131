import React from 'react'
import styled, { withTheme } from 'styled-components'

import { useHeaderCellNumber } from '../hooks'
import Loader from '../../Loader'
import { CustomSkeleton } from '../../Skeleton'
import Cell from './Cell'

const renderPlaceHolderRows = (height) => {
  const NUMBER_OF_PLACEHOLDER_ROWS = 5
  const rows = []
  for (let i = 0; i < NUMBER_OF_PLACEHOLDER_ROWS; i++) {
    rows.push(<PlaceholderRow height={height} key={i} />)
  }
  return rows
}

const StyledLoader = styled(Loader)`
  z-index: 10; // Has to be placed above squeletton lines.
  position: absolute;
  left: 50%;
  ${({ theme }) => `
    margin-top: ${theme.sizes.s9};
  `}
`

const StyledTr = styled.tr`
  ${Cell} {
    vertical-align: middle;
  }
`

const LoaderCell = styled(Cell)`
  padding: 0;
  height: 0;
  width: 0;
  border: none;
`

function PlaceholderRow({ height }) {
  const [headerCellNumber] = useHeaderCellNumber();
  return (
    <StyledTr>
      <Cell colSpan={headerCellNumber}>
        <CustomSkeleton h={height} />
      </Cell>
    </StyledTr>
  );
}

function LoadingBody({ theme }) {
  const { s12 } = theme.sizes;
  const [headerCellNumber] = useHeaderCellNumber();

  return (
    <>
      <tr>
        <LoaderCell colSpan={headerCellNumber}>
          <StyledLoader aria-live="assertive" size="large" />
        </LoaderCell>
      </tr>
      {renderPlaceHolderRows(s12)}
    </>
  );
}

export default withTheme(LoadingBody);