import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const CarretDown = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M15.857 3.75c-.207-.445-.67-.737-1.186-.75H1.338c-.523.008-.992.301-1.2.75-.224.404-.172.895.133 1.25l6.666 7.499c.276.286.657.464 1.067.5.424.02.829-.17 1.067-.5l6.666-7.5c.3-.357.348-.847.12-1.25z"/></g>
    </StyledIcon>
  )

export default styled(CarretDown)``
  