import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Bold = props => (
    <StyledIcon viewBox="0 0 9.6 12" {...props}>
      <g><path d="M7.5 5.13A3 3 0 0 0 5.4 0H0v1.2h1.2v9.6H0V12h6a3.6 3.6 0 0 0 1.5-6.87zM5.4 1.2a1.8 1.8 0 0 1 0 3.6h-3V1.2zm.6 9.6H2.4V6H6a2.4 2.4 0 0 1 0 4.8z"/></g>
    </StyledIcon>
  )

export default styled(Bold)``
  