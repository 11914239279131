import React from 'react';
import AbstractSelectionSelect from './AbstractSelectionSelect'; // eslint-disable-line import/no-cycle

/**
 * A wrapper to add single selection to a select.
 */
class SingleSelect extends AbstractSelectionSelect {
  renderSelected = (previous) => {
    const { selected } = this.props;

    // If we have a selected value, render its label.
    if (selected && selected.length > 0) {
      // If we have too many values, console an alert.
      if (selected.length > 1) {
        // eslint-disable-next-line no-console
        console.error('Too many options for a select in single mode.');
      }

      return [selected[0].label];
    }

    return previous;
  }
}

export default SingleSelect;