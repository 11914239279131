import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const CogOutline = props => (
    <StyledIcon viewBox="0 0 22 22" {...props}>
      <g>
        <path d="M11.4113948 4l-.2664683 1.30792041c-.1146041.56251767-.4654023 1.04866681-.9631237 1.33473264L8.13242012 7.82053767c-.49303423.28337188-1.08338654.34346404-1.62340605.16524704l-1.38618594-.45746847-.54531011.92021081.96986732.8300851c.4439578.37997233.69952824.93510405.69952824 1.51946475v2.4038462c0 .5843607-.25557044 1.1394924-.69952824 1.5194648l-.96986732.830085.54531011.9202109 1.38618594-.4574685c.54001951-.178217 1.13037182-.1181249 1.62340605.165247l2.04938268 1.1778846c.4977214.2860659.8485196.772215.9631237 1.3347327L11.4113948 20h1.1772104l.2664683-1.3079204c.1146041-.5625177.4654023-1.0486668.9631237-1.3347327l2.0493827-1.1778846c.4930342-.2833719 1.0833865-.343464 1.623406-.165247l1.386186.4574685.5453101-.9202109-.9698673-.830085c-.4439578-.3799724-.6995283-.9351041-.6995283-1.5194648v-2.4038462c0-.5843607.2555705-1.13949242.6995283-1.51946475l.9698673-.8300851-.5453101-.92021081-1.386186.45746847c-.5400195.178217-1.1303718.11812484-1.623406-.16524704l-2.0493827-1.17788462c-.4977214-.28606583-.8485196-.77221497-.9631237-1.33473264L12.5886052 4h-1.1772104zM9.77777778 2h4.44444442l.5925926 2.90865385 2.0493827 1.17788461L19.7777778 5.125 22 8.875l-2.2469136 1.9230769v2.4038462L22 15.125l-2.2222222 3.75-2.9135803-.9615385-2.0493827 1.1778847L14.2222222 22H9.77777778l-.59259259-2.9086538-2.04938272-1.1778847L4.22222222 18.875 2 15.125l2.24691358-1.9230769v-2.4038462L2 8.875l2.22222222-3.75 2.91358025.96153846 2.04938272-1.17788461L9.77777778 2zM11 12c0-.5554305.4445695-1 1-1 .5554305 0 1 .4445695 1 1 0 .5554305-.4445695 1-1 1-.5554305 0-1-.4445695-1-1zm-2 0c0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3-3 1.34-3 3z" />
      </g>
    </StyledIcon>
  )

export default styled(CogOutline)``
