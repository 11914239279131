import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Gauge = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g>
        <path
          d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM4 9H2V7h2v2zm5 5H7v-2h2v2zm-1-4c-1.1 0-2-.9-2-2 0-.4.1-.8.3-1.1L4.7 3.6l1.8-.9L8.1 6c1 .1 1.9.9 1.9 2s-.9 2-2 2zm6-1h-2V7h2v2z"
          fillRule="nonzero"
        />
      </g>
    </StyledIcon>
  )

export default styled(Gauge)``
