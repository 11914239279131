import React from 'react'

const LinkSVG = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="link">
      <g id="Vector">
        <path fillRule="evenodd" clipRule="evenodd" d="M22.3905 9.60945C20.7021 7.92101 17.9646 7.92101 16.2761 9.60945L14.9428 10.9428C14.4221 11.4635 13.5779 11.4635 13.0572 10.9428C12.5365 10.4221 12.5365 9.57786 13.0572 9.05717L14.3905 7.72383C17.1204 4.99399 21.5463 4.99399 24.2761 7.72383C27.006 10.4537 27.006 14.8796 24.2761 17.6095L22.9428 18.9428C22.4221 19.4635 21.5779 19.4635 21.0572 18.9428C20.5365 18.4221 20.5365 17.5779 21.0572 17.0572L22.3905 15.7238C24.079 14.0354 24.079 11.2979 22.3905 9.60945Z" fill="#9EA9B0" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.9428 13.0572C11.4635 13.5779 11.4635 14.4221 10.9428 14.9428L9.60948 16.2761C7.92104 17.9646 7.92104 20.7021 9.60948 22.3905C11.2979 24.0789 14.0354 24.0789 15.7239 22.3905L17.0572 21.0572C17.5779 20.5365 18.4221 20.5365 18.9428 21.0572C19.4635 21.5779 19.4635 22.4221 18.9428 22.9428L17.6095 24.2761C14.8796 27.006 10.4537 27.006 7.72386 24.2761C4.99403 21.5463 4.99402 17.1203 7.72386 14.3905L9.0572 13.0572C9.57789 12.5365 10.4221 12.5365 10.9428 13.0572Z" fill="#9EA9B0" />
        <path fillRule="evenodd" clipRule="evenodd" d="M20.2761 11.7238C20.7968 12.2445 20.7968 13.0888 20.2761 13.6095L13.6095 20.2761C13.0888 20.7968 12.2446 20.7968 11.7239 20.2761C11.2032 19.7554 11.2032 18.9112 11.7239 18.3905L18.3905 11.7238C18.9112 11.2031 19.7554 11.2031 20.2761 11.7238Z" fill="#9EA9B0" />
      </g>
    </g>
  </svg>
)

export default LinkSVG
