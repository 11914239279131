import * as React from 'react'
import styled from 'styled-components'

import LinkButtonStyled from './styles/LinkButtonStyled'
import LinkButtonWrapper from './styles/LinkButtonWrapper'
import StyledLoader from './styles/StyledLoader'


export const LinkButton = (props) => {
  const {
    className,
    'data-testid': dataTestId,
    disabled,
    icon: Icon,
    iconSize,
    isLoading,
    label,
    onClick,
    style,
    ...rest
  } = props

  return (
    <LinkButtonWrapper className={className} style={style}>
      <LinkButtonStyled
        {...rest}
        data-testid={dataTestId}
        disabled={disabled}
        onClick={!disabled ? onClick : undefined}
        type="button"
      >
        {Icon && !isLoading && <Icon mr="s3" size={iconSize} />}
        {isLoading && <StyledLoader size={iconSize} />}
        {label}
      </LinkButtonStyled>
    </LinkButtonWrapper>
  )
}

export default styled(LinkButton)``
