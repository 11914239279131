import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Gift = props => (
    <StyledIcon viewBox="0 0 12 11.98" {...props}>
      <g><path d="M10.8 3.58H8.93a3 3 0 0 0 .74-.53 1.8 1.8 0 0 0 0-2.55 1.84 1.84 0 0 0-2.55 0A5.27 5.27 0 0 0 6 3.55a5.27 5.27 0 0 0-1.13-3 1.84 1.84 0 0 0-2.55 0 1.8 1.8 0 0 0 0 2.55 3 3 0 0 0 .74.53H1.2A1.2 1.2 0 0 0 0 4.78v1.8a.6.6 0 0 0 .6.6v3.6A1.2 1.2 0 0 0 1.8 12h8.4a1.2 1.2 0 0 0 1.2-1.2V7.18a.6.6 0 0 0 .6-.6v-1.8a1.2 1.2 0 0 0-1.2-1.2zm-7.2 7.2H1.8v-3.6h1.8zM3.6 6H1.2V4.78h2.4zm-.42-3.79A.6.6 0 1 1 4 1.36a3.17 3.17 0 0 1 .67 1.52 3.2 3.2 0 0 1-1.49-.67zm4 8.58H4.8V7.18h2.4zM7.2 6H4.8V4.78h2.4zM8 1.36a.6.6 0 0 1 .85 0 .6.6 0 0 1 0 .85 3.18 3.18 0 0 1-1.52.67A3.18 3.18 0 0 1 8 1.36zm2.22 9.42H8.4v-3.6h1.8zM10.8 6H8.4V4.78h2.4z"/></g>
    </StyledIcon>
  )

export default styled(Gift)``
  