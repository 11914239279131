import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const DataFile1 = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M3.74 5.28l-.66.66V4.1h-1v4.61h3.05v-1H3.08v-.3l1-1 .68.59a.51.51 0 0 0 .36.15V5.94l-.66-.66a.51.51 0 0 0-.72 0z"/><path d="M1 1h5.15v2.08H8.2v1.54h1V2.35L6.87 0H1a1 1 0 0 0-1 1v9.23a1 1 0 0 0 1 1h4.1v-1H1z"/><path d="M10.34 9.61a2.57 2.57 0 1 0-.72.72L11.28 12l.72-.72zm-2.14.13A1.54 1.54 0 1 1 9.74 8.2 1.54 1.54 0 0 1 8.2 9.74z"/></g>
    </StyledIcon>
  )

export default styled(DataFile1)``
  