import styled from 'styled-components'
import { Body2 } from '../../Typography'

const Label = styled(Body2)`
  ${({ disabled, ellipsis, medium, theme }) => `
    ${medium ? `font-weight: ${theme.fonts.body2Medium.fontWeight};` : ''}
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
    ${ellipsis ? 'display: inline;' : ''}
  `}
`

export default Label