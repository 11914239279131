import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { ChevronLeft } from 'component-library/icons'

import PaginationIcon from './PaginationIcon'
import { usePage } from '../contexts'

const PaginationPrevious = ({ onClick, ...rest }) => {
  const { currentPage, goToPreviousPage } = usePage()

  const onGoToPreviousPage = () => {
    goToPreviousPage()
    onClick?.()
  }

  return currentPage !== 1 ? (
    <PaginationIcon icon={ChevronLeft} onClick={onGoToPreviousPage} {...rest} />
  ) : null
}

PaginationPrevious.propTypes = {
  /**
   * The Button props.
   */
  '...rest': PropTypes.object,
  /**
   * Function called when the button-icon is clicked.
   */
  onClick: PropTypes.func,
}

export default styled(PaginationPrevious)``
