import * as React from 'react'
import styled, { withTheme } from 'styled-components'
import { get } from 'lodash'
import cx from 'classnames'

import Marker from '../styles/Marker'

/**
 * Round badge of variable size. Intended to enhance a notification.
 */
export function RoundNotification({
  className,
  color,
  locator,
  size = 's3',
  style,
  theme,
  type,
}) {
  const setColor = () =>
    get(theme, `components.roundNotification[${type}].color`, color)

  const newClassNames = cx(className, locator)

  return (
    <Marker
      className={newClassNames}
      color={setColor()}
      size={size}
      style={style}
    />
  )
}

export default styled(withTheme(RoundNotification))``
