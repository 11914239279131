import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Mjml = props => (
    <StyledIcon viewBox="0 0 12 10.8" {...props}>
      <g><path d="M7 2.75H1.33A1.36 1.36 0 0 1 0 1.38 1.36 1.36 0 0 1 1.33 0H7a1.36 1.36 0 0 1 1.35 1.38A1.36 1.36 0 0 1 7 2.75zm-5.68 4a1.36 1.36 0 0 1 0-2.72 1.36 1.36 0 0 1 0 2.72zm9.34 4A1.36 1.36 0 1 1 12 9.42a1.34 1.34 0 0 1-1.32 1.37zm0-8a1.34 1.34 0 0 1-1.29-1.36 1.32 1.32 0 1 1 2.63 0 1.34 1.34 0 0 1-1.32 1.36zm0 4H5A1.38 1.38 0 0 1 5 4h5.69a1.38 1.38 0 0 1 0 2.75zM7 10.8H1.33a1.38 1.38 0 0 1 0-2.8H7a1.38 1.38 0 0 1 0 2.8z"/></g>
    </StyledIcon>
  )

export default styled(Mjml)``
  