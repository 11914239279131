import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AddCircle = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8.8 4H7.2v3.2H4v1.6h3.2V12h1.6V8.8H12V7.2H8.8V4zM8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.4A6.407 6.407 0 0 1 1.6 8c0-3.529 2.871-6.4 6.4-6.4 3.529 0 6.4 2.871 6.4 6.4 0 3.529-2.871 6.4-6.4 6.4z"/></g>
    </StyledIcon>
  )

export default styled(AddCircle)``
  