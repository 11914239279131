import * as React from 'react'
import styled from 'styled-components'
import { includes, isEqual, map, reduce, without } from 'lodash'
import StyledCheckboxRadioGroup from './styles/StyledCheckboxRadioGroup'
import AlignmentWrapper from './styles/AlignmentWrapper'

class CheckboxRadioGroup extends React.Component {
  state = {
    arraySelection: [],
  }

  componentDidMount() {
    this.setState({ arraySelection: this.createArraySelection() })
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.options, this.props.options)) {
      this.setState({
        arraySelection: this.createArraySelection(),
      })
    }
  }

  handleClick = (id, type) => {
    const { onClick } = this.props

    onClick && onClick()
    this.handleSelection(id, type)
  }

  handleSelection = (id, type) => {
    const { onChange } = this.props
    let { arraySelection } = this.state
    arraySelection = [...arraySelection]

    if (type === 'radio') {
      if (id && !this.isChecked(id)) {
        arraySelection = [id]
      }
    } else if (type === 'checkbox') {
      if (id && !this.isChecked(id)) {
        arraySelection = [...arraySelection, id]
      } else {
        arraySelection = without(arraySelection, id)
      }
    }

    this.setState({ arraySelection }, () => onChange && onChange(arraySelection))
  }

  isChecked = (id) => {
    const { arraySelection } = this.state

    return includes(arraySelection, id)
  }

  createArraySelection = () =>
    reduce(
      this.props.options,
      (acc, o) => {
        const { checked, id } = o
        if (checked) {
          return this.props.type === 'checkbox' ? [...acc, id] : [id]
        }
        return acc
      },
      []
    )

  render() {
    const { className, horizontalAlign, locator, options, type } = this.props

    return (
      <AlignmentWrapper className={className} horizontalAlign={horizontalAlign}>
        {map(options, (option, index) => (
          <StyledCheckboxRadioGroup
            checked={this.isChecked(option.id)}
            data-testid={option['data-testid']}
            disabled={option.disabled}
            horizontalAlign={horizontalAlign}
            id={option.id}
            key={`spacer-${option.id}`}
            label={option.label}
            labelStyle={option.labelStyle}
            locator={locator ? `${locator}-${option.id}` : ''}
            medium={option.medium}
            name={option.name}
            noPaddingTop={index === 0}
            onClick={() => this.handleClick(option.id, type)}
            subLabel={option.subLabel}
            type={type}
          />
        ))}
      </AlignmentWrapper>
    )
  }
}

export default styled(CheckboxRadioGroup)``
