import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ButtonContent = props => (
    <StyledIcon  {...props}>
      <g><path d="M45 8.33H3a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3h20.2v-2H3a1 1 0 0 1-1-1v-18a1 1 0 0 1 1-1h42a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H30.3l2 2H45a3 3 0 0 0 3-3v-18a3 3 0 0 0-3-3z"/><path d="M24.2 25.63v11.2l2.56-2.47L29.13 40l1.84-.77-2.26-5.4h3.69l-8.2-8.2z"/></g>
    </StyledIcon>
  )

export default styled(ButtonContent)``
  