import * as React from 'react'

import InputStyled from '../styles/InputStyled'
import SearchSelectInputContainer from '../styles/SearchSelectInputContainer'
import SelectedTextLabel from '../styles/SelectedTextLabel'


/**
 * A wrapper to add search on a select.
 */
class SearchSelect extends React.Component {
  /**
   * Return a element containing the value if it's a string, or false otherwise.
   */
  renderInputIfString(value) {
    if (value && typeof value === 'string') {
      return [this.renderInput(value)]
    }

    return false
  }

  /**
   * Render an input with a placeholder and the searchText as value.
   */
  renderInput(placeholder) {
    const {
      'data-testid-search': dataTestIdSearch,
      disabled,
      maxLength,
      onSearch,
      onSelectedTextClick = () => undefined,
      searchText,
      selectedText = '',
      setInputRef = () => undefined,
      shouldShowSelectedText = true,
    } = this.props

    return (
      <SearchSelectInputContainer key="searchInput">
        {shouldShowSelectedText && (
          <SelectedTextLabel onClick={onSelectedTextClick}>{selectedText}</SelectedTextLabel>
        )}
        <InputStyled
          data-testid={dataTestIdSearch}
          disabled={disabled}
          maxLength={maxLength}
          onChange={onSearch}
          placeholder={shouldShowSelectedText ? '' : placeholder}
          ref={setInputRef}
          value={shouldShowSelectedText ? '' : searchText}
        />
      </SearchSelectInputContainer>
    )
  }

  /**
   * Render an input containing the first element (if it is a string) in an input,
   * a normal input otherwise.
   */
  renderSelected = (previous) => {
    const { placeholder } = this.props
    return this.renderInputIfString(previous[0]) || [...previous, this.renderInput(placeholder)]
  }

  /**
   * Default render for the placeholder.
   */
  renderPlaceholder = () => {
    const { placeholder } = this.props

    return [placeholder]
  }

  render() {
    const { options = [], renderSelectedArray = [], selectToGenerate = [], ...rest } = this.props

    const Selected = selectToGenerate[0]
    const props = {
      ...rest,
      selectToGenerate: selectToGenerate.slice(1),
      options,
      renderSelectedArray: [this.renderSelected, ...renderSelectedArray],
      renderPlaceholder: this.renderPlaceholder,
    }

    return <Selected {...props} />
  }
}

export default SearchSelect
