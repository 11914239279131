import React from 'react'
import styled from 'styled-components'

import Table from './components/Table'

import { TableProvider, useIds } from '../hooks'

import Body from '../components/Body'
import Cell from '../components/Cell'
import Controls from '../components/Controls'
import FullWidth from '../components/FullWidth'
import Header from '../components/Header'
import HeaderCell from '../components/HeaderCell'
import Pagination from '../components/Pagination'
import Row from '../components/Row'

function DataList({
  children,
  isInfinite,
  isLoading = false,
  isSelectable = false,
  name,
}) {
  return (
    <TableProvider
      isInfinite={isInfinite}
      isLoading={isLoading}
      isSelectable={isSelectable}
      name={name}
    >
      {children}
    </TableProvider>
  )
}

DataList.Body = Body
DataList.Cell = Cell
DataList.Controls = Controls
DataList.FullWidth = FullWidth
DataList.Header = Header
DataList.HeaderCell = HeaderCell
DataList.Pagination = Pagination
DataList.Row = Row
DataList.Table = Table
DataList.useIds = useIds

export default styled(DataList)``
