import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Underline = props => (
    <StyledIcon viewBox="0 0 9.6 12" {...props}>
      <g><path d="M0 10.8h9.6V12H0zM6 0v1.2h1.2V6a2.4 2.4 0 0 1-4.8 0V1.2h1.2V0H0v1.2h1.2V6a3.6 3.6 0 0 0 7.2 0V1.2h1.2V0z"/></g>
    </StyledIcon>
  )

export default styled(Underline)``
  