import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const RadioChecked = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M9.27 6A3.27 3.27 0 1 1 6 2.73 3.27 3.27 0 0 1 9.27 6z"/><path d="M6 12a6 6 0 1 1 6-6 6 6 0 0 1-6 6zM6 1a5 5 0 1 0 5 5 5 5 0 0 0-5-5z"/></g>
    </StyledIcon>
  )

export default styled(RadioChecked)``
  