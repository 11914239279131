import styled from 'styled-components'
import PropTypes from 'prop-types'
import { margins, paddings, resetCSS } from '../helpers'

const MailjetLogo = styled.div.attrs(resetCSS)`
  ${margins};
  ${paddings};
  ${({ baseUrl = "https://assets.mailjet.com", color, size, theme }) => `
    height: ${theme.sizes.sizes[size] || theme.sizes[size] || size};
    width: ${theme.sizes.sizes[size] || theme.sizes[size] || size};
    background-color: ${color ? theme.colors[color] || color : 'inherit'};
    mask-size: ${theme.sizes.sizes[size] || theme.sizes[size] || size} ${theme.sizes.sizes[size] || theme.sizes[size] || size};;
    mask-image: url('${baseUrl}/lib/images/mailjetLogo/mj_logo_only_icon_color.svg');
  `}
  mask-position: center;
  mask-repeat: no-repeat;
  margin-bottom: 5px;
`;

MailjetLogo.defaultProps = {
  color: 'mj40',
  size: 's4',
}

MailjetLogo.propTypes = {
  /** The base URL for the icon. */
  baseUrl: PropTypes.string,
  /** The color of the icon (like 'red'), or a theme key. */
  color: PropTypes.string,
  /** The size of the icon (like '12px'), or a theme key. */
  size: PropTypes.string,
}

export default styled(MailjetLogo)``
