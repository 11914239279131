import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const List3 = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M10.8 3.6H5.4a1.2 1.2 0 0 1-1.2-1.2V1.2A1.2 1.2 0 0 1 5.4 0h5.4A1.2 1.2 0 0 1 12 1.2v1.2a1.2 1.2 0 0 1-1.2 1.2zM5.4 1.2v1.2h5.4V1.2zm5.4 6.6H5.4a1.2 1.2 0 0 1-1.2-1.2V5.4a1.2 1.2 0 0 1 1.2-1.2h5.4A1.2 1.2 0 0 1 12 5.4v1.2a1.2 1.2 0 0 1-1.2 1.2zM5.4 5.4v1.2h5.4V5.4zm5.4 6.6H5.4a1.2 1.2 0 0 1-1.2-1.2V9.6a1.2 1.2 0 0 1 1.2-1.2h5.4A1.2 1.2 0 0 1 12 9.6v1.2a1.2 1.2 0 0 1-1.2 1.2zM5.4 9.6v1.2h5.4V9.6zm-3-6H1.2A1.2 1.2 0 0 1 0 2.4V1.2A1.2 1.2 0 0 1 1.2 0h1.2a1.2 1.2 0 0 1 1.2 1.2v1.2a1.2 1.2 0 0 1-1.2 1.2zM1.2 1.2v1.2h1.2V1.2zm1.2 6.6H1.2A1.2 1.2 0 0 1 0 6.6V5.4a1.2 1.2 0 0 1 1.2-1.2h1.2a1.2 1.2 0 0 1 1.2 1.2v1.2a1.2 1.2 0 0 1-1.2 1.2zM1.2 5.4v1.2h1.2V5.4zM2.4 12H1.2A1.2 1.2 0 0 1 0 10.8V9.6a1.2 1.2 0 0 1 1.2-1.2h1.2a1.2 1.2 0 0 1 1.2 1.2v1.2A1.2 1.2 0 0 1 2.4 12zM1.2 9.6v1.2h1.2V9.6z"/></g>
    </StyledIcon>
  )

export default styled(List3)``
  