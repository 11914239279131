import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Coins = props => (
    <StyledIcon  {...props}>
      <g><path d="M11.636 3.53c-.727 0-1.963.14-2.909.564V2.118C8.727.565 5.745 0 4.364 0 2.982 0 0 .565 0 2.118V8.47c0 1.553 2.982 2.117 4.364 2.117.727 0 1.963-.14 2.909-.564C7.49 11.506 10.327 12 11.636 12 13.018 12 16 11.435 16 9.882V5.647c0-1.553-2.982-2.118-4.364-2.118zm0 1.411c1.31 0 2.473.424 2.837.706-.364.282-1.528.706-2.837.706-1.309 0-2.472-.424-2.836-.706.364-.282 1.527-.706 2.836-.706zm2.91 2.753c-.219.282-1.528.777-2.91.777-1.381 0-2.69-.495-2.909-.777V7.2c.946.424 2.182.565 2.91.565.727 0 1.963-.141 2.908-.565v.494zM1.454 5.788c.945.424 2.181.565 2.909.565.727 0 1.963-.141 2.909-.565v.494c-.218.283-1.528.777-2.91.777-1.381 0-2.69-.494-2.908-.777v-.494zm0-2.117c.945.423 2.181.564 2.909.564.727 0 1.963-.14 2.909-.564v.494c-.218.282-1.528.776-2.91.776-1.381 0-2.69-.494-2.908-.776V3.67zm2.909-2.26c1.309 0 2.472.424 2.836.707-.364.282-1.527.706-2.836.706-1.31 0-2.473-.424-2.837-.706.364-.283 1.528-.706 2.837-.706zM1.454 8.4v-.494c.946.423 2.182.565 2.91.565.727 0 1.963-.142 2.909-.565V8.4c-.218.282-1.528.776-2.91.776-1.381 0-2.69-.494-2.908-.776zm10.182 2.188c-1.381 0-2.69-.494-2.909-.776v-.494c.946.423 2.182.564 2.91.564.727 0 1.963-.14 2.908-.564v.494c-.218.282-1.527.776-2.909.776z"/></g>
    </StyledIcon>
  )

export default styled(Coins)``
  