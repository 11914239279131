import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Medium = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M1.897 4.152a.642.642 0 0 0-.201-.535L.202 1.775V1.5H4.84l3.586 8.048L11.578 1.5H16v.275L14.723 3.03a.387.387 0 0 0-.142.366v9.21c-.023.14.032.28.142.366l1.247 1.254v.275H9.696v-.275l1.292-1.284c.127-.13.127-.168.127-.367V5.13l-3.593 9.34h-.486L2.853 5.13v6.26a.876.876 0 0 0 .232.718l1.68 2.086v.275H0v-.275l1.68-2.086c.18-.19.26-.457.217-.719V4.152z"/></g>
    </StyledIcon>
  )

export default styled(Medium)``
  