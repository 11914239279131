import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Book = props => (
  <StyledIcon viewBox="0 0 24 22" {...props}>
    <g>
      <path
        d="M 24 0 L 11.999 3.259 L 0 0 L 0 18.741 L 11.999 22 L 24 18.741 L 24 0 Z M 22.956 1.361 L 22.956 17.948 L 11.999 20.923 L 1.044 17.948 L 1.044 1.361 L 11.999 4.335 L 22.956 1.361 Z M 9.234 16.905 L 4.054 15.486 L 4.331 14.484 L 9.51 15.903 L 9.234 16.905 Z M 4.054 6.446 L 9.234 7.865 L 9.51 6.863 L 4.331 5.444 L 4.054 6.446 Z M 9.25 12.22 L 4.063 10.929 L 4.315 9.921 L 9.502 11.212 L 9.25 12.22 Z M 14.489 15.903 L 14.765 16.905 L 19.945 15.486 L 19.668 14.484 L 14.489 15.903 Z M 14.765 7.865 L 14.489 6.863 L 19.668 5.444 L 19.945 6.446 L 14.765 7.865 Z M 14.498 11.212 L 14.75 12.22 L 19.937 10.929 L 19.685 9.921 L 14.498 11.212 Z"
        fillRule="evenodd"
      />
    </g>
  </StyledIcon>
)

export default styled(Book)``
