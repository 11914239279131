import React from 'react'
import { noop } from 'lodash'

import { useDropdown, useRegisterComponent } from '../../hooks'

function Header({ onFocus = noop, ...props }) {
  const { setIsOpen } = useDropdown()
  const component = useRegisterComponent()

  function toggle(e) {
    e.preventDefault()
    e.stopPropagation()

    setIsOpen(prevState => !prevState)
    onFocus()
  }

  return <div onClick={toggle} ref={component} {...props} />
}

export default Header
