import styled from 'styled-components'
import TertiaryButton from '../../Button/styles/TertiaryButton'

const TertiaryLink = styled(TertiaryButton)`
  text-decoration: none;

  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${({ theme }) => theme.components.button.tertiary.text};
  }
`

export default TertiaryLink
