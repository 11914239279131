import React from 'react'
import styled from 'styled-components'
import { map } from 'lodash'
import PropTypes from 'prop-types'

import Button from '../../Button'
import { getRange } from '../helpers'
import { usePage } from '../contexts'

const PageButton = styled(Button)`
  ${({ selected, theme: { components, sizes, globals } }) => `
    border-radius: ${globals.borderRadius.bR1};
    margin-left: ${sizes.s1};
    margin-right: ${sizes.s1};
    padding: ${sizes.s2} ${sizes.s3};
    transition: none;
    && {
      ${
        selected
          ? `
        color: ${components.pagination.page.selected};
        pointer-events: none;
      `
          : ''
      };
    }
  `}
`

const PaginationTogglePages = ({
  'data-testid': dataTestId,
  maxSidePageDisplayed = 2,
  ...rest
}) => {
  const { currentPage, lastPage, setCurrentPage } = usePage()
  const pages = getRange(currentPage, lastPage, maxSidePageDisplayed)

  const onClickPage = (page) => {
    setCurrentPage(page)
  }

  return map(pages, page => (
    <PageButton
      data-testid={`${dataTestId}-${page}`}
      key={page}
      mode="toggle"
      onClick={() => onClickPage(page)}
      selected={page === currentPage}
      small
      {...rest}
    >
      {page}
    </PageButton>
  ))
}

PaginationTogglePages.defaultProps = {
  'data-testid': 'dataTestId-PaginationTogglePages-component',
}

PaginationTogglePages.propTypes = {
  /**
   * The Button props.
   */
  '...rest': PropTypes.object,
  /* The data-testid. */
  'data-testid': PropTypes.string,
  /**
   * The numbers of pages to display on the left and right of the current page.
   */
  maxSidePageDisplayed: PropTypes.number,
}

export default PaginationTogglePages
