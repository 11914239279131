// @noSnapshot

import React from 'react'
import styled from 'styled-components'
import { CarretRight } from 'component-library/icons'
import SubOptions from './SubOptions'
import Option from '../Option'

const StyledOption = styled(Option)`
  position: relative;

  :hover > ${SubOptions} {
    display: flex;
  }
`

const OptionWithSubs = ({ children, ...props }) => (
  <StyledOption {...props}>
    {children}
    <CarretRight ml="auto" size="s3" />
  </StyledOption>
)

export default OptionWithSubs
