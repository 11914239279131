import styled, { css } from 'styled-components'

const getTransition = ({ isVisible, theme }) => {
  const { easing, velocities } = theme.animations

  return css`
    transition-property: opacity, transform;
    transition-duration: ${isVisible ? velocities.v3 : velocities.v1};
    transition-timing-function: ${isVisible ? easing.decelerate : easing.accelerate};
    transition-delay: 0s;
  `
}

const ErrorMessageContainer = styled.div`
  align-items: center;
  display: flex;
  position: relative;

  ${({ isVisible, theme }) => `
    opacity: ${isVisible ? 1 : 0};
    transform: translateY(${isVisible ? 0 : `-${theme.sizes.s3}`});
  `}

  ${getTransition};
`

export default ErrorMessageContainer
