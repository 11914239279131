// @noSnapshot
import React, { useEffect, useState } from 'react'

import { head, keys } from 'lodash'

const CodeEditorContext = React.createContext()

const useNoResponsive = () => {
  const context = React.useContext(CodeEditorContext)

  const { hasNoResponsive } = context
  if (!context) {
    throw new Error(
      `CodeEditor compound components cannot be rendered outside the CodeEditor component`,
    )
  }
  return [hasNoResponsive]
}

const useLanguage = () => {
  const context = React.useContext(CodeEditorContext)

  const { languages, onLanguageChange, selectedLanguage } = context
  if (!context) {
    throw new Error(
      `CodeEditor compound components cannot be rendered outside the CodeEditor component`,
    )
  }
  return [selectedLanguage, languages, onLanguageChange]
}

const useValue = () => {
  const context = React.useContext(CodeEditorContext)

  const { value } = context
  if (!context) {
    throw new Error(
      `CodeEditor compound components cannot be rendered outside the CodeEditor component`,
    )
  }
  return [value]
}

const CodeEditorProvider = ({
  children,
  values,
  selectedLanguage: selectedLangFromProps,
  hasNoResponsive,
}) => {
  const [selectedLanguage, setLanguage] = useState(selectedLangFromProps || head(keys(values)))

  useEffect(() => {
    if (selectedLangFromProps && selectedLangFromProps !== selectedLanguage) {
      setLanguage(selectedLangFromProps)
    }
  }, [selectedLangFromProps])

  const getLanguageValue = (values, selectedLanguage) => values[selectedLanguage] || ''

  return (
    <CodeEditorContext.Provider
      value={{
        hasNoResponsive: hasNoResponsive,
        languages: keys(values),
        onLanguageChange: setLanguage,
        selectedLanguage,
        value: getLanguageValue(values, selectedLanguage),
      }}
    >
      {children}
    </CodeEditorContext.Provider>
  )
}

export { CodeEditorProvider, CodeEditorContext, useLanguage, useNoResponsive, useValue }
