import styled from 'styled-components'
import PropTypes from 'prop-types'
import { shouldReturnWhiteOrBlackText } from '../../../helpers'
import { Caption } from '../../Typography'

/**
 * The notification text, for this input. For example, this can be an error notification.
 */
const TagText = styled(Caption)`
  display: inline-flex;
  color: ${({ backgroundColor, theme }) =>
    !!backgroundColor
      ? shouldReturnWhiteOrBlackText(theme.colors[backgroundColor] || backgroundColor)
      : theme.components.tag.text};
  user-select: none;
`

TagText.propTypes = {
  // The tag text color.
  backgroundColor: PropTypes.string,
}

export default TagText
