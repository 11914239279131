import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Body2Medium } from '../../Typography';
import genericButtonStyle from '../helpers/genericButtonStyle';

const getColor = (secondary, isNeutral, isDark, attr = 'text') => {
  const { neutral, outlineDark } = secondary;
  if (isDark) {
    return outlineDark[attr];
  }
  return isNeutral ? neutral[attr] : secondary[attr];
};

const SecondaryButton = styled(Body2Medium.withComponent('button'))`
  ${genericButtonStyle};

  ${({ neutral, outlineDark, theme: { components: { button: { secondary } }, globals: { borderRadius } } }) => `
    &:before {
      content: ' ';
      position: absolute;
      border: ${secondary.borderWidth} solid ${getColor(secondary, neutral, outlineDark, 'borderColor')};
      border-radius: ${borderRadius.borderRadius1};
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    svg * {
      fill: ${getColor(secondary, neutral, outlineDark, 'text')};
    }

    color: ${getColor(secondary, neutral, outlineDark, 'text')};
    background: ${getColor(secondary, neutral, outlineDark, 'background')};

    &:active:not(.is-disabled):not(.is-loading) {
      &:before {
        border: ${secondary.borderPressedWidth} solid ${getColor(secondary, neutral, outlineDark, 'borderPressedColor')};
      }
      svg * {
        fill: ${getColor(secondary, neutral, outlineDark, 'textPressed')};
      }
      color: ${getColor(secondary, neutral, outlineDark, 'textPressed')};
      background: ${getColor(secondary, neutral, outlineDark, 'backgroundPressed')};
    }

    &:hover:not(.is-loading):not(.is-disabled):not(:active):not(:focus) {
      &:before {
        border: ${secondary.borderHoverWidth} solid ${getColor(secondary, neutral, outlineDark, 'borderHoverColor')};
      }
      svg * {
        fill: ${getColor(secondary, neutral, outlineDark, 'textHover')};
      }
      color: ${getColor(secondary, neutral, outlineDark, 'textHover')};
      background: ${getColor(secondary, neutral, outlineDark, 'backgroundHover')};
    }

    &:focus:not(:active):not(.is-loading) {
      &:before {
        border: ${secondary.borderFocusWidth} solid ${getColor(secondary, neutral, outlineDark, 'borderFocusColor')};
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      svg * {
        fill: ${getColor(secondary, neutral, outlineDark, 'textFocus')};
      }
      color: ${getColor(secondary, neutral, outlineDark, 'textFocus')};
      background: ${getColor(secondary, neutral, outlineDark, 'backgroundFocus')};
    }

    &.is-loading {
      &:before {
        border: ${secondary.borderLoadingWidth} solid ${getColor(secondary, neutral, outlineDark, 'borderLoadingColor')};
      }
      background: ${getColor(secondary, neutral, outlineDark, 'backgroundLoading')};
      color: ${secondary.hidden};

      // Loader colors.
      > * {
        border-color: ${getColor(secondary, neutral, outlineDark, 'loader')};
        border-top-color: ${secondary.hidden};
      }
    }
  `}
`;

SecondaryButton.propTypes = {
  neutral: PropTypes.bool,
  outlineDark: PropTypes.bool,
};

export default SecondaryButton;
