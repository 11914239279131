import React, { useReducer, useState } from 'react'
import { SortOrder } from '../config'

/** Context providing the number of column */
const HeaderCellNumberContext = React.createContext(undefined)
/** Context providing if the table has infinite scroller or not */
const isInfiniteContext = React.createContext(false)
/** Context providing if the table is loading or not */
const IsLoadingContext = React.createContext(false)
/** Context providing if the table is selectable or not */
const IsSelectableContext = React.createContext(false)
/** Context providing all checkbox ids (row id) and if they are checked or not */
const IdsContext = React.createContext(undefined)
/** Context providing the table attributes (mainly for accessibility and style purposes) */
const TableContext = React.createContext(undefined)

/** Context providing all header cell ids and the sorting order (ascending, descending or none) */
const SortedHeadersContext = React.createContext(undefined)

const Actions = {
  REGISTER: 'register',
  REMOVE: 'remove',
  RESET: 'reset',
  SELECT: 'select',
  SELECT_ALL: 'selectAll',
  UNSELECT: 'unselect',
  UNSELECT_ALL: 'unselectAll',
}



const listIdsReducer = (state, { id, type }) => {
  switch (type) {
    case Actions.SELECT:
      return {
        ...state,
        [id]: true,
      }
    case Actions.REGISTER:
    case Actions.UNSELECT:
      return {
        ...state,
        [id]: false,
      }
    case Actions.SELECT_ALL:
      return Object.keys(state).reduce((acc, id) => ({ ...acc, [id]: true }), {})
    case Actions.UNSELECT_ALL:
      return Object.keys(state).reduce((acc, id) => ({ ...acc, [id]: false }), {})
    case Actions.RESET:
      return {}
    case Actions.REMOVE: {
      const { id, ...newState } = state
      return newState
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`)
    }
  }
}

const sortedHeadersReducer = (_, { id, sortDir }) => {
  let newOrder;

  if (sortDir === SortOrder.ASC) {
    newOrder = SortOrder.DESC;
  } else if (sortDir === SortOrder.DESC) {
    newOrder = SortOrder.NONE;
  } else {
    newOrder = SortOrder.ASC;
  }

  return id ? { [id]: newOrder } : {};
};

const useHeaderCellNumber = () => {
  const context = React.useContext(HeaderCellNumberContext)

  if (context === undefined) {
    throw new Error(
      'DataList / DataTable compound components cannot be rendered outside the DataList / DataTable component',
    )
  }

  const { headerCellNumber, setHeaderCellNumber } = context
  return [headerCellNumber, setHeaderCellNumber]
}

const useIds = () => {
  const context = React.useContext(IdsContext)

  if (context === undefined) {
    throw new Error(
      'DataList / DataTable compound components cannot be rendered outside the DataList / DataTable component',
    )
  }
  const { listIds, dispatch } = context

  const register = (id) => dispatch({ type: Actions.REGISTER, id })
  const remove = (id) => dispatch({ type: Actions.REMOVE, id })
  const reset = () => dispatch({ type: Actions.RESET })
  const select = (id) => dispatch({ type: Actions.SELECT, id })
  const selectAll = () => dispatch({ type: Actions.SELECT_ALL })
  const unselect = (id) => dispatch({ type: Actions.UNSELECT, id })
  const unselectAll = () => dispatch({ type: Actions.UNSELECT_ALL })

  return [listIds, { register, remove, reset, select, selectAll, unselect, unselectAll }]
}

const useSortedHeaders = () => {
  const context = React.useContext(SortedHeadersContext)

  if (context === undefined) {
    throw new Error(
      'DataList / DataTable compound components cannot be rendered outside the DataList / DataTable component',
    )
  }
  const { sortedHeaders, setSortedHeaders } = context

  return [sortedHeaders, setSortedHeaders]
}

const useIsInfinite = () => {
  const context = React.useContext(isInfiniteContext)

  const isInfinite = context
  if (context === undefined) {
    throw new Error(
      'DataList / DataTable compound components cannot be rendered outside the DataList / DataTable component',
    )
  }
  return isInfinite
}

const useIsLoading = () => {
  const context = React.useContext(IsLoadingContext)

  if (context === undefined) {
    throw new Error(
      'DataList / DataTable compound components cannot be rendered outside the DataList / DataTable component',
    )
  }
  const isLoading = context
  return isLoading
}

const useIsSelectable = () => {
  const context = React.useContext(IsSelectableContext)

  if (context === undefined) {
    throw new Error(
      'DataList / DataTable compound components cannot be rendered outside the DataList / DataTable component',
    )
  }
  const isSelectable = context
  return isSelectable
}

const useTableContext = () => {
  const context = React.useContext(TableContext)

  if (context === undefined) {
    throw new Error(
      'DataList / DataTable compound components cannot be rendered outside the DataList / DataTable component',
    )
  }
  return context
}

const TableProvider = ({
  children,
  name,
  isInfinite = false,
  isLoading = false,
  isSelectable = false,
  outerBorder = true,
}) => {
  const [listIds, dispatch] = useReducer(listIdsReducer, {})
  const [headerCellNumber, setHeaderCellNumber] = useState(undefined)
  const [sortedHeaders, setSortedHeaders] = useReducer(sortedHeadersReducer, {})

  return (
    <TableContext.Provider value={{ name, outerBorder }}>
      <SortedHeadersContext.Provider
        value={{
          sortedHeaders,
          setSortedHeaders,
        }}
      >
        <HeaderCellNumberContext.Provider
          value={{
            headerCellNumber,
            setHeaderCellNumber,
          }}
        >
          <IdsContext.Provider
            value={{
              listIds,
              dispatch,
            }}
          >
            <IsSelectableContext.Provider value={isSelectable}>
              <IsLoadingContext.Provider value={isLoading}>
                <isInfiniteContext.Provider value={isInfinite}>
                  {children}
                </isInfiniteContext.Provider>
              </IsLoadingContext.Provider>
            </IsSelectableContext.Provider>
          </IdsContext.Provider>
        </HeaderCellNumberContext.Provider>
      </SortedHeadersContext.Provider>
    </TableContext.Provider>
  )
}

export {
  TableProvider,
  useHeaderCellNumber,
  useIds,
  useIsInfinite,
  useIsLoading,
  useIsSelectable,
  useSortedHeaders,
  useTableContext,
}
