import styled from 'styled-components'
import PropTypes from 'prop-types'
import visuallyHidden from '../../VisuallyHidden'

// Get position style or return visually hidden depending on the state
const getPositionStyle = (direction, showSubOptions) =>
  showSubOptions ? positionStyles[direction] : visuallyHidden

const positionStyles = {
  bottom: 'top: 100%; width: 100%;',
  left: 'top: 0; right: 100%; width: 100%;',
  right: 'top: 0; left: 100%; width: 100%;',
  top: 'top: 0; transform: translateY(-100%); width: 100%;',
}

const OptionsPanelContainer = styled.div`
  position: absolute;
  ${({ subOptionsDirection, showSubOptions }) =>
    getPositionStyle(subOptionsDirection, showSubOptions)}
`

OptionsPanelContainer.propTypes = {
  // Direct where to render the options
  subOptionsDirection: PropTypes.oneOf(['bottom', 'left', 'right', 'top']).isRequired,

  // Indicates if sub options are displayed or not.
  showSubOptions: PropTypes.bool,
}

export default OptionsPanelContainer
