import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const GeoTagOutline = props => (
    <StyledIcon viewBox="0 0 12 14" {...props}>
      <g fillRule="nonzero">
        <circle cx="6" cy="6" r="1.714" />
        <path d="M5.4 13.457c-.086-.086-3.6-3.171-3.6-3.257C.6 9.171 0 7.629 0 6c0-3.343 2.657-6 6-6s6 2.657 6 6c0 1.629-.6 3.171-1.8 4.286-.086.085-3.514 3.171-3.6 3.257a.918.918 0 0 1-1.2-.086zM3.086 9.171L6 11.743 8.914 9.17c.857-.857 1.372-1.885 1.372-3.085C10.286 3.686 8.4 1.8 6 1.8 3.6 1.8 1.714 3.6 1.714 6c0 1.2.515 2.314 1.372 3.171 0-.085 0-.085 0 0z" />
      </g>
    </StyledIcon>
  )

export default styled(GeoTagOutline)``
