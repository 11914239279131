import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Home = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M12 15h-1.6a1.59 1.59 0 0 1-1.6-1.579v-2.368H7.2v2.368a1.59 1.59 0 0 1-1.6 1.58H4a1.59 1.59 0 0 1-1.6-1.58V8.684H.8a.8.8 0 0 1-.738-.487.782.782 0 0 1 .173-.86l7.2-7.106a.808.808 0 0 1 1.131 0l7.2 7.106a.781.781 0 0 1 .174.86.8.8 0 0 1-.739.487h-1.6v4.737c0 .871-.718 1.58-1.6 1.58zM6.4 9.474h3.2c.442 0 .8.354.8.79v3.157h1.602L12 7.894c0-.435.358-.789.8-.789h.469L8 1.906 2.733 7.105H3.2c.442 0 .8.354.8.79v5.526h1.6v-3.158c0-.436.359-.79.8-.79z"/></g>
    </StyledIcon>
  )

export default styled(Home)``
  