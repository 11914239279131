import styled from 'styled-components'
import CheckboxRadio from '../../CheckboxRadio'

const StyledCheckboxRadioGroup = styled(CheckboxRadio)`
  ${({ theme, horizontalAlign, noPaddingTop }) => `
    ${
      horizontalAlign
        ? `
      align-items:center;
      &:not(:last-child) {
        margin-right: ${theme.sizes.margins.m3}
      }
      `
        : !noPaddingTop && `padding-top: ${theme.sizes.s3}`
    }
  `};
`

export default StyledCheckboxRadioGroup
