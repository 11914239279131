import React from 'react'

const IntegrationSVG = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.5001 36.5005C8.46248 33.4628 8.46248 28.5379 11.5001 25.5003L14.5858 22.4145C15.3669 21.6335 16.6332 21.6335 17.4142 22.4145L25.5864 30.5861C26.3675 31.3672 26.3675 32.6336 25.5864 33.4146L22.5001 36.5007C19.4624 39.5381 14.5376 39.538 11.5001 36.5005Z'
      fill='#090E12'
    />
    <path
      d='M22.4142 17.4146C21.6332 16.6335 21.6332 15.3672 22.4142 14.5862L25.5001 11.5003C28.5376 8.46272 33.4624 8.46264 36.5001 11.5001C39.538 14.5377 39.5379 19.463 36.5001 22.5006L33.4142 25.5863C32.6332 26.3673 31.3669 26.3672 30.5858 25.5862L22.4142 17.4146Z'
      fill='#090E12'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.4142 34.5858C14.1953 35.3668 14.1953 36.6332 13.4142 37.4142L9.41421 41.4142C8.63317 42.1953 7.36683 42.1953 6.58579 41.4142C5.80474 40.6332 5.80474 39.3668 6.58579 38.5858L10.5858 34.5858C11.3668 33.8047 12.6332 33.8047 13.4142 34.5858Z'
      fill='#090E12'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.0607 21.9393C22.6464 22.5251 22.6464 23.4749 22.0607 24.0607L18.0607 28.0607C17.4749 28.6464 16.5251 28.6464 15.9393 28.0607C15.3536 27.4749 15.3536 26.5251 15.9393 25.9393L19.9393 21.9393C20.5251 21.3536 21.4749 21.3536 22.0607 21.9393Z'
      fill='#090E12'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M26.0607 25.9393C26.6464 26.5251 26.6464 27.4749 26.0607 28.0607L22.0607 32.0607C21.4749 32.6464 20.5251 32.6464 19.9393 32.0607C19.3536 31.4749 19.3536 30.5251 19.9393 29.9393L23.9393 25.9393C24.5251 25.3536 25.4749 25.3536 26.0607 25.9393Z'
      fill='#090E12'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M41.4142 6.58579C42.1953 7.36683 42.1953 8.63317 41.4142 9.41421L37.4142 13.4142C36.6332 14.1953 35.3668 14.1953 34.5858 13.4142C33.8047 12.6332 33.8047 11.3668 34.5858 10.5858L38.5858 6.58579C39.3668 5.80474 40.6332 5.80474 41.4142 6.58579Z'
      fill='#090E12'
    />
  </svg>
)

export default IntegrationSVG
