import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Resize = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M10 4.667H6c-.736 0-1.333.597-1.333 1.333v4c0 .736.597 1.333 1.333 1.333h4c.736 0 1.333-.597 1.333-1.333V6c0-.735-.597-1.333-1.333-1.333zM9.998 10H6V6h4l-.002 4zm4.669 3.724l-2.196-2.195-.942.942 2.195 2.196H12V16h4v-4h-1.333v1.724zM4 1.334V0H0v4h1.333V2.276L3.53 4.471l.942-.942-2.195-2.196H4zm-.471 10.195l.942.942-2.195 2.196H4V16H0v-4h1.333v1.724l2.196-2.195zM12 1.333V0h4v4h-1.333V2.276L12.47 4.471l-.942-.942 2.195-2.196H12z"/></g>
    </StyledIcon>
  )

export default styled(Resize)``
  