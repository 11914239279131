import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Shield = props => (
    <StyledIcon viewBox="0 0 10 14" {...props}>
      <g>
        <path
          d="M11.25 3C9.937 3 7.841 1.944 6.45.9a.747.747 0 0 0-.9 0C4.159 1.944 2.063 3 .75 3a.75.75 0 0 0-.75.75c0 4.188 2.026 7.363 5.705 8.94a.75.75 0 0 0 .59 0C9.976 11.112 12 7.937 12 3.75a.75.75 0 0 0-.75-.75zM6 11.178V6.75H1.962a9.028 9.028 0 0 1-.442-2.32C2.966 4.186 4.677 3.334 6 2.423V6.75h4.038C9.379 8.726 8.02 10.232 6 11.178z"
          fillRule="nonzero"
        />
      </g>
    </StyledIcon>
  )

export default styled(Shield)``
