import React from 'react'

// Allows to set cards state in template without props drilling or passing setState function to children
// Props can get consumed both in the pricing template and in the <Slider/>
const NavigationContext = React.createContext({
  // Set as true for menu child tree
  isNavigationChild: false,
})

export default NavigationContext
