import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const MagicWand = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M0 14.735l9.488-9.488 1.265 1.265L1.265 16 0 14.735zM10.753 3.982l2.531-2.531 1.265 1.265-2.53 2.531-1.266-1.265zm1.327 4.954l.463-1.728L16 8.134l-.463 1.728-3.457-.926zm-2.236 5.353l-.926-3.457 1.729-.463.926 3.458-1.729.462zM7.867 0l.926 3.456-1.728.463L6.14.463 7.867 0zM2.174 4.429l3.457.926-.463 1.727-3.457-.926.463-1.727z"/></g>
    </StyledIcon>
  )

export default styled(MagicWand)``
  