import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Paperclip = props => (
    <StyledIcon viewBox="0 0 8 16" {...props}>
      <g><path d="M3.846 16C1.618 16 0 14.238 0 11.81V5.333C0 2.293 2.15 0 5 0s5 2.293 5 5.333v5.334H8.462V5.333c0-2.207-1.457-3.81-3.462-3.81s-3.462 1.603-3.462 3.81v6.477c0 1.328.714 2.666 2.308 2.666 1.594 0 2.308-1.338 2.308-2.666V6.095c0-.458-.112-1.524-1.154-1.524-1.042 0-1.154 1.066-1.154 1.524v5.334H2.308V6.095C2.308 4.272 3.39 3.048 5 3.048s2.692 1.224 2.692 3.047v5.715c0 2.428-1.617 4.19-3.846 4.19z"/></g>
    </StyledIcon>
  )

export default styled(Paperclip)``
  