import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const  MxToolboxLogo = props =>(
    <StyledIcon viewBox='0 0 20 12' {...props}>
      <defs>
        <mask id="plus">
          <circle cx="15.5" cy="7.5" fill="white" r="4.5"/>
          <path
            d="M15.5570819,6.79951059 L16.73565,5.64310667 L17.4360132,6.35689333 L16.2709972,7.5 L17.4360132,8.64310667 L16.73565,9.35689333 L15.5570819,8.20048941 L14.3785138,9.35689333 L13.6781506,8.64310667 L14.8431666,7.5 L13.6781506,6.35689333 L14.3785138,5.64310667 L15.5570819,6.79951059 Z"
            fill="black"
          />
        </mask>
      </defs>

      <g>
        <path
          d="M13.5,1.84145371 L13.5,0.5 L8.30901699,0.5 L6.9880391,3.14195578 L5.73553148,0.5 L0.5,0.5 L0.5,10.5 L4.5,10.5 L4.5,4.11721778 L7,8.49221778 L9.49991959,4.11735849 L9.50006288,7.4723535 C9.51171329,4.91164166 11.1411476,2.67398866 13.5,1.84145371 Z M9.50006527,7.52822431 L9.50019219,10.5 L10.3026398,10.5 C9.78660493,9.60793165 9.50497374,8.58936191 9.50006527,7.52822431 Z"
        />
        <circle cx="15.5" cy="7.5" mask="url(#plus)" r="4.5"/>
      </g>
    </StyledIcon>
  )

export default styled(MxToolboxLogo)``