import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const VideoPlaceholder = props => (
    <StyledIcon viewBox="0 0 40 40" {...props}>
      <g><path d="M22.133 19a.833.833 0 0 0 .834-.058l2.075-1.442a.833.833 0 0 0 .375-.717.892.892 0 0 0-.375-.725L23 14.667a.833.833 0 0 0-1.3.725v2.833a.833.833 0 0 0 .433.775zm.367-3.65l2.042 1.417v.075L22.5 18.225V15.35zm9.583-6.875a1.25 1.25 0 0 1 1.25 1.25v14.167c0 .69-.56 1.25-1.25 1.25h-17.5a1.258 1.258 0 0 1-1.25-1.25V9.725c0-.69.56-1.25 1.25-1.25h17.5zM16.667 24.308v-3.333h-2.5v2.917a.425.425 0 0 0 .416.416h2.084zm0-4.166v-1.667h-2.5v1.667h2.5zm0-2.5v-1.667h-2.5v1.667h2.5zm0-2.5v-1.667h-2.5v1.667h2.5zm0-2.5V9.308h-2.084a.425.425 0 0 0-.416.417v2.917h2.5zm12.5 0V9.308H17.5v15h11.667V12.642zm3.333 11.25v-2.917H30v3.333h2.083a.425.425 0 0 0 .417-.416zm0-3.75v-1.667H30v1.667h2.5zm0-2.5v-1.667H30v1.667h2.5zm0-2.5v-1.667H30v1.667h2.5zm0-2.5V9.725a.425.425 0 0 0-.417-.417H30v3.334h2.5zM22.167 39.167h3.25V40h-3.25v-.833zm8.333 0c.376.006.748-.08 1.083-.25l.417.666a2.875 2.875 0 0 1-1.5.417h-1.833v-.833H30.5zM37.167 0A2.809 2.809 0 0 1 40 2.833V30.5a2.809 2.809 0 0 1-2.833 2.833h-3.834v3H32.5v-3h-23A2.808 2.808 0 0 1 6.667 30.5v-23H3.583v-.833h3.084V2.833A2.808 2.808 0 0 1 9.5 0h27.667zm2 30.5V2.833a2.025 2.025 0 0 0-2-2H9.5a2.025 2.025 0 0 0-2 2V30.5a2.025 2.025 0 0 0 2 2h27.667a2.025 2.025 0 0 0 2-2zm-23.584 8.667h3.25V40h-3.25v-.833zM0 27.75h.833V31H0v-3.25zm9.083 11.417h3.25V40h-3.25v-.833zM0 21.25h.833v3.25H0v-3.25zm0 16.333V34.25h.833v3.25L0 37.583zM0 14.75h.833V18H0v-3.25zM0 9.5c.01-.5.154-.99.417-1.417l.666.417c-.17.305-.256.65-.25 1v2H0v-2zM2.583 40l.084-.833h3.166V40h-3.25z"/></g>
    </StyledIcon>
  )

export default styled(VideoPlaceholder)``
  