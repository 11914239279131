import styled from 'styled-components'
import { Body2Medium } from '../../Typography'

const Placeholder = styled(Body2Medium.withComponent('div'))`
  color: ${({ theme }) => theme.components.select.text};
  padding-bottom: ${({ theme }) => theme.sizes.s1};
  padding-top: ${({ theme }) => theme.sizes.s1};

  &:disabled {
    cursor: not-allowed;
  }
`

export default Placeholder
