import * as React from 'react'
import styled from 'styled-components'

import cx from 'classnames'

import BadgePanel from './styles/BadgePanel'
import StyledRoundNotification from './styles/StyledRoundNotification'
import { CaptionMedium } from '../Typography'


/**
 * Badge Component.
 */
class Badge extends React.Component {
  static defaultProps = {
    backgroundColor: 'html',
    fontColor: 'snow',
    notif: false,
    notifType: 'warning',
    roundLeft: true,
    roundRight: true,
    width: 'auto',
  }

  render() {
    const {
      backgroundColor,
      className,
      'data-testid': dataTestId,
      fontColor,
      locator,
      message,
      notif,
      notifColor,
      notifType,
      roundLeft,
      roundRight,
      style,
      width,
    } = this.props

    const newClassNames = cx(className, locator)

    return (
      <BadgePanel
        backgroundColor={backgroundColor}
        className={newClassNames}
        roundLeft={roundLeft}
        roundRight={roundRight}
        style={style}
        width={width}
      >
        {notif && (
          <StyledRoundNotification color={notifColor ?? ''} type={notifType ?? 'warning'} />
        )}

        {typeof message === 'object' ? (
          <div data-testid={dataTestId}>{message}</div>
        ) : (
          <CaptionMedium color={fontColor} data-testid={dataTestId}>
            {message}
          </CaptionMedium>
        )}
      </BadgePanel>
    )
  }
}

export { Badge }
export default styled(Badge)``
