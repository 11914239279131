import styled from 'styled-components'
import { gridHelper } from '@utils/GridHelper'
import {
  styleForDesktop,
  styleForMobile,
  styleForTablet,
  styleForTabletAndMobile,
} from '@utils'

export const BottomSectionChildren = styled.div`
  grid-column: 1 / 12;
  ${gridHelper(12, ['.', 10, '.'])}
  order: 99;

  ${styleForDesktop} {
    & > * {
      grid-column: 2 / 12;
    }
  }

  ${styleForTablet} {
    ${gridHelper(6, [6])}
    grid-column: 1 / 7;
  }

  ${styleForTabletAndMobile} {
    order: -99;
  }

  ${styleForMobile} {
    ${gridHelper(4, [4])}
    grid-column: 1 / 5;
  }
`
