// @noSnapshot
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { isObject } from 'lodash'
import { Body2Medium } from '../../../Typography'

const Div2Medium = Body2Medium.withComponent('div')

const AccordionText = styled(({ children, ...rest }) => {
  const Component = isObject(children) ? Div2Medium : Body2Medium
  return <Component {...rest}>{children}</Component>
})`
  cursor: ${({ locked }) => (locked ? 'default' : 'pointer')};
  color: ${({ theme }) => theme.components.accordion.label};
`

AccordionText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

export default AccordionText
