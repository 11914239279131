import styled from 'styled-components'

const OverflowDiv = styled.div`
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;

  ${({ theme, isInfinite }) =>
    isInfinite &&
    `
    margin-bottom: ${theme.sizes.s5};
  `}
`

export default OverflowDiv
