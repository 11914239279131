import React from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { some } from 'lodash'

import { useIds, useIsInfinite, useIsSelectable, useTableContext } from '../hooks'
import { CONTROLS_CLASS_SUFFIX } from '../config'
import CheckboxRadio from '../../CheckboxRadio'
import ControlsContainer from './ControlsContainer'
import CustomControlsContainer from './CustomControlsContainer'
import visuallyHidden from '../../VisuallyHidden'
import { Body2 } from '../../Typography'

const SelectAll = styled.div`
  display: flex;
  cursor: pointer;
`

const SelectAllCheckbox = styled(CheckboxRadio)`
  label {
    ${visuallyHidden};
  }
`

export function Controls({
  children,
  className,
  selectAllItemsLabel = 'Select all items',
  selectedItemsLabel = 'Items selected',
}) {
  const isSelectable = useIsSelectable()
  const isInfinite = useIsInfinite()
  const [listIds, { selectAll, unselectAll }] = useIds()
  const { name: tableName } = useTableContext()

  const checkboxIsChecked = some(listIds)

  const onChangeCheckbox = checkboxIsChecked ? unselectAll : selectAll

  return (
    <>
      <ControlsContainer
        className={cx(className, `${tableName}${CONTROLS_CLASS_SUFFIX}`)}
        isInfinite={isInfinite}
      >
        {isSelectable ? (
          <SelectAll role="status">
            <SelectAllCheckbox
              checked={checkboxIsChecked}
              id={`${tableName}-check-all`}
              label={selectAllItemsLabel}
              onChange={onChangeCheckbox}
              type="checkbox"
            />
            {checkboxIsChecked ? (
              <Body2 onClick={onChangeCheckbox}>
                {`${Object.keys(listIds).reduce(
                  (acc, key) => acc + Number(listIds[key]),
                  0,
                )} ${selectedItemsLabel}`}
              </Body2>
            ) : null}
          </SelectAll>
        ) : (
          <></>
        )}
        <CustomControlsContainer>{children}</CustomControlsContainer>
      </ControlsContainer>
    </>
  )
}


export default styled(Controls)``
