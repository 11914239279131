import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const MobilePhone = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8.2-.001H1.8c-.882 0-1.6.717-1.6 1.6v12.8c0 .883.718 1.6 1.6 1.6h6.4c.882 0 1.6-.717 1.6-1.6v-12.8c0-.882-.718-1.6-1.6-1.6zm0 1.6v1.6H1.8v-1.6h6.4zm0 3.2v6.4H1.8v-6.4h6.4zm-6.4 9.6v-1.6h6.4v1.6H1.8z"/></g>
    </StyledIcon>
  )

export default styled(MobilePhone)``
  