import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const DataComputer = props => (
    <StyledIcon viewBox="0 0 13 11.38" {...props}>
      <g><path d="M11.92 0H1.08A1.08 1.08 0 0 0 0 1.08v7a1.08 1.08 0 0 0 1.08 1.13H6v1.08H3.79v1.08h5.42v-1.08H7V9.21h4.88A1.08 1.08 0 0 0 13 8.13v-7A1.08 1.08 0 0 0 11.92 0zm0 1.08V6.5H1.08V1.08zm-10.83 7v-.5h10.83v.54z"/><path d="M4.87 5.42A1.63 1.63 0 0 0 6.5 3.79H4.87V2.17a1.63 1.63 0 0 0 0 3.25z"/><path d="M5.42 1.63v1.62H7a1.63 1.63 0 0 0-1.58-1.62zm2.7 0h1.63v1.08H8.12zm-.54 1.62h2.17v1.08H7.58zM6.5 4.88h3.25v1.08H6.5z"/></g>
    </StyledIcon>
  )

export default styled(DataComputer)``
  