import React from 'react'
import styled from 'styled-components'

import { StyledIcon } from 'component-library/icons'

import Button from '../../Button'

const StyledButton = styled(Button)`
  ${({ theme: { components, sizes } }) => `
    padding: ${sizes.s3} ${sizes.s2};
    ${StyledIcon} {
      fill: ${components.pagination.icon.color};
      height: ${sizes.s3};
      min-height: ${sizes.s3};
      min-width: ${sizes.s3};
      width: ${sizes.s3};
    }
  `}
`

const PaginationIcon = (props) => <StyledButton mode="icon" noFocus {...props} />

export default styled(PaginationIcon)``
