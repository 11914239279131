import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Flag = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M14.6 3.2H9V.8a.8.8 0 0 0-.8-.8H1.8a.8.8 0 0 0-.8.8V16h1.6V9.6h1.6V12a.8.8 0 0 0 .8.8h9.6a.8.8 0 0 0 .8-.8V4a.8.8 0 0 0-.8-.8zm-12-1.6h4.8V8H2.6V1.6zm11.2 9.6H6.931l1.835-1.834A.795.795 0 0 0 9 8.8v-4h4.8v6.4z"/></g>
    </StyledIcon>
  )

export default styled(Flag)``
  