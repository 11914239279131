// @noSnapshot

import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import { margins, paddings } from '../../helpers/injectedStyle/spaces'
import useOptions from './hooks/useOptions'


function hoverOptions({ disabled = false, readOnly = false, theme }) {
  return readOnly
    ? `
    cursor: default;
  `
    : disabled
    ? `
    cursor: not-allowed;
    background-color: ${theme.components.optionPanel.option.background};
  `
    : `
    cursor: pointer;
    background-color: ${theme.components.optionPanel.option.backgroundHover};
    color: ${theme.components.optionPanel.option.textHover};
    fill: ${theme.components.optionPanel.option.textHover};
  `
}

const StyledOption = styled.div.attrs(({ href, target }) => ({
  as: !isEmpty(href) ? 'a' : undefined,
  rel: !isEmpty(href) && target === '_blank' ? 'noreferrer noopener' : undefined,
}))`
  ${({ disabled, theme }) => `
    padding: ${theme.sizes.s3} ${theme.sizes.s4};
    color: ${theme.components.optionPanel.option.text};
    fill: ${theme.components.optionPanel.option.text};
    background-color: ${theme.components.optionPanel.option.background};
    ${disabled && `opacity: ${theme.globals.opacity.o1};`};
  `};

  &:hover {
    ${hoverOptions}
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;

  ${margins}
  ${paddings}

  ${({ withTextWrapEllipsis }) =>
    withTextWrapEllipsis &&
    `
    & > * {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`

function Option({ children, href, id, onClick, useRouter, ...props }) {
  const { onOptionClick, withTextWrapEllipsis } = useOptions()
  const realAs = useRouter ? RouterLink : null

  function handleClick(e) {
    !href && e.preventDefault()
    e.stopPropagation()
    onOptionClick && onOptionClick(id)
    onClick && onClick(e)
  }

  return (
    <StyledOption
      {...props}
      as={realAs}
      href={!useRouter ? href : null}
      onClick={!props.disabled ? handleClick : null}
      to={useRouter ? href : null}
      withTextWrapEllipsis={withTextWrapEllipsis}
    >
      {children}
    </StyledOption>
  )
}

Option.propTypes = {
  /**
   * Url for this Option.
   * Providing this prop will turn it into a link.
   */
  href: PropTypes.string,
  /** The Option's id. */
  id: PropTypes.bool,
  /** Function called when the Option is clicked. */
  onClick: PropTypes.func,
}

export default Option
