import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const CheckPlain = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><circle cx="6" cy="6" r="4.5"/><path d="M6 0a6 6 0 1 0 6 6 6 6 0 0 0-6-6zm-.45 9.45l-3-2.25.9-1.2 1.8 1.35 3.15-4.2 1.2.9zM10 4"/></g>
    </StyledIcon>
  )

export default styled(CheckPlain)``
  