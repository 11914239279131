import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const FileCheck = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M2 4.61h4v1H2zm0 2.05h3v1H2z"/><path d="M8.1 3.08v4.61h1V2.35L6.79 0H1a1 1 0 0 0-1 1v9.23a1 1 0 0 0 1 1h5.58v-1H1V1h5.07v2.08z"/><path d="M11.28 8.35l-2.17 2.2L8 9.38l-.72.72L9.11 12 12 9.08z"/></g>
    </StyledIcon>
  )

export default styled(FileCheck)``
  