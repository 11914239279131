import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Calendar = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g fillRule="evenodd">
        <path d="M14,14.9336 L2,14.9336 C1.448,14.9336 1,14.4546 1,13.8666 L1,6.3996 L15,6.3996 L15,13.8666 C15,14.4546 14.552,14.9336 14,14.9336 M2,3.2006 L14,3.2006 C14.552,3.2006 15,3.6786 15,4.2666 L15,5.3336 L1,5.3336 L1,4.2666 C1,3.6786 1.448,3.2006 2,3.2006 M14,2.1336 L12,2.1336 L12,0.5336 C12,0.2386 11.776,-0.0004 11.5,-0.0004 C11.224,-0.0004 11,0.2386 11,0.5336 L11,2.1336 L5,2.1336 L5,0.5336 C5,0.2386 4.776,-0.0004 4.5,-0.0004 C4.224,-0.0004 4,0.2386 4,0.5336 L4,2.1336 L2,2.1336 C0.897,2.1336 0,3.0906 0,4.2666 L0,13.8666 C0,15.0426 0.897,15.9996 2,15.9996 L14,15.9996 C15.103,15.9996 16,15.0426 16,13.8666 L16,4.2666 C16,3.0906 15.103,2.1336 14,2.1336" />
      </g>
    </StyledIcon>
  )

export default styled(Calendar)``
