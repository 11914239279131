import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const LightBulb = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8 0C4.416 0 1.5 2.871 1.5 6.4c0 3.529 2.916 6.4 6.5 6.4s6.5-2.871 6.5-6.4C14.5 2.871 11.584 0 8 0zm.813 11.128v-1.9l2.075-1.363-.9-1.33L8 7.837 6.013 6.534l-.9 1.33 2.074 1.364v1.9C4.886 10.745 3.125 8.774 3.125 6.4c0-2.647 2.186-4.8 4.875-4.8s4.875 2.153 4.875 4.8c0 2.374-1.76 4.345-4.063 4.728zM5.563 15.2v-1.6h4.875v1.6H8.812v.8H7.188v-.8H5.563z"/></g>
    </StyledIcon>
  )

export default styled(LightBulb)``
  