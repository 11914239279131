import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AlignCenter = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M7 9V7H2V3h5V0h2v3h5v4H9v2h3v4H9v3H7v-3H4V9h3z"/></g>
    </StyledIcon>
  )

export default styled(AlignCenter)``
  