import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const FileError = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M10.032 6.884L8.5 8.426 6.968 6.884 5.947 7.912l1.532 1.543-1.532 1.542 1.021 1.028L8.5 10.483l1.532 1.542 1.021-1.028-1.532-1.542 1.532-1.543-1.021-1.028zM11.688 0H3.444C2.648 0 2 .653 2 1.455v13.09A1.45 1.45 0 0 0 3.444 16h10.112A1.45 1.45 0 0 0 15 14.545V3.335L11.688 0zM3.444 14.545V1.455h7.223v2.909h2.889v10.181H3.444z"/></g>
    </StyledIcon>
  )

export default styled(FileError)``
  