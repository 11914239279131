import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const PaperAirplane = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g>
        <path
          d="M3.556 9.616v4.933c0 .705.802 1.007 1.303.504l2.306-2.718 4.712 3.523c.4.302 1.002.1 1.103-.403L15.987.858c.1-.604-.4-1.007-1.002-.806L.448 5.892c-.501.2-.602.905-.2 1.308L1.85 8.408l4.612-2.215c.401-.2.702.302.401.504l-3.308 2.92z"
          fillRule="nonzero"
        />
      </g>
    </StyledIcon>
  )

export default styled(PaperAirplane)``
