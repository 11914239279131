import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const RssContent2 = props => (
    <StyledIcon  {...props}>
      <g><path d="M45 4.33H3a3 3 0 0 0-3 3v34a3 3 0 0 0 3 3h42a3 3 0 0 0 3-3v-34a3 3 0 0 0-3-3zm1 37a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-34a1 1 0 0 1 1-1h42a1 1 0 0 1 1 1z"/><path d="M16 28.33a4 4 0 1 0 4 4 4 4 0 0 0-4-4zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm-4-15.66v2c8.27 0 15 7 15 15.66h2c0-9.74-7.63-17.66-17-17.66z"/><path d="M12 10.33v2a24 24 0 0 1 24 24h2a26 26 0 0 0-26-26z"/></g>
    </StyledIcon>
  )

export default styled(RssContent2)``
  