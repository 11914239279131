import styled from 'styled-components'
import { resetCSS } from '../../../helpers'
import { Body2 } from '../../Typography'

const SelectedTextLabel = styled(Body2).attrs(resetCSS)`
  user-select: none;
  cursor: text;
  position: absolute;
  color: ${({ theme }) => theme.components.select.selectedText};
  // styles to truncate long label text
  max-width: calc(100% - 45px);
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export default SelectedTextLabel
