const { sanitizeTrailingSlash } = require('./sanitizeTrailingSlash')

/**
 * Allows you to generate a formatted url.
 * @param {string} location : text indicating the base of the url,
 * @param {string} name : name to format and add after location argument,
 * @param {string} splitter : split element to the name argument
 */
const getFormatedChildPageSlug = (location, name, splitter) =>
  getFormatedStringForSlug(
    sanitizeTrailingSlash(
      location +
        '/' +
        name.toLowerCase().split(splitter.toLowerCase())[0].toLowerCase(),
    ),
  )

/**
 * Generate the name on lowercase, and ' ' replace by '-' for slug.
 * @param {string} name name to format
 */
const getFormatedStringForSlug = (name) => name.replace(/ /g, '-').toLowerCase()

/**
 * Give the correct categories name for integration pages.
 * @param {string} name
 */

const getIntegrationsCategoriesFormatedSlug = (name) =>
  getFormatedStringForSlug(name).split('-&')[0]

module.exports = {
  getFormatedChildPageSlug,
  getFormatedStringForSlug,
  getIntegrationsCategoriesFormatedSlug,
}
