import React from 'react'
import styled from 'styled-components'

const ArrowPreviousSVG = (props) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g id='Arrow-Previous'>
      <g id='Vector'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.70711 2.29289C7.31658 1.90237 6.68342 1.90237 6.29289 2.29289L1.29289 7.29289C0.902369 7.68342 0.902369 8.31658 1.29289 8.70711L6.29289 13.7071C6.68342 14.0976 7.31658 14.0976 7.70711 13.7071C8.09763 13.3166 8.09763 12.6834 7.70711 12.2929L3.41421 8L7.70711 3.70711C8.09763 3.31658 8.09763 2.68342 7.70711 2.29289Z'
          fill='#007874'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15 8C15 7.44772 14.5523 7 14 7H2C1.44771 7 1 7.44772 1 8C1 8.55228 1.44771 9 2 9H14C14.5523 9 15 8.55228 15 8Z'
          fill='#007874'
        />
      </g>
    </g>
  </svg>
)

export default styled(ArrowPreviousSVG)``
