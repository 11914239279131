import styled from 'styled-components'
import { ArrowBack } from 'component-library/icons'

const NavigationArrow = styled(ArrowBack).attrs(() => ({
  size: '7px',
}))`
  ${({ isBackwards }) => isBackwards && 'transform: rotate(180deg)'};
  fill: ${({ theme }) => theme.colors.grey};
`

export default NavigationArrow
