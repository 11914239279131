import React from 'react'

import PropTypes from 'prop-types'

import { SteppedSliderProvider } from './hooks'

import Bar from './components/Bar'
import Labels from './components/Labels'

const SteppedSlider = ({ children, onChange, selectedStepId, steps }) => (
  <SteppedSliderProvider onChange={onChange} selectedStepId={selectedStepId} steps={steps}>
    {children}
  </SteppedSliderProvider>
)

SteppedSlider.Bar = Bar
SteppedSlider.Labels = Labels

SteppedSlider.propTypes = {
  /**
   * Id of the selected step.
   */
  selectedStepId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /**
   * Array of objects describing the steps displayed in SteppedSlider.Labels.
   */
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      /** Used to identify a label. */
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /** Step label. */
      label: PropTypes.string,
    }),
  ).isRequired,

  /**
   * Function called when the selected step changes.
   * (selectedStepId: string|number) => void
   */
  onChange: PropTypes.func,
}

export default SteppedSlider
