import styled from 'styled-components'

const Header = styled.div`
  ${({ theme }) => `
    padding: ${`${theme.sizes.s3} ${theme.sizes.s4}`};  
    background-color: ${theme.colors['blacksteel'] || 'blacksteel'};
  `};
`

export default Header
