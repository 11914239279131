import styled from 'styled-components'
import StyledSkeleton from '../styles/StyledSkeleton'

const BadgeSkeleton = styled(StyledSkeleton)`
  ${({ theme }) => `
    border-radius: ${theme.globals.borderRadius.bR3};
    height: ${theme.sizes.s4};
    width: ${theme.sizes.s9};
    
    &:before {
      border-radius: ${theme.globals.borderRadius.bR3};
    }
  `}
`

export default BadgeSkeleton
