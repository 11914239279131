import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const MultipleCog = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M10.72 6.83a4.19 4.19 0 0 1-5.55-5.55 4.66 4.66 0 0 0-1.91.78L2.4 1.2 1.2 2.4l.86.86a4.7 4.7 0 0 0-.7 1.54H0v2.4h1.36a4.72 4.72 0 0 0 .71 1.54l-.87.86 1.2 1.2.86-.86a4.7 4.7 0 0 0 1.54.71V12h2.4v-1.36a4.71 4.71 0 0 0 1.54-.71l.86.86 1.2-1.2-.86-.86a4.66 4.66 0 0 0 .78-1.9z"/><path d="M12 3.55v-1.1h-.9a2.16 2.16 0 0 0-.21-.53l.56-.57-.81-.8-.57.56A2.14 2.14 0 0 0 9.54.9V0H8.45v.9a2.15 2.15 0 0 0-.53.21L7.36.55l-.82.82.57.57a2.18 2.18 0 0 0-.22.53H6v1.07h.89a2.2 2.2 0 0 0 .22.53l-.57.57.82.82.57-.56a2.16 2.16 0 0 0 .53.21V6h1.08v-.89a2.19 2.19 0 0 0 .53-.21l.57.56.82-.82-.56-.57a2.16 2.16 0 0 0 .21-.53zM9 4.2A1.2 1.2 0 1 1 10.2 3 1.2 1.2 0 0 1 9 4.2z"/></g>
    </StyledIcon>
  )

export default styled(MultipleCog)``
  