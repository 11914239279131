import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Templates = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M3.2 4H8v1.6H3.2V4zm0 3.2H8v1.6H3.2V7.2zm0 3.2h9.6V12H3.2v-1.6zM9.6 4h3.2v4.8H9.6V4zm4.8-4H1.6C.718 0 0 .718 0 1.6v12.8c0 .882.718 1.6 1.6 1.6h12.8c.882 0 1.6-.718 1.6-1.6V1.6c0-.882-.718-1.6-1.6-1.6zm-.002 14.4H1.6V1.6h12.8l-.002 12.8z"/></g>
    </StyledIcon>
  )

export default styled(Templates)``
  