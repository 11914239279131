import { useState } from 'react'
import axios from 'axios'
import { getCookieByName } from '@utils'

const useFormWithCaptcha = ({
  callback = () => null,
  endpoint = '/.netlify/functions/send-zapier-form-with-captcha',
  name,
  onError = (error) => console.log('Error on the submit form: ' + error),
}) => {
  const [fieldsState, setFieldsState] = useState()
  const onFieldChange = ({ fieldName, value }) => {
    setFieldsState({
      ...fieldsState,
      [fieldName]: value,
    })
  }

  // Submit newsletter subscription according to the current brand and lang
  const onSubmit = (token, encodeUrl) => {
    fieldsState.token = token
    // Set gclid cookie as form input
    if (typeof window !== 'undefined')
      fieldsState.gclid = getCookieByName('gclid')
    // Include form Contentful name
    if (name) fieldsState['submitted-form'] = name
    // If an external link is used
    if (encodeUrl) fieldsState.encodeUrl = encodeUrl
    axios({
      method: 'post',
      url: endpoint,
      data: fieldsState,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(callback)
      .catch((error) => {
        onError(error.response)
      })
  }

  return [onFieldChange, onSubmit]
}

export default useFormWithCaptcha
