import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ChevronRight = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M9.475 7.96L3 14.328 4.721 16 13 7.96 4.721 0 3 1.672z"/></g>
    </StyledIcon>
  )

export default styled(ChevronRight)``
  