import React, { useState, createContext, useContext } from 'react';

// Base context for input ID and local value management
const BaseContext = createContext(undefined);

// Event context for focus state management
const EventContext = createContext(undefined);

// InfoBar context for input counter state and info/error management
const InfoBarContext = createContext(undefined);

// Input context for passing down input-related props
const InputContext = createContext(undefined);

// Custom hook to use the input counter state
const useCounter = () => {
  const context = useContext(InfoBarContext);
  if (!context) {
    throw new Error(`Input compound components cannot be rendered outside the Input component`);
  }
  return context.inputCounterState;
};

// Custom hook to use the focus state
const useFocus = () => {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error(`Input compound components cannot be rendered outside the Input component`);
  }
  return context;
};

// Custom hook to use the info status and error state
const useInfoStatus = () => {
  const context = useContext(InfoBarContext);
  if (!context) {
    throw new Error(`Input compound components cannot be rendered outside the Input component`);
  }
  const { hasInfo, isError, setHasInfo, setIsError } = context;
  return { hasInfo, isError, setHasInfo, setIsError };
};

// Custom hook to use the input context
const useInputContext = () => {
  const context = useContext(InputContext);
  if (!context) {
    throw new Error(`Input compound components cannot be rendered outside the Input component`);
  }
  return context;
};

// Custom hook to use the input ID
const useInputId = () => {
  const context = useContext(BaseContext);
  if (!context) {
    throw new Error(`Input compound components cannot be rendered outside the Input component`);
  }
  const { inputId, setInputId } = context;
  return { inputId, setInputId };
};

// Custom hook to use the local value
const useLocalValue = (initialValue = '') => {
  const context = useContext(BaseContext);
  if (!context) {
    throw new Error(`Input compound components cannot be rendered outside the Input component`);
  }
  const { localValue = initialValue, setLocalValue } = context;
  return { localValue, setLocalValue };
};

// InputProvider component to provide context values to its children
const InputProvider = ({
  children,
  autoFocus,
  cantTypeWhenLoading,
  disabled,
  isLoading,
  infoStatus,
  maxLength,
  readOnly,
  required,
  size,
}) => {
  const isFocusState = useState(false);
  const [inputId, setInputId] = useState('');
  const inputCounterState = useState(undefined);
  const [localValue, setLocalValue] = useState('');
  const [hasInfo, setHasInfo] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <InputContext.Provider
      value={{
        autoFocus,
        cantTypeWhenLoading,
        disabled,
        isLoading,
        infoStatus,
        maxLength,
        readOnly,
        required,
        size,
      }}
    >
      <EventContext.Provider value={isFocusState}>
        <BaseContext.Provider value={{ inputId, setInputId, localValue, setLocalValue }}>
          <InfoBarContext.Provider
            value={{
              inputCounterState,
              hasInfo,
              setHasInfo,
              isError,
              setIsError,
            }}
          >
            {children}
          </InfoBarContext.Provider>
        </BaseContext.Provider>
      </EventContext.Provider>
    </InputContext.Provider>
  );
};

export {
  InputProvider,
  useCounter,
  useFocus,
  useInfoStatus,
  useInputContext,
  useInputId,
  useLocalValue,
};
