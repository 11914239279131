const React = require('react')

const GlobalContext = React.createContext({
  alternativeTheme: null,
  breadcrumbData: {},
  isDevServer: false,
  locale: '',
  location: {},
  siteConfig: {},
})

module.exports = GlobalContext
