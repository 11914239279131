import styled, { css } from 'styled-components'

const getTransition = ({ isOpened, theme }) => {
  const { easing, velocities } = theme.animations
  return css`
    transition-property: opacity, visibility;
    transition-duration: ${isOpened ? velocities.v3 : velocities.v1}, 0s;
    transition-timing-function: ${isOpened ? easing.decelerate : easing.accelerate};
    transition-delay: 0s, ${isOpened ? '0s' : velocities.v1};
  `
}

const AnimContainer = styled('div')`
  ${({ isOpened, isPortal }) => `
    position: relative;
    z-index: ${isPortal ? 1 : 3};

    opacity: ${isOpened ? 1 : 0};
    visibility: ${isOpened ? 'visible' : 'hidden'};
  `}
  ${getTransition};
`

export default AnimContainer
