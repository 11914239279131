import styled from 'styled-components'
import RoundNotification from '../../RoundNotification'

const StyledRoundNotification = styled(RoundNotification)`
  position: absolute;
  right: ${({ theme }) => `-${theme.sizes.s2}`};
  top: -3px;
  z-index: 1;
`

export default StyledRoundNotification
