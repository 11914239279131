// @noSnapshot
import React from 'react';

import CheckboxRadio from '../../CheckboxRadio';
import OptionBlock from '../styles/OptionBlock';
import { Caption } from '../../Typography';
import Tooltip from '../../Tooltip';

class Checkbox extends React.Component {
  static defaultProps = {
    isSelected: false,
  };

  render() {
    const {
      'data-testid': dataTestId,
      disabled,
      id,
      ellipsis,
      isChecked,
      isSelected,
      label,
      locator,
      onClick,
      readOnly,
      rightLabel,
      tooltip,
    } = this.props;

    return (
      <Tooltip {...tooltip}>
        <OptionBlock
          className={locator}
          data-testid={dataTestId}
          disabled={disabled}
          isSelected={isSelected || isChecked}
          onClick={onClick}
          readOnly={readOnly}
        >
          <CheckboxRadio
            checked={isSelected || isChecked}
            disabled={disabled}
            ellipsis={ellipsis}
            id={id}
            label={label}
            readOnly={readOnly}
            type="checkbox"
          />

          {/* Right label. */}
          {rightLabel && <Caption ml="s3">{rightLabel}</Caption>}
        </OptionBlock>
      </Tooltip>
    );
  }
}

export default Checkbox;
