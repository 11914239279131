import React, { useContext, useEffect, useState } from 'react'

import { findIndex } from 'lodash'

const SteppedSliderContext = React.createContext()

const useSteppedSlider = () => useContext(SteppedSliderContext)

function SteppedSliderProvider({ children, steps, selectedStepId, onChange }) {
  const [selectedId, setSelectedId] = useState(selectedStepId)

  useEffect(() => {
    setSelectedId(selectedStepId)
  }, [selectedStepId])

  useEffect(() => {
    if (steps.length === 0) {
      // eslint-disable-next-line no-console
      console.warn('The steps prop in SteppedSlider is empty.')
      return
    }

    const stepIndex = findIndex(steps, step => step.id === selectedId)
    if (stepIndex === -1) {
      throw new Error('SelectedStepId does not match any step id from the steps prop.')
    }
  }, [steps])

  const changeSelectedId = (newSelectedId) => {
    setSelectedId(newSelectedId)
    onChange?.(newSelectedId)
  }

  return (
    <SteppedSliderContext.Provider value={{ selectedId, changeSelectedId, steps }}>
      {children}
    </SteppedSliderContext.Provider>
  )
}

export { SteppedSliderProvider, useSteppedSlider }
