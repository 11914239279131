import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const EditImage = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><circle cx="3.5" cy="5" r="1"/><path d="M5.5 5.5l-1 2h2l-1-2zm-2 1L2 8.5h2.5v-1l-1-1z"/><path d="M1 1h5v2h2v2h1V2.29L6.71 0H1a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3.5v-1H1zm10.85 6.15l-1.5-1.5a.5.5 0 0 0-.71 0l-3.5 3.5a.5.5 0 0 0-.14.23l-.5 2a.5.5 0 0 0 .61.61l2-.5a.5.5 0 0 0 .23-.13l3.5-3.5a.5.5 0 0 0 .01-.71zm-4.11 3.4l-1.06.26L7 9.76l1.5-1.55.79.79zM10 8.29l-.79-.79.79-.79.79.79z"/></g>
    </StyledIcon>
  )

export default styled(EditImage)``
  