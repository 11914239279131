import React from 'react'
import styled from 'styled-components'

const CrossMenuSVG = ({ ...props }) => (
  <svg
    width='24px'
    height='32px'
    viewBox='0 0 24 32'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...props}
  >
    <title>Cross-Menu</title>
    <defs>
      <rect id='path-1' x='0' y='0' width='24' height='32'></rect>
    </defs>
    <g id='Header' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Menu-V2-Mobile-Open' transform='translate(-16.000000, -16.000000)'>
        <g id='Cross-Menu' transform='translate(16.000000, 16.000000)'>
          <mask id='mask-2' fill='white'>
            <use xlinkHref='#path-1'></use>
          </mask>
          <g id='Rectangle'></g>
          <path
            d='M2.6515625,29 L2.6515625,27.8339844 L2.56367187,25.8242188 L3.71210937,29 L4.315625,29 L5.46699219,25.8212891 L5.37910156,27.8339844 L5.37910156,29 L6.2609375,29 L6.2609375,24.734375 L5.10664062,24.734375 L4.01679687,27.828125 L2.92109375,24.734375 L1.77265625,24.734375 L1.77265625,29 L2.6515625,29 Z M11.4878906,29 L11.4878906,28.2939453 L9.50742187,28.2939453 L9.50742187,27.1513672 L11.1949219,27.1513672 L11.1949219,26.4628906 L9.50742187,26.4628906 L9.50742187,25.4462891 L11.4820312,25.4462891 L11.4820312,24.734375 L8.62851562,24.734375 L8.62851562,29 L11.4878906,29 Z M14.4824219,29 L14.4824219,26.1933594 L16.1933594,29 L17.0722656,29 L17.0722656,24.734375 L16.1962891,24.734375 L16.1962891,27.546875 L14.4824219,24.734375 L13.6035156,24.734375 L13.6035156,29 L14.4824219,29 Z M21.0335937,29.0585938 C21.5394531,29.0585938 21.9383789,28.9228516 22.2303711,28.6513672 C22.5223633,28.3798828 22.6683594,28.0107422 22.6683594,27.5439453 L22.6683594,27.5439453 L22.6683594,24.734375 L21.7865234,24.734375 L21.7865234,27.5732422 C21.7787109,28.0927734 21.5277344,28.3525391 21.0335937,28.3525391 C20.7972656,28.3525391 20.612207,28.2885742 20.478418,28.1606445 C20.3446289,28.0327148 20.2777344,27.8291016 20.2777344,27.5498047 L20.2777344,27.5498047 L20.2777344,24.734375 L19.3988281,24.734375 L19.3988281,27.5761719 C19.4046875,28.0371094 19.5541016,28.3994141 19.8470703,28.6630859 C20.1400391,28.9267578 20.5355469,29.0585938 21.0335937,29.0585938 Z'
            id='MENU'
            fill='#FFFFFF'
            fillRule='nonzero'
            mask='url(#mask-2)'
          ></path>
          <path
            d='M4,10 L20,10 M12,2 L12,18'
            id='Combined-Shape'
            stroke='#FFFFFF'
            strokeWidth='2'
            strokeLinecap='square'
            mask='url(#mask-2)'
            transform='translate(12.000000, 10.000000) rotate(45.000000) translate(-12.000000, -10.000000) '
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default styled(CrossMenuSVG)``
