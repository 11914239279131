import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const CheckboxUnchecked = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M9.84 1.2a1 1 0 0 1 1 1v7.64a1 1 0 0 1-1 1H2.16a1 1 0 0 1-1-1V2.16a1 1 0 0 1 1-1h7.68m0-1.2H2.16A2.15 2.15 0 0 0 0 2.16v7.68A2.15 2.15 0 0 0 2.16 12h7.68A2.15 2.15 0 0 0 12 9.84V2.16A2.15 2.15 0 0 0 9.84 0z"/></g>
    </StyledIcon>
  )

export default styled(CheckboxUnchecked)``
  