import { mapValues, merge, omitBy, transform } from 'lodash'
import { mailjet } from 'component-library/components'
import { findCurrentSiteConfig } from '@utils/Gatsby-node/siteArchitectureHelpers'

const { colors: mailjetColors } = mailjet
// These colors are referring to the design system documentation, see https://www.figma.com/file/nSInMAfczlcbNEdvX5ZSeV/Sinch-Library?node-id=3231%3A59559
const colors = {
  neutral0: '#FFFFFF',
  neutral05: '#F7F9FA',
  neutral10: '#EBEEF0',
  neutral20: '#DCE2E5',
  neutral30: '#C9D1D6',
  neutral35: '#B7C1C7',
  neutral40: '#9EA9B0',
  neutral50: '#808A91',
  neutral60: '#626C73',
  neutral70: '#4B575E',
  neutral80: '#272F36',
  neutral90: '#1A2126',
  neutral95: '#14181C',
  neutral100: '#090E12',

  raspberry05: '#FFF6F5',
  raspberry10: '#FFE5E3',
  raspberry20: '#FFD8D6',
  raspberry30: '#FFBDBD',
  raspberry35: '#FFA8A8',
  raspberry40: '#FF8080',
  raspberry50: '#EB5454',
  raspberry60: '#BD3C41',
  raspberry70: '#993138',
  raspberry80: '#57181C',
  raspberry90: '#3D1215',

  pumpkin05: '#FFF7F0',
  pumpkin10: '#FFE9D6',
  pumpkin20: '#FFD9BD',
  pumpkin30: '#FFC299',
  pumpkin35: '#FFAD75',
  pumpkin40: '#F58B4E',
  pumpkin50: '#D66A33',
  pumpkin60: '#AD4E24',
  pumpkin70: '#8F3D1F',
  pumpkin80: '#4D2215',
  pumpkin90: '#361811',

  mud05: '#FFF8F5',
  mud10: '#FFE9E0',
  mud20: '#FAD9CD',
  mud30: '#EFC7BA',
  mud35: '#E5B5A5',
  mud40: '#CC9A8B',
  mud50: '#AD7D6F',
  mud60: '#8C5F54',
  mud70: '#734D45',
  mud80: '#3D2A26',
  mud90: '#291D1B',

  honey05: '#FFF9EB',
  honey10: '#FCECCB',
  honey20: '#FFDD99',
  honey30: '#FFCA61',
  honey35: '#FFBE3C',
  honey40: '#EB9605',
  honey50: '#C77904',
  honey60: '#9E5A00',
  honey70: '#824703',
  honey80: '#472706',
  honey90: '#331B06',

  grass05: '#ECFFEB',
  grass10: '#D7F5D7',
  grass20: '#C2EDC5',
  grass30: '#99E0A2',
  grass35: '#77D486',
  grass40: '#53BD69',
  grass50: '#3D9956',
  grass60: '#2B7845',
  grass70: '#23613C',
  grass80: '#163323',
  grass90: '#102419',

  tropical05: '#F0FAF4',
  tropical10: '#E2F0E8',
  tropical20: '#CEE5DA',
  tropical30: '#AFDBCD',
  tropical35: '#80CFBC',
  tropical40: '#51B8A6',
  tropical50: '#06998B',
  tropical60: '#007874',
  tropical70: '#006063',
  tropical80: '#0C333B',
  tropical90: '#0E2329',

  ocean05: '#F2F9FF',
  ocean10: '#E0F1FF',
  ocean20: '#C7E5FF',
  ocean30: '#A8D4FF',
  ocean35: '#8AC4FF',
  ocean40: '#61ABFF',
  ocean50: '#3089F0',
  ocean60: '#0D68D1',
  ocean70: '#1454A8',
  ocean80: '#112F57',
  ocean90: '#0F2138',

  violet05: '#F8F5FF',
  violet10: '#EFEBFF',
  violet20: '#E2DBFF',
  violet30: '#D0C8FA',
  violet35: '#C1B6FA',
  violet40: '#A89BFA',
  violet50: '#857AEB',
  violet60: '#645DC2',
  violet70: '#4D4AA8',
  violet80: '#2A2A54',
  violet90: '#1E1E33',

  candy05: '#FFF5FC',
  candy10: '#FFE5F7',
  candy20: '#FFD6F3',
  candy30: '#FFBDEC',
  candy35: '#FAA5E3',
  candy40: '#EB83CF',
  candy50: '#D15CB4',
  candy60: '#A3488E',
  candy70: '#8A3778',
  candy80: '#472140',
  candy90: '#2E192A',
}

// Get colors with new primary colors setup
const getColors = (siteConf) => {
  const { primary, secondary, strongBackground } = siteConf.colors
  // Duplicate a set of color using a new naming
  const buildColorSet = (themeColor, newColorName) => {
    const omitColors = omitBy(
      colors,
      (_value, key) => !key.includes(themeColor),
    )
    return transform(omitColors, (array, val, key) => {
      key = key.replace(themeColor, newColorName)
      array[key] = val
    })
  }

  const primaryColors = buildColorSet(primary, 'primary')
  const secondaryColors = buildColorSet(secondary, 'secondary')
  // New colors are including designers theme colors + specific set of colors for the current brand
  const newColors = {
    get surfaceMinimal() {
      return this.neutral05
    },
    get surfacePrimary() {
      return this.neutral0
    },
    get surfaceSecondary() {
      return this.neutral10
    },
    get strongBackground() {
      return this[strongBackground]
    },
    ...primaryColors,
    ...secondaryColors,
    ...colors,
    // Special design system aliases, the same are used in Figma Mockups. Needs getter since it is self referencing values
    get iconPrimary() {
      return this.neutral0
    },
    get surfaceBanner() {
      return this.primary10
    },
    get surfaceBannerHover() {
      return this.primary20
    },
    get surfaceHeaderDeveloper() {
      return this.ocean10
    },
    get surfaceHeaderMarketing() {
      return this.primary10
    },
    get textLight() {
      return this.neutral100
    },
    get textQuote() {
      return this.primary60
    },
    get buttonPrimaryText() {
      return this.neutral0
    },
  }

  return merge({}, mailjetColors, newColors)
}

export const colorsToDarkMode = (theme) => {
  const { colors } = theme
  const {
    colors: { primary, secondary },
  } = findCurrentSiteConfig()
  // Most of the following colors are equivalent from the color list François provided, no aliases are needed for the most part
  const dictionaryLightToDark = {
    ...colors,
    neutral0: 'neutral100',
    neutral05: 'neutral95',
    neutral100: 'neutral0',
    neutral10: 'neutral100',
    neutral20: 'neutral80',
    neutral30: 'neutral70',
    neutral40: 'neutral60',
    neutral50: 'neutral50',
    neutral60: 'neutral40',
    neutral70: 'neutral30',
    neutral80: 'neutral20',
    neutral90: 'neutral10',
    ocean40: 'ocean60',
    ocean50: 'ocean50',
    ocean60: 'ocean40',
    ocean70: 'ocean35',
    raspberry10: 'neutral100',
    raspberry40: 'raspberry60',
    raspberry50: 'raspberry50',
    raspberry60: 'raspberry40',
    raspberry70: 'raspberry35',
    tropical40: 'tropical60',
    tropical50: 'tropical50',
    tropical60: 'tropical40',
    tropical70: 'tropical35',
    violet10: 'neutral100',
    violet40: 'violet60',
    violet50: 'violet50',
    violet60: 'violet40',
    get primary10() {
      return this[primary + 10]
    },
    get primary60() {
      return this[primary + 40]
    },
    // Some aliases are still needed, these would require specific component based implementation in order to match the design system
    iconPrimary: 'neutral0',
    surfaceHeaderDeveloper: 'ocean90',
    surfaceHeaderMarketing: 'tropical80',
    surfaceMinimal: 'neutral95',
    surfacePrimary: 'neutral90',
    surfaceSecondary: 'neutral100',
    surfaceBanner: 'primary10',
    surfaceBannerHover: 'primary20',
    // Used for specific component like banner where text is not using the regular dark conversion
    textLight: 'neutral100',
    textQuote: 'primary40',
    // Exception to ensure primary button text is not switching from dark to light
    buttonPrimaryText: 'neutral0',
  }

  // Swap colors accordingly to equivalents provided in above
  const darkTheme = {
    ...theme,
    colors: mapValues(colors, (colorLightValue, colorLightLabel) => {
      // Handle primary/secondary exception, switching these to their alias equivalent
      const colorShade =
        colorLightLabel.includes('primary') || colorLightLabel.includes(primary)
          ? primary + colorLightLabel.split('primary')[1]
          : colorLightLabel.includes('secondary') || colorLightLabel.includes(secondary)
          ? secondary + colorLightLabel.split('secondary')[1]
          : colorLightLabel
      return colors[dictionaryLightToDark[colorShade]] || colorLightValue
    }),
  }
  return darkTheme
}

export default getColors
