import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const CarretRight = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M3.75.143C3.305.35 3.013.813 3 1.329v13.333c.008.523.301.992.75 1.2.404.224.895.172 1.25-.133l7.499-6.666c.286-.276.464-.657.5-1.067.02-.424-.17-.829-.5-1.067L4.999.263a1.091 1.091 0 0 0-1.25-.12z"/></g>
    </StyledIcon>
  )

export default styled(CarretRight)``
  