import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Gear = props => (
    <StyledIcon viewBox="0 0 24 24" {...props}>
      <g><path d="M22.703 10.176a3 3 0 01-1.845-4.454c.482-.804.337-1.497-.142-1.974l-.465-.465c-.477-.476-1.17-.622-1.974-.14a3 3 0 01-4.453-1.845C13.598.387 13.004 0 12.329 0h-.658c-.675 0-1.267.387-1.495 1.298a3 3 0 01-4.454 1.845c-.804-.483-1.498-.338-1.975.139l-.465.465c-.477.477-.623 1.171-.14 1.976a3 3 0 01-1.844 4.453C.39 10.402 0 10.995 0 11.671v.658c0 .675.387 1.267 1.298 1.495a3 3 0 011.845 4.454c-.483.803-.338 1.497.14 1.974l.466.465c.478.478 1.172.622 1.974.14a3 3 0 014.453 1.846c.226.91.82 1.297 1.495 1.297h.658c.675 0 1.267-.387 1.495-1.297a3 3 0 014.454-1.845c.802.481 1.495.337 1.974-.142l.465-.465c.477-.477.622-1.17.14-1.974a3 3 0 011.846-4.453c.91-.226 1.297-.82 1.297-1.495v-.658c0-.676-.39-1.268-1.297-1.495zM12 16.5a4.5 4.5 0 110-9 4.5 4.5 0 010 9z"  fillRule="evenodd"/></g>
    </StyledIcon>
  )

export default styled(Gear)``
