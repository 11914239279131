import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Instagram = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M11.845 7.692a3.631 3.631 0 0 0-1.031-2.05 3.653 3.653 0 0 0-5.522.407 3.633 3.633 0 0 0-.68 2.716 3.633 3.633 0 0 0 1.438 2.4 3.629 3.629 0 0 0 2.715.68 3.632 3.632 0 0 0 2.4-1.438 3.633 3.633 0 0 0 .68-2.715zm-3.273 2.85a2.323 2.323 0 0 1-1.737-.435 2.324 2.324 0 0 1-.92-1.536 2.324 2.324 0 0 1 .435-1.736 2.324 2.324 0 0 1 2.222-.92 2.326 2.326 0 0 1 1.97 1.97 2.342 2.342 0 0 1-1.97 2.657zm4.027-7.141a.69.69 0 0 1 .201.485.69.69 0 0 1-.2.485.69.69 0 0 1-.486.2.69.69 0 0 1-.485-.2.69.69 0 0 1-.2-.485.69.69 0 0 1 .2-.485.69.69 0 0 1 .485-.201.69.69 0 0 1 .485.2zM11.677 0H4.323A4.328 4.328 0 0 0 0 4.323v7.354A4.328 4.328 0 0 0 4.323 16h7.354A4.328 4.328 0 0 0 16 11.677V4.323A4.328 4.328 0 0 0 11.677 0zm3.03 11.677c0 1.67-1.36 3.03-3.03 3.03H4.323a3.034 3.034 0 0 1-3.03-3.03V4.323c0-1.67 1.36-3.03 3.03-3.03h7.354c1.67 0 3.03 1.36 3.03 3.03v7.354z"/></g>
    </StyledIcon>
  )

export default styled(Instagram)``
  