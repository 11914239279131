import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const EyeWhip = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M2.725 10.447l1.038-1.044a9.892 9.892 0 0 1-2.009-1.797C2.518 6.646 4.721 4.302 8 4.302c.27 0 .53.02.785.052L10.01 3.12A8.176 8.176 0 0 0 8 2.866c-4.87 0-7.627 4.181-7.741 4.36l-.259.4.266.393c.062.093.913 1.325 2.459 2.428zM15.74 7.225c-.069-.106-1.087-1.639-2.93-2.858l2.836-2.851L14.637.5.73 14.484 1.74 15.5l3.675-3.696A8.586 8.586 0 0 0 8 12.202c4.86 0 7.619-4.011 7.734-4.183L16 7.626l-.26-.4zm-7.742 3.54c-.494 0-.96-.055-1.406-.146l1.362-1.37c.18.05.368.08.565.08a2.149 2.149 0 0 0 2.063-2.722l1.186-1.192a9.91 9.91 0 0 1 2.475 2.191c-.777.927-2.987 3.16-6.245 3.16z"/></g>
    </StyledIcon>
  )

export default styled(EyeWhip)``
  