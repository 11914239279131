import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ImagePlaceholder = props => (
    <StyledIcon viewBox="0 0 40 40" {...props}>
      <g><path d="M26.425 15A1.083 1.083 0 0 0 25 15l-4.633 3.75a.208.208 0 0 1-.134.05.192.192 0 0 1-.141-.058l-.367-.35a.775.775 0 0 0-1.183.133l-1.925 3a.733.733 0 0 0 .65 1.117h12.808a.75.75 0 0 0 .758-.734v-2.741a.742.742 0 0 0-.241-.534L26.425 15zM30 21.808H17.425l1.742-2.766.316.3c.19.188.449.293.717.291.24 0 .472-.082.658-.233l3.334-2.775 1.275-1.042a.267.267 0 0 1 .166-.066.308.308 0 0 1 .175.066L30 19.242v2.566zm-10.833-9.166a1.667 1.667 0 1 1-3.334 0 1.667 1.667 0 0 1 3.334 0zm-2.5 0a.833.833 0 1 0 1.666 0 .833.833 0 0 0-1.666 0zm15.416-4.167a1.25 1.25 0 0 1 1.25 1.25v14.167c0 .69-.56 1.25-1.25 1.25h-17.5a1.258 1.258 0 0 1-1.25-1.25V9.725c0-.69.56-1.25 1.25-1.25h17.5zm.417 15.417V9.725a.425.425 0 0 0-.417-.417h-17.5a.425.425 0 0 0-.416.417v14.167a.425.425 0 0 0 .416.416h17.5a.425.425 0 0 0 .417-.416zM37.167 0A2.809 2.809 0 0 1 40 2.833V30.5a2.809 2.809 0 0 1-2.833 2.833h-3.834v3H32.5v-3h-23A2.808 2.808 0 0 1 6.667 30.5v-23H3.583v-.833h3.084V2.833A2.808 2.808 0 0 1 9.5 0h27.667zm2 30.5V2.833a2.025 2.025 0 0 0-2-2H9.5a2.025 2.025 0 0 0-2 2V30.5a2.025 2.025 0 0 0 2 2h27.667a2.025 2.025 0 0 0 2-2zm-17 8.667h3.25V40h-3.25v-.833zm-6.584 0h3.25V40h-3.25v-.833zm14.917 0c.376.006.748-.08 1.083-.25l.417.666a2.875 2.875 0 0 1-1.5.417h-1.833v-.833H30.5zM0 21.25h.833v3.25H0v-3.25zm0 6.5h.833V31H0v-3.25zm0 9.833V34.25h.833v3.25L0 37.583zm9.083 1.584h3.25V40h-3.25v-.833zm-6.5.833l.084-.833h3.166V40h-3.25zM0 14.75h.833V18H0v-3.25zM0 9.5c.01-.5.154-.99.417-1.417l.666.417c-.17.305-.256.65-.25 1v2H0v-2z"/></g>
    </StyledIcon>
  )

export default styled(ImagePlaceholder)``
  