import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AlignTextCenter = props => (
    <StyledIcon viewBox="0 0 12 10.8" {...props}>
      <g><path d="M.6 0h10.8v1.2H.6zm1.2 2.4h8.4v1.2H1.8zM0 4.8h12V6H0zm1.8 2.4h8.4v1.2H1.8zM.6 9.6h10.8v1.2H.6z"/></g>
    </StyledIcon>
  )

export default styled(AlignTextCenter)``
  