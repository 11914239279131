import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Disabled = props => (
    <StyledIcon viewBox="0 0 24 24" {...props}>
      <g><path d="M22.5 0c.9 0 1.5.6 1.5 1.5v21c0 .9-.6 1.5-1.5 1.5h-21C.6 24 0 23.4 0 22.5v-21C0 .6.6 0 1.5 0zM21 3H3v18h18V3zm-5.85 3.75l2.1 2.1L14.1 12l3.15 3.15-2.1 2.1L12 14.1l-3.15 3.15-2.1-2.1L9.9 12 6.75 8.85l2.1-2.1L12 9.9l3.15-3.15z"  fillRule="evenodd"/></g>
    </StyledIcon>
  )

export default styled(Disabled)``
