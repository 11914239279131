import Breakpoints from '../theme/Breakpoints'

const styleForTabletAndMobile = () =>
  `@media screen and (max-width: ${Breakpoints.tablet.maxBreakpoint}px)`

const styleForMobile = () =>
  `@media screen and (max-width: ${Breakpoints.mobile.maxBreakpoint}px)`

// Only used for card viewer like carousel
const styleForBigMobile = () =>
  `@media screen and (min-width: ${Breakpoints.bigMobile.breakpoint}px) and (max-width: ${Breakpoints.bigMobile.maxBreakpoint}px)`

const styleForTablet = () =>
  `@media screen and (min-width: ${Breakpoints.tablet.breakpoint}px) and (max-width: ${Breakpoints.tablet.maxBreakpoint}px)`

// Only used for card viewer like carousel
const styleForBigTablet = () =>
  `@media screen and (min-width: ${Breakpoints.bigTablet.breakpoint}px) and (max-width: ${Breakpoints.bigTablet.maxBreakpoint}px)`

const styleForDesktop = () =>
  `@media screen and (min-width: ${Breakpoints.desktop.breakpoint}px)`

const styleForDesktopAndTablet = () =>
  `@media screen and (min-width: ${Breakpoints.tablet.breakpoint}px)`

export {
  styleForBigMobile,
  styleForBigTablet,
  styleForDesktop,
  styleForDesktopAndTablet,
  styleForMobile,
  styleForTablet,
  styleForTabletAndMobile,
}
