import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Pinterest = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M15.975 8.223A7.99 7.99 0 0 0 7.87.001C3.496.064-.033 3.677 0 8.05a7.99 7.99 0 0 0 4.5 7.127.226.226 0 0 0 .324-.212c-.02-.514-.003-1.063.1-1.505.101-.435.498-2.117.76-3.222a3.95 3.95 0 0 0 .04-1.64 3.355 3.355 0 0 1-.058-.62c0-1.18.684-2.062 1.536-2.062.725 0 1.075.544 1.075 1.196 0 .728-.464 1.818-.704 2.827-.2.846.424 1.535 1.258 1.535 1.51 0 2.67-1.592 2.67-3.889 0-2.033-1.462-3.455-3.548-3.455-2.416 0-3.834 1.813-3.834 3.686 0 .73.28 1.512.632 1.938a.25.25 0 0 1 .059.243l-.236.963c-.037.156-.123.189-.284.114-1.06-.494-1.724-2.045-1.724-3.29 0-2.679 1.946-5.139 5.611-5.139 2.946 0 5.235 2.1 5.235 4.905 0 2.926-1.845 5.282-4.406 5.282-.677 0-1.322-.277-1.701-.652a.212.212 0 0 0-.356.096c-.133.509-.35 1.332-.419 1.6-.125.48-.389 1.042-.653 1.53-.07.13 0 .29.144.326a8.003 8.003 0 0 0 2.044.245c4.192-.039 7.79-3.564 7.91-7.755z"/></g>
    </StyledIcon>
  )

export default styled(Pinterest)``
  