import styled from 'styled-components'

/**
 * Fix for styling the input range left part of the track on Chrome.
 */
const FilledSliderBackground = styled.span`
  ${({ theme }) => `
    display: block;
    @-moz-document url-prefix() {
      display: none;
    }
    background: ${theme.components.slider.trackFilled};
    height: ${theme.sizes.s2};
    margin-top: -${theme.sizes.s4};
    margin-left: ${theme.sizes.s1};
    margin-bottom: ${theme.sizes.s12};
    position: relative;
    pointer-events: none;
    border-top-left-radius: ${theme.globals.borderRadius.bR3};
    border-bottom-left-radius: ${theme.globals.borderRadius.bR3};
    z-index: 1;
  `}
`

export default FilledSliderBackground
