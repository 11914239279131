import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AddUser = props => (
    <StyledIcon viewBox="0 0 12 10.91" {...props}>
      <g><path d="M4.36 6.55a2.18 2.18 0 1 1 2.19-2.19 2.18 2.18 0 0 1-2.19 2.19zm0-3.27a1.09 1.09 0 1 0 1.09 1.08 1.09 1.09 0 0 0-1.09-1.09zm4.37 7.63H0v-.55c0-1.92 1.79-3.27 4.36-3.27s4.36 1.35 4.36 3.27zM1.18 9.82h6.37C7.22 8.83 6 8.18 4.36 8.18s-2.86.65-3.18 1.64zM12 1.64h-1.64V0H9.27v1.64H7.64v1.09h1.63v1.63h1.09V2.73H12V1.64z"/></g>
    </StyledIcon>
  )

export default styled(AddUser)``
  