import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const UserTalk = props => (
    <StyledIcon viewBox="0 0 12 11.5" {...props}>
      <g><path d="M9 6V5a2 2 0 1 0-2-2 2 2 0 0 0 .4 1.2l-.8.6A3 3 0 0 1 6 3a3 3 0 1 1 3 3z"/><circle cx="6.5" cy="7" r=".5"/><circle cx="7.75" cy="5.75" r=".75"/><path d="M4 7.5a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3a1 1 0 1 0 1 1 1 1 0 0 0-1-1zm4 7H0V11c0-1.77 1.64-3 4-3s4 1.23 4 3zm-6.92-1h5.84C6.62 9.6 5.5 9 4 9s-2.62.6-2.92 1.5z"/></g>
    </StyledIcon>
  )

export default styled(UserTalk)``
  