import { indexOf } from 'lodash';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Body2Medium } from '../../Typography';

import genericButtonStyle from '../helpers/genericButtonStyle';

const getCssFromOperator = (operator) => css`
  &:before {
    content: ' ';
    border-color: ${({ theme }) => theme.components.button.toggle[`border${operator}`]};
  }
  color: ${({ theme }) => theme.components.button.toggle[`text${operator}`]};
  fill: ${({ theme }) => theme.components.button.toggle[`text${operator}`]};
  background-color: ${({ theme }) => theme.components.button.toggle[`background${operator}`]};
`;

const hasBetterPriority = (a, b) => {
  const priorities = ['selected', 'normal', 'disabled', null];
  return indexOf(priorities, a) <= indexOf(priorities, b);
};

const handleBorder = (column, stateBrothers) => {
  if (!stateBrothers) {
    return ``;
  }

  const { right, left, myself } = stateBrothers;

  const hasBorderBottom = !column || hasBetterPriority(myself, right);
  const hasBorderLeft = column || hasBetterPriority(myself, left);
  const hasBorderRight = column || (myself !== right && hasBetterPriority(myself, right));
  const hasBorderTop = !column || (myself !== left && hasBetterPriority(myself, left));
  const hasBorderBottomLeftRadius = (column && !right) || (!column && !left);
  const hasBorderBottomRightRadius = !right;
  const hasBorderTopLeftRadius = !left;
  const hasBorderTopRightRadius = (column && !left) || (!column && !right);

  const borderRadiusCSS = css`
    border-bottom-left-radius: ${({ theme }) =>
      hasBorderBottomLeftRadius ? theme.globals.borderRadius.borderRadius1 : null};
    border-bottom-right-radius: ${({ theme }) =>
      hasBorderBottomRightRadius ? theme.globals.borderRadius.borderRadius1 : null};
    border-top-left-radius: ${({ theme }) =>
      hasBorderTopLeftRadius ? theme.globals.borderRadius.borderRadius1 : null};
    border-top-right-radius: ${({ theme }) =>
      hasBorderTopRightRadius ? theme.globals.borderRadius.borderRadius1 : null};
  `;
  
  return css`
    &:before {
      content: ' ';
      position: absolute;
      border-top: ${({ theme }) => theme.globals.borderWidth.borderWidth1} solid
        ${({ theme }) => theme.components.button.toggle.border};
      border-bottom: ${({ theme }) => theme.globals.borderWidth.borderWidth1} solid
        ${({ theme }) => theme.components.button.toggle.border};
      border-left: ${({ theme }) => theme.globals.borderWidth.borderWidth1} solid
        ${({ theme }) => theme.components.button.toggle.border};
      border-right: ${({ theme }) => theme.globals.borderWidth.borderWidth1} solid
        ${({ theme }) => theme.components.button.toggle.border};
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      border-bottom-style: ${hasBorderBottom ? 'solid' : 'hidden'};
      border-left-style: ${hasBorderLeft ? 'solid' : 'hidden'};
      border-right-style: ${hasBorderRight ? 'solid' : 'hidden'};
      border-top-style: ${hasBorderTop ? 'solid' : 'hidden'};
      ${borderRadiusCSS};
    }
    ${borderRadiusCSS};
  `;
};

/**
 * The button for the Toggle.
 * Handle the is-selected state.
 */
const ToggleButton = styled(Body2Medium.withComponent('button'))`
  ${genericButtonStyle};

  border-radius: 0;
  ${({ column, stateBrothers }) => handleBorder(column, stateBrothers)};

  align-items: center;

  // I don't use getCssFromOperator cause inheritance.
  background-color: ${({ theme }) => theme.components.button.toggle.background};
  color: ${({ theme }) => theme.components.button.toggle.text};
  fill: ${({ theme }) => theme.components.button.toggle.text};

  min-width: 0;
  padding: ${({ theme }) => `${theme.sizes.s3} ${theme.sizes.s4}`};

  &:active:not(.is-disabled):not(.is-loading):not(.is-selected) {
    ${getCssFromOperator('Pressed')};
  }

  &:not(.is-disabled):not(.is-selected):not(.focus):not(.active) {
    ${getCssFromOperator()};
  }

  &:hover:not(.is-loading):not(.is-disabled):not(:active):not(.is-selected):not(:focus),
  &:hover:not(.is-loading):not(.is-disabled):not(:active):not(:focus) {
    ${getCssFromOperator('Hover')};
    &.is-selected {
      ${getCssFromOperator('Active')};
    }
  }

  &.is-selected {
    ${getCssFromOperator('Active')};
  }

  // Text with the icon at left.
  > svg + div {
    margin-left: ${({ theme }) => theme.sizes.s3};
  }
`;

ToggleButton.propTypes = {
  stateBrothers: PropTypes.object,
};

export default ToggleButton;
