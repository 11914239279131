import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Document = props => (
    <StyledIcon viewBox="0 0 18 24" {...props}>
      <g><path d="M18.57.003c.385-.025.762.125 1.035.412.273.287.416.683.393 1.087V16.5l-7.142 7.498H1.431a1.315 1.315 0 01-1.036-.412 1.457 1.457 0 01-.393-1.087V1.502c-.023-.404.12-.8.393-1.087.273-.287.65-.437 1.036-.412zM17.14 3.002H2.86v17.996h8.57V15h5.712V3.002zm-8.57 11.997v1.5H5.716v-1.5h2.857zm5.714-4.499V12h-8.57v-1.5h8.57zm0-4.499v1.5h-8.57v-1.5h8.57z"  fillRule="evenodd"/></g>
    </StyledIcon>
  )

export default styled(Document)``
