import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const HtmlFive = props => (
    <StyledIcon viewBox='0 0 14 16' {...props}>
      <g>
        <path d="M13.738.326A.997.997 0 0 0 13 0H1a1.004 1.004 0 0 0-.996 1.09l1 11c.029.317.208.603.481.767l5 3a1.004 1.004 0 0 0 1.03 0l5-3c.273-.164.452-.449.481-.767l1-11a.998.998 0 0 0-.258-.764zM11 4H5v2h6v5.721l-4 1.333-4-1.333V9h2v1.279l2 .667 2-.667V8H3V2h8v2z"/>
      </g>
    </StyledIcon>
  )
  
export default styled(HtmlFive)``