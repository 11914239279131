import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Web = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M3.493 9.5h-.005l-.654-2.667h.667l.328 1.301.32-1.3h.685l.329 1.3.319-1.3h.685l-.666 2.638.007.028h-.687l-.325-1.328-.329 1.3.008.028h-.682zm3.334 0H6.82l-.654-2.667h.667l.328 1.301.32-1.3h.685l.328 1.3.32-1.3H9.5l-.666 2.64.007.026h-.686l-.326-1.328L7.5 9.473l.006.027h-.68zm3.333 0h-.005L9.5 6.833h.667l.328 1.301.32-1.3h.685l.329 1.3.319-1.3h.685l-.666 2.64.007.026h-.686l-.326-1.328-.328 1.301.007.027h-.681zm5.173-5.333H13.32A6.65 6.65 0 0 0 8 1.5a6.65 6.65 0 0 0-5.319 2.667H.667A.667.667 0 0 0 0 4.833V11.5c0 .369.299.667.667.667H2.68A6.647 6.647 0 0 0 8 14.833a6.647 6.647 0 0 0 5.319-2.666h2.014A.666.666 0 0 0 16 11.5V4.833a.666.666 0 0 0-.667-.666zm-3.824 0h-.842V3.56c.3.176.582.38.842.607zM9.333 3.009v1.158H6.667V3.009A5.284 5.284 0 0 1 8 2.833c.46 0 .907.065 1.333.176zm-4 .551v.607h-.842c.261-.228.542-.431.842-.607zm-.842 8.607h.842v.607a5.36 5.36 0 0 1-.842-.607zm2.176 1.158v-1.158h2.666v1.158A5.278 5.278 0 0 1 8 13.5a5.29 5.29 0 0 1-1.333-.175zm4-.551v-.607h.842c-.26.229-.542.432-.842.607zm4-1.94H1.333V5.5h13.334v5.333z"/></g>
    </StyledIcon>
  )

export default styled(Web)``
  