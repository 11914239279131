import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const CalendarCheck = props => (
    <StyledIcon viewBox="0 0 12 11.85" {...props}>
      <g><path d="M6.07 9.11H1V4h8.1v3.59h1V2.53a1 1 0 0 0-1-1h-.49V0h-1v2.53h-.52v-1H3.54V0h-1v2.53H2v-1H1a1 1 0 0 0-1 1v6.58a1 1 0 0 0 1 1h5.07zm3.04 2.74L7.24 9.97l.71-.71 1.16 1.16 2.17-2.18.72.72-2.89 2.89z"/></g>
    </StyledIcon>
  )

export default styled(CalendarCheck)``
  