import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ProgrammingWindow = props => (
    <StyledIcon viewBox="0 0 12 10.91" {...props}>
      <g><path d="M10.91 0H1.09A1.09 1.09 0 0 0 0 1.09v8.73a1.09 1.09 0 0 0 1.09 1.09h9.82A1.09 1.09 0 0 0 12 9.82V1.09A1.09 1.09 0 0 0 10.91 0zm0 2.18H6.55V1.09h4.36zm-7.09 0V1.09h1.63v1.09zM2.73 1.09v1.09H1.09V1.09zM1.09 9.82V3.27h9.82v6.55z"/><path d="M6.55 7.09h2.73v1.09H6.55zm-3.66-1.8l1.25 1.25L2.89 7.8l.77.77 2.02-2.03-2.02-2.02-.77.77z"/></g>
    </StyledIcon>
  )

export default styled(ProgrammingWindow)``
  