import React, { useRef } from 'react'
import { Button } from 'component-library/components'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { useClickOutside } from '@hooks'

const ButtonMenuContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral10};
    position: relative;

    ${Button} {
      padding: 14px;

      &:active:not(.is-disabled):not(.is-loading),
      &:focus:not(:active):not(.is-loading),
      &:hover:not(.is-loading):not(.is-disabled):not(:active) {
        background-color: ${theme.colors.neutral20};
        svg * {
          fill: ${theme.colors.neutral100};
        }
      }
    }

    svg {
      max-height: ${theme.sizes.s5};
      max-width: ${theme.sizes.s5};
      min-height: ${theme.sizes.s5};
      min-width: ${theme.sizes.s5};

      * {
        fill: ${theme.colors.neutral70};
      }
    }
  `}
`

const SubMenuPanel = styled.div`
  ${({ isOpen, theme }) => css`
    animation: ease ${isOpen ? 'open 0.25s' : 'close 0.15s'};
    background-color: ${theme.colors.neutral0};
    border-radius: ${theme.sizes.s4} 0px ${theme.sizes.s4} ${theme.sizes.s4};
    box-shadow: 0 ${theme.sizes.s1} ${theme.sizes.s3} -${theme.sizes.s1} ${theme.colors.neutral100}29;
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    padding: ${theme.sizes.s4};
    position: absolute;
    right: 0;
    top: ${theme.sizes.menuScrolledHeight};
    width: max-content;
    z-index: 1;

    @keyframes close {
      0% {
        opacity: 1;
        transform: translateY(0) scale(1);
      }
      100% {
        opacity: 0;
        transform: translateY(-10px) scale(0.9);
      }
    }

    @keyframes open {
      0% {
        opacity: 0;
        transform: translateY(-10px) scale(0.9);
      }
      100% {
        opacity: 1;
        transform: translateY(0) scaleY(1);
      }
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      list-style: none;
    }
  `}
`

// On mobile only
const MobileLangSelector = ({ children, icon, mode, ...rest }) => {
  const mobileLangSelectorRef = useRef()
  const [isOpen, setOpen] = useClickOutside(
    mobileLangSelectorRef,
    () => {
      setOpen(!isOpen)
    },
    false,
  )

  const buttonProps = {
    icon,
    mode,
    onClick: () => {
      setOpen(!isOpen)
    },
    small: true,
  }

  return (
    <ButtonMenuContainer {...rest}>
      <div ref={mobileLangSelectorRef}>
        <Button {...buttonProps} />
      </div>

      <SubMenuPanel isOpen={isOpen}>{children}</SubMenuPanel>
    </ButtonMenuContainer>
  )
}

MobileLangSelector.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  icon: PropTypes.object,
  isPrimary: PropTypes.bool,
  label: PropTypes.string,
  mode: PropTypes.string,
}

export default styled(MobileLangSelector)``
