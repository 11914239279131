import styled, { css } from 'styled-components'

import ButtonIcon from './ButtonIcon'

const ButtonIconGrey = styled(ButtonIcon)`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral10};
    border: none;
    box-shadow: none;
    max-height: ${theme.sizes.s8};
    max-width: ${theme.sizes.s8};
    min-height: ${theme.sizes.s8};
    min-width: ${theme.sizes.s8};
    padding: ${theme.sizes.s3};

    p {
      align-items: center;
      display: flex;
    }

    svg {
      max-height: ${theme.sizes.s6};
      max-width: ${theme.sizes.s6};
    }

    svg * {
      fill: ${theme.colors.neutral40};
    }

    &:active:not(.is-disabled):not(.is-loading) {
      background-color: ${theme.colors.neutral10};
      border: none;
      svg * {
        fill: ${theme.colors.neutral50};
      }
    }

    &:focus:not(:active):not(.is-loading) {
      background-color: ${theme.colors.neutral10};
      outline: ${theme.sizes.s1} solid
        ${theme.components.button.primary.borderFocusColor};
      outline-offset: ${theme.sizes.s1};
    }

    &:hover:not(.is-loading):not(.is-disabled):not(:active) {
      background-color: ${theme.colors.neutral05};
      border: none;

      svg * {
        fill: ${theme.colors.neutral35};
      }
    }

    &.is-disabled {
      opacity: 0.4;
    }
  `}
`

export default styled(ButtonIconGrey)``
