import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Crop = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M5.865 7.772l.926 1.485 2.135-3.424 2.71 4.349H4.364l1.501-2.41zm-2.229 3.864H16v1.455H3.636a1.456 1.456 0 0 1-1.454-1.455V5.091h1.454v6.545zm8.728-.727V4.364H0V2.909h12.364c.802 0 1.454.653 1.454 1.455v6.545h-1.454zM2.182 0h1.454v2.182H2.182V0zm10.182 13.818h1.454V16h-1.454v-2.182z"/></g>
    </StyledIcon>
  )

export default styled(Crop)``
  