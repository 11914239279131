import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const GridOutlier = props => (
    <StyledIcon viewBox="0 0 24 24" {...props}>
      <g><path d="M22.5 13.5A1.5 1.5 0 0124 15v7.5a1.5 1.5 0 01-1.5 1.5H15a1.5 1.5 0 01-1.5-1.5V15a1.5 1.5 0 011.5-1.5zM9 13.5a1.5 1.5 0 011.5 1.5v7.5A1.5 1.5 0 019 24H1.5A1.5 1.5 0 010 22.5V15a1.5 1.5 0 011.5-1.5zm12 3h-4.5V21H21v-4.5zm-13.5 0H3V21h4.5v-4.5zM9 0a1.5 1.5 0 011.5 1.5V9A1.5 1.5 0 019 10.5H1.5A1.5 1.5 0 010 9V1.5A1.5 1.5 0 011.5 0zm10.81.44l3.75 3.75a1.5 1.5 0 010 2.12l-3.75 3.75a1.5 1.5 0 01-2.12 0l-3.75-3.75a1.5 1.5 0 010-2.12L17.69.44a1.5 1.5 0 012.12 0zM7.5 3H3v4.5h4.5V3z"  fillRule="evenodd"/></g>
    </StyledIcon>
  )

export default styled(GridOutlier)``
