import { css } from 'styled-components'

/**
 * Inject this style into your component so it can support borders props :
 * - bC for border-color (default to 'smoke') : theme color, HEX or rgb value
 * - bR for border-radius (default to 'bR1'/'borderRadius1') : theme borderRadius or string like '2px'
 * - bS for border-style (default to 'solid') : a string containing one of the authorized style for a border
 * - bW for border-width (default to 'bW1'/'borderWidth1') : theme borderRadius or string like '2px'
 *
 * For activating a border, add :
 * - b_b for bottom border
 * - b_l for left border
 * - b_r for right border
 * - b_t for top border
 */
export default ({
  b_b,
  bC = 'smoke',
  bR = 'bR1',
  bS = 'solid',
  bW = 'bW1',
  b_l,
  b_r,
  b_t,
  theme,
}) => {
  if (b_b || b_l || b_r || b_t || bR) {
    const borderColor = theme.colors[bC] || bC
    const borderRadius = theme.globals.borderRadius[bR] || bR
    const borderStyle = bS
    const borderWidth = theme.globals.borderWidth[bW] || bW

    const fullBorder = `${borderWidth} ${borderStyle} ${borderColor}`

    if (b_b && b_l && b_r && b_t) {
      // Border on all sides, let construct only one border.
      return css`
        border: ${fullBorder};
        border-radius: ${borderRadius};
      `
    }

    return css`
      ${b_b && `border-bottom: ${fullBorder}`};
      ${b_l && `border-left: ${fullBorder}`};
      ${b_r && `border-right: ${fullBorder}`};
      ${b_t && `border-top: ${fullBorder}`};
      border-radius: ${borderRadius};
    `
  }

  return css``
}
