import React, { useMemo } from 'react'

import styled from 'styled-components'
import { clamp, findIndex, floor } from 'lodash'

import Slider from '../../Slider'

import { useSteppedSlider } from '../hooks'

const Bar = (props) => {
  const { selectedId, changeSelectedId, steps } = useSteppedSlider()

  const stepOffset = 100 / (steps.length || 100)

  const calculatedValue = useMemo(() => {
    if (steps.length === 0) {
      return 1
    }
    const stepIndex = findIndex(steps, step => step.id === selectedId)

    return stepOffset * stepIndex + stepOffset / 2
  }, [selectedId])

  const onChange = (value) => {
    if (steps.length === 0) {
      return
    }
    let stepIndex = floor(value / stepOffset)
    stepIndex = clamp(stepIndex, 0, steps.length - 1)
    if (selectedId !== steps[stepIndex].id) {
      changeSelectedId(steps[stepIndex].id)
    }
  }

  return <Slider max={100} min={1} onChange={onChange} {...props} value={calculatedValue} />
}

export default styled(Bar)``
