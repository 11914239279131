const { cloneDeep, compact, find } = require('lodash')

const getSectionContentByName = (sectionData, nameContent) =>
  find(sectionData, (content) => content.name === nameContent)

const getSectionByName = getSectionContentByName

const getSectionContentByNameMatchingString = (sectionData, stringToMatch) =>
  find(
    sectionData,
    ({ name }) =>
      name && name.toLowerCase().indexOf(stringToMatch.toLowerCase()) > -1,
  )
// Use clone deep to avoid data side effect
const getSectionByType = (sectionData, typeToMatch) =>
  find(
    compact(cloneDeep(sectionData)),
    ({ __typename }) => __typename && __typename === typeToMatch,
  )

const getParagraphWithAnyForType = (sections, type) =>
  find(sections, ({ type: typeEl }) => typeEl === type)

module.exports = {
  getParagraphWithAnyForType,
  getSectionByName,
  getSectionByType,
  getSectionContentByName,
  getSectionContentByNameMatchingString,
}
