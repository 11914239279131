import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Feedback = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8.8,7 L7.2,7 L7.2,3 L8.8,3 L8.8,7 M8,10 C7.448,10 7,9.5528 7,9 C7,8.448 7.448,8 8,8 C8.552,8 9,8.448 9,9 C9,9.5528 8.552,10 8,10 Z M16,1.6 L16,11.2 C16,12.0836556 15.2836556,12.8 14.4,12.8 L3.2,12.8 L0,16 L0,1.6 C0,0.7163444 0.7163444,0 1.6,0 L14.4,0 C15.2836556,0 16,0.7163444 16,1.6 M14.4,1.6 L1.6,1.6 L1.6,12.16 L2.56,11.2 L14.4,11.2 L14.4,1.6 Z"/></g>
    </StyledIcon>
  )

export default styled(Feedback)``
