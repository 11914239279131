import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const FavoriteFolder = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8 8.38c-.64-2.16-2.8-1.44-2.8.16 0 1.76 2.72 3.36 2.72 3.36.24-.16 2.8-1.52 2.8-3.36 0-1.6-2.16-2.32-2.72-.16zm6.32-4.48H7.76L6.48 2.14c-.32-.4-.8-.64-1.28-.64H1.6C.72 1.5 0 2.22 0 3.1v10.4c0 .88.72 1.6 1.6 1.6h12.8c.88 0 1.6-.72 1.6-1.6v-8c-.08-.88-.8-1.6-1.68-1.6zm-12.8 9.6V3.1h3.6l1.36 1.76c.32.4.8.64 1.28.64h6.56v8H1.52z"/></g>
    </StyledIcon>
  )

export default styled(FavoriteFolder)``
  