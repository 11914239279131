import * as React from 'react'
import { isFunction, isObject } from 'lodash'
import TooltipMessage from '../styles/TooltipMessage'
import Container from '../styles/Container'


const ToolTipContent = React.forwardRef(
  (props, ref) => {
    const {
      active,
      'data-testid': dataTestId,
      direction = 'top',
      locator,
      message,
      noWrap,
      style,
    } = props

    const isMessageString = !isFunction(message) && !isObject(message)

    return (
      <Container
        aria-hidden={!active}
        className={locator}
        direction={direction}
        noWrap={noWrap}
        ref={ref}
        style={style}
      >
        {isMessageString && <TooltipMessage data-testid={dataTestId}>{message}</TooltipMessage>}
        {!isMessageString && message}
      </Container>
    )
  },
)

export default ToolTipContent
