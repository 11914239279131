import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const MoveUp = props => (
    <StyledIcon viewBox="0 0 5 12" {...props}>
      <g><path d="M0 9.57A2.43 2.43 0 0 0 2.44 12a2.4 2.4 0 1 0 .05-4.8A2.44 2.44 0 0 0 0 9.57zm1.23 0A1.22 1.22 0 0 1 2.48 8.4a1.2 1.2 0 1 1 0 2.4 1.22 1.22 0 0 1-1.25-1.21zm1.92-5.96L5 3.63 2.57 0 .07 3.57l1.85.02-.03 2.4 1.23.02.03-2.4z"/></g>
    </StyledIcon>
  )

export default styled(MoveUp)``
  