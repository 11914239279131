// @noSnapshot

import React from 'react'
import OptionsPanelContainer from '../styles/OptionsPanelContainer'
import SelectOptionsPanel from '../styles/SelectOptionsPanel'


const OptionPanel = ({
  ellipsis,
  isPanelWidthCustom,
  limitOptionsHeight,
  noResultText,
  optimize,
  options,
  panelStyle,
  selectedIcon,
  setOptionPanelRef = () => undefined,
}) => (
  <OptionsPanelContainer>
    <SelectOptionsPanel
      // Max height of 250px decided by Alexandre Deslandes (https://redmine.mailjet.tech/issues/26579).
      ellipsis={ellipsis}
      isPanelWidthCustom={isPanelWidthCustom}
      maxHeight={limitOptionsHeight ? '250px' : null}
      noResultText={noResultText}
      optimize={optimize}
      options={options || []}
      ref={setOptionPanelRef}
      selectedIcon={selectedIcon}
      style={panelStyle}
    />
  </OptionsPanelContainer>
)

export default OptionPanel
