import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const SearchGlass = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M11.73 10.15L8.86 7.28a4.68 4.68 0 0 0 .68-2.44A5 5 0 0 0 4.7 0 4.7 4.7 0 0 0 0 4.7a5 5 0 0 0 4.84 4.84 4.68 4.68 0 0 0 2.37-.64l2.89 2.89a.72.72 0 0 0 1 0l.72-.72c.3-.28.18-.64-.09-.92zM1.44 4.7A3.25 3.25 0 0 1 4.7 1.44a3.51 3.51 0 0 1 3.4 3.4A3.25 3.25 0 0 1 4.84 8.1a3.51 3.51 0 0 1-3.4-3.4z"/></g>
    </StyledIcon>
  )

export default styled(SearchGlass)``
  