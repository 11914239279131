import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const TurnArown = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M7.38 1.813c-.503-.508-1.38-.508-1.885 0L1.724 5.612a1.35 1.35 0 0 0 0 1.899l7.543 7.596c.252.254.586.393.942.393s.691-.139.943-.393l3.771-3.798a1.35 1.35 0 0 0 0-1.899L7.381 1.813zm2.83 12.344L2.666 6.561l3.771-3.799 7.542 7.597-3.77 3.798zm3.123-10.571c0-1.197-1.036-1.743-2-1.743h-.666V.5h.666c1.932 0 3.334 1.299 3.334 3.086v.271H16l-2 2.686-2-2.686h1.333v-.271zm-10.666 8.6c0 1.198 1.036 1.743 2 1.743h.666v1.343h-.666c-1.932 0-3.334-1.299-3.334-3.086v-.271H0l2-2.686 2 2.686H2.667v.27z"/></g>
    </StyledIcon>
  )

export default styled(TurnArown)``
  