import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AlignTextJustify = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M0 0h16v1.778H0V0zm0 3.556h16v1.777H0V3.556zM0 7.11h16v1.78H0V7.11zm0 3.556h16v1.777H0v-1.777zm0 3.555h11.2V16H0v-1.778z"/></g>
    </StyledIcon>
  )

export default styled(AlignTextJustify)``
  