import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Panel from '../../../Panel';

const calculateBorders = (reducedBorders) => {
  if (!reducedBorders) {
    // Border all over the component except bottom.
    return css`
      border: ${({ theme }) => theme.globals.borderWidth.borderWidth1} solid
        ${({ theme }) => theme.components.accordion.border};
      border-bottom: 0;
      border-radius: ${({ theme }) => theme.globals.borderRadius.borderRadius1};
    `;
  }

  // Only a border at the top.
  return css`
    border-top: ${({ theme }) => theme.globals.borderWidth.borderWidth1} solid
      ${({ theme }) => theme.components.accordion.border};
  `;
}

const AccordionWrapper = styled(Panel)`
  ${({ reducedBorders }) => calculateBorders(reducedBorders)};
`;

AccordionWrapper.propTypes = {
  // When this component has reduced borders, it only has borders at the top and at the bottom.
  // Otherwise, it will have borders all over it.
  reducedBorders: PropTypes.bool,
};

export default AccordionWrapper;
