import React from 'react'

const LabelContext = React.createContext(undefined)

const useLabelContext = () => {
  const context = React.useContext(LabelContext)

  if (!context) {
    throw new Error(
      `FormLabel compound components cannot be rendered outside the FormLabel component`,
    )
  }
  return context
}

const LabelProvider = ({ children, htmlFor, required }) => (
  <LabelContext.Provider value={{ htmlFor, required }}>{children}</LabelContext.Provider>
)

export { LabelProvider, useLabelContext }
