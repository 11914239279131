import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const DragHorizontally = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M12 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0zM6 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0zM0 8a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/></g>
    </StyledIcon>
  )

export default styled(DragHorizontally)``
  