import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Clock = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M10.8 7.667V9.56H6.44V5.2h1.893v2.467H10.8zM8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2zm0-2a8 8 0 1 1 0 16A8 8 0 0 1 8 0z"/></g>
    </StyledIcon>
  )

export default styled(Clock)``
  