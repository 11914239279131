import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Rename = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M9.6 12H1.2A1.2 1.2 0 0 1 0 10.8V2.4a1.2 1.2 0 0 1 1.2-1.2h4.2v1.2H1.2v8.4h8.4V5.4h1.2v5.4A1.2 1.2 0 0 1 9.6 12z"/><path d="M4.68 5.02l1.7 1.69-2.12.43.42-2.12zM11 2A1.2 1.2 0 0 0 9.35.35L5.53 4.17l1.7 1.7z"/></g>
    </StyledIcon>
  )

export default styled(Rename)``
  