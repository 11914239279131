import styled, { css } from 'styled-components'

import { styleForMobile, styleForTablet } from '@utils'

// Correspond to the button on main bar for desktop and tablet, and the language button on mobile
export const RightPart = styled.div`
  ${({ theme }) => css`
    display: flex;

    ${styleForTablet} {
      margin-right: ${theme.sizes.s6};
    }

    ${styleForMobile} {
      border-bottom: solid ${theme.colors.neutral20} ${theme.sizes.s0};
      border-left: solid ${theme.colors.neutral20} ${theme.sizes.s0};
    }

    li {
      list-style: none;
    }
  `}
`
