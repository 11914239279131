import React from 'react'

const VideosWebinarsSVG = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 11C8.23858 11 6 13.2386 6 16V32C6 34.7614 8.23858 37 11 37H37C39.7614 37 42 34.7614 42 32V16C42 13.2386 39.7614 11 37 11H11ZM31 25.7321C32.3333 24.9623 32.3333 23.0377 31 22.2679L22 17.0718C20.6667 16.302 19 17.2642 19 18.8038L19 29.1962C19 30.7358 20.6667 31.698 22 30.9282L31 25.7321Z'
      fill='#090E12'
    />
  </svg>
)

export default VideosWebinarsSVG
