import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Share = props => (
    <StyledIcon viewBox="0 0 13 13" {...props}>
      <g>
        <path
          d="M8.8 4.8c1.323 0 2.4-1.077 2.4-2.4S10.123 0 8.8 0a2.403 2.403 0 0 0-2.4 2.4c0 .18.023.352.06.52L3.885 4.529A2.378 2.378 0 0 0 2.4 4 2.403 2.403 0 0 0 0 6.4c0 1.323 1.077 2.4 2.4 2.4.563 0 1.075-.203 1.485-.529l2.575 1.61c-.037.167-.06.34-.06.519 0 1.323 1.077 2.4 2.4 2.4s2.4-1.077 2.4-2.4S10.123 8 8.8 8a2.38 2.38 0 0 0-1.485.529L4.74 6.919c.037-.167.06-.34.06-.519 0-.18-.023-.352-.06-.52l2.576-1.609c.41.326.92.529 1.484.529zm0-3.2a.8.8 0 1 1-.002 1.602A.8.8 0 0 1 8.8 1.6zM2.4 7.2a.8.8 0 1 1 .002-1.602A.8.8 0 0 1 2.4 7.2zm6.4 2.4a.8.8 0 1 1-.002 1.602A.8.8 0 0 1 8.8 9.6z"
          fillRule="nonzero"
        />
      </g>
    </StyledIcon>
  )

export default styled(Share)``
