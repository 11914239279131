import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const BusinessGraph = props => (
    <StyledIcon viewBox="0 0 12 8" {...props}>
      <g><path d="M11 7V.5a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0-.5.5V7h-.5V3.5A.5.5 0 0 0 7 3H5a.5.5 0 0 0-.5.5V7H4V2.5a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0-.5.5V7H0v1h12V7zM9 1h1v6H9zM5.5 4h1v3h-1zM2 3h1v4H2z"/></g>
    </StyledIcon>
  )

export default styled(BusinessGraph)``
  