import { useEffect, useState } from 'react'
// Hook executing a handler when clicking outside the element referenced
const useClickOutside = (
  ref,
  handler,
  isOpenDefaultValue = false,
  events = ['click'],
) => {
  const [isOpen, setOpen] = useState(isOpenDefaultValue)

  const detectClickOutside = (event) => {
    // Avoid propagation causing the panel handler to re-fire when clicking on the element that trigger the event
    event.stopPropagation()
    ref.current &&
      !ref.current.contains(event.target) &&
      handler &&
      handler(event)
  }

  useEffect(() => {
    // Don't fire event if notOpen
    if (isOpen) {
      for (const event of events)
        window.addEventListener(event, detectClickOutside)
      return () => {
        for (const event of events)
          window.removeEventListener(event, detectClickOutside)
      }
    }
  })

  return [isOpen, setOpen]
}

export default useClickOutside
