import Divider from './components/Divider'
import NoResultOption from './components/NoResultOption'
import Option from './Option'
import OptionsPanel from './OptionsPanel'
import OptionWithSubs from './components/OptionWithSubs'
import SubOptions from './components/SubOptions'
import OptionsProvider from './hooks/OptionsProvider'

const Options = OptionsPanel
Options.Option = Option
Options.Divider = Divider
Options.NoResultOption = NoResultOption
Options.OptionWithSubs = OptionWithSubs
Options.SubOptions = SubOptions
Options.OptionsProvider = OptionsProvider

export default Options
