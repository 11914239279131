import styled from 'styled-components'
import Panel from '../../Panel'
import ContainerIcon from './ContainerIcon'

const Wrapper = styled(Panel)`
  ${({ disabled, theme }) => `
    display: flex;  
    background-color: ${theme.components.checkbox.backgroundWrapper};  
    opacity: ${disabled ? theme.globals.opacity.o1 : theme.globals.opacity.o3};
    cursor: ${disabled ? 'not-allowed' : 'default'};
    user-select: none;
  `}
  ${({ ellipsis }) =>
    ellipsis &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
  ${({ theme }) => `
    input:focus + ${ContainerIcon} {
      border-width: ${theme.globals.borderWidth.borderWidth2};
      border-color: ${theme.components.checkbox.borderFocus};
    }
  `}
`

export default Wrapper
