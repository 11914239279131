import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Users = props => (
    <StyledIcon viewBox="0 0 24 16" {...props}>
      <g>
        <path
          d="M15.888.002c2.476.134 4.4 2.104 4.362 4.465V6.43c-.001 1.676-1.029 3.197-2.63 3.894l.287 1.375a.73.73 0 00.553.555l3.836.912c1.001.239 1.704 1.096 1.704 2.079v4.04c0 .395-.336.715-.75.715h-6.888c.089-.229.136-.47.138-.714v-3.384c-.004-1.594-1.112-2.994-2.72-3.437l-.205-.057a2.16 2.16 0 001.073-1.337 2.056 2.056 0 00-.272-1.663 5.826 5.826 0 01-.876-3.51 5.846 5.846 0 00-1.545-3.906C12.805.714 14.3-.042 15.888.002zM7.434 1.427l.244.003c2.46.16 4.358 2.125 4.322 4.473a7.196 7.196 0 001.108 4.26c.113.17.146.376.092.57a.722.722 0 01-.38.448 7.754 7.754 0 01-3.45.571l.256.746c.077.225.267.4.507.464l3.235.88c.966.263 1.632 1.103 1.632 2.06v3.384c0 .394-.336.714-.75.714H.75c-.414 0-.75-.32-.75-.714v-3.384c.001-.958.67-1.8 1.638-2.06l3.236-.88a.735.735 0 00.506-.464l.256-.746a7.754 7.754 0 01-3.45-.571.722.722 0 01-.38-.447.685.685 0 01.09-.569A7.385 7.385 0 003 5.715a4.189 4.189 0 011.38-3.091A4.629 4.629 0 017.678 1.43z"
          fillRule="evenodd"
        />
      </g>
    </StyledIcon>
  )

export default styled(Users)``
