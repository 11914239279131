import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const InboxChecked = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g>
        <path d="M15 0H1C.4 0 0 .4 0 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zm-1 2v9h-3c-.6 0-1 .4-1 1v1H6v-1c0-.6-.4-1-1-1H2V2h12z" />
        <path d="M6.707 9.707c-.256 0-.512-.098-.707-.293l-2-2L5.414 6l1.293 1.293L10 4l1.414 1.414-4 4c-.195.195-.451.293-.707.293z" />
      </g>
    </StyledIcon>
  )

export default styled(InboxChecked)``
