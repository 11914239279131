import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const CarretUp = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M.143 12.25c.207.445.67.737 1.186.75h13.333c.523-.008.992-.301 1.2-.75A1.092 1.092 0 0 0 15.73 11L9.063 3.501a1.682 1.682 0 0 0-1.067-.5c-.424-.02-.829.17-1.067.5l-6.666 7.5c-.3.357-.348.847-.12 1.25z"/></g>
    </StyledIcon>
  )

export default styled(CarretUp)``
  