import React from 'react'
import styled from 'styled-components'

import { ButtonPrimaryStyled } from '@components/Button/ButtonPrimary'
import { withLink } from '@components/Button/withLink'
import { Body3Bold } from '@typography'

const RightButton = ({ children, small, to, ...props }) => (
  <ButtonPrimaryStyled
    aria-label={children && children.toString()}
    mode='primary'
    small={small}
    {...props}
  >
    <Body3Bold as='span'>{children}</Body3Bold>
  </ButtonPrimaryStyled>
)

export default styled(withLink(RightButton))``
