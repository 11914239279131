import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Files = props => (
    <StyledIcon viewBox="0 0 10 12" {...props}>
      <g><path d="M7.45 0H3.33a1.1 1.1 0 0 0-1.11 1.09v7.64a1.1 1.1 0 0 0 1.11 1.09h5.56A1.1 1.1 0 0 0 10 8.73V2.5zm1.44 8.73H3.33V1.09h3.34v2.18h2.22z"/><path d="M1.11 2.18H0v8.73A1.1 1.1 0 0 0 1.11 12h6.67v-1.09H1.11z"/></g>
    </StyledIcon>
  )

export default styled(Files)``
  