import React from 'react'

const CheckPricingSVG = () => (
  <svg
    width='16px'
    height='16px'
    viewBox='0 0 16 16'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <title>Check</title>
    <g
      id='Mailjet'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g
        id='Pricing-Mailjet-Desktop'
        transform='translate(-272.000000, -2062.000000)'
        fill='#6F67D9'
      >
        <g id='Features-Tables' transform='translate(0.000000, 1724.000000)'>
          <g id='Table' transform='translate(24.000000, 228.000000)'>
            <g
              id='04-Icons/16px/Info'
              transform='translate(248.000000, 110.000000)'
            >
              <path
                d='M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 Z M9,7 L9,12 L7,12 L7,7 L9,7 Z M8,4 C8.55228475,4 9,4.44771525 9,5 C9,5.55228475 8.55228475,6 8,6 C7.44771525,6 7,5.55228475 7,5 C7,4.44771525 7.44771525,4 8,4 Z'
                id='Combined-Shape'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CheckPricingSVG
