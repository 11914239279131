// @noSnapshot
import React from 'react'
import OptionsContext from './OptionsContext'

function OptionsProvider({ children, onOptionClick, withTextWrapEllipsis }) {
  return (
    <OptionsContext.Provider value={{ onOptionClick, withTextWrapEllipsis }}>
      {children}
    </OptionsContext.Provider>
  )
}

export default OptionsProvider
