import React from 'react'
import InfoMsgStyled from '../styles/InfoMsg'

import { INFO_MSG_NS } from '../config'

import { useInfoStatus, useInputId } from '../hooks'

const InfoMsg = ({
  children,
  ...rest
}) => {
  const { inputId } = useInputId()
  const { hasInfo, isError } = useInfoStatus()

  return (
    <InfoMsgStyled
      {...rest}
      className={isError || !hasInfo ? 'isHidden' : ''}
      id={`${inputId}-${INFO_MSG_NS}`}
    >
      {children}
    </InfoMsgStyled>
  )
}

export default InfoMsg
