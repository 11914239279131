import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const QuestionPlain = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8 0C3.589 0 0 3.59 0 8c0 4.412 3.589 8 8 8s8-3.588 8-8c0-4.41-3.589-8-8-8zm0 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm.75-3.875V10h-1.5V7.667H8c.828 0 1.5-.698 1.5-1.556 0-.859-.672-1.555-1.5-1.555s-1.5.696-1.5 1.555H5C5 4.396 6.346 3 8 3s3 1.396 3 3.111c0 1.448-.958 2.667-2.25 3.014z"/></g>
    </StyledIcon>
  )

export default styled(QuestionPlain)``
  