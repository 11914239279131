import styled from 'styled-components'
import { StyledIcon } from 'component-library/icons'
import { Caption } from '../../Typography'

const ErrorMsg = styled(Caption)`
  display: flex;

  ${({ theme }) => `
    color: ${theme.components.labelledWrapper.notification.textError};
    ${StyledIcon} {
      fill: currentColor;
    }

    &.isHidden {
      display: none;
    }
  `}
`

export default ErrorMsg
