import React from 'react'
import { noop } from 'lodash'

import Button from '../../../Button'
import { useDropdown, useRegisterComponent } from '../../hooks'
import Caret from './Caret'



function IconButton({
  children,
  onFocus = noop,
  showCaret = false,
  ...props
}) {
  const { disabled, setIsOpen } = useDropdown()
  const component = useRegisterComponent()

  function toggle(e) {
    e.preventDefault()
    e.stopPropagation()

    setIsOpen(prevState => !prevState)
    onFocus()
  }

  return (
    <Button _ref={component} disabled={disabled} mode="icon" onClick={toggle} {...props}>
      {children}
      {showCaret && <Caret />}
    </Button>
  )
}

export default IconButton
