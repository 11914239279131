// @noSnapshot
import React, { Component } from 'react'

import { noop } from 'lodash'

import PropTypes from 'prop-types'

import isInBrowser from '../../../helpers/isInBrowser'

import EditorGlobalStyle from '../styles/EditorGlobalStyle'

const EXTRA_KEYS_ALLOW_TAB_FOCUS_EXIT = { 'Shift-Tab': false, Tab: false }
const EXTRA_KEYS_PREVENT_TAB_FOCUS_EXIT = { 'Shift-Tab': true, Tab: true }

class Editor extends Component {
  componentDidMount() {
    const {
      allowTabFocusExit,
      hideLineNumbers,
      highlight,
      onBlur,
      onFocus,
      onScroll,
      readOnly,
      setCodeMirrorRef,
    } = this.props

    if (isInBrowser()) {
      // eslint-disable-next-line no-undef
      this._codeMirror = CodeMirror.fromTextArea(this._textarea, {
        mode: highlight,
        lineNumbers: !hideLineNumbers,
        theme: 'material',
        autoCloseTags: true,
        tabSize: 2,
        lineWrapping: true,
      })

      this.setOptions(readOnly)

      if (allowTabFocusExit) {
        this._codeMirror.setOption('extraKeys', EXTRA_KEYS_ALLOW_TAB_FOCUS_EXIT)
      }

      this._codeMirror.on('cursorActivity', this.handleChange)

      this._codeMirror.on('blur', onBlur)
      this._codeMirror.on('focus', onFocus)
      this._codeMirror.on('scroll', onScroll)
      setCodeMirrorRef?.(this._codeMirror)
    }
  }

  shouldComponentUpdate(nextProps) {
    const { allowTabFocusExit, hideLineNumbers, highlight, readOnly } = nextProps
    return (
      allowTabFocusExit !== this.props.allowTabFocusExit ||
      readOnly ||
      highlight !== this.props.highlight ||
      hideLineNumbers !== this.props.hideLineNumbers
    )
  }

  componentDidUpdate(prevProps) {
    if (this.props.contentID !== prevProps.contentID || this.props.value !== prevProps.value) {
      this._codeMirror.setValue(this.props.value)
    }

    if (this.props.highlight !== prevProps.highlight) {
      this._codeMirror.setOption('mode', this.props.highlight)
    }

    if (this.props.hideLineNumbers !== prevProps.hideLineNumbers) {
      this._codeMirror.setOption('lineNumbers', !this.props.hideLineNumbers)
    }

    if (this.props.readOnly !== prevProps.readOnly) {
      this.setOptions(this.props.readOnly)
    }

    if (this.props.allowTabFocusExit !== prevProps.allowTabFocusExit) {
      if (this.props.allowTabFocusExit) {
        this._codeMirror.setOption('extraKeys', EXTRA_KEYS_ALLOW_TAB_FOCUS_EXIT)
      } else {
        this._codeMirror.setOption('extraKeys', EXTRA_KEYS_PREVENT_TAB_FOCUS_EXIT)
      }
    }
  }

  handleChange = (instance) => {
    const { onChange } = this.props

    const value = instance.getValue()

    return onChange(value)
  }

  setOptions(readOnly) {
    this._codeMirror.setOption('readOnly', readOnly)
    this._codeMirror.setOption('cursorHeight', readOnly ? 0 : 1)
    this._codeMirror.setOption(
      'styleActiveLine',
      readOnly
        ? false
        : {
            nonEmpty: true,
          },
    )
    this._codeMirror.setOption(
      'highlightSelectionMatches',
      readOnly
        ? false
        : {
            wordsOnly: true,
          },
    )
    this._codeMirror.setOption(
      'matchTags',
      readOnly
        ? false
        : {
            bothTags: true,
          },
    )
  }

  getValue = () => this._codeMirror.getValue()

  getCursor = () => this._codeMirror.getCursor()

  getScrollInfo = () => this._codeMirror.getScrollInfo()

  getCodeMirror = () => this._codeMirror

  setValue = (value) => this._codeMirror.setValue(value)

  insertText = (t) => {
    this._codeMirror.replaceSelection(t)
    this._codeMirror.focus()
  }

  refresh = () => this._codeMirror.refresh()

  focus = () => this._codeMirror.focus()

  // eslint-disable-next-line no-undef
  findMatchingTag = (cursor) => CodeMirror.findMatchingTag(this._codeMirror, cursor)

  // eslint-disable-next-line no-undef
  innerMode = (token) => CodeMirror.innerMode(this._codeMirror.getMode(), token.state)

  hasFocus = () => this._codeMirror.hasFocus()

  indexFromPos = (pos) => this._codeMirror.indexFromPos(pos)

  scrollTo = (left, top) => this._codeMirror.scrollTo(left, top)

  render() {
    const { onChange, plainPage, value } = this.props

    return (
      <div className="code-editor">
        <EditorGlobalStyle plainPage={plainPage} suppressMultiMountWarning />
        <textarea
          defaultValue={value}
          onChange={onChange}
          ref={textarea => (this._textarea = textarea)}
        />
      </div>
    )
  }
}

Editor.propTypes = {
  // The text that is displayed in the Editor
  value: PropTypes.string.isRequired,

  // Allow Tab and Shift-Tab the ability to exit the editor's focus.
  allowTabFocusExit: PropTypes.bool,
  // Hide line numbers in editor.
  hideLineNumbers: PropTypes.bool,
  // Mime type, based on selected language
  highlight: PropTypes.string,
  // OnBlur callback
  onBlur: PropTypes.func,
  // Callback fired when code editor content is changed.
  onChange: PropTypes.func,
  // OnFocus callback
  onFocus: PropTypes.func,
  // OnScroll callback
  onScroll: PropTypes.func,
  // use it to take all the space of it wrapper and not only necessary lines
  plainPage: PropTypes.bool,
  // Is editor in read-only mode ?
  readOnly: PropTypes.bool,
}

Editor.defaultProps = {
  allowTabFocusExit: false,
  highlight: 'xml',
  onBlur: noop,
  onChange: noop,
  onFocus: noop,
  onScroll: noop,
  plainPage: false,
  readOnly: false,
}

export default Editor
