import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AlignCenterH = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M7 7h2V4h4v3h3v2h-3v3H9V9H7v5H3V9H0V7h3V2h4v5z"/></g>
    </StyledIcon>
  )

export default styled(AlignCenterH)``
  