import styled from 'styled-components'

import { StyledIcon } from '../../../../icons'
import Badge from '../../../Badge'

const AccordionRightPartContainer = styled('div')`
  display: flex;
  align-items: center;

  ${StyledIcon} {
    margin-left: ${({ theme }) => theme.sizes.s4};
  }
  ${Badge} {
    margin-right: ${({ theme }) => theme.sizes.s4};
  }
`

export default AccordionRightPartContainer
