// @noSnapshot

import React from 'react'
import styled from 'styled-components'
import { Info } from 'component-library/icons'
import Option from '../Option'
import { Body2 } from '../../Typography'

const NoResultOption = ({ children, ...props }) => (
  <Option readOnly {...props}>
    <Info color="darkgrey" mr="s3" />
    <Body2>{children}</Body2>
  </Option>
)

export default styled(NoResultOption)``
