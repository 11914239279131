import styled from 'styled-components'

const ChildrenContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default ChildrenContainer
