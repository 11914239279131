import axios from 'axios'
import { useContext, useState } from 'react'
import GlobalContext from '@components/Layout/GlobalContext'
import { getEmailNotValidatedMessage } from '@utils/getHardDataTranslated'

const useNewsletterSubscription = ({
  callback = () => null,
  onError = () => null,
  // Ref assigned to input tag, used to reset value and display error
  inputRef,
}) => {
  const {
    locale,
    siteConfig: { name: siteName },
  } = useContext(GlobalContext)
  const [inputState, setInputState] = useState()

  const setMessageError = (msg) => {
    if (inputRef?.current) {
      inputRef.current.setCustomValidity(msg)
      inputRef.current.reportValidity()
    }
  }

  const onFieldChange = ({ event, fieldName }) => {
    const { value } = event.target
    // Reset error input HTML native state
    inputRef.current.setCustomValidity('')
    setInputState({
      email: value,
      cfInputName: fieldName,
      // Not used
      locale,
    })
  }

  // Submit newsletter subscription according to the current brand and lang
  const onSubmit = (event, token) => {
    event.preventDefault()
    inputState.token = token
    if (inputState && siteName) {
      // Todo: recaptcha to add?
      axios({
        method: 'post',
        url: '/.netlify/functions/subscribe-newsletter',
        data: inputState,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then(() => {
          // Reset value
          inputRef.current.value = ''
          if (callback) callback()
          // Trigger GTM analytics tags using custom event trigger
          window?.dataLayer?.push({ event: 'nl-subscription' })
        })
        .catch(({ response: { status } }) => {
          if (status === 401) {
            setMessageError(getEmailNotValidatedMessage()[locale])
          }
          if (onError) onError()
        })
    }
  }

  return [onFieldChange, onSubmit]
}

export default useNewsletterSubscription
