import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const CreditCard = props => (
    <StyledIcon viewBox="0 0 24 12" {...props}>
      <g><path d="M24 7.5v9c0 .9-.6 1.5-1.5 1.5h-21C.6 18 0 17.4 0 16.5v-9h24zM9 12H3v1.5h6V12zm12 0h-3v1.5h3V12zm1.5-12c.9 0 1.5.6 1.5 1.5v3H0v-3C0 .6.6 0 1.5 0z"  fillRule="evenodd"/></g>
    </StyledIcon>
  )

export default styled(CreditCard)``
