import styled, { css } from 'styled-components'

import { injectFontStyle } from '@typography/helpers'

const OrderedList = styled.ol`
  ${({ theme }) => css`
    li::marker {
      color: ${theme.colors.neutral70};
      ${injectFontStyle(theme, 'body1')}
    }
  `}
`

const UnorderedBadgeCheckList = styled.ul`
  ${({ theme }) => css`
    list-style-position: inside;
    margin: 0;
    padding: 0;
    row-gap: ${theme.sizes.s3};

    li {
      margin-top: ${theme.sizes.s3};
      display: list-item;

      &::marker {
        color: ${theme.colors.primary40};
      }
      
      p {
        display: inline;
      }

      & li {
        margin-left: ${theme.sizes.s4};
      }
    }
  `}
`

export { OrderedList, UnorderedBadgeCheckList }
