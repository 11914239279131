// @noSnapshot
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import { margins } from '../../../helpers'

const CRITICAL = 'critical'
const WARNING = 'warning'

const getBackgroundColor = ({ mode, theme }) => {
  const { backgroundColor } = theme.components.alert

  const defaultColor = css`
    background-color: ${backgroundColor.warning};
  `
  switch (mode) {
    case WARNING:
      return defaultColor
    case CRITICAL:
      return css`
        background-color: ${backgroundColor.critical};
      `
    default:
      return defaultColor
  }
}

const StyledAlert = styled('div').attrs(() => ({
  role: 'alert',
}))`
  text-align: center;
  ${({ theme }) => `
    padding: ${theme.sizes.s4} ${theme.sizes.s5};
    & * {
      color: ${theme.components.alert.text.color};
    }
    & a {
      text-decoration: underline;
      &:hover, &:active, &:focus {
        color: ${theme.components.alert.text.color};
        text-decoration: underline;
      }
    }
  `}
  ${getBackgroundColor};
  ${margins};
`

StyledAlert.defaultProps = {
  mode: WARNING,
}

StyledAlert.propTypes = {
  /* The Alert's mode */
  mode: PropTypes.oneOf([CRITICAL, WARNING]),
}

export default StyledAlert
