import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const UserRequest = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M8 12H0v-.5c0-1.77 1.64-3 4-3s4 1.23 4 3zm-6.92-1h5.84C6.62 10.1 5.5 9.5 4 9.5s-2.62.6-2.92 1.5zM4 7a1 1 0 1 1 0-2V4a2 2 0 1 0 1.72 3zm4.5 0A3.5 3.5 0 1 1 12 3.5 3.5 3.5 0 0 1 8.5 7zm0-6A2.5 2.5 0 1 0 11 3.5 2.5 2.5 0 0 0 8.5 1z"/><path d="M8 3.5h1v2H8z"/><circle cx="8.5" cy="2.5" r=".5"/></g>
    </StyledIcon>
  )

export default styled(UserRequest)``
  