import React from 'react'
import styled from 'styled-components'

import { Body2Medium } from '../../Typography'


import { useLabelContext } from '../hooks'

const BaseLabel = ({ children, ...rest }) => {
  const { htmlFor, required } = useLabelContext()

  return (
    <Body2Medium as="label" htmlFor={htmlFor} {...rest}>
      {children}
      {required && <>*</>}
    </Body2Medium>
  )
}

const Label = styled(BaseLabel)``
export default Label
