import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Alarm = props => (
    <StyledIcon viewBox="0 0 12 9.77" {...props}>
      <g><path d="M3.05 5.27l-1-.16a4 4 0 0 1 2.35-3l.4.92a3 3 0 0 0-1.75 2.24zm5.87 0A3 3 0 0 0 7.15 3l.4-.92a4 4 0 0 1 2.35 3z"/><path d="M12 5.77h-1A5 5 0 0 0 7.36 1l.27-1A6 6 0 0 1 12 5.77zm-11 0H0A6 6 0 0 1 4.36 0l.27 1A5 5 0 0 0 1 5.77zm5 4a1 1 0 0 0 1-1H5a1 1 0 0 0 1 1zm3-1.5H3V7.15L3.34 7c.35-.12.66-.42.66-.65v-.73a2 2 0 0 1 4 0v.76c0 .22.31.53.66.65l.34.12zm-4.33-1h2.66A1.44 1.44 0 0 1 7 6.38v-.76a1 1 0 0 0-2 0v.76a1.44 1.44 0 0 1-.33.89z"/></g>
    </StyledIcon>
  )

export default styled(Alarm)``
  