import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AlertPlain = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M8 0C3.589 0 0 3.589 0 8c0 4.412 3.589 8 8 8s8-3.588 8-8c0-4.411-3.589-8-8-8zm-.8 3.2h1.6v5.6H7.2V3.2zm.8 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/></g>
    </StyledIcon>
  )

export default styled(AlertPlain)``
  