import React from 'react'

const XSVG = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="x">
      <path 
        clipRule="evenodd" 
        d="M4.66666 26.6666H7.99999L14.2449 19.2653L19.3333 26.6666H26.6667L18.2857 14.4762L26 5.33331H22.6667L16.7891 12.2993L12 5.33331H4.66666L12.7483 17.0884L4.66666 26.6666ZM10.948 7.33333H8.46875L20.3854 24.6667H22.8646L10.948 7.33333Z" 
        fill="#9EA9B0" 
        fillRule="evenodd" 
        id="Vector" 
      />
    </g>
  </svg>
)

export default XSVG
