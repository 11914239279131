import styled from 'styled-components'

import { Body2Medium } from '../../Typography'

import genericButtonStyle from '../helpers/genericButtonStyle'

const TertiaryButton = styled(Body2Medium.withComponent('button'))`
  ${genericButtonStyle};

  color: ${({ theme }) => theme.components.button.tertiary.text};
  fill: ${({ theme }) => theme.components.button.tertiary.text};
  background-color: ${({ theme }) => theme.components.button.tertiary.background};

  &:before {
    border: 0;
  }

  &:active:not(.is-disabled):not(.is-loading) {
    &:before {
      border: 0;
    }
    color: ${({ theme }) => theme.components.button.tertiary.textPressed};
    fill: ${({ theme }) => theme.components.button.tertiary.textPressed};
    background-color: ${({ theme }) => theme.components.button.tertiary.backgroundPressed};
  }

  &:hover:not(.is-loading):not(.is-disabled):not(:active):not(:focus) {
    &:before {
      border: 0;
    }
    background-color: ${({ theme }) => theme.components.button.tertiary.backgroundHover};
    color: ${({ theme }) => theme.components.button.tertiary.textHover};
    fill: ${({ theme }) => theme.components.button.tertiary.textHover};
  }

  &:focus:not(:active):not(.is-loading) {
    &:before {
      border: ${({ theme }) => theme.globals.borderWidth.borderWidth1} dotted
        ${({ theme }) => theme.components.button.tertiary.border};
    }
    background-color: ${({ theme }) => theme.components.button.tertiary.backgroundFocus};
    color: ${({ theme }) => theme.components.button.tertiary.textFocus};
    fill: ${({ theme }) => theme.components.button.tertiary.textFocus};
  }

  &.is-loading {
    &:before {
      border: 0;
    }

    color: ${({ theme }) => theme.components.button.tertiary.hidden};
    fill: ${({ theme }) => theme.components.button.tertiary.backgroundLoading};
    background-color: ${({ theme }) => theme.components.button.tertiary.backgroundLoading};

    // Loader colors.
    > * {
      border-color: ${({ theme }) => theme.components.button.tertiary.loader};
      border-top-color: ${({ theme }) => theme.components.button.tertiary.hidden};
    }
  }
`

export default TertiaryButton
