import { css } from 'styled-components'


const handleHeights = ({ maxH, minH, h, theme }) => css`
  ${maxH && `max-height: ${theme.sizes.heights[maxH] || theme.sizes[maxH] || maxH}`};
  ${minH && `min-height: ${theme.sizes.heights[minH] || theme.sizes[minH] || minH}`};
  ${h && `height: ${theme.sizes.heights[h] || theme.sizes[h] || h}`};
`

const handleWidths = ({ maxW, minW, w, theme }) => css`
  ${maxW && `max-width: ${theme.sizes.widths[maxW] || theme.sizes[maxW] || maxW}`};
  ${minW && `min-width: ${theme.sizes.widths[minW] || theme.sizes[minW] || minW}`};
  ${w && `width: ${theme.sizes.widths[w] || theme.sizes[w] || w}`};
`

/**
 * Inject this style into your component so it can support sizes props :
 * - heights props : maxH (max-height), minH (min-height), h (height)
 * - widths props : maxW (max-width), minW (min-width), w (width)
 *
 * Don't support negative values.
 * Correct values are for example :
 * - for heights : s4, 150px, 10%
 * - for widths : s4, 150px, 10%
 */
const sizes = () => css`
  ${handleHeights};
  ${handleWidths};
`

export default sizes
