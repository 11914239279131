import { css } from 'styled-components'

/**
 * Inject this style into your component so it can support paddings props :
 * - pb (padding-bottom)
 * - pl (padding-left)
 * - pr (padding-right)
 * - pt (padding-top)
 *
 * Don't support negative values.
 * Correct values are for example : s4, 150px, 10%
 */
const paddings = ({ pb, pl, pr, pt, theme }) => css`
  ${pb && `padding-bottom: ${theme.sizes.paddings[pb] || theme.sizes[pb] || pb}`};
  ${pl && `padding-left: ${theme.sizes.paddings[pl] || theme.sizes[pl] || pl}`};
  ${pr && `padding-right: ${theme.sizes.paddings[pr] || theme.sizes[pr] || pr}`};
  ${pt && `padding-top: ${theme.sizes.paddings[pt] || theme.sizes[pt] || pt}`};
`

export default paddings
