import * as React from 'react'
import { findIndex, get, isEqual, isNull } from 'lodash'
import cx from 'classnames'

import StyledRoundNotification from './styles/StyledRoundNotification'
import ToggleButton from './styles/ToggleButton'
import TogglesContainer from './styles/TogglesContainer'

class Toggle extends React.Component {
  static defaultProps = {
    alignContent: 'ce',
  }

  state = {
    selectedIndex: null,
    selectedOption: null,
    propsOptions: [],
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { options } = nextProps

    if (!isEqual(options, prevState.propsOptions)) {
      const index = findIndex(options, e => e.isSelectedOnLoad)
      const optionSelected = get(options, [index])
      if (optionSelected && optionSelected.id !== prevState.selectedOption) {
        return {
          selectedIndex: index,
          selectedOption: optionSelected.id,
          propsOptions: options,
        }
      }
      return { propsOptions: options }
    }
    return null
  }

  /**
   * Handle a click on an option.
   * @param option the option which was clicked
   */
  handleClick = (option, index) => {
    const { onDeselect, onSelect, unselectable } = option
    const { disableDeselect, onChange } = this.props
    const { selectedOption } = this.state

    if (selectedOption === option.id) {
      if (!disableDeselect) {
        // If the clicked option is the selected one and deselect isn't disabled, just deselect it.
        this.setState({
          selectedOption: null,
          selectedIndex: null,
        })
        onDeselect && onDeselect()
        onChange && onChange(null)
      }
    } else {
      if (unselectable) return onChange && onChange(option.id)
      // Otherwise, we have a new selected option ! Congratulations !
      this.setState({
        selectedOption: option.id,
        selectedIndex: index,
      })

      onSelect && onSelect()
      onChange && onChange(option.id)
    }
  }

  /**
   * Get position of a button from selected button.
   */
  getStateBrothers = (index) => {
    const { options } = this.props
    const { selectedIndex } = this.state

    const evaluate = (pos) => {
      let optionToEvaluate = get(options, `[${pos}]`, null)
      if (!isNull(optionToEvaluate)) {
        switch (true) {
          case selectedIndex === pos:
            optionToEvaluate = 'selected'
            break
          default:
            optionToEvaluate = get(options, `[${pos}].disabled`, false) ? 'disabled' : 'normal'
        }
      }

      return optionToEvaluate
    }

    return {
      left: evaluate(index - 1),
      myself: evaluate(index),
      right: evaluate(index + 1),
    }
  }

  render() {
    const { alignContent, className, column, locator, options, ...rest } = this.props
    const { selectedOption } = this.state

    const newClassNames = cx(className, locator)

    return (
      <TogglesContainer className={newClassNames} {...rest}>
        {options.map((o, i) => {
          const isSelected = selectedOption ? selectedOption === o.id : false
          const Icon = o.icon

          return (
            <ToggleButton
              column={column}
              data-testid={o['data-testid']}
              disabled={o.disabled}
              jc={alignContent}
              key={o.label || o.id || ''}
              locator={locator ? `${locator}-button-${i}` : undefined}
              mode="toggle"
              onClick={() => this.handleClick(o, i)}
              selected={isSelected}
              stateBrothers={this.getStateBrothers(i)}
              style={o.innerStyle}
              tooltip={o.tooltip}
            >
              {Icon && !o.label && <Icon pb="s1" pl="s1" pr="s1" pt="s1" />}
              {Icon && o.label && (
                <>
                  <Icon mr="s3" />
                  {o.label}
                </>
              )}
              {!Icon && o.label}
              {o.notification && <StyledRoundNotification type={get(o, 'notification.type')} />}
            </ToggleButton>
          )
        })}
      </TogglesContainer>
    )
  }
}

export default Toggle
