import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const RefreshMail = props => (
    <StyledIcon viewBox="0 0 12 11" {...props}>
      <g><path d="M6 5.06l2.17-1.3a1 1 0 0 0-.67-.26h-3a1 1 0 0 0-.67.26z"/><path d="M6.19 5.82a.38.38 0 0 1-.39 0L3.51 4.44V6.5a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V4.44z"/><path d="M10.38 6.5a4.5 4.5 0 0 1-8.69.31L3 5.5H0v3l.91-.91A5.5 5.5 0 0 0 11.4 6.5zm.71-3.09A5.5 5.5 0 0 0 .6 4.5h1a4.5 4.5 0 0 1 8.69-.31L9 5.5h3v-3z"/></g>
    </StyledIcon>
  )

export default styled(RefreshMail)``
  