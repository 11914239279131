import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AlignTop = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M3 4h4v12H3V4zm6 0h4v8H9V4zM0 0h16v2H0V0z"/></g>
    </StyledIcon>
  )

export default styled(AlignTop)``
  