import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Download = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M2 12.667H0V16h16v-3.333h-2V14H2v-1.333zM12.667 6.96L8 11.533 3.333 6.96l1.16-1.133L7.16 8.493V0h1.653v8.467L11.48 5.8l1.187 1.16z"/></g>
    </StyledIcon>
  )

export default styled(Download)``
  