import React from 'react'
import styled, { useTheme } from 'styled-components'

const CheckSVG = ({ themeContext = false, stroke = '#3B76CB', ...props }) => {
  const theme = useTheme()
  stroke = themeContext ? theme.colors[stroke] : stroke
  return (
    <svg
      width='10px'
      height='9px'
      viewBox='0 0 10 9'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <title>Check</title>
      <g
        id='Homepage-2020'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g
          id='Homepage-Desktop-01'
          transform='translate(-173.000000, -969.000000)'
          stroke={stroke}
          strokeWidth='2'
        >
          <polyline id='Check' points='174 973 177 976 182 970'></polyline>
        </g>
      </g>
    </svg>
  )
}

export default styled(CheckSVG)``
