import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const List2 = props => (
    <StyledIcon viewBox="0 0 11.73 12" {...props}>
      <g><path d="M3.09 1.23h8.64v1.23H3.09zm0 4.33h8.64v1.23H3.09zm0 4.32h8.64v1.23H3.09zM.81 1.15v2.23h.68v-3A.34.34 0 0 0 1.28 0a.34.34 0 0 0-.37.1L0 1l.48.48zm.12 3.17a.93.93 0 0 0-.93.93v.31h.62v-.31a.31.31 0 0 1 .62 0A4.21 4.21 0 0 1 .09 6.88a.31.31 0 0 0 .22.53h1.54v-.62H1a3.08 3.08 0 0 0 .84-1.54.93.93 0 0 0-.91-.93zm0 4.59H0v.62h.93a.31.31 0 1 1 0 .62H.62v.62h.31a.31.31 0 1 1 0 .62H0V12h.93a.93.93 0 0 0 .69-1.54.93.93 0 0 0-.69-1.55z"/></g>
    </StyledIcon>
  )

export default styled(List2)``
  