import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const HtmlContent = props => (
    <StyledIcon  {...props}>
      <g><path d="M28.33 10.39a1 1 0 0 0-1.28.62l-9 26a1 1 0 0 0 .62 1.27 1.26 1.26 0 0 0 .33 0 1 1 0 0 0 .95-.67l9-26a1 1 0 0 0-.62-1.22zM14.71 12a1 1 0 0 0-1.42 0L2 23.29a1 1 0 0 0 0 1.42L13.29 36a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.41L4.1 24l10.61-10.61a1 1 0 0 0 0-1.39zM46 23.29L34.71 12a1 1 0 0 0-1.42 0 1 1 0 0 0 0 1.41L43.9 24 33.29 34.61a1 1 0 0 0 0 1.41 1 1 0 0 0 1.42 0L46 24.71a1 1 0 0 0 0-1.42z"/></g>
    </StyledIcon>
  )

export default styled(HtmlContent)``
  