import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ZipFolder = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M11.2 6.6h1.6v1.6h-1.6V6.6zM9.6 8.2h1.6v1.6H9.6V8.2zm1.6 1.6h1.6v1.6h-1.6V9.8zm3.2-6.4H7.8L6.48 1.64C6.18 1.24 5.7 1 5.2 1H1.6C.718 1 0 1.718 0 2.6V13c0 .882.718 1.6 1.6 1.6h12.8c.882 0 1.6-.718 1.6-1.6V5c0-.882-.718-1.6-1.6-1.6zM11.2 13v-1.6H9.6V13h-8V2.6h3.6l1.32 1.76c.3.4.78.64 1.28.64h1.8v1.6h1.6V5h3.2v8h-3.2z"/></g>
    </StyledIcon>
  )

export default styled(ZipFolder)``
  