import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const OpenEnvelope = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g>
        <path
          d="M6,.13.25,3.58v7.17h11.5V3.58ZM9.32,3.87,6,5.19,2.68,3.87,6,1.87ZM1.75,9.25V5.11L6,6.81l4.25-1.7V9.25Z"
          fillRule="nonzero"
        />
      </g>
    </StyledIcon>
  )

export default styled(OpenEnvelope)``
