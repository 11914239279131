import styled from 'styled-components'

const CustomControlsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  & > * {
    margin-right: 24px;
  }

  & > *:last-child {
    margin-right: 0px;
  }

  @media (max-width: ${({ theme }) => theme.responsive.sm.breakpoint}px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    & > * {
      ${({ theme }) => `
        margin-bottom: ${theme.sizes.s3};
        margin-right: 0px;

        &:last-child {
          margin-bottom: 0px;
        }
      `};
    }
  }
`

export default CustomControlsContainer
