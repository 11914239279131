import styled from 'styled-components'
import { Caption } from '../../Typography'

const SubLabel = styled(Caption)`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.sizes.s3};
`

export default SubLabel
