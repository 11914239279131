import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Receipt = props => (
    <StyledIcon viewBox="0 0 12 14" {...props}>
      <g>
        <path
          d="M11.478.82H1.64a.775.775 0 0 0-.82.82v12.297l2.46-1.64 1.64 1.64 1.639-1.64 1.64 1.64 1.64-1.64 2.459 1.64V1.64a.775.775 0 0 0-.82-.82zm-4.92 8.198H3.28v-1.64h3.28v1.64zm0-3.279H3.28v-1.64h3.28v1.64zm2.46 3.28a.82.82 0 1 1 0-1.64.82.82 0 0 1 0 1.64zm0-3.28a.82.82 0 1 1 0-1.64.82.82 0 0 1 0 1.64z"
          fillRule="evenodd"
        />
      </g>
    </StyledIcon>
  )

export default styled(Receipt)``
