import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Contact = props => (
    <StyledIcon viewBox="2 0 24 27" {...props}>
      <g><path d="M23.333 1C24.804 1 26 2.165 26 3.6v20.8c0 1.434-1.196 2.6-2.667 2.6h-16c-1.47 0-2.666-1.166-2.666-2.6v-2.6H2v-2.6h2.667v-3.9H2v-2.6h2.667V8.8H2V6.2h2.667V3.6c0-1.435 1.196-2.6 2.666-2.6zm0 2.6h-16v2.6h1.334v2.6H7.333v3.9h1.334v2.6H7.333v3.9h1.334v2.6H7.333v2.6h16.002l-.002-20.8zm-8 11.7c2.455 0 4 1.507 4 3.9h-8c0-2.319 1.45-3.805 3.773-3.896l.227-.004zm0-6.5C16.806 8.8 18 9.964 18 11.4c0 1.436-1.194 2.6-2.667 2.6-1.472 0-2.666-1.164-2.666-2.6 0-1.436 1.194-2.6 2.666-2.6z"/></g>
    </StyledIcon>
  )

export default styled(Contact)``
  