import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const CalendarSettings = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M5 9H1V4h8v1h1V2.5a1 1 0 0 0-1-1h-.5V0h-1v2.5H7v-1H3.5V0h-1v2.5H2v-1H1a1 1 0 0 0-1 1V9a1 1 0 0 0 1 1h4z"/><path d="M12 9.5v-1h-.55a2.48 2.48 0 0 0-.37-.88l.39-.39-.71-.71-.39.39a2.48 2.48 0 0 0-.88-.37V6h-1v.55a2.49 2.49 0 0 0-.88.37l-.39-.39-.71.71.39.39a2.48 2.48 0 0 0-.37.88H6v1h.55a2.49 2.49 0 0 0 .37.88l-.39.39.71.71.39-.39a2.48 2.48 0 0 0 .88.37V12h1v-.55a2.48 2.48 0 0 0 .88-.37l.39.39.71-.71-.39-.39a2.46 2.46 0 0 0 .37-.88zM9 10a1 1 0 1 1 1-1 1 1 0 0 1-1 1z"/></g>
    </StyledIcon>
  )

export default styled(CalendarSettings)``
  