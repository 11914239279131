import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AlignTextLeft = props => (
    <StyledIcon viewBox="0 0 12 10.8" {...props}>
      <g><path d="M0 0h10.8v1.2H0zm0 2.4h8.4v1.2H0zm0 2.4h12V6H0zm0 2.4h8.4v1.2H0zm0 2.4h10.8v1.2H0z"/></g>
    </StyledIcon>
  )

export default styled(AlignTextLeft)``
  