import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const BounceClassification = props => (
  <StyledIcon viewBox="0 0 16 16" {...props}>
    <g>
      <path
        d="M11.4286 5.28199L13.3676 5.92799L14.5766 4.71899L12.6376 4.07199L11.4286 5.28199ZM11.2836 1.42599L10.0746 2.63599L10.7216 4.57399L11.9306 3.36499L11.2836 1.42599ZM13.4286 3.28199L15.6606 4.02599C15.8246 4.08099 15.9496 4.21699 15.9886 4.38599C16.0286 4.55399 15.9786 4.73099 15.8556 4.85399L13.8556 6.85399C13.7606 6.94899 13.6336 6.99999 13.5026 6.99999C13.4496 6.99999 13.3956 6.99199 13.3446 6.97399L10.6376 6.07199L5.8556 10.853C5.7586 10.951 5.6306 11 5.5026 11C5.3746 11 5.2466 10.951 5.1486 10.853C4.9536 10.658 4.9536 10.342 5.1486 10.146L9.9296 5.36599L9.0276 2.65799C8.9686 2.47899 9.0146 2.28099 9.1486 2.14699L11.1486 0.146991C11.2706 0.0239909 11.4496 -0.0260091 11.6176 0.0129909C11.7856 0.0529909 11.9226 0.177991 11.9766 0.341991L12.7216 2.57399L14.1486 1.14699C14.3446 0.950991 14.6606 0.950991 14.8556 1.14699C15.0516 1.34199 15.0516 1.65799 14.8556 1.85399L13.4286 3.28199ZM9.7975 9.15019C9.7145 8.88719 9.8605 8.60619 10.1235 8.52319C10.3935 8.44219 10.6685 8.58719 10.7505 8.85019C11.5125 11.2732 10.5265 13.8852 8.3535 15.2032C7.4675 15.7402 6.4805 16.0022 5.5015 16.0022C4.0815 16.0022 2.6775 15.4512 1.6145 14.3882C-0.182504 12.5912 -0.517504 9.82019 0.800496 7.64819C2.1165 5.47519 4.7285 4.49019 7.1525 5.25219C7.4155 5.33519 7.5615 5.61619 7.4785 5.87919C7.3965 6.14219 7.1215 6.28919 6.8525 6.20619C4.8715 5.58319 2.7325 6.38919 1.6555 8.16719C0.577496 9.94419 0.852496 12.2112 2.3215 13.6812C3.7895 15.1492 6.0575 15.4262 7.8365 14.3472C9.6135 13.2692 10.4205 11.1322 9.7975 9.15019Z"
        fillRule="evenodd"
      />
    </g>
  </StyledIcon>
)

export default styled(BounceClassification)``
