import React from 'react'

import PropTypes from 'prop-types'
import styled from 'styled-components'

import calculatePercentFilled from './helpers/index'
import FilledSliderBackground from './style/FilledSliderBackground'
import StyledSlider from './style/StyledSlider'

const Slider = ({ max = 100, min = 0, onChange, step = 1, value, ...rest }) => {
  const onValueChange = (event) => {
    onChange(Number(event.target.value), event)
  }

  return (
    <>
      <StyledSlider
        max={max}
        min={min}
        onChange={onValueChange}
        step={step}
        value={value}
        {...rest}
      />
      <FilledSliderBackground
        style={{ width: `${calculatePercentFilled(value, min, max, step)}%` }}
      />
    </>
  )
}

Slider.propTypes = {
  /**
   * Value of the slider.
   */
  value: PropTypes.number.isRequired,

  /**
   * Maximum value allowed.
   */
  max: PropTypes.number,
  /**
   * Minimum value allowed.
   */
  min: PropTypes.number,
  /**
   * Function called when the value changes.
   * (value: number | string, event: React.SyntethicEvent) => void
   */
  onChange: PropTypes.func,
  /**
   * Interval between values.
   */
  step: PropTypes.number,
}

export default styled(Slider)``
