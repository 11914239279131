import React from 'react'

import { useInputContext, useInputId } from '../hooks'
import FormLabel from '../../FormLabel'


const Label = ({ children, ...rest }) => {
  const { required } = useInputContext()
  const { inputId } = useInputId()

  return (
    <FormLabel {...rest} htmlFor={inputId} required={required}>
      {children}
    </FormLabel>
  )
}

Label.Base = FormLabel.Base

export default Label
