import React, { useContext } from 'react'
import GlobalContext from '@components/Layout/GlobalContext'
import { MailgunLogoSVG, MailjetLogoSVG, SinchLogoSVG } from '@icons'

const LogoWithBrandName = () => {
  const { siteConfig } = useContext(GlobalContext)

  switch (siteConfig.name) {
    case 'Mailjet':
      return <MailjetLogoSVG />
    case 'Mailgun':
      return <MailgunLogoSVG />
    case 'Sinch':
      return <SinchLogoSVG />
    default:
      return <MailjetLogoSVG />
  }
}

export default LogoWithBrandName
