import styled from 'styled-components'
import Image from '@components/Image'

export const Icon = styled(Image)`
  height: ${({ theme, iconHeight }) =>
    iconHeight ? theme.sizes[iconHeight] : theme.sizes.s4};
  width: ${({ theme, iconWidth }) =>
    iconWidth ? theme.sizes[iconWidth] : theme.sizes.s4};

  & * {
    fill: ${({ theme, fillColor }) =>
      fillColor ? theme.colors[fillColor] : theme.colors.neutral90};
    stroke: ${({ theme, strokeColor }) =>
      strokeColor ? theme.colors[strokeColor] : theme.colors.neutral0};
  }
`
