import { useContext } from 'react'
import OptionsContext from './OptionsContext'

function useOptions() {
  const context = useContext(OptionsContext)
  if (!context) {
    throw new Error(
      'Options compound components cannot be rendered outside of the OptionsProvider component',
    )
  }
  return context
}

export default useOptions
