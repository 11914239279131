import { css, keyframes } from 'styled-components'

// KeyFrames
export const fadeInKeyframes = ({ translateY, theme }) => keyframes`
  0% {
    opacity: 0;
    transform: translateY(translateY);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const fadeInKeyAnimation = ({ theme }) =>
  fadeInKeyframes({ translateY: 0, theme })
export const fadeInUpKeyAnimation = ({ theme }) =>
  fadeInKeyframes({ translateY: -theme.sizes.s5, theme })

export const fadeInDownKeyAnimation = ({ theme }) =>
  fadeInKeyframes({ translateY: theme.sizes.s5, theme })

const templateAnimationFade = ({
  isAnimating = false,
  delay,
  duration = 250,
  keyframes,
}) => css`
  opacity: 0;

  ${isAnimating &&
  css`
    animation: ${fadeInKeyAnimation} ${duration}ms ease-in-out;
    ${delay && `animation-delay: ${delay}ms;`}
    animation-fill-mode: forwards;
  `}
`
// Functions
export const fadeInAnim = (isAnimating = false, delay, duration = 250) =>
  templateAnimationFade({
    isAnimating,
    delay,
    duration,
    keyframes: fadeInKeyAnimation,
  })
export const fadeInUpAnim = (isAnimating = false, delay, duration = 250) =>
  templateAnimationFade({
    isAnimating,
    delay,
    duration,
    keyframes: fadeInUpKeyAnimation,
  })
export const fadeInDownAnim = (isAnimating = false, delay, duration = 250) =>
  templateAnimationFade({
    isAnimating,
    delay,
    duration,
    keyframes: fadeInDownKeyAnimation,
  })

export const scaleUpKeyframes = () => keyframes`
  0% {
    scale: 1;
  }
  100% {
    scale: 1.05;
  }
`

export const scaleUp = (duration = 250) => css`
  animation: ${scaleUpKeyframes} ${duration}ms ease-in-out forwards;
`
