import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import backend from 'i18next-xhr-backend'

import { isEmpty } from 'lodash'

import { LOC_EN } from './config'

async function loadLocales(url, options, callback) {
  try {
    const waitForLocale = await import(`./locale/${url}`)
    !isEmpty(waitForLocale) && callback(waitForLocale, { status: '200' })
  } catch (e) {
    callback(null, { status: '404' })
  }
}

const commonConfig = locale => ({
  backend: {
    ajax: loadLocales,
    loadPath: '{{lng}}',
    parse: data => data,
  },

  lng: locale,
  fallbackLng: LOC_EN,

  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  debug: false, // debug variable => set to true will log i18n into console
})

const i18nConfig = locale => {
  if (!i18n.language || i18n.language !== locale) {
    const i18nRoot = i18n
      .use(backend) // Lazy loading for translations. Load needed language
      .use(initReactI18next) // passes i18n down to react-i18next

    i18nRoot.init({
      ...commonConfig(locale),

      react: {
        wait: true,
        useSuspense: false,
      },
    })
  }
}

export { i18n, i18nConfig }
