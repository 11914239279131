import styled from 'styled-components'

import OptionsPanel from '../../OptionsPanel'

const SelectOptionsPanel = styled(OptionsPanel)`
  position: absolute;
  top: 100%;
  ${({ isPanelWidthCustom }) => (!isPanelWidthCustom ? 'width: 100%' : 'overflow-x: hidden')};
`

export default SelectOptionsPanel
