const getAccordion = (colors) => ({
  background: colors.neutral0,
  backgroundHover: colors.neutral05,
  backgroundPressed: colors.neutral0,
  border: colors.neutral20,
  icon: colors.neutral90,
  iconLocked: colors.neutral90,
  iconPressed: colors.neutral90,
  label: colors.neutral90,
  labelPressed: colors.neutral90,
})

export default getAccordion
