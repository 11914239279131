import { merge } from 'lodash'
import { hexToRGB, lightenDarkenColor } from '../../../helpers/color'


const colorsVariationsFactory = ({ colors }) => {
  const lDColors = lightenDarkenColors({ colors })
  const statsColors = statisticsColors({ colors })

  return {
    ...colors,
    ...lDColors,
    ...statsColors,
    ...alphaColors({ colors: merge({}, lDColors, colors) }),
  }
}

/**
 * Add lighten and darken colors.
 */
const lightenDarkenColors = ({ colors }) => ({
  lightsteel: lightenDarkenColor(colors.steel, 5), // .colorSteel (lighten 5%) -> #60626A
  darksteel: lightenDarkenColor(colors.steel, -10), // .colorSteel (darken 10%) -> #3A3C44
  blacksteel: lightenDarkenColor(colors.steel, -15), // .colorSteel (darken 15%) -> #2D2F37
  smoke: lightenDarkenColor(colors.lightsmoke, -5), // .colorLightsmoke (darken 5%) -> #E5E5E5
  darksmoke: lightenDarkenColor(colors.lightsmoke, -10), // .colorLightsmoke (darken 10%) -> #D9D9D9
  cloudgrey: lightenDarkenColor(colors.snow, -10), // .colorSnow (darken 10%) -> #E6E6E6
  darkgrey: lightenDarkenColor(colors.grey, -20), // .colorGrey (darken 20%) -> #555555
  lightgrey: lightenDarkenColor(colors.grey, 10), // .colorGrey (lighten 10%) -> #A2A2A2
  lightlagoon: colors.pw50,
  bee2: colors.pw50,
  bee3: colors.pw70,
})

/**
 * Add colors with alpha channels.
 */
const alphaColors = ({ colors }) => ({
  overlay: hexToRGB(colors.blacksteel, 0.6), // rgba(.blackSteel 0.6) -> overlay colors
  scrollbarlight: hexToRGB(colors.darksmoke, 0.4), // rgba(.darkSmoke 0.4) -> scrollbar color
  scrollbarlightHover: hexToRGB(colors.darksmoke, 0.8), // rgba(.darkSmoke 0.8) -> scrollbar color hover
  scrollbardark: hexToRGB(colors.steel, 0.4), // rgba(.steel 0.4) -> scrollbar color in dark theme
  scrollbardarkHover: hexToRGB(colors.steel, 0.8), // rgba(.steel 0.4) -> scrollbar color hover in dark theme
  softlagoon: colors.pw20,
  softmarine: hexToRGB(colors.marine, 0.1), // rgba(.marine 0.1)
})

/**
 * Add Statistics colors and items
 */
const statisticsColors = ({ colors }) => ({
  openedPristine: colors.pw70,
  typo: colors.pw50,
  supprUnsub: colors.pw40,
  supprBounced: colors.neutral50,
  recycled: colors.neutral40,
  unsubscribed: colors.mg50,
  spam: colors.mg40,
  blocked: colors.or50,
  failed: colors.or40,
  permanentFail: colors.ye60,
  temporaryFail: colors.ye40,
  accepted: colors.gr40,
  openRate: colors.ir40,
  delivered: colors.cy40,
  inbox: colors.bl60,
  clickedRate: colors.bl50,
  bounced: colors.bl40,
  hardBounces: colors.mj60,
  complainedRate: colors.mj40,
  softBounced: colors.pi40,
  retrying: colors.pi60,
})

export default colorsVariationsFactory
