import React from 'react'
import styled from 'styled-components'

const PauseAnimationSVG = (props) => (
  <svg
    width='56'
    height='40'
    viewBox='0 0 56 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='56' height='40' rx='8' fill='black' fillOpacity='0.08' />
    <rect width='56' height='40' rx='8' fill='black' fillOpacity='0.08' />
    <rect width='56' height='40' rx='8' fill='black' fillOpacity='0.08' />
    <path
      d='M22.5 13C21.6716 13 21 13.6716 21 14.5V25.5C21 26.3284 21.6716 27 22.5 27H24.5C25.3284 27 26 26.3284 26 25.5V14.5C26 13.6716 25.3284 13 24.5 13H22.5Z'
      fill='white'
    />
    <path
      d='M31.5 13C30.6716 13 30 13.6716 30 14.5V25.5C30 26.3284 30.6716 27 31.5 27H33.5C34.3284 27 35 26.3284 35 25.5V14.5C35 13.6716 34.3284 13 33.5 13H31.5Z'
      fill='white'
    />
  </svg>
)

export default styled(PauseAnimationSVG)``
