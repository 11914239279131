import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Add = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M15.303 1.672c-.387-.417-.946-.638-1.663-.66-2.024-.065-3.455 1.012-4.254 3.2a.325.325 0 0 0 .391.426 2.57 2.57 0 0 1 .677-.095c.295 0 .688.054.883.312.15.198.17.496.06.884-.092.327-.568 1.203-1.158 2.13C9.52 9 9.123 9.505 9.009 9.505c-.38 0-1.75-6.076-1.788-6.209-.393-1.392-.59-2.091-1.868-2.091-.974 0-3.147 1.778-4.93 3.328l-.274.237a.424.424 0 0 0-.06.583l.333.428c.14.18.395.218.582.086l.018-.013c.577-.41 1.122-.799 1.534-.82.447-.022.835.677 1.298 2.34.881 3.23 2.22 7.079 3.594 7.079 1.435 0 3.148-1.217 5.363-4.099 2.003-2.608 3.076-4.676 3.145-6.18.052-1.137-.168-1.979-.653-2.502z"/></g>
    </StyledIcon>
  )

export default styled(Add)``
