import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Like = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M15.823 5.083a.763.763 0 0 0-.585-.273h-4.571V1.762c0-.42-.34-.762-.762-.762H7.619a.761.761 0 0 0-.7.461L4.83 6.333h-.26c0-.42-.34-.762-.761-.762H.762A.762.762 0 0 0 0 6.333v7.62c0 .42.34.761.762.761H3.81a.762.762 0 0 0 .761-.762v-.397l2.765 1.105c.09.036.186.054.283.054h6.095a.763.763 0 0 0 .75-.625l1.524-8.381a.765.765 0 0 0-.165-.625zM3.048 13.19H1.524V7.095h1.524v6.095zm10.03 0H7.766l-3.194-1.277V7.857h.762a.76.76 0 0 0 .7-.462l2.088-4.871h1.022V5.57c0 .421.34.762.762.762h4.42L13.08 13.19z"/></g>
    </StyledIcon>
  )

export default styled(Like)``
  