import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const AlignCenterVertical = props => (
    <StyledIcon viewBox="0 0 12 9" {...props}>
      <g><path d="M12 4h-1.5V1.75h-4V4h-1V0h-4v4H0v1h1.5v4h4V5h1v2.25h4V5H12zM4.5 8h-2V1h2z"/></g>
    </StyledIcon>
  )

export default styled(AlignCenterVertical)``
  