import styled from 'styled-components'
import Loader from '../../Loader'

const ButtonLoader = styled(Loader)`
  // Center the loader in wrapper.
  position: absolute;
  ${({ theme, size }) => `
    left: calc(50% - ${theme.sizes[size] || theme.sizes.sizes[size]} / 2);
    top: calc(50% - ${theme.sizes[size] || theme.sizes.sizes[size]} / 2);
  `}
`

export default ButtonLoader
