const { find } = require('lodash')

const getLanguageTable = () => [
  {
    name: 'English',
    suffix: 'en',
    locale: 'en-US',
    keywordExtractorId: 'english',
  },
  {
    name: 'Français',
    suffix: 'fr',
    locale: 'fr-FR',
    keywordExtractorId: 'french',
  },
  {
    name: 'Deutsch',
    suffix: 'de',
    locale: 'de-DE',
    keywordExtractorId: 'german',
  },
  {
    name: 'Español',
    suffix: 'es',
    locale: 'es-ES',
    keywordExtractorId: 'spanish',
  },
]



const getAllLocalesProps = (propName) => {
  const result = []
  const languageTable = getLanguageTable()

  for (let i = 0; i < languageTable.length; i++) {
    result.push(languageTable[i][propName])
  }
  return result
}

const getAllSuffixArray = () => getAllLocalesProps('suffix')

const getAllLocalesArray = () => getAllLocalesProps('locale')

const getSuffixByLocale = (locale) => {
  if (!locale) return

  const languageSuffix = find(
    getLanguageTable(),
    (obj) => obj.locale === locale,
  ).suffix
  return locale === 'en-US' ? '/' : '/' + languageSuffix + '/'
}

module.exports = {getAllLocalesArray, getAllSuffixArray, getSuffixByLocale, getLanguageTable}
