import * as React from 'react'

import TitleText from '../styles/TitleText'


class Title extends React.Component {
  render() {
    const { label } = this.props
    return <TitleText>{label}</TitleText>
  }
}

export default Title
