import React from 'react'
import RemoteMailgunSVG from './RemoteSVG/mg_logo_only_icon_color'

const MailgunSVG = () => {
  const svgString = RemoteMailgunSVG

  return <div dangerouslySetInnerHTML={{ __html: svgString }} />
}

export { MailgunSVG, RemoteMailgunSVG as MailgunSVGString }
