import styled from 'styled-components'

const LabelContainer = styled('div')`
  ${({ ellipsis }) =>
    ellipsis
      ? `
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `
      : `
      display: flex;
      flex-direction: column;
    `}
`

export default LabelContainer