import styled, { css } from 'styled-components'

export const Spinner = styled.div`
  ${({ theme }) => css`
    border: ${theme.sizes.s12} solid ${theme.colors.neutral05};
    border-top: ${theme.sizes.s12} solid ${theme.colors.primary50};
    border-radius: 50%;
    width: ${theme.sizes.s5};
    height: ${theme.sizes.s5};
    animation: spin 1s linear infinite;
  

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
  `}
`
