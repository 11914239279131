import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Delete = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M2.556 14.316V4.21h10.888v10.105c0 .929-.697 1.684-1.555 1.684H4.11c-.858 0-1.555-.755-1.555-1.684zM4.11 5.895v8.42h7.779l-.001-8.42H4.11zm6.222-4.21H15v1.683H1V1.684h4.667V0h4.666v1.684zM5.667 7.578h1.555v5.053H5.667V7.579zm3.11 0h1.556v5.053H8.778V7.579z"/></g>
    </StyledIcon>
  )

export default styled(Delete)``
  