import React from 'react'

const PauseSVG = () => (
  <svg
    width='24px'
    height='24px'
    viewBox='0 0 24 24'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
  >
    <title>Pause</title>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g
        id='Podcast-Desktop-01'
        transform='translate(-205.000000, -644.000000)'
        fill='#131B20'
      >
        <g id='Thumbnail' transform='translate(129.000000, 568.000000)'>
          <path
            d='M85,76 L85,100 L76,100 L76,76 L85,76 Z M100,76 L100,100 L91,100 L91,76 L100,76 Z'
            id='Pause'
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default PauseSVG
