import React from 'react'

const QuotesSVGForQuoteRichText = (props) => (
  <svg
    height='184px'
    version='1.1'
    viewBox='0 -30 280 215'
    width='280px'
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...props}
  >
    <g
      fill='none'
      fillRule='evenodd'
      id='Homepage-2020'
      stroke='none'
      strokeWidth='1'
    >
      <g
        fill='#FFFFFF'
        id='Homepage-Desktop-01'
        transform='translate(-864.000000, -1808.000000)'
      >
        <g id='Quote-Red' transform='translate(0.000000, 1772.000000)'>
          <path
            d='M1077,12 C1103.99512,12 1144,22.4392548 1144,93.5 C1144,164.560745 1086.96748,220 1028,220 L1028,172 L1028,172 C1059.89781,172 1087.5,142.097776 1087.5,120 C1058.4043,128.935547 1016,116.44552 1016,69.5 C1016,22.5544799 1050.00488,12 1077,12 Z M925,12 C951.995117,12 992,22.4392548 992,93.5 C992,164.560745 934.967484,220 876,220 L876,172 L876,172 C907.897807,172 935.5,142.097776 935.5,120 C906.404297,128.935547 864,116.44552 864,69.5 C864,22.5544799 898.004883,12 925,12 Z'
            id='Combined-Shape'
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default QuotesSVGForQuoteRichText
