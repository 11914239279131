import styled, { css } from 'styled-components'
import { gridHelper, styleForMobile, styleForTablet } from '@utils'

export const ColumnsSubSection = styled.div`
  ${({ gridConfig, numberElement, numberLine, theme }) => css`
    ${gridHelper(12, ...gridConfig.desktop)}
    grid-column: 1 / 13;
    grid-column-gap: ${theme.sizes.s8};
    grid-row-gap: ${theme.sizes.s8};
    margin-top: ${theme.sizes.s4};
    order: 3;

    ${styleForTablet} {
      ${gridHelper(6, ...gridConfig.tablet)}
      grid-column-gap: ${theme.sizes.s5};
      grid-row-gap: ${theme.sizes.s7};
      grid-template-rows: repeat(${numberLine}, 1fr);
      margin-top: 0;
    }

    ${styleForMobile} {
      ${gridHelper(4, ...gridConfig.mobile)}
      grid-row-gap: ${theme.sizes.s6};
      grid-template-rows: repeat(${numberElement}, auto);
      margin-top: 0;
    }
  `}
`
