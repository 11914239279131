import React from 'react'

const BarLineChartSVG = () => (
  <svg
    fill='none'
    height='14'
    viewBox='0 0 16 14'
    width='16'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.5 0C1.11929 0 0 1.11929 0 2.5V11.5C0 12.8807 1.11929 14 2.5 14H13.5C14.8807 14 16 12.8807 16 11.5V2.5C16 1.11929 14.8807 0 13.5 0H2.5ZM14 6.25C14 5.83579 13.6642 5.5 13.25 5.5C12.8358 5.5 12.5 5.83579 12.5 6.25V11.25C12.5 11.6642 12.8358 12 13.25 12C13.6642 12 14 11.6642 14 11.25V6.25ZM6.25 7.5C6.66421 7.5 7 7.83579 7 8.25V11.25C7 11.6642 6.66421 12 6.25 12C5.83579 12 5.5 11.6642 5.5 11.25V8.25C5.5 7.83579 5.83579 7.5 6.25 7.5ZM10.5 8.75C10.5 8.33579 10.1642 8 9.75 8C9.33579 8 9 8.33579 9 8.75V11.25C9 11.6642 9.33579 12 9.75 12C10.1642 12 10.5 11.6642 10.5 11.25V8.75ZM2.75 9.5C3.16421 9.5 3.5 9.83579 3.5 10.25V11.25C3.5 11.6642 3.16421 12 2.75 12C2.33579 12 2 11.6642 2 11.25V10.25C2 9.83579 2.33579 9.5 2.75 9.5ZM13.6574 3.37968C14.0052 3.15466 14.1047 2.69032 13.8797 2.34256C13.6547 1.9948 13.1903 1.8953 12.8426 2.12032L9.03163 4.58622L7.45 3.4C7.19862 3.21147 6.85637 3.19962 6.59256 3.37032L2.34256 6.12032C1.9948 6.34534 1.8953 6.80968 2.12032 7.15744C2.34534 7.5052 2.80968 7.6047 3.15744 7.37968L6.96837 4.91378L8.55 6.1C8.80138 6.28853 9.14363 6.30038 9.40744 6.12968L13.6574 3.37968Z'
      fill='#EB5454'
    />
  </svg>
)

export default BarLineChartSVG
