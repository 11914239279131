import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ChevronSVG } from '@icons'
import { OnlyDesktopAndTablet, OnlyMobile } from '@styles/DisplayOnMedia'
import { StyledLink } from '@styles/StyledLink'
import { Body2, Caption1 } from '@typography'

import { StyledTopMenuButton } from '../styles'
import EarthSVG from './EarthSVG'

const TopMenuButtonContainer = styled.div`
  position: relative;
`

const TopMenuButton = ({
  children,
  isDropdown = false,
  isLanguageButton = false,
  label,
  rotation,
  to,
  ...rest
}) => (
  <TopMenuButtonContainer>
    <StyledLink to={to}>
      <StyledTopMenuButton {...rest}>
        <OnlyDesktopAndTablet>
          <div>
            {isLanguageButton && <EarthSVG />}
            <Caption1 as='span'>{label}</Caption1>
            {isDropdown && <ChevronSVG rotation={rotation} />}
          </div>
        </OnlyDesktopAndTablet>
        <OnlyMobile>
          <div>
            <Body2 as='span'>{label}</Body2>
            {isDropdown && <ChevronSVG />}
          </div>
        </OnlyMobile>
      </StyledTopMenuButton>
    </StyledLink>
    {isDropdown && children}
  </TopMenuButtonContainer>
)

TopMenuButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isDropdown: PropTypes.bool,
  isLanguageButton: PropTypes.bool,
  label: PropTypes.string,
  to: PropTypes.string,
}

export default TopMenuButton
