import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const FormatClear = props => (
    <StyledIcon viewBox="0 0 11.22 12" {...props}>
      <g><path d="M10.62 0H1.77L3 1.2h2.7l-.52 2.21 1 1L6.9 1.2H10v1.2h1.2V.6a.6.6 0 0 0-.58-.6zM.85.78L0 1.62l4.57 4.57-1.03 4.61H1.62V12h4.8v-1.2H4.77l.8-3.6 4.63 4.62.85-.84L.85.78z"/></g>
    </StyledIcon>
  )

export default styled(FormatClear)``
  