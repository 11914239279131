import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Snapchat = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M15.2 11.5c-1.9-.3-2.8-2.2-2.8-2.4-.1-.2-.1-.3-.1-.5.1-.2.5-.4.8-.5.1 0 .1 0 .2-.1.5-.2.7-.5.7-.8 0-.3-.2-.5-.5-.6-.1 0-.2-.1-.3-.1-.1 0-.2 0-.3.1-.2.1-.4.2-.6.2h-.2v-.2c.1-1 .1-2.3-.2-3C10.9 1.2 8.8 1 8.2 1h-.3c-.7 0-2.8.2-3.8 2.4-.3.8-.3 2-.2 3v.3h-.2c-.2 0-.4-.1-.6-.2-.3-.1-1 .2-1.1.6 0 .3.1.6.8.9.1 0 .1 0 .2.1.3.1.7.2.8.4 0 .1 0 .3-.1.5 0 .1-.9 2.1-2.9 2.4-.2 0-.3.2-.3.4v.2c.2.4.8.7 2 .8 0 .1.1.2.1.3 0 .1.1.2.1.4 0 .1.1.3.5.3h.4c.3-.1.5-.1.8-.1H5c.4.1.7.3 1 .5.5.4 1 .7 2 .7h.2c.8 0 1.6-.2 2.2-.7.3-.2.7-.5 1-.5h.6c.3 0 .6 0 .8.1h.4c.2 0 .4-.1.4-.3 0-.1.1-.2.1-.4 0-.1 0-.2.1-.3 1.2-.2 1.8-.4 1.9-.8v-.2c-.2-.1-.3-.3-.5-.3z"/><path d="M8 15.4h-.1c-1.1 0-1.7-.3-2.3-.7-.3-.2-.6-.4-.8-.4h-.5c-.2 0-.4 0-.7.1-.2 0-.3.1-.5.1-.5 0-.8-.3-.9-.7 0-.1-.1-.2-.1-.3-1.2-.2-1.8-.6-2-1.1-.1-.3-.1-.4-.1-.5 0-.5.3-.9.8-.9 1.7-.3 2.5-2.1 2.5-2.1v-.1c-.1-.1-.3-.2-.4-.2-.1 0-.2-.1-.3-.1C1.5 8 1.5 7.4 1.5 7c.1-.3.3-.6.6-.8.4-.2.9-.2 1.2-.1h.1c-.1-1-.1-2.2.3-3C4.8.6 7.2.4 7.9.4h.3c.7 0 3.1.2 4.2 2.7.4.8.3 1.9.3 2.9.1.1.3 0 .5 0s.4 0 .5.1c.5.2.8.6.8 1.1 0 .4-.2.9-1.1 1.3-.1 0-.1.1-.2.1s-.3.1-.4.2c.1.2.9 1.9 2.5 2.2.5.1.8.5.8.9 0 .1 0 .2-.1.3-.2.5-.8.8-2 1.1 0 .1-.1.2-.1.3-.1.4-.5.7-.9.7-.1 0-.3 0-.4-.1-.3-.1-.7-.1-1.1 0-.2 0-.5.2-.8.4-.9.6-1.7.8-2.7.8zm-3.7-2.2c.2 0 .4 0 .6.1.5.1.8.3 1.2.6.5.3.9.6 1.7.6h.1c.8 0 1.5-.2 2-.6.4-.3.8-.5 1.2-.6.6-.1 1.1 0 1.5 0h.3V13c0-.1.1-.2.1-.3l.1-.3h.3c1-.2 1.4-.4 1.5-.5-2-.4-2.9-2.3-3.1-2.7-.2-.3-.2-.6-.1-.9.2-.4.7-.6 1.1-.7.1 0 .1 0 .2-.1.4-.1.5-.3.5-.3s0-.1-.2-.1H13c-.3.1-.5.2-.8.2-.1 0-.2 0-.3-.1l-.4-.1v-.6c.1-1 .1-2.2-.1-2.8-.9-2-2.8-2.1-3.3-2.1h-.3c-.6 0-2.4.2-3.3 2.1-.2.6-.1 1.8-.1 2.7v.7l-.3.1c-.1 0-.2 0-.3.1-.2 0-.5-.1-.8-.2h-.3c-.1 0-.1.1-.1.1s.1.2.5.3c.1 0 .1 0 .2.1.4.1.9.3 1.1.7.2.4 0 .8-.1.9 0 .1-1 2.2-3.1 2.7.1.1.5.3 1.5.5H3v.3c0 .1.1.3.1.4 0 .1 0 .2.1.3 0 0 .1 0 .2-.1.3-.1.6-.1.9-.1zM15.1 12zM.9 11.9z" fill="#000"/></g>
    </StyledIcon>
  )

export default styled(Snapchat)``
  