import styled from 'styled-components'
import { resetCSS } from '../../../helpers'
import PrimaryButton from '../../Button/styles/PrimaryButton'

const PrimaryLink = styled(PrimaryButton).attrs(resetCSS)`
  text-decoration: none;

  &:active,
  &:hover,
  &:focus {
    text-decoration: none;
  }
`

export default PrimaryLink
