import { isArray, map } from 'lodash'

export const sanitizePriceForLocale = (pricesValue, locale = 'en-US') => {
  if (isArray(pricesValue)) {
    return map(pricesValue, (price) =>
      ['de-DE', 'es-ES'].includes(locale) ? price.replaceAll(',', ' ') : price,
    )
  } else {
    return ['de-DE', 'es-ES'].includes(locale)
      ? pricesValue.replace(',', ' ')
      : pricesValue
  }
}
