import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Deliverability = props => (
    <StyledIcon viewBox="2 0 24 27" {...props}>
      <g><path d="M23.057 12.304c1.23 0 2.229 1.014 2.229 2.261V24.74c0 1.247-.999 2.261-2.229 2.261H5.23C3.999 27 3 25.986 3 24.74V14.564c0-1.247.998-2.26 2.229-2.26zm0 3.593l-8.325 5.279a1.1 1.1 0 01-1.179 0L5.23 15.898v8.841h17.83l-.002-8.842zm-2.103-1.332H7.331l6.812 4.318 6.811-4.318zM15.257 1v5.183l2.555-2.592 1.576 1.6-5.245 5.32-5.245-5.32 1.576-1.6 2.555 2.592V1h2.228z"/></g>
    </StyledIcon>
  )

export default styled(Deliverability)``
  