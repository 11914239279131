import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ArrowUp = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M2.453 7.889l3.166-2.955V16h2.762V4.934l3.166 2.955L13.5 6.067 7 0 .5 6.067z"/></g>
    </StyledIcon>
  )

export default styled(ArrowUp)``
  