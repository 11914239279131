import { isNil, split } from 'lodash'
// Display a string date based on date and locale. Possibly no displaying day.
const getStringDateFormated = (date, locale) => {
  const dateSplited = split(date, '-')
  const year = parseInt(dateSplited[0])
  const month = parseInt(dateSplited[1]) - 1
  const day = dateSplited[2] && parseInt(dateSplited[2])

  if (!year || isNil(month)) return ''

  const dateInstance = new Date(year, month, day || 1, 0, 0, 0)
  const options = { year: 'numeric', month: 'long' }
  // Day is optional
  if (day) options.day = 'numeric'

  return new Intl.DateTimeFormat(locale, options).format(dateInstance)
}

export { getStringDateFormated }
