import React from 'react'

import styled from 'styled-components'
import PropTypes from 'prop-types'
import { map } from 'lodash'

import { Body2, Body2Medium } from '../../Typography'

import LabelsContainer from '../styles/LabelsContainer'
import { useSteppedSlider } from '../hooks'

const Labels = ({ 'data-testid': dataTestId, ...props }) => {
  const { selectedId, changeSelectedId, steps } = useSteppedSlider()

  const onClickLabel = (stepId) => {
    if (selectedId !== stepId) {
      changeSelectedId(stepId)
    }
  }

  return (
    <LabelsContainer {...props}>
      {map(steps, step => {
        const { id, label } = step
        const labelDataTestid = `${dataTestId}-${id}`
        return id === selectedId ? (
          <Body2Medium data-testid={labelDataTestid} key={id} onClick={() => onClickLabel(id)}>
            {label}
          </Body2Medium>
        ) : (
          <Body2 data-testid={labelDataTestid} key={id} onClick={() => onClickLabel(id)}>
            {label}
          </Body2>
        )
      })}
    </LabelsContainer>
  )
}

Labels.propTypes = {
  /**
   * The base data-testid that will be set to each label with the step id suffixed.
   */
  'data-testid': PropTypes.string,
}

export default styled(Labels)``
