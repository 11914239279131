import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';

import RoundNotification from '../../RoundNotification';
import Tooltip from '../../Tooltip';
import ButtonLoader from '../styles/ButtonLoader';
import DropdownButton from '../styles/DropdownButton';
import IconButton from '../styles/IconButton';
import PrimaryButton from '../styles/PrimaryButton';
import SecondaryButton from '../styles/SecondaryButton';
import StyledImage from '../styles/StyledImage';
import TertiaryButton from '../styles/TertiaryButton';
import ToggleButton from '../styles/ToggleButton';
import UpgradeButton from '../styles/UpgradeButton';

/**
 * A button component.
 * The mode props allow users to choose what type of button they want.
 */
class Button extends React.Component {
  static defaultProps = {
    mode: 'primary',
    neutral: false,
    onClick: noop,
    outlineDark: false,
    type: 'button',
  };

  onClick = (e) => {
    const { noFocus, onClick } = this.props;

    // Remove the focus after the click if asked.
    noFocus && document.activeElement && document.activeElement.blur();

    onClick && onClick(e);
  };

  render() {
    const {
      children,
      className,
      'data-testid': dataTestId,
      'data-testid-loader': dataTestIdLoader,
      deletion,
      disabled,
      icon: Icon,
      image,
      isLoading,
      locator,
      mode,
      notif,
      _ref,
      selected,
      small,
      tooltip,
      ...rest
    } = this.props;

    // Can't be loading if disabled.
    const realIsLoading = isLoading && !disabled;

    // Calculate the children according to the button mode.
    let realChildren;
    switch (mode) {
      case 'icon':
        realChildren = (
          <>
            {Icon && !image && (
              <>
                <Icon key="icon" />
                {notif && <RoundNotification color={notif.color} type={notif.type} />}
              </>
            )}
            {image && <StyledImage mt="-4px">{image}</StyledImage>}
            {children && children}
          </>
        );
        break;
      case 'tertiary':
        realChildren = (
          <>
            {Icon && !image && <Icon mr="s3" size="s3" />}
            {image && <StyledImage mt="0">{image}</StyledImage>}
            {children}
          </>
        );
        break;
      default:
        realChildren = children;
        break;
    }

    // Add a loader to the content if we are loading.
    const content = (
      <>
        {realIsLoading && <ButtonLoader data-testid={dataTestIdLoader} size="s4" />}
        {realChildren}
      </>
    );

    // Add classes.
    const newClassNames = cx(className, locator, {
      'is-disabled': disabled,
      'is-loading': realIsLoading,
      'is-selected': selected,
    });

    // Primary by default.
    let s = <PrimaryButton deletion={deletion}>{content}</PrimaryButton>;
    switch (mode) {
      case 'dropdown':
        // Button for dropdown component.
        s = <DropdownButton>{content}</DropdownButton>;
        break;
      case 'icon':
        // Icon Button.
        s = (
          <IconButton as="button" deletion={deletion} disabled={disabled} isLoading={isLoading}>
            {content}
          </IconButton>
        );
        break;
      case 'secondary':
        // Secondary Button.
        s = <SecondaryButton>{content}</SecondaryButton>;
        break;
      case 'tertiary':
        // Tertiary button.
        s = <TertiaryButton>{content}</TertiaryButton>;
        break;
      case 'toggle':
        // Button for toggle.
        s = <ToggleButton>{content}</ToggleButton>;
        break;
      case 'upgrade':
        // Upgrade Button.
        s = <UpgradeButton>{content}</UpgradeButton>;
        break;
    }

    // Prevent onClick when disabled or loading.
    const realOnClick = disabled || isLoading ? noop : this.onClick;
    const clonedElement = React.cloneElement(s, {
      ...rest,
      className: newClassNames,
      'data-testid': dataTestId,
      disabled,
      onClick: realOnClick,
      ref: _ref,
      $small: small,
    });

    if (tooltip) {
      return (
        <Tooltip direction={tooltip.direction || 'top'} message={tooltip.message || ''}>
          {clonedElement}
        </Tooltip>
      );
    }
    return clonedElement;
  }
}

Button.propTypes = {
  _ref: PropTypes.oneOfType([
    PropTypes.func, 
    typeof Element !== 'undefined' ? PropTypes.shape({ current: PropTypes.instanceOf(Element) }) : PropTypes.any
  ]),
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  'data-testid-loader': PropTypes.string,
  deletion: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.elementType,
  image: PropTypes.node,
  isLoading: PropTypes.bool,
  locator: PropTypes.string,
  mode: PropTypes.oneOf(['dropdown', 'icon', 'primary', 'secondary', 'tertiary', 'toggle', 'upgrade']),
  neutral: PropTypes.bool,
  noFocus: PropTypes.bool,
  notif: PropTypes.shape({
    color: PropTypes.string,
    type: PropTypes.string,
  }),
  onClick: PropTypes.func,
  outlineDark: PropTypes.bool,
  selected: PropTypes.bool,
  small: PropTypes.bool,
  style: PropTypes.object,
  tooltip: PropTypes.shape({
    direction: PropTypes.string,
    message: PropTypes.string,
  }),
  type: PropTypes.string,
};

export default Button;