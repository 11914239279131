import React from 'react'

// s4 for margin when infinite loader is set
const MARGIN_HEIGHT = 24

const useInfiniteScroll = (
  isLoading,
  isLoadingMoreRef,
  elementRef,
  onLoadMore,
  isAnchoredToElement = false,
  ...arrayToWatch
) => {
  React.useEffect(() => {
    const { current = {} } = elementRef
    const scrollAnchor = isAnchoredToElement ? current : document

    const scrollFunction = () => {
      const currentScrollHeight =
        current.getBoundingClientRect().top + current.getBoundingClientRect().height + MARGIN_HEIGHT

      const scrollTop = isAnchoredToElement ? current.scrollTop : window.innerHeight
      const scrollTopMax = isAnchoredToElement
        ? current.scrollHeight - current.clientHeight
        : currentScrollHeight

      if (
        !isLoadingMoreRef.current &&
        !isLoading &&
        (isAnchoredToElement || !!window) &&
        !!current &&
        scrollTopMax <= scrollTop
      ) {
        isLoadingMoreRef.current = true
        onLoadMore && onLoadMore()
      }
    }
    if (scrollAnchor) {
      scrollAnchor.addEventListener('scroll', scrollFunction)
      return () => scrollAnchor.removeEventListener('scroll', scrollFunction)
    }
  }, [elementRef, isLoading, ...arrayToWatch])
}

export { useInfiniteScroll }
