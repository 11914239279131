import { Children } from 'react'
import styled from 'styled-components'

import { optionsPanelStyle } from './styles'
import NoResultOption from './components/NoResultOption'

const OptionsPanel = styled.div`
  ${optionsPanelStyle};
  top: 100%;
  display: flex;

  ${({ alignOptions = 'left', children }) => `
    left: ${alignOptions === 'left' ? '0' : 'auto'};
    right: ${alignOptions === 'right' ? '0' : 'auto'};
    
    & > ${NoResultOption} {
      ${
        Children.count(children) > 1 &&
        `
        display: none;
      `
      };
    } 
  `};
`

export default OptionsPanel
