import React from 'react'
import styled, { css } from 'styled-components'

import ButtonPrimary from './ButtonPrimary'
import { withLink } from './withLink'

const StyledButtonPrimary = styled(ButtonPrimary)`
  ${({ theme }) => css`
    background: ${theme.colors.primary50};
    border: ${theme.sizes.s1} solid ${theme.colors.primary50};
    border-radius: ${theme.sizes.s3};
    box-shadow: 0 ${theme.sizes.s1} ${theme.sizes.s3} -${theme.sizes.s1} ${theme.colors.primary50}66;
    height: ${theme.sizes.s8};
    padding: ${theme.sizes.s12};
    width: ${theme.sizes.s8};

    &.is-disabled {
      background: ${theme.colors.primary50};
      border-color: ${theme.colors.primary50};
      opacity: 0.4;
    }

    p {
      align-items: center;
      display: flex;
    }

    svg * {
      fill: ${theme.colors.iconPrimary};
    }
  `}
`
const ButtonIcon = ({ children, icon, small, to, ...props }) => (
  <StyledButtonPrimary
    aria-label={children && children.toString()}
    mode='primary'
    small={small}
    {...props}
  >
    {icon}
  </StyledButtonPrimary>
)

export default styled(withLink(ButtonIcon))``
