import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const CloseCircle = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M6 0a6 6 0 1 0 6 6 6 6 0 0 0-6-6zm2.82 8L8 8.82l-2-2-2 2L3.18 8l2-2-2-2L4 3.18l2 2 2-2 .82.82-2 2z"/></g>
    </StyledIcon>
  )

export default styled(CloseCircle)``
  