import styled, { css } from 'styled-components'

const trackHeight = ({ theme }) => css`
  height: ${theme.sizes.s2};
`

const trackBorderRadius = ({ theme }) => css`
  border-radius: ${theme.globals.borderRadius.bR3};
`

const trackStyle = () => css`
  width: 100%;
  cursor: pointer;
  ${trackHeight};
  ${trackBorderRadius};
`

const thumbStyle = ({
  theme: {
    components: { slider },
    globals,
    sizes,
  },
}) => css`
  cursor: pointer;
  box-sizing: border-box;
  height: ${sizes.s4};
  width: ${sizes.s4};
  border: ${globals.borderWidth.bW5} solid ${slider.thumbBorder};
  border-radius: ${globals.borderRadius.bRCircle};
  background-color: ${slider.thumb};
  box-shadow: 0 ${sizes.s0} ${sizes.s1} 0 ${slider.thumbShadow};
  z-index: 2;
  position: relative;
`

const StyledSlider = styled.input.attrs(() => ({
  type: 'range',
}))`
  display: inline;
  -webkit-appearance: none;
  background: ${({ theme }) => theme.colors.transparent}; // Otherwise white in Firefox
  width: 100%;

  :focus:not(:focus-visible) {
    outline: none;
  }

  /* WebKit/Blink */
  &::-webkit-slider-runnable-track {
    ${trackStyle}
    ${({
      theme: {
        components: { slider },
        sizes,
      },
    }) => `
      background: ${slider.trackBackground};
      padding-top: ${sizes.s1};
      margin-top: ${sizes.s3};
      margin-bottom: ${sizes.s3};
    `}
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -${({ theme }) => theme.sizes.s3};
    ${thumbStyle}
  }

  /* Firefox */
  &::-moz-range-track {
    ${trackStyle}
    background: ${({ theme }) => theme.components.slider.trackBackground};
  }

  &::-moz-range-progress {
    ${({ theme }) => `
      width: 100%;
      cursor: pointer;
      background: ${theme.components.slider.trackFilled};
    `}
    ${trackHeight};
    ${trackBorderRadius};
  }

  &::-moz-range-thumb {
    ${thumbStyle}
  }

  /* IE/Edge */
  &::-ms-track {
    ${({ theme }) => `
      width: 100%;
      cursor: pointer;
      background: ${theme.colors.transparent};
      border-color: ${theme.colors.transparent};
      color: ${theme.colors.transparent};
    `}
    ${trackHeight};
  }

  &::-ms-fill-lower {
    background: ${({ theme }) => theme.components.slider.trackFilled};
    ${trackBorderRadius};
  }

  &::-ms-fill-upper {
    background: ${({ theme }) => theme.components.slider.trackBackground};
    ${trackBorderRadius};
  }

  &::-ms-thumb {
    margin-top: 0;
  }
`

export default StyledSlider
