import styled from 'styled-components'
import { Body2 } from '../../Typography'

const InputStyled = styled(Body2.withComponent('input'))`
  width: 100%;
  background: inherit;
  border: none;
  color: ${({ theme }) => theme.components.select.text};
  cursor: text;

  ::-webkit-input-placeholder,
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.components.select.placeholder};
  }

  ::placeholder {
    color: ${({ theme }) => theme.components.select.placeholder};
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
  }
`

export default InputStyled
