import styled from 'styled-components'

const PaginationRighPart = styled('div')`
  align-items: center;
  display: flex;
  margin-left: auto;
  order: 2;
`

export default PaginationRighPart
