import styled from 'styled-components'

import LanguagesDesktop from '../components/LanguagesDesktop'
import LanguagesMobile from '../components/LanguagesMobile'

const ContainerStyled = styled.div`
  ${({ hasNoResponsive, theme }) => `
    overflow: hidden;
    border-radius: ${theme.globals.borderRadius.borderRadius1};
    box-shadow: ${theme.globals.shadows.shadow2};

    ${LanguagesMobile} {
      display: none;
    }

    ${
      !hasNoResponsive &&
      `
      @media (max-width: ${theme.responsive.md.breakpoint}px) {
        ${LanguagesDesktop} {
          display: none;
        }
        ${LanguagesMobile} {
          display: block;
        }
      }
    `
    }
  `};

  ${({ isScrollable, theme }) =>
    isScrollable &&
    `
    background-color: ${theme.colors.darksteel};
    overflow-y: auto;
    height: 100%;
    width: 100%;
     `};
`

export default ContainerStyled
