import React from 'react'
import { css } from 'styled-components'
import { display, margins, paddings } from '../../../helpers'


const getVisitedStyle = ({ icon, theme }) => {
  const { textVisited } = theme.components.link
  return !icon
    ? css`
        &:visited {
          color: ${textVisited};
          svg {
            fill: ${textVisited};
          }
        }

        &.is-disabled:visited,
        &.is-disabled:visited:hover,
        &.is-disabled:visited:active,
        &.is-disabled:visited:focus {
          color: ${textVisited};
          svg {
            fill: ${textVisited};
          }
          cursor: not-allowed;
          opacity: 0.4;
        }
      `
    : null
}

const getFocusStyle = ({ icon, theme }) => {
  const { textFocus } = theme.components.link

  const baseStyle = `
  color: ${textFocus};
  `

  return !icon
    ? `
  &:focus:not(.is-disabled) {
    ${baseStyle};
  }`
    : `
    &:focus:not(.is-disabled) {
      ${baseStyle};
      svg {
        fill: ${textFocus};
      }
    }
  `
}



const LinkStyle = css`
  ${display};
  ${margins};
  ${paddings};
  box-sizing: border-box;
  cursor: pointer;
  ${({ theme: { components, globals } }) => css`
    color: ${components.link.text};
    text-decoration: ${components.link.textDecoration};
    svg {
      fill: ${components.link.text};
    }

    &:hover:not(.is-disabled) {
      color: ${components.link.textHover};
      text-decoration: ${components.link.textDecorationHover};
      svg {
        fill: ${components.link.textHover};
      }
    }

    ${getVisitedStyle};

    ${getFocusStyle};

    &.is-disabled:not(:visited),
    &.is-disabled:not(:visited):hover,
    &.is-disabled:not(:visited):active,
    &.is-disabled:not(:visited):focus {
      color: ${components.link.text};
      svg {
        fill: ${components.link.text};
      }
      cursor: not-allowed;
      opacity: ${globals.opacity.o1};
    }

    &:active:not(.is-disabled) {
      color: ${components.link.textPressed};

      svg {
        fill: ${components.link.textPressed};
      }
    }
  `}
`

export default LinkStyle
