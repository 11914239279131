import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ArrowDown = props => (
    <StyledIcon viewBox="7 6 6 8" {...props}>
      <g><path d="M7.7 10.1l1.6 1.4V6h1.4v5.5l1.6-1.4.9.9-3.2 3-3.2-3z"/></g>
    </StyledIcon>
  )

export default styled(ArrowDown)``
  