import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Globe = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g fillRule="evenodd" id="Icon/top-dropdown/domain-monitoring" stroke="none" strokeWidth="1">
                <g fillRule="nonzero" id="world-2" transform="translate(0.000000, 0.266667)">
                    <path d="M7.33333333,14.6666667 L7.33333333,8.53333333 L4,8.53333333 C4.2,11.3333333 5.66666667,13.5333333 7.33333333,14.6666667 Z"/>
                    <path d="M12,8.53333333 L8.66666667,8.53333333 L8.66666667,14.6666667 C10.3333333,13.5333333 11.8,11.3333333 12,8.53333333 Z"/>
                    <path d="M7.33333333,7.2 L7.33333333,1.06666667 C5.46666667,2.4 4.2,4.73333333 4,7.2 L7.33333333,7.2 Z"/>
                    <path d="M13.3333333,7.2 L16,7.2 C15.7333333,3.6 13,0.666666667 9.53333333,0 C11.6666667,1.6 13.1333333,4.33333333 13.3333333,7.2 Z"/>
                    <path d="M8.66666667,1.06666667 L8.66666667,7.2 L12,7.2 C11.8,4.73333333 10.5333333,2.33333333 8.66666667,1.06666667 Z"/>
                    <path d="M13.3333333,8.53333333 C13.1333333,11.4666667 11.7333333,14.1333333 9.53333333,15.7333333 C13,15.0666667 15.6666667,12.1333333 16,8.53333333 L13.3333333,8.53333333 Z"/>
                    <path d="M2.66666667,8.53333333 L0,8.53333333 C0.266666667,12.1333333 2.93333333,15.0666667 6.46666667,15.7333333 C4.33333333,14.1333333 2.86666667,11.4666667 2.66666667,8.53333333 Z"/>
                    <path d="M2.66666667,7.2 C2.86666667,4.33333333 4.33333333,1.6 6.46666667,5.92118946e-16 C3,0.666666667 0.333333333,3.6 0.0666666667,7.2 L2.66666667,7.2 Z"/>
                </g>
            </g>
    </StyledIcon>
  )

export default styled(Globe)``
