import React from 'react';
import { get, includes, throttle } from 'lodash';
import { Portal } from 'react-portal';

const CHECKING_UPDATE_INTERVAL = 20;
const UPDATING_POSITION_INTERVAL = 50;

const computePosition = (
  pos,
  opts
) => {
  const { targetRect, rect } = opts;
  let { left = 0, top = 0 } = targetRect || {};
  const { height = 0, width = 0 } = targetRect || {};

  if (pos === 'top') {
    // Already right placed.
  } else if (pos === 'bottom') {
    top += height;
  } else if (pos === 'left') {
    top += height / 2;
  } else if (pos === 'right') {
    left += width;
    top += height / 2;
  } else {
    // Make explicit that we do nothing with other options
  }

  // never go beyond window
  const portalWidth = rect?.width;
  if (portalWidth && left + portalWidth > window.innerWidth) {
    const delta = left + portalWidth - window.innerWidth;
    left -= delta;
  }

  return { left, top, widthParent: width };
};

class StickyPortal extends React.Component {
  static defaultProps = {
    zIndex: 9000,
  };

  _checkForUpdate = undefined;
  _childrenRef = null;
  _root = null;

  state = {
    isFirstRender: false,
    previousLeftPosition: undefined,
    previousTopPosition: undefined,
    x: 0,
    y: 0,
    width: undefined,
  };

  componentDidMount() {
    const { refreshEventOff, refreshEventOn } = this.props;

    refreshEventOff && refreshEventOn && refreshEventOn(this.updatePosition);

    this.updatePosition();

    !!window && window.addEventListener('resize', this.delayedUpdate);
    this._checkForUpdate = setInterval(this.shouldPositionUpdate, CHECKING_UPDATE_INTERVAL);
  }

  componentDidUpdate(prevProps) {
    const { isOpened } = this.props;

    if (prevProps.isOpened !== isOpened) {
      this.updatePosition();
    }
  }

  componentWillUnmount() {
    const { refreshEventOff } = this.props;

    refreshEventOff && refreshEventOff(this.updatePosition);

    !!window && window.removeEventListener('resize', this.delayedUpdate);
    if (this._checkForUpdate) {
      clearInterval(this._checkForUpdate);
    }
  }

  handleWidth = () => {
    const { style } = this.props;
    const { width } = this.state;

    const styleWidth = get(style, 'width', '100%');

    return styleWidth === '100%' ? width : styleWidth;
  };

  handlePositioner = (pos) => {
    const { style } = this.props;
    const stylePos = get(style, pos);
    if (stylePos) {
      if (includes(['100%', 'auto'], stylePos)) {
        return 0;
      }
      return stylePos;
    }
    return 0;
  };

  setRef = (el) => {
    const { ref } = this.props;
    ref && ref(el); // not sure what this is doing but keep it for now.
    this._root = el;
  };

  setChildrenRef = (n) => {
    this._childrenRef = n;
  };

  getRelativeAncestor() {
    if (!this._root) {
      return null;
    }
    let parent = this._root.parentElement;
    while (
      get(parent, 'style.position') !== 'relative' &&
      !!window &&
      parent !== window.document.body
    ) {
      parent = parent?.parentElement ?? null;
    }
    return parent;
  }

  getStyle = () => {
    const { style, zIndex } = this.props;
    const { isFirstRender, x, y } = this.state;

    let resultStyle = {
      ...style,
      width: this.handleWidth(),
      position: 'absolute',
      top: this.handlePositioner('top'),
      left: this.handlePositioner('left'),
      transform: `translateX(${x}px) translateY(${y}px)`,
      zIndex,
    };

    // The transitions should be applied to the first transform (when we're placing in absolute the portal)
    if (!isFirstRender) {
      resultStyle = {
        ...resultStyle,
        transition: 'transform 25ms ease',
      };
    }

    return resultStyle;
  };

  shouldPositionUpdate = () => {
    if (!this.props.isOpened || !this._childrenRef) {
      return;
    }

    // Get the closest relative ancestor.
    const refNode = this.getRelativeAncestor();

    if (!refNode) {
      return;
    }

    const targetBoundingRect = refNode.getBoundingClientRect();

    const { left, top } = targetBoundingRect;
    const { previousLeftPosition, previousTopPosition } = this.state;

    // We check if the referenced target has moved since the last check.
    if (previousLeftPosition !== left || previousTopPosition !== top) {
      this.updatePosition(targetBoundingRect);
    }
  };

  delayedUpdate = throttle(() => this.updatePosition(), UPDATING_POSITION_INTERVAL);

  updatePosition = (targetBoundingRect) => {
    let refNode;

    const getTargetRect = () => {
      if (!targetBoundingRect) {
        if (!this.props.isOpened) {
          return;
        }

        if (!this._childrenRef) {
          return;
        }

        // Get the closest relative ancestor.
        refNode = this.getRelativeAncestor();

        if (!refNode) {
          return;
        }
        return refNode.getBoundingClientRect();
      }
      return targetBoundingRect;
    };

    const { position } = this.props;

    // Get target rect.
    const targetRect = getTargetRect();

    if (!targetRect) {
      return;
    }

    // Get node rect.
    const rect = this._childrenRef ? this._childrenRef.getBoundingClientRect() : undefined;

    const finalPos = computePosition(position, {
      rect,
      targetRect,
    });

    let nextState = {
      previousLeftPosition: targetRect?.left,
      previousTopPosition: targetRect?.top,
      x: Math.round(finalPos.left + (!!window && window.pageXOffset ? window.pageXOffset : 0)),
      y: Math.round(finalPos.top + (!!window && window.pageYOffset ? window.pageYOffset : 0)),
      width: finalPos.widthParent,
    };

    const { isFirstRender } = this.state;

    if (!isFirstRender) {
      nextState = {
        ...nextState,
        isFirstRender: true,
      };
    } else if (isFirstRender) {
      nextState = {
        ...nextState,
        isFirstRender: false,
      };
    }

    // Here, handle the scroll value.
    this.setState(nextState);
  };

  render() {
    const { className, children, isOpened } = this.props;

    return (
      <div ref={this.setRef}>
        {isOpened && (
          <Portal>
            <div className={className} ref={this.setChildrenRef} style={this.getStyle()}>
              {children}
            </div>
          </Portal>
        )}
      </div>
    );
  }
}

export default StickyPortal;
