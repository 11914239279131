import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Server2 = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M10.8 0H1.2A1.2 1.2 0 0 0 0 1.2v9.6A1.2 1.2 0 0 0 1.2 12h9.6a1.2 1.2 0 0 0 1.2-1.2V1.2A1.2 1.2 0 0 0 10.8 0zM7.2 1.2v9.6H4.8V1.2zm-6 0h2.4v9.6H1.2zm9.6 9.6H8.4V1.2h2.4z"/><path d="M2.4 9a.6.6 0 1 0 .6.6.6.6 0 0 0-.6-.6zM6 9a.6.6 0 1 0 .6.6A.6.6 0 0 0 6 9zm3.6 0a.6.6 0 1 0 .6.6.6.6 0 0 0-.6-.6zM1.8 1.8H3v6.6H1.8zm3.6 0h1.2v6.6H5.4zm3.6 0h1.2v6.6H9z"/></g>
    </StyledIcon>
  )

export default styled(Server2)``
  