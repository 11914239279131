import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const NineSquares = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g>
        <path d="M0 2 l0 -2 2 0 2 0 0 2 0 2 -2 0 -2 0 0 -2z" />
        <path d="M6 2 l0 -2 2 0 2 0 0 2 0 2 -2 0 -2 0 0 -2z" />
        <path d="M12 2 l0 -2 2 0 2 0 0 2 0 2 -2 0 -2 0 0 -2z" />
        <path d="M0 8 l0 -2 2 0 2 0 0 2 0 2 -2 0 -2 0 0 -2z" />
        <path d="M6 8 l0 -2 2 0 2 0 0 2 0 2 -2 0 -2 0 0 -2z" />
        <path d="M12 8 l0 -2 2 0 2 0 0 2 0 2 -2 0 -2 0 0 -2z" />
        <path d="M0 14 l0 -2 2 0 2 0 0 2 0 2 -2 0 -2 0 0 -2z" />
        <path d="M6 14 l0 -2 2 0 2 0 0 2 0 2 -2 0 -2 0 0 -2z" />
        <path d="M12 14 l0 -2 2 0 2 0 0 2 0 2 -2 0 -2 0 0 -2z" />
      </g>
    </StyledIcon>
  )

export default styled(NineSquares)``
