import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const RoundCheck = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M6 12a6 6 0 1 1 6-6 6 6 0 0 1-6 6zM6 1.2A4.8 4.8 0 1 0 10.8 6 4.81 4.81 0 0 0 6 1.2z"/><path d="M5.5 8.96L3.07 6.53l1.06-1.06L5.3 6.64l2.49-3.48 1.22.88L5.5 8.96z"/></g>
    </StyledIcon>
  )

export default styled(RoundCheck)``
  