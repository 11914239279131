import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Error = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M11.333 5.668l-1.002-1.001-2.33 2.332-2.332-2.332-1.002 1.001L6.998 8l-2.331 2.332 1.002 1.001L8 9.003l2.331 2.33 1.002-1.001L9.002 8l2.331-2.332zM8 16c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zM8 1.6A6.407 6.407 0 0 0 1.6 8c0 3.529 2.871 6.4 6.4 6.4 3.529 0 6.4-2.871 6.4-6.4 0-3.529-2.871-6.4-6.4-6.4z"/></g>
    </StyledIcon>
  )

export default styled(Error)``
  