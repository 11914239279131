import React, { Component } from 'react'
import { merge } from 'lodash'
import { ThemeProvider } from 'styled-components'


/**
 * A component which may be used to override partial parts of the 'theme'.
 * Pass partial theme into 'otherTheme' and it will be merged with the actual one.
 */
class ThemePartialProvider extends Component{
  /** Merge all themes together. */
  static calculateTheme(props) {
    const { dark, otherTheme = {}, theme = {} } = props

    const {
      colors: themeColors,
      colorsVariationsFactory,
      componentsFactory,
      darkComponentsFactory,
      globals,
    } = theme
    const { components: otherComponents = {}, colors: otherColors = {}, ...otherRest } = otherTheme

    const baseColors = merge({}, themeColors, otherColors)
    const colors = !!colorsVariationsFactory
      ? colorsVariationsFactory({ colors: baseColors })
      : ({})

    let components = {}
    const componentsArgs = { colors, globals }

    if (dark) {
      components = !!darkComponentsFactory
        ? merge({}, components, darkComponentsFactory(componentsArgs))
        : {}
    } else {
      components = !!componentsFactory ? componentsFactory(componentsArgs) : {}
    }

    return merge(
      {},
      theme || {},
      otherRest || {},
      { colors },
      { components: merge({}, components, otherComponents) },
    )
  }

  state = {
    theme: ThemePartialProvider.calculateTheme(this.props),
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { dark, light, otherTheme, theme } = nextProps

    if (
      light !== prevState.light ||
      dark !== prevState.dark ||
      otherTheme !== prevState.otherTheme ||
      theme !== prevState.theme
    ) {
      // At least one theme changed, recalculate the theme.
      return {
        dark,
        light,
        otherTheme,
        theme: ThemePartialProvider.calculateTheme(nextProps),
      }
    }

    return null
  }

  render() {
    const { children, theme: propTheme } = this.props
    const { theme } = this.state

    const realTheme = theme || propTheme

    return <ThemeProvider theme={realTheme}>{children}</ThemeProvider>
  }
}

export default ThemePartialProvider
