import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const FourCol = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M3.667.667h.666v14.666h-.666V.667zm8 0h.666v14.666h-.666V.667zm-4 0h.666v14.666h-.666V.667zm6.89 0c.429 0 .776.347.776.776v13.11a.78.78 0 0 1-.776.78H1.443a.78.78 0 0 1-.776-.78V1.443c0-.429.347-.776.776-.776h13.114zm0-.667H1.443A1.447 1.447 0 0 0 0 1.443v13.11A1.45 1.45 0 0 0 1.443 16h13.114A1.45 1.45 0 0 0 16 14.553V1.443A1.447 1.447 0 0 0 14.557 0z"/></g>
    </StyledIcon>
  )

export default styled(FourCol)``
  