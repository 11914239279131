import React from 'react'
import styled from 'styled-components'

const ExternalLinkSVG = (props) => (
  <svg
    width='16'
    height='16'
    aria-hidden='true'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 16 16'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 5C3.44772 5 3 5.44772 3 6V12C3 12.5523 3.44772 13 4 13H10C10.5523 13 11 12.5523 11 12V9.83333C11 9.28105 11.4477 8.83333 12 8.83333C12.5523 8.83333 13 9.28105 13 9.83333V12C13 13.6569 11.6569 15 10 15H4C2.34315 15 1 13.6569 1 12V6C1 4.34315 2.34315 3 4 3H6.16667C6.71895 3 7.16667 3.44772 7.16667 4C7.16667 4.55228 6.71895 5 6.16667 5H4Z'
      fill='currentColor'
    ></path>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 2C9 1.44772 9.44771 1 10 1H14C14.5523 1 15 1.44772 15 2V6C15 6.55228 14.5523 7 14 7C13.4477 7 13 6.55228 13 6V3H10C9.44771 3 9 2.55228 9 2Z'
      fill='currentColor'
    ></path>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.7071 1.29289C15.0976 1.68342 15.0976 2.31658 14.7071 2.70711L6.70711 10.7071C6.31658 11.0976 5.68342 11.0976 5.29289 10.7071C4.90237 10.3166 4.90237 9.68342 5.29289 9.29289L13.2929 1.29289C13.6834 0.902369 14.3166 0.902369 14.7071 1.29289Z'
      fill='currentColor'
    ></path>
  </svg>
)

export default styled(ExternalLinkSVG)``
