// Track CTA click event for GA4
const trackClick = ({
  // Will pass extra parameter to GA4. Since 'to' will be null it helps to identify which file is downloaded
  fileName,
  text,
  to,
}) => {
  // Make sure to only run that client side when gtag is available
  if (typeof window === 'undefined' || !window?.gtag) return

  window.gtag('event', 'marketing_cta_click', {
    ...(fileName ? { fileName } : {}),
    from: location.href,
    to,
    text,
  })
}

export default trackClick
