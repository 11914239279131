import * as React from 'react'
import cx from 'classnames'
import { isFunction } from 'lodash'

import Div from '../Div'
import { Body2Medium, Caption } from '../Typography'

import ErrorMessageContainer from './styles/ErrorMessageContainer'
import InformationMessageContainer from './styles/InformationMessageContainer'
import InformationErrorBlock from './styles/InformationErrorBlock'
import LabelContainer from './styles/LabelContainer'
import NotifIcon from './styles/NotifIcon'
import NotifText from './styles/NotifText'
import StyledDiv from './styles/StyledDiv'
import SubLabel from './styles/SubLabel'

/**
 * Wrapper of labels, errors, informations messages
 */
class LabelledWrapper extends React.PureComponent {
  /**
   * Indicate if the error message should be displayed.
   */
  isErrorMessageDisplayed = () => {
    const { error, errorMessage } = this.props
    return !!error && !!errorMessage
  }

  /**
   * Indicate if we have to add space below the input.
   */
  shouldAddSpaceBelow = () =>
    this.isErrorMessageDisplayed() || this.props.informationMessage || this.props.maxLength

  render() {
    const {
      ariaLabelId,
      charNumber,
      children,
      className,
      disabled,
      errorMessage,
      focus = false,
      id,
      information = false,
      informationMessage,
      label,
      labelRightChildren,
      locator,
      maxLength,
      optionalMessage,
      required,
      showLengthIndicator = true,
      style,
      sublabel,
    } = this.props

    const shouldDisplayErrorMessage = this.isErrorMessageDisplayed()
    const shouldDisplayInformation =
      !this.isErrorMessageDisplayed() && informationMessage !== undefined && (information || focus)
    const shouldDisplayMaxLength = maxLength && showLengthIndicator

    return (
      <StyledDiv className={cx(className, locator)} disabled={disabled} style={style}>
        {label && (
          <LabelContainer sublabel={sublabel}>
            {!isFunction(label) ? (
              <Body2Medium as="label" htmlFor={id}>
                {label}
                {required && '*'}
              </Body2Medium>
            ) : (
              <div id={ariaLabelId && ariaLabelId}>{label()}</div>
            )}
            {optionalMessage && <Caption ml="s3">{`(${optionalMessage})`}</Caption>}

            {labelRightChildren}
          </LabelContainer>
        )}
        {sublabel && <SubLabel>{sublabel}</SubLabel>}

        {children}

        {/* Information and error block. */}
        <InformationErrorBlock ai="ce" di="f" jc="sb" mt={this.shouldAddSpaceBelow() ? 's3' : '0'}>
          <div>
            <InformationMessageContainer
              hasError={!shouldDisplayErrorMessage}
              isVisible={shouldDisplayInformation}
            >
              <Caption ai="ce" di="f">
                {informationMessage}
              </Caption>
            </InformationMessageContainer>
            <ErrorMessageContainer isVisible={shouldDisplayErrorMessage}>
              {shouldDisplayErrorMessage && (
                <>
                  <NotifIcon error />
                  <NotifText error key="error">
                    {errorMessage}
                  </NotifText>
                </>
              )}
            </ErrorMessageContainer>
          </div>
          {shouldDisplayMaxLength && (
            <Div di="f" fg={1} jc="fe">
              <Caption ml="s3">
                {charNumber}/{maxLength}
              </Caption>
            </Div>
          )}
        </InformationErrorBlock>
      </StyledDiv>
    )
  }
}

export default LabelledWrapper
