import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const FlipDown = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M5.897 11c.813 0 1.474-.673 1.474-1.5V2c0-.827-.661-1.5-1.474-1.5H1.474C.661.5 0 1.173 0 2v7.5c0 .827.661 1.5 1.474 1.5h4.423zM1.474 2h4.423v7.5H1.474V2zm11.794 6.75c.813 0 1.474.674 1.474 1.5V14c0 .827-.661 1.5-1.474 1.5H2.948c-.813 0-1.474-.673-1.474-1.5v-1.5h1.474V14h10.32v-3.75H8.845v-1.5h4.423zm-.737-4.329C12.53 2 10.866 2 10.319 2h-.737V.5h.737c2.308 0 3.686 1.466 3.686 3.921v.268l.953-.969L16 4.78l-2.732 2.78-2.733-2.78 1.042-1.06.954.97v-.27z"/></g>
    </StyledIcon>
  )

export default styled(FlipDown)``
  