// @noSnapshot
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { deprecated, resetCSS } from '../../../helpers'


class TypoWrapper extends React.Component {
  render() {
    const { className, style, children } = this.props
    return (
      <h2 className={className} style={style}>
        {children}
      </h2>
    )
  }
}

const SubTitle = styled(TypoWrapper).attrs(resetCSS)`
  color: ${({ color, theme }) =>
    color ? theme.colors[color] || color : theme.components.typography.subTitle.text};
  font-family: ${({ theme }) => theme.fonts.subtitle.fontFamily};
  font-size: ${({ theme }) => theme.fonts.subtitle.fontSize};
  font-weight: ${({ theme }) => theme.fonts.subtitle.fontWeight};
  line-height: ${({ theme }) => theme.fonts.subtitle.lineHeight};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
`

export default deprecated(SubTitle, 'SubTitle', 'Please use Header4 instead.')
