import styled from 'styled-components'

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  // fix Safari issue when Controls are into Modal with scrollbar
  min-height: fit-content;
  position: relative;
  z-index: 3;

  ${({ theme }) => `
    padding: ${theme.sizes.s12} ${theme.sizes.s3};
    background-color: ${theme.components.datatable.controls.background};

    & > * {
      margin-right: ${theme.sizes.s4};
    }
  `};

  & > *:last-child {
    margin-right: 0px;
  }

  @media (max-width: ${({ theme }) => theme.responsive.sm.breakpoint}px) {
    align-items: baseline;
  }

  &.isScrolling {
    box-shadow: ${({ theme }) => theme.globals.shadows.shadow2};
  }
`

export default ControlsContainer
