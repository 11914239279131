import React, { useMemo } from 'react'
import { find, map, toString } from 'lodash'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Toggle from '../../Toggle'
import { usePage } from '../contexts'

// Safe to use index in the id since the list is immutable.
const itemsToToggleOptions = (items = [], dataTestId, value) =>
  map(items, (item, index) => ({
    'data-testid': `${dataTestId}-${item}`,
    id: item,
    label: toString(item),
    /** condition needs to support old changes when initialItemsPerPage isn't provided */
    isSelectedOnLoad: value ? item === value : index === 0,
  }))

const PaginationTogglePage = ({ 'data-testid': dataTestId, items = [], onChange, ...rest }) => {
  const { initialItemsPerPage } = usePage()
  const options = useMemo(
    () => itemsToToggleOptions(items, dataTestId, initialItemsPerPage),
    [items, initialItemsPerPage],
  )

  const onChangeItem = (optionId) => {
    /**
     * We want to have the component to be resilient to any changes in <Toggle>,
     * hence the value equality check (==).
     */
    const { label } = find(options, ({ id }) => id == optionId) || {}
    onChange?.(Number(label))
  }

  return (
    <Toggle
      data-testid={dataTestId}
      disableDeselect
      onChange={onChangeItem}
      options={options}
      {...rest}
    />
  )
}

PaginationTogglePage.defaultProps = {
  'data-testid': 'dataTestId-PaginationItemsPerPage-component',
}

PaginationTogglePage.propTypes = {
  /**
   * Array containing the list of items per page. By default, the first
   * item will be selected.
   */
  items: PropTypes.arrayOf(PropTypes.number).isRequired,

  /* The data-testid. */
  'data-testid': PropTypes.string,
  /**
   * Called when an item is selected.
   * (itemsPerPage: number) => void
   */
  onChange: PropTypes.func,
}

export default styled(PaginationTogglePage)``
