import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const MoveBlockUp = props => (
    <StyledIcon viewBox="0 0 10.58 12" {...props}>
      <g><path d="M5.88 7.3V2.25L7.23 3.6l.83-.83L5.29 0 2.52 2.77l.83.83L4.7 2.25V7.3h1.18zM0 9.65v1.18A1.18 1.18 0 0 0 1.18 12h8.23a1.18 1.18 0 0 0 1.18-1.18V9.65a1.18 1.18 0 0 0-1.18-1.18H1.18A1.18 1.18 0 0 0 0 9.65zm1.18 0h8.23v1.18H1.18z"/></g>
    </StyledIcon>
  )

export default styled(MoveBlockUp)``
  