import React from 'react'

const InboxReadySVG = () => (
  <svg
    width='42'
    height='46'
    viewBox='0 0 42 46'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.0158 45.5976L41.1566 35.5176V30.5088L21.0158 40.5888L5.35073 32.7488V27.6545L21.0158 35.4945L41.1566 25.4145V10.3026L21.0158 20.3826L5.35073 12.5426V7.49875L21.0158 15.3388L41.1566 5.25875V0.25L21.0158 10.33L0.875 0.25V15.3113L21.0158 25.3913L36.6808 17.5513V22.6457L21.0158 30.4857L0.875 20.4057V35.5176L21.0158 45.5976Z'
      fill='#25A878'
    />
  </svg>
)

const InboxReadySVGString = `
  <svg width="42" height="46" viewBox="0 0 42 46" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M21.0158 45.5976L41.1566 35.5176V30.5088L21.0158 40.5888L5.35073 32.7488V27.6545L21.0158 35.4945L41.1566 25.4145V10.3026L21.0158 20.3826L5.35073 12.5426V7.49875L21.0158 15.3388L41.1566 5.25875V0.25L21.0158 10.33L0.875 0.25V15.3113L21.0158 25.3913L36.6808 17.5513V22.6457L21.0158 30.4857L0.875 20.4057V35.5176L21.0158 45.5976Z" fill="#25A878"/>
  </svg>
`

export { InboxReadySVG, InboxReadySVGString }
