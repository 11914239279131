// Decode encoded hash, removing comma
export const getDomIdFromHash = (hash) => decodeURIComponent(hash.slice(1))

// Scroll smoothly to a dom node, minus the window fixed navbar height
export const scrollToNode = (node, offset = 0) =>
  setTimeout(() => {
    // Scroll certain amounts from current position
    window.scroll({
      top: node.getBoundingClientRect().top + window.scrollY - offset,
      left: 0,
      behavior: 'smooth',
    })
  }, 300)
