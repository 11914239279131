import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Google = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M5.604 9.165a.342.342 0 0 1-.342-.343V7.567c0-.189.153-.342.342-.342h4.615s-.003 1.14-.007 1.173A5.11 5.11 0 0 1 0 8.074C.02 5.256 2.395 2.946 5.212 3a5.091 5.091 0 0 1 3.412 1.4.343.343 0 0 1-.001.498l-.917.863a.344.344 0 0 1-.467.002A3.158 3.158 0 0 0 5.11 4.94a3.174 3.174 0 0 0-3.168 3.296 3.18 3.18 0 0 0 3.145 3.045A3.176 3.176 0 0 0 8.1 9.165H5.604zm8.844-1.826h1.226a.3.3 0 0 1 .3.3V8.67a.3.3 0 0 1-.3.3h-1.226v1.226a.3.3 0 0 1-.3.3h-1.032a.3.3 0 0 1-.3-.3V8.971h-1.227a.3.3 0 0 1-.3-.3V7.639a.3.3 0 0 1 .3-.3h1.226V6.113a.3.3 0 0 1 .3-.3h1.032a.3.3 0 0 1 .3.3v1.226z"/></g>
    </StyledIcon>
  )

export default styled(Google)``
  