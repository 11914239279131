import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const MultipleUsers = props => (
    <StyledIcon viewBox="0 0 12 10.91" {...props}>
      <g><path d="M10.57 9.3A1.91 1.91 0 1 0 8 9.3a1.86 1.86 0 0 0-1.43 1.6h1.07c0-.33.77-.74 1.64-.74s1.64.41 1.64.74H12a1.86 1.86 0 0 0-1.43-1.6zm-1.3-2.21a.82.82 0 1 1-.82.82.82.82 0 0 1 .82-.82zM4 9.3a1.91 1.91 0 1 0-2.59 0A1.86 1.86 0 0 0 0 10.91h1.09c0-.33.77-.74 1.64-.74s1.64.41 1.64.74h1.08A1.86 1.86 0 0 0 4 9.3zM2.73 7.09a.82.82 0 1 1-.82.82.82.82 0 0 1 .82-.82zm4.91-2.18h1.09A1.86 1.86 0 0 0 7.29 3.3a1.91 1.91 0 1 0-2.59 0 1.86 1.86 0 0 0-1.43 1.6h1.09c0-.33.77-.74 1.64-.74s1.64.42 1.64.75zM6 1.09a.82.82 0 1 1-.82.82.82.82 0 0 1 .82-.82z"/></g>
    </StyledIcon>
  )

export default styled(MultipleUsers)``
  