import styled, { css } from 'styled-components'
import { styleForTablet, styleForMobile, styleForTabletAndMobile } from '@utils'

export const DivCards = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: ${theme.sizes.s15};

    & > :last-child {
      padding-top: ${theme.sizes.s7};

      ${styleForMobile} {
        padding-top: ${theme.sizes.s6};
      }
    }

    ${styleForTabletAndMobile} {
      padding-bottom: 0;
    }

    ${styleForTablet} {
      padding-top: ${theme.sizes.s10};
    }

    ${styleForMobile} {
      padding-top: ${theme.sizes.s8};
    }
  `}
`
