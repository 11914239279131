import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Notification = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M6 0a6 6 0 1 0 6 6 6 6 0 0 0-6-6zm-.6 2.4h1.2v4.2H5.4zM6 9.15a.75.75 0 1 1 .75-.75.75.75 0 0 1-.75.75z"/></g>
    </StyledIcon>
  )

export default styled(Notification)``
  