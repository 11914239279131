import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

const PageContext = createContext(undefined)

const PageProvider = ({
  currentPage: currentPageFromParent,
  children,
  initialItemsPerPage,
  lastPage = 1,
  onChangePage,
  startingPage,
}) => {
  const [currentPage, setCurrentPage] = useState(startingPage || 1)

  useEffect(() => {
    if (lastPage < currentPage) {
      setCurrentPage(lastPage)
    }
  }, [currentPage, lastPage])

  useEffect(() => {
    if (currentPageFromParent !== undefined) {
      setCurrentPage(currentPageFromParent)
    }
  }, [currentPageFromParent])

  const onChangeCurrentPage = (newPage) => {
    if (currentPage !== newPage) {
      onChangePage?.(newPage)
    }
    setCurrentPage(newPage)
  }

  const goToFirstPage = () => {
    onChangeCurrentPage(1)
  }

  const goToPreviousPage = () => {
    onChangeCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)
  }

  const goToNextPage = () => {
    onChangeCurrentPage(currentPage < lastPage ? currentPage + 1 : currentPage)
  }

  const goToLastPage = () => {
    onChangeCurrentPage(lastPage)
  }

  return (
    <PageContext.Provider
      value={{
        currentPage,
        goToFirstPage,
        goToLastPage,
        goToPreviousPage,
        goToNextPage,
        initialItemsPerPage,
        lastPage,
        onChangePage,
        setCurrentPage: onChangeCurrentPage,
      }}
    >
      {children}
    </PageContext.Provider>
  )
}

const usePage = () => {
  const pageContext = useContext(PageContext)
  if (!pageContext) {
    throw new Error('You need to wrap your inner pagination-components inside <Pagination>.')
  }
  return pageContext
}

export { PageProvider, usePage }
