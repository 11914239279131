import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const BoderDotted = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M.5 1a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zM3 1a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm2.5 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zM8 1a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm2.5 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zM13 1a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm2.5 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zM.5 16a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zM3 16a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm2.5 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zM8 16a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm2.5 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm2.5 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm2.5 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zM0 3a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0zm0 2.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0zM0 8a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0zm0 2.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0zM0 13a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0zM15 3a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0zm0 2.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0zM15 8a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0zm0 2.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0zm0 2.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0z"/></g>
    </StyledIcon>
  )

export default styled(BoderDotted)``
  