import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const SendTest = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M5 6.4h4.7V8H5zm9.6-2.2L15.9 0l-4 1.4 2 .7zM5 3.9h6.2v1.6H5z"/><path d="M3.5 2.3h6.9V.7H1.9v5.7h1.6z"/><path d="M14.6 6.5L10 10.3H5.9L1.3 6.5c-.1-.1-.3-.1-.5-.1-.4 0-.8.3-.8.8v7.2c0 .9.7 1.6 1.6 1.6h12.7c.9 0 1.6-.7 1.6-1.6V7.2c0-.2-.1-.4-.2-.5-.3-.4-.8-.4-1.1-.2zm-13 7.8V8.9l3.5 2.9c.1.1.3.2.5.2h4.8c.2 0 .4-.1.5-.2l3.5-2.9v5.5H1.6z"/></g>
    </StyledIcon>
  )

export default styled(SendTest)``
  