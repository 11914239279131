import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Integration = props => (
    <StyledIcon viewBox="0 0 26 24" {...props}>
      <g><path d="M23.818 3H4.182A2.184 2.184 0 002 5.182v17.454c0 1.204.979 2.182 2.182 2.182h19.636A2.183 2.183 0 0026 22.636V5.182A2.183 2.183 0 0023.818 3zm0 4.364h-8.727V5.182h8.727v2.182zm-14.182 0V5.182h3.273v2.182H9.636zm2.182 2.181h12V15h-12V9.545zM7.455 5.182v2.182H4.182V5.182h3.273zM4.182 9.545h5.454v13.091H4.182V9.546zm7.636 13.091v-5.454h11.998l-.001 5.454H11.818z"/></g>
    </StyledIcon>
  )

export default styled(Integration)``
  