import React, { Component } from 'react'

/** Prints component deprecation message via console.warn() */
const deprecated = (InnerComponent, name, message = '') =>
  class extends Component{
    UNSAFE_componentWillMount() {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn(`${name || InnerComponent.displayName} is deprecated.`, message)
      }
    }

    render() {
      // Render the wrapped component with the same props
      return <InnerComponent {...this.props} />
    }
  }

export default deprecated
