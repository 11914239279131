import { css } from 'styled-components'
import { margins, paddings } from '../../helpers/injectedStyle/spaces'
import { sizes } from '../../helpers'

export const subOptionsPositionStyles = {
  bottom: 'top: 100%; width: 100%; margin-left: -16px;',
  left: 'top: 0; right: 100%; width: 100%; margin-top: -10px; ',
  right: 'top: 0; left: 100%; width: 100%; margin-top: -10px;',
  top: 'top: 0; transform: translateY(-100%); width: 100%; margin-left: -16px;',
}

export const optionsPanelStyle = ({ maxH, theme }) => css`
  flex-direction: column;
  box-sizing: border-box;
  z-index: 1;
  width: 200px;

  white-space: normal;

  background-color: ${theme.components.optionPanel.option.background};
  border-radius: ${theme.globals.borderRadius.borderRadius1};
  box-shadow: ${theme.globals.shadows.shadow2};
  overflow-y: ${maxH ? 'auto' : null};
  padding-bottom: ${theme.sizes.s3};
  padding-top: ${theme.sizes.s3};

  ${margins};
  ${paddings};
  ${sizes};
`
