import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Body2 } from '../../Typography'
import OptionBlock from './OptionBlock'

const OptionText = styled(Body2)`
  display: flex;
  flex-grow: 1;
  ${({ ellipsis }) =>
    ellipsis &&
    `
      display: inline;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  `};
  color: ${({ deletion, theme }) =>
    deletion
      ? theme.components.optionPanel.deletion.text
      : theme.components.optionPanel.option.text};

  ${OptionBlock}:hover & {
    color: ${({ deletion, theme }) =>
      deletion
        ? theme.components.optionPanel.deletion.textHover
        : theme.components.optionPanel.option.textHover};
  }
`

OptionText.defaultProps = {
  ellipsis: false,
}

OptionText.propTypes = {
  /** if the text is part of a deletion option */
  deletion: PropTypes.bool,
  /** Should the text be displayed with elipsis ? */
  ellipsis: PropTypes.bool,
}

export default OptionText
