import styled, { css } from 'styled-components'
import {
  styleForDesktop,
  styleForMobile,
  styleForTablet,
  styleForTabletAndMobile,
} from '@utils'

export const Background = styled.span`
  ${({
    backgroundPictureColor,
    onlyDesktop,
    onlyTablet = false,
    onlyMobile = false,
    theme,
  }) => css`
    align-items: center;
    background-color: transparent;
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: auto 0;
    overflow: hidden;
    position: absolute;
    width: 100%;

    & picture {
      align-items: center;
      display: flex;
      height: 100%;
      width: 100%;
    }

    & > div {
      width: 100%;
    }

    &,
    & * {
      z-index: 0;

      ${backgroundPictureColor &&
      `
      svg * {
        fill: ${theme.colors[backgroundPictureColor] || backgroundPictureColor}
        stroke: ${
          theme.colors[backgroundPictureColor] || backgroundPictureColor
        }
      }`}
    }

    img {
      position: absolute;
    }

    ${onlyTablet &&
    css`
      ${styleForDesktop} {
        display: none;
      }
      ${styleForMobile} {
        display: none;
      }
    `}

    ${onlyMobile &&
    css`
      ${styleForDesktop} {
        display: none;
      }
      ${styleForTablet} {
        display: none;
      }
    `}
    
    ${styleForTabletAndMobile} {
      ${onlyDesktop && `display: none;`}
    }
  `}
`
