import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const Padlock = props => (
    <StyledIcon viewBox="0 0 12 16" {...props}>
      <g>
        <path
          d="M6 0C3.8 0 2 1.8 2 4v1.5C.8 6.6 0 8.2 0 10c0 3.3 2.7 6 6 6s6-2.7 6-6c0-1.8-.8-3.4-2-4.5V4c0-2.2-1.8-4-4-4zm1 10.7V13H5v-2.3c-.6-.3-1-1-1-1.7 0-1.1.9-2 2-2s2 .9 2 2c0 .7-.4 1.4-1 1.7zm1-6.3C7.4 4.1 6.7 4 6 4s-1.4.1-2 .4V4c0-1.1.9-2 2-2s2 .9 2 2v.4z"
          fillRule="evenodd"
        />
      </g>
    </StyledIcon>
  )

export default styled(Padlock)``
