import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const MultipleFiles = props => (
    <StyledIcon viewBox="0 0 12 13.09" {...props}>
      <g><path d="M4.36 0H1.09A1.09 1.09 0 0 0 0 1.09v3.82A1.09 1.09 0 0 0 1.09 6h3.27a1.09 1.09 0 0 0 1.09-1.09V1.09A1.09 1.09 0 0 0 4.36 0zM1.09 4.91V1.09h2.18v1.09h1.09v2.73zM10.91 0H7.64a1.09 1.09 0 0 0-1.09 1.09v3.82A1.09 1.09 0 0 0 7.64 6h3.27A1.09 1.09 0 0 0 12 4.91V1.09A1.09 1.09 0 0 0 10.91 0zM7.64 4.91V1.09h2.18v1.09h1.09v2.73zM4.36 7.09H1.09A1.09 1.09 0 0 0 0 8.18V12a1.09 1.09 0 0 0 1.09 1.09h3.27A1.09 1.09 0 0 0 5.45 12V8.18a1.09 1.09 0 0 0-1.09-1.09zM1.09 12V8.18h2.18v1.09h1.09V12zm9.82-4.91H7.64a1.09 1.09 0 0 0-1.09 1.09V12a1.09 1.09 0 0 0 1.09 1.09h3.27A1.09 1.09 0 0 0 12 12V8.18a1.09 1.09 0 0 0-1.09-1.09zM7.64 12V8.18h2.18v1.09h1.09V12z"/></g>
    </StyledIcon>
  )

export default styled(MultipleFiles)``
  