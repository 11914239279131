import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Embed = props => (
    <StyledIcon viewBox="0 0 13.2 7.6" {...props}>
      <g><path d="M3 6.76a.6.6 0 0 1-.42-.17L.18 4.23a.6.6 0 0 1 0-.86L2.58 1a.6.6 0 0 1 .84.86l-2 1.93 2 1.93a.6.6 0 0 1-.42 1zm7.2 0a.6.6 0 0 1-.42-1l2-1.93-2-1.93a.6.6 0 0 1 .84-.9L13 3.37a.6.6 0 0 1 0 .86l-2.4 2.36a.6.6 0 0 1-.4.17zm-5.03.84h-.11a.3.3 0 0 1-.16-.39l2.85-7a.3.3 0 1 1 .55.2l-2.85 7a.3.3 0 0 1-.28.19z"/></g>
    </StyledIcon>
  )

export default styled(Embed)``
  