import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Enlarge = props => (
    <StyledIcon viewBox="0 0 11 10" {...props}>
      <g><path d="M1 1.11h1.5V0H1a1.06 1.06 0 0 0-1 1.11v1.67h1zM10 0H8.5v1.11H10v1.67h1V1.11A1.06 1.06 0 0 0 10 0zM1 7.22H0v1.67A1.06 1.06 0 0 0 1 10h1.5V8.89H1zm9 1.67H8.5V10H10a1.06 1.06 0 0 0 1-1.11V7.22h-1z"/></g>
    </StyledIcon>
  )

export default styled(Enlarge)``
  