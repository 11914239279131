import React, { useContext } from 'react'
import parse, { domToReact } from 'html-react-parser'
import { get } from 'lodash'
import { Div } from 'component-library/components'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Breakpoints from '@theme/Breakpoints'

import ImageDiv from './styles/ImageDiv'
import GlobalContext from '@components/Layout/GlobalContext'

// Calculating "sizes" attribute from maximal image size scenario, which is for now a section with text top.
// In this scenario, and on desktop the image spread over 10 columns of it's container.
const maxImageWidth =
  Math.ceil((Breakpoints.desktop.containerMaxWidth.slice(0, -2) * 10) / 12) +
  'px'

// Max size scenario on tablet and mobile is the container max content width (container width - lateral paddings)
const maxImageWidthTablet = `(max-width: ${Breakpoints.tablet.maxBreakpoint}px) `
const maxImageWidthMobile = `(max-width: ${Breakpoints.mobile.maxBreakpoint}px) `

// This image component provide an all-in-one image renderer, using lazy loading by default
// and providing the possibility to optimize the image weight regarding it's displayed width.
const Image = ({
  alt,
  ctfImagePropsUsed = false,
  decoding = 'auto',
  desktopMaxWidth = maxImageWidth,
  displayWidth,
  file,
  link,
  gatsbyImageData,
  lazyLoading = true,
  maxWidth = null,
  mobileMaxWidth = 'calc(100vw - 32px)',
  svg,
  tabletMaxWidth = 'calc(100vw - 48px)',
  ...props
}) => {
  // Return null if no data are provided, avoiding potential errors due to data missing
  if (!gatsbyImageData && !svg && !file) {
    return null
  }

  if (file && file.contentType === 'image/svg+xml') {
    // Inlined SVGs
    if (svg) {
      // Add title to svg
      if (alt !== 'undefined') {
        const title = React.createElement('title', {}, alt)
        const optionsParse = {
          replace: ({ attribs, children, name }) => {
            if (name === 'svg') {
              return (
                <svg {...attribs}>
                  {title}
                  {domToReact(children)}
                </svg>
              )
            }
          },
        }
        const svgParsed = parse(svg.content, optionsParse)
        return <Div {...props}>{svgParsed}</Div>
      } else {
        return (
          <Div dangerouslySetInnerHTML={{ __html: svg.content }} {...props} />
        )
      }
    }
    // SVGs that can/should not be inlined and images
    return <img src={file.url} alt={alt} {...props} decoding={decoding} />
  }

  // Generate sizes
  const sizes = `${maxWidth ? maxWidth + ',' : ''} ${
    maxImageWidthMobile + mobileMaxWidth
  },
    ${maxImageWidthTablet + tabletMaxWidth}, ${desktopMaxWidth}
  `
  const { canInjectThirdParties, isDevServer } = useContext(GlobalContext)
  // Using picture and source tags to allows use on WebP format on compatible browsers
  // First render is using a low quality version in order to improve LCP, then the best resolution are rendered after client render
  const lowQualitySrc = {
    png: gatsbyImageData?.images?.fallback?.[0]?.srcSet?.split(',')?.[0],
    webp: gatsbyImageData?.images?.sources?.[0]?.srcSet?.split(',')?.[0],
  }
  const highQualitySrc = {
    png: gatsbyImageData?.images?.fallback?.[0]?.srcSet,
    webp: gatsbyImageData?.images?.sources?.[0]?.srcSet,
  }
  // Lazy loaded image are not impacting LCP, eagerly loaded will use the highest quality once the client side hydration happen
  const isHighQualityImage =
    isDevServer || lazyLoading || typeof window !== 'undefined'

  const ImageBody = () => (
    <picture {...props}>
      <source
        // Native js will replace low quality srcSet in Layout component after blocking JS scripts for eagerly loaded img
        {...(!isHighQualityImage && {
          'data-img-onload': !isHighQualityImage && highQualitySrc.webp,
        })}
        srcSet={
          isHighQualityImage || canInjectThirdParties
            ? highQualitySrc.webp
            : lowQualitySrc.webp
        }
        sizes={
          ctfImagePropsUsed
            ? gatsbyImageData?.images?.sources?.[0]?.sizes
            : sizes
        }
        type={
          ctfImagePropsUsed
            ? gatsbyImageData?.images?.sources?.[0].type
            : 'image/webp'
        }
      />
      <source
        // Native js will replace low quality srcSet in Layout component after blocking JS scripts for eagerly loaded img
        {...(!isHighQualityImage && {
          'data-img-onload': !isHighQualityImage && highQualitySrc.png,
        })}
        srcSet={
          isHighQualityImage || canInjectThirdParties
            ? highQualitySrc.png
            : lowQualitySrc.png
        }
        sizes={
          ctfImagePropsUsed ? gatsbyImageData?.images?.fallback?.sizes : sizes
        }
        type={
          ctfImagePropsUsed
            ? gatsbyImageData?.images?.fallback?.type
            : 'image/png'
        }
      />
      <img
        src={
          ctfImagePropsUsed
            ? get(file, 'url')
            : get(gatsbyImageData, 'images.fallback.src')
        }
        type={ctfImagePropsUsed ? get(file, 'contentType') : 'image/png'}
        alt={alt}
        loading={lazyLoading ? 'lazy' : 'eager'}
        decoding={decoding}
      />
    </picture>
  )

  const aspectRatio =
    get(gatsbyImageData, 'width') / get(gatsbyImageData, 'height')

  // Non SVG images
  return (
    <ImageDiv
      displayWidth={displayWidth}
      ratioWidthHeight={aspectRatio}
      data-is-loaded={isHighQualityImage}
      as={link && 'a'}
      href={link}
    >
      <ImageBody />
    </ImageDiv>
  )
}

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  desktopMaxWidth: PropTypes.string,
  displayWidth: PropTypes.number,
  file: PropTypes.object.isRequired,
  gatsbyImageData: PropTypes.object,
  lazyLoading: PropTypes.bool,
  link: PropTypes.string,
  maxWidth: PropTypes.string,
  mobileMaxWidth: PropTypes.string,
  svg: PropTypes.object,
  tabletMaxWidth: PropTypes.string,
  ctfImagePropsUsed: PropTypes.bool,
}

export default styled(Image)``
