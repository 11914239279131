import styled from 'styled-components'
import { resetCSS } from 'component-library/helpers'
import Toggle from '../../Toggle'

const PaginationToggle = styled(Toggle).attrs(resetCSS)`
  margin-right: 0;
  ${({ theme }) => `
    @media (max-width: ${theme.responsive.sm.breakpoint}px) {
      margin-bottom: ${theme.sizes.s13};
    }
  `}
`

export default PaginationToggle
