import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { hexToRGB } from '../../../../helpers'
import { isNil, reduce } from 'lodash'
import { transformControls } from '../../helpers'
import genericTableStyle from '../../helpers/genericTableStyle'
import { useIsLoading, useIsSelectable, useTableContext } from '../../hooks'
import { CONTROLS_CLASS_SUFFIX } from '../../config'

import Cell from '../../components/Cell'
import FullWidth from '../../components/FullWidth'
import OverflowDiv from '../../components/OverflowDiv'

const StyledTable = styled.table.attrs(({ name }) => ({
  role: 'region',
  id: name,
  'aria-live': 'polite',
}))`
  ${genericTableStyle};

  border: ${({ theme, outerBorder }) =>
    outerBorder
      ? `${theme.globals.borderWidth.borderWidth1} solid ${theme.components.datatable.borderColor}`
      : 'none;'};

  border-collapse: ${({ outerBorder }) => (outerBorder ? 'collapse' : 'inherit')};

  ${Cell} {
    ${({ isSelectable }) =>
      isSelectable &&
      `
      &:nth-child(2) {
        border-left: 0px;
      };
    `};

    ${({ theme }) => `
      &:first-child {
        padding-left: ${theme.sizes.s5};
      };
      &:last-child {
        padding-right: ${theme.sizes.s5};
      };
    `};
  }

  ${({ theme }) => `
    tr.is-checked,
    tr.is-checked + ${FullWidth} {
      background-color: ${hexToRGB(theme.components.datatable.backgroundColorRowChecked, 0.1)};
    };
  `};
`

function Table({
  children,
  'data-testid': dataTestId,
  className,
  scrollRef = { current: null },
}) {
  const isLoading = useIsLoading()
  const isSelectable = useIsSelectable()
  const { name: tableName, outerBorder } = useTableContext()
  const tableRef = useRef(null)
  const [controlsHeight, setControlsHeight] = useState(0)
  const controlsElement = document.querySelectorAll(
    `.${tableName}${CONTROLS_CLASS_SUFFIX}`
  )

  const { current: currentScroll } = scrollRef

  useEffect(() => {
    setControlsHeight(reduce(controlsElement, (acc, value) => acc + value.offsetHeight, 0))
  }, [controlsElement])

  useEffect(() => {
    const { current } = tableRef
    const tableFixHead = () => {
      const { valueFromTop, valueFromTopAbs } = transformControls(
        current,
        controlsElement,
        controlsHeight,
        currentScroll,
      )

      current?.querySelectorAll('thead th').forEach(th => {
        if (!isLoading) {
          th.style.transform = `translateY(${valueFromTop < 0 ? valueFromTopAbs : 0}px)`
          if (valueFromTop < 0) {
            th.classList.add('isScrolling')
          } else {
            th.classList.remove('isScrolling')
          }
        } else {
          th.style.transform = 'none'
          th.classList.remove('isScrolling')
        }
      })
    }

    if (isNil(currentScroll) && document) {
      document.addEventListener('scroll', tableFixHead)
      return () => document.removeEventListener('scroll', tableFixHead)
    }

    currentScroll?.addEventListener('scroll', tableFixHead)
    return () => currentScroll?.removeEventListener('scroll', tableFixHead)
  }, [tableRef, isLoading, controlsHeight, currentScroll, currentScroll])

  return (
    <OverflowDiv className={className} ref={tableRef}>
      <StyledTable
        data-testid={dataTestId}
        isLoading={isLoading}
        isSelectable={isSelectable}
        name={tableName}
        outerBorder={outerBorder}
      >
        {children}
      </StyledTable>
    </OverflowDiv>
  )
}

const DocTable = Table
export { DocTable }
export default styled(Table)``
