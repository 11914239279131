import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const ServerSettings = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M5 9h-.5C2.23 9 1 8.29 1 8v-.7a7.93 7.93 0 0 0 3.5.7H5V7h-.5C2.23 7 1 6.29 1 6v-.7a7.93 7.93 0 0 0 3.5.7H5V5h-.5C2.23 5 1 4.29 1 4v-.7a7.93 7.93 0 0 0 3.5.7A7.93 7.93 0 0 0 8 3.3V4h1V2C9 .69 6.74 0 4.5 0S0 .69 0 2v6c0 1.31 2.26 2 4.5 2H5zm-.5-8C6.77 1 8 1.71 8 2S6.77 3 4.5 3 1 2.29 1 2s1.23-1 3.5-1z"/><path d="M12 9.5v-1h-.55a2.49 2.49 0 0 0-.37-.88l.39-.39-.71-.71-.39.39a2.46 2.46 0 0 0-.88-.36V6h-1v.55a2.48 2.48 0 0 0-.88.37l-.39-.39-.71.71.39.39a2.48 2.48 0 0 0-.37.88H6v1h.55a2.5 2.5 0 0 0 .37.88l-.39.39.71.71.39-.39a2.47 2.47 0 0 0 .88.37V12h1v-.55a2.49 2.49 0 0 0 .88-.37l.39.39.71-.71-.39-.39a2.48 2.48 0 0 0 .37-.88zM9 10a1 1 0 1 1 1-1 1 1 0 0 1-1 1z"/></g>
    </StyledIcon>
  )

export default styled(ServerSettings)``
  