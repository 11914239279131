import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const FullWidth = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M12.384 5l-1.086 1.051 1.761 1.705H2.941l1.761-1.705L3.616 5 0 8.5 3.616 12l1.086-1.051-1.761-1.705h10.118l-1.761 1.705L12.384 12 16 8.5z"/></g>
    </StyledIcon>
  )

export default styled(FullWidth)``
  