import styled, { css, keyframes } from 'styled-components'
import {
  borders,
  margins,
  paddings,
  resetCSS,
  sizes,
} from 'component-library/helpers'

const opacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const StyledSkeleton = styled.div.attrs(resetCSS)`
  ${({ theme }) => css`
    background-color: ${theme.components.skeleton.backgroundStart};
    position: relative;

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: ${theme.components.skeleton.backgroundEnd};
      opacity: 0;
      animation: ${opacityAnimation} 1s ease-in-out infinite;
      ${borders};
    }
  `}

  ${sizes}
  ${margins}
  ${paddings}
  ${borders}
}
`

export default StyledSkeleton
