import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Indeterminate = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M0 6h16v4H0z"/></g>
    </StyledIcon>
  )

export default styled(Indeterminate)``
  