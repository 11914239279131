import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Search = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M15.645 13.542l-3.828-3.829c.596-.98.91-2.107.907-3.255A6.67 6.67 0 0 0 6.27 0 6.268 6.268 0 0 0 0 6.27a6.67 6.67 0 0 0 6.456 6.459 6.24 6.24 0 0 0 3.16-.854l3.855 3.856a.96.96 0 0 0 1.334 0l.96-.961c.4-.374.24-.854-.12-1.228zM1.921 6.271a4.337 4.337 0 0 1 4.348-4.35 4.682 4.682 0 0 1 4.535 4.537 4.337 4.337 0 0 1-4.348 4.35A4.682 4.682 0 0 1 1.92 6.27z"/></g>
    </StyledIcon>
  )

export default styled(Search)``
  