import React from 'react'
import styled from 'styled-components'
import StyledIcon from '../StyledIcon'

const StarOutline = props => (
    <StyledIcon viewBox="0 0 24 24" {...props}>
      <g><path d="M12 19.572L4.584 23.5 6 15.178 0 9.284 8.292 8.07 12 .5l3.708 7.57L24 9.284l-6 5.894 1.416 8.322L12 19.572zm-3.423-1.64L12 16.118l3.423 1.814-.652-3.835 2.716-2.668-3.759-.55L12 7.348l-1.728 3.53-3.76.55 2.717 2.668-.652 3.835z"  fillRule="evenodd"/></g>
    </StyledIcon>
  )

export default styled(StarOutline)``
