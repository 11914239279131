import React from 'react'
import { range } from 'lodash'
import StyledSkeleton from '../styles/StyledSkeleton'
import WrapperParagraph from '../styles/WrapperParagraph'

function ParagraphSkeleton({ lines = 1, width, ...rest }) {
  if (lines < 1) {
    // eslint-disable-next-line no-console
    console.warn('Warning: ParagraphSkeleton should always have at least one line')
  }

  const remainingLines = range(0, lines - 1)

  return (
    <WrapperParagraph {...rest} lines={lines} width={width}>
      <StyledSkeleton h="s12" />
      {remainingLines.map(line => (
        <StyledSkeleton h="s12" key={line} mt="s12" />
      ))}
    </WrapperParagraph>
  )
}

export default ParagraphSkeleton
