import styled from 'styled-components'

import { Body2, Body2Medium } from '../../Typography'

const LabelsContainer = styled.div`
  display: flex;

  ${Body2}, ${Body2Medium} {
    display: flex;
    flex: auto;
    justify-content: center;
    cursor: pointer;
  }
`

export default LabelsContainer
