import { css } from 'styled-components'

// Flex gap is not supported in Safari. This helper is meant to reproduce the gap css property in a flex element.
// We consider that the default case is using one line, 99 being a great number to avoid applying multiple line style.
export const flexGapHelper = (rowLength = 99, gap = 's4') => css`
  & > *:not(:nth-child(-n + ${rowLength})) {
    margin-top: ${({ theme }) => theme.sizes[gap] || gap};
  }
  & > *:not(:nth-child(${rowLength}n + 1)) {
    margin-left: ${({ theme }) => theme.sizes[gap] || gap};
  }
`
