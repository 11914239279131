import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Body2 } from '../../Typography'

const TextAreaStyled = styled(Body2.withComponent('textarea'))`
  box-sizing: border-box;
  flex-grow: 1;
  min-height: ${({ minH }) => minH};
  max-height: ${({ maxH }) => maxH};
  overflow: ${({ noOverflow }) => (noOverflow ? 'hidden' : null)};
  padding: ${({ theme }) => `${theme.sizes.s3} ${theme.sizes.s4}`};
  color: ${({ theme }) => theme.components.textArea.text};
  background-color: ${({ theme }) => theme.components.textArea.background};
  resize: none;
  white-space: ${({ lineNumbers }) => (lineNumbers ? 'pre' : 'pre-wrap')};
  word-wrap: break-word;
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.components.textArea.placeholder};
  }

  &:disabled {
    background: inherit;
    cursor: not-allowed;
  }
`

TextAreaStyled.propTypes = {
  // Indicate that the overflow should be hidden.
  noOverflow: PropTypes.bool,
}

export default TextAreaStyled
