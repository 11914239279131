import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Filter = props => (
    <StyledIcon viewBox="0 0 18 12" {...props}>
      <g><path d="M7,12 L11,12 L11,10 L7,10 L7,12 Z M0,0 L0,2 L18,2 L18,0 L0,0 Z M3,7 L15,7 L15,5 L3,5 L3,7 Z" id="Shape" /></g>
    </StyledIcon>
  )

export default styled(Filter)``
