import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const PreviewNewTab = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M14.667 0h-12C1.93 0 1.333.598 1.333 1.333v9.334h1.334V4h11.999l-.001 8H9.333v1.333h5.334c.735 0 1.333-.598 1.333-1.333V1.333C16 .598 15.402 0 14.667 0zM8 1.333v1.334H6V1.333h2zm-5.333 0h2v1.334h-2V1.333zm6.666 1.334V1.333h5.334v1.334H9.333zm-2 9.333c.368 0 .667.299.667.667v2.666a.667.667 0 0 1-.667.667H.667A.667.667 0 0 1 0 15.333v-2.666C0 12.299.299 12 .667 12h6.666zm-.666 2.667v-1.334H1.333v1.334h5.334zm1.138-3.528l-.943-.943 3-3L8.667 6H12v3.333L10.805 8.14l-3 3z"/></g>
    </StyledIcon>
  )

export default styled(PreviewNewTab)``
  