import React from 'react'
import { noop } from 'lodash'
import Button from '../../../Button'
import { useDropdown, useRegisterComponent } from '../../hooks'
import Caret from './Caret'


function MenuButton({ children, onFocus = noop, ...props }) {
  const { disabled, setIsOpen } = useDropdown()
  const component = useRegisterComponent()

  function toggle(e) {
    e.preventDefault()
    e.stopPropagation()

    setIsOpen(prevState => !prevState)
    onFocus()
  }

  return (
    <Button _ref={component} disabled={disabled} mode="dropdown" onClick={toggle} {...props}>
      {children}
      <Caret />
    </Button>
  )
}

export default MenuButton
