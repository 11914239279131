import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const HighlightColor = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M0 16l3.209-3.301 2.395 2.465-.813.836H0zm3.262-4.959l6.758-6.954 3.952 4.067-6.758 6.954-3.952-4.067zm12.27-4.491l-.712.731-3.953-4.067.712-.733a1.565 1.565 0 0 1 2.259 0l1.694 1.743a1.68 1.68 0 0 1 0 2.326z"/></g>
    </StyledIcon>
  )

export default styled(HighlightColor)``
  