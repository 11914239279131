import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const EmailTime = props => (
    <StyledIcon viewBox="0 0 12 12" {...props}>
      <g><path d="M7 8v3H1V7.47l2.71 1.94a.5.5 0 0 0 .58 0L6 8.19V7h-.06L4 8.39 2.06 7H4V6H1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V8zm1.5-1A3.5 3.5 0 1 1 12 3.5 3.5 3.5 0 0 1 8.5 7zm0-6A2.5 2.5 0 1 0 11 3.5 2.5 2.5 0 0 0 8.5 1z"/><path d="M10 4H8V1.5h1V3h1v1z"/></g>
    </StyledIcon>
  )

export default styled(EmailTime)``
  