import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const Linkedin = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M16 9.81V16h-3.43v-5.776c0-1.45-.495-2.441-1.737-2.441-.947 0-1.511.667-1.76 1.313-.09.23-.113.552-.113.875V16H5.53s.046-9.782 0-10.796h3.43v1.53l-.023.035h.023v-.035c.455-.734 1.27-1.783 3.09-1.783C14.308 4.95 16 6.494 16 9.81zM1.941 0C.768 0 0 .806 0 1.865 0 2.9.745 3.73 1.896 3.73h.023c1.196 0 1.94-.83 1.94-1.866C3.836.805 3.115 0 1.94 0zM.204 16h3.429V5.204H.203V16z"/></g>
    </StyledIcon>
  )

export default styled(Linkedin)``
  