import React from 'react'
import styled from 'styled-components'
import StyledIcon from './StyledIcon'

const DividerDouble = props => (
    <StyledIcon viewBox="0 0 16 16" {...props}>
      <g><path d="M0 6.5h16v1H0v-1zm0 2h16v1H0v-1z"/></g>
    </StyledIcon>
  )

export default styled(DividerDouble)``
  