// @ts-nocheck TODO: Remove // @noSnapshot

import React from 'react';
import { isEqual } from 'lodash';

const withOptimization = (WrappedComponent) => {
  class OptimizationComponent extends React.Component {
    static defaultProps = {
      optimize: false,
    };

    state = {
      finalOptions: [],
    };

    componentDidMount() {
      const { optimize } = this.props;
      if (optimize) {
        this.loadOptionsDynamically();
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      const { options } = nextProps;

      if (!isEqual(options, this.props.options)) {
        this.loadOptionsDynamically();
      }
    }

    componentWillUnmount() {
      clearTimeout(this._loadDynamicTimeout);
    }

    loadOptionsDynamically = () => {
      const { options } = this.props;
      const { finalOptions } = this.state;

      const numberItemLoaded = 3;
      this._loadDynamicTimeout = setTimeout(() => {
        const hasMore = finalOptions.length + 1 < options.length;
        this.setState((prev, props) => ({
          finalOptions: props.options.slice(0, prev.finalOptions.length + numberItemLoaded),
        }));
        if (hasMore) {
          this.loadOptionsDynamically();
        }
      }, 0);
    };

    render() {
      const { forwardedRef, options, optimize, ...rest } = this.props;
      const { finalOptions } = this.state;

      if (optimize) {
        return <WrappedComponent options={finalOptions} ref={forwardedRef} {...rest} />;
      }
      return <WrappedComponent options={options} ref={forwardedRef} {...rest} />;
    }
  }
  
  OptimizationComponent.displayName = `withOptimization(${getDisplayName(WrappedComponent)})`;

  return React.forwardRef((props, ref) => <OptimizationComponent {...props} forwardedRef={ref} />);
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

withOptimization.defaultProps = {
  optimize: false,
};

export default withOptimization;
